import React from 'react';

import { useStore } from 'effector-react';

import { modalApi } from '../../effector/modals';
import { Strategies$ } from '../../effector/strategies';
import { Strategy } from '../../types/strategy';
import { Button, ButtonType } from '../UIKit/Button';

export const RemoveUserInfo: React.FC<{ id: number; userName: string }> = ({ id, userName }) => {
  const strategiesStore: Strategy[] = useStore(Strategies$);

  const strategies = strategiesStore?.filter((s) => s.managerId === id).map((s) => <li key={s.id}>{s.name}</li>);

  return (
    <div className="strategy-change">
      <div className="strategy-change__description">
        <div className="form__group">
          Пользователь {userName} не может быть удален, так как является автором одной или нескольких стратегий. Перед
          удалением пользователя необходимо изменить авторов указанных стратегий или удалить стратегии:
        </div>
        <ul>{strategies}</ul>
      </div>
      <div className="strategy-change__button-wrapper">
        <Button
          className="button__primary button__large"
          onClick={() => modalApi.hide('')}
          disabled={false}
          type={ButtonType.button}
        >
          Закрыть
        </Button>
      </div>
    </div>
  );
};
