/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { Modals$, TModal$State } from '../../effector/modals';
import { positionsApi } from '../../effector/positions';
import { Position } from '../../types/position';
import { TextArea } from '../FormControls/TextArea';
import { Button, ButtonType } from '../UIKit/Button';

const validationSchema = Yup.object().shape({
  description: Yup.string(),
});

const initialValues = {
  description: '',
};

type FormFields = {
  description: string;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ loading, error }) => (
  <Form className="form" noValidate>
    <div className="form__group">
      <Field name="description" rows={5} placeholder="Комментарий к сигналу" component={TextArea} />
    </div>

    {error && <p className="error-message">{error}</p>}
    <Button className="button__primary button__large button__wide" disabled={loading} type={ButtonType.submit}>
      Закрыть
    </Button>
  </Form>
);

export const RemovePositionForm: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const modalsState = useStore<TModal$State<{ strategyId: string; position: Position }>>(Modals$);

  const strategyId = modalsState.data?.strategyId;
  const position = modalsState.data?.position;

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    }
  };

  const onSubmit = (values: FormFields) => {
    const params = {
      strategyId,
      securityKey: position?.securityKey,
      comment: values.description,
    };

    setLoading(true);
    positionsApi
      .close(params)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const params = [
    {
      text: 'Время открытия',
      value: moment(position?.openTime).format('DD.MM.YYYY hh:mm'),
    },
    {
      text: 'Код инструмента',
      value: position?.symbol,
    },
    {
      text: 'Код класса',
      value: position?.classCode,
    },
    {
      text: 'Вес',
      value: position?.weight,
    },
  ];

  return (
    <div className="remove-position">
      <div className="remove-position__description">
        <table className="remove-position__table">
          <tbody>
            {params.map((item, index) => (
              <tr key={index}>
                <td>{item.text}</td>
                <td className="remove-position__value">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {(props) =>
          React.createElement(FormTemplate, {
            ...props,
            error,
            loading,
          })
        }
      </Formik>
    </div>
  );
};
