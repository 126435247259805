/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useState } from 'react';

import cn from 'classnames';
import { Collapse } from 'react-collapse';

import { StrategySummary } from '../../../types/strategySummary';
import { Option } from '../index';

type Props = {
  strategy: StrategySummary;
  options: Option[];
};

export const StrategyParams: React.FC<Props> = ({ strategy, options }) => {
  const [isOpen, setIsOpened] = useState(false);
  const onTopClick = useCallback(() => setIsOpened(!isOpen), [isOpen]);

  return (
    <div
      className={cn('strategies-params__item', {
        'strategies-params__item-active': isOpen,
      })}
    >
      <div
        className={cn('strategies-params__item-top', {
          'strategies-params__item-top-active': isOpen,
        })}
        onClick={onTopClick}
      >
        <div className="strategies-params__item-name">
          <span
            className={cn({
              'strategies-params__item-on': !strategy.isRestricted,
              'strategies-params__item-off': strategy.isRestricted,
            })}
          />
          <span>{strategy.name}</span>
        </div>
        <div className="strategies-params__item-icon">{isOpen ? '-' : '+'}</div>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="strategies-params__item-content">
          {options.map((option, ind) => (
            <div className="strategies-params__details" key={ind}>
              <span>{option.name}</span>:{' '}
              <span>
                {
                  // @ts-ignore
                  option.formatter
                    ? option.formatter(strategy, option.accessor)
                    : // @ts-ignore
                      strategy[option.accessor]
                }
              </span>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};
