/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';

import { useStore } from 'effector-react';
import moment from 'moment';
import { Tooltip } from 'react-tippy';

import { PageParams, TableGrid } from '../../../../components/TableGrid';
import { ClientDetails$ } from '../../../../effector/clientDetails';

export const SignalHistory: React.FC<{ forceReload: boolean; options: (arg: PageParams) => void }> = ({
  forceReload,
  options,
}) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Дата и время',
        accessor: 'dateTime',
        className: 'table__column-details',
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original: { dateTime },
            },
          },
        }) => {
          const timeFormated = moment(dateTime).format('DD.MM.YYYY HH:mm');

          return <span>{timeFormated}</span>;
        },
      },
      {
        Header: ' Клиент',
        accessor: 'client',
        className: 'table__column-details',
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original: { client },
            },
          },
        }) => {
          return <span dangerouslySetInnerHTML={{ __html: client }} />;
        },
      },
      {
        Header: 'Сигналы',
        accessor: 'signals',
        className: 'table__column-details',
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original: { signals, tooltip },
            },
          },
        }) => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // tslint:disable-next-line: jsx-wrap-multiline
            <Tooltip
              theme="light"
              html={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
              arrow={true}
              position="top"
              trigger="mouseenter"
            >
              <span dangerouslySetInnerHTML={{ __html: signals }} />
            </Tooltip>
          );
        },
      },
      {
        Header: 'Сообщения',
        accessor: 'messages',
        className: 'table__column-details',
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original: { signals, messages, tooltip },
            },
          },
        }) => {
          return signals ? (
            messages
          ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // tslint:disable-next-line: jsx-wrap-multiline
            <Tooltip
              theme="light"
              html={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
              arrow={true}
              position="top"
              trigger="mouseenter"
            >
              <span dangerouslySetInnerHTML={{ __html: messages }} />
            </Tooltip>
          );
        },
      },
      {
        Header: 'Выполнение',
        accessor: 'executions',
        className: 'table__column-details',
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original: { executions },
            },
          },
        }) => {
          return <span dangerouslySetInnerHTML={{ __html: executions }} />;
        },
      },
    ];
  }, []);

  const { history } = useStore(ClientDetails$);

  return (
    <div className="form__group">
      <TableGrid
        columns={columns}
        data={history}
        sortField="datetime"
        forceReload={forceReload}
        isRecursiveFetch={false}
        pageSize={100000}
        onPageChange={options}
      />
    </div>
  );
};
