/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { split } from '../../api';
import { ERRORS_TEXTS } from '../../const/validation';
import { modalApi } from '../../effector/modals';
import { searchApi } from '../../effector/search';
import { Input } from '../FormControls/Input';
import { Search } from '../Search';
import { Button, ButtonType } from '../UIKit/Button';

const validationSchema = Yup.object().shape({
  coefficient: Yup.number()
    .required(ERRORS_TEXTS.required)
    .positive(ERRORS_TEXTS.number)
    .typeError(ERRORS_TEXTS.number),
});

const initialValues = {
  coefficient: '',
};

type FormFields = {
  coefficient: number;
};

type FormProps = {
  strategyId?: string;
  error: string;
  isDisabled: boolean;
  onSearchPosition: (value: string) => void;
  onSearchNewPosition: (value: string) => void;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ error, isDisabled, errors, onSearchPosition, onSearchNewPosition }) => {
  const disable = Object.keys(errors).length > 0;

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <Search onSearchClick={onSearchPosition} />
      </div>

      <div className="form__group">
        <Search onSearchClick={onSearchNewPosition} />
      </div>

      <div className="form__group">
        <Field type="text" name="coefficient" placeholder="Коэффициент" component={Input} />
      </div>

      {error && <p className="error-message">{error}</p>}
      <div className="remove-delayed-signal__button-wrapper">
        <Button className="button__primary button__large" disabled={disable || isDisabled} type={ButtonType.submit}>
          Заменить
        </Button>
      </div>
    </Form>
  );
};

export const Split: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [position, setPosition] = useState<string>('');
  const [newPosition, setNewPosition] = useState<string>('');
  const [error, setError] = useState<string>('');

  const onSearchPositionClick = (securityKey: string) => {
    setPosition(securityKey);
    // @ts-ignore
    searchApi.clearSuggestion();
  };

  const onSearchNewPositionClick = (securityKey: string) => {
    setNewPosition(securityKey);
    // @ts-ignore
    searchApi.clearSuggestion();
  };
  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      // @ts-ignore
      modalApi.hide('');
    }
  };

  const onSubmit = (values: FormFields) => {
    setLoading(true);
    const [symbol, classCode, board] = newPosition.split(' ');

    const params = {
      strategyId: '00000000-0000-0000-0000-000000000000',
      symbol,
      classCode,
      board,
      weight: Number(values.coefficient),
      securityKey2: position,
    };

    split(params)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="change-position">
      <Formik
        // @ts-ignore
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) =>
          React.createElement(FormTemplate, {
            ...props,
            onSearchPosition: onSearchPositionClick,
            onSearchNewPosition: onSearchNewPositionClick,
            isDisabled: loading || !newPosition || !position,
            error,
          })
        }
      </Formik>
    </div>
  );
};
