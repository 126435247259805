/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getUser, login, logout, changePassword, checkLoginSms } from '../api';
import { UserChangePassword, UserLogin, LoginSmsCode } from '../api/types/user';
import { User } from '../types/user';
import { withPersist } from '../utils/effectorPersist';
import { Nullable } from '../utils/types';

export type TUser$State = Nullable<User>;

export const User$ = withPersist<TUser$State>(createStore<TUser$State>(null));

//@ts-ignore
const { set, remove } = createApi(User$, {
  // @ts-ignore
  set: (state, { result }) => result?.result,
  remove: () => null,
});

export const userApi = {
  get: createEffect().use(getUser),
  login: createEffect<UserLogin, {}, {}>().use(login),
  checkLoginSms: createEffect<LoginSmsCode, {}, {}>().use(checkLoginSms),
  logout: createEffect().use(logout),
  changePassword: createEffect<UserChangePassword, {}, {}>().use(changePassword),
};

forward({
  from: userApi.get.done,
  to: set,
});

forward({
  from: userApi.login.done,
  to: set,
});

forward({
  from: userApi.checkLoginSms.done,
  to: set,
});

forward({
  from: userApi.logout.done,
  to: remove,
});

forward({
  from: userApi.changePassword.done,
  to: set,
});

window?.addEventListener('logout', () => {
  userApi.logout('');
});
