export enum DealType {
  Standard = 'standard',
  Coupon = 'coupon',
  Dividend = 'dividend',
  Varmargin = 'varmargin',
  Close = 'close',
  Replace = 'replace',
}

export type Deal = {
  symbol: string;
  name: string;
  comment: string;
  weight: number;
  price: number;
  time: string;
  type: DealType;
  realizedPL?: number;
  execPrice?: number;
  isInProcess: boolean;
  donePrt?: number;
  nExecPrice?: number;
};
