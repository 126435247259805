import React, { useEffect, useMemo, useState } from 'react';

type Props = {
  endDate: Date;
  config?: {
    hours?: boolean;
    minutes?: boolean;
    seconds?: boolean;
    optionalHours?: boolean;
  };
  timeFinishCallback?: () => void;
};

export const Timer: React.FC<Props> = ({
  endDate,
  config = {
    hours: false,
    minutes: true,
    seconds: true,
    optionalHours: false,
  },
  timeFinishCallback,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  let timer = -1;

  const diff = useMemo(() => +new Date(endDate) - +currentDate, [endDate, currentDate]);

  const time = useMemo(() => {
    const sec_num = diff / 1000;

    let hours: number | string = Math.floor(sec_num / 3600);
    let minutes: number | string = Math.floor((sec_num - hours * 3600) / 60);
    let seconds: number | string = Math.floor(sec_num - hours * 3600 - minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    const values = [];
    if (config.hours || (config.optionalHours && Number(hours) > 0)) {
      values.push(hours);
    }
    if (config.minutes) {
      values.push(minutes);
    }
    if (config.seconds) {
      values.push(seconds);
    }
    return values.join(':');
  }, [config, diff]);

  useEffect(() => {
    setCurrentDate(new Date());
    timer = window.setInterval(() => {
      if (diff < 1000) {
        timeFinishCallback?.();
        return;
      }
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, [timeFinishCallback, diff]);

  return <span className="timer">{time}</span>;
};
