/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, Fragment, useState, useCallback } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { DatePicker } from '../../../../components/FormControls/DatePicker';
import { Select } from '../../../../components/FormControls/Select';
import { Button, ButtonType } from '../../../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../../../const/validation';
import { Errors$, errorsApi } from '../../../../effector/errors';
import { StrategyDictionary$ } from '../../../../effector/strategyDictionary';
import { Error } from '../../../../types/error';
import { Option } from '../../../../types/option';

import 'moment/locale/ru';

const periods = [
  {
    id: 0,
    name: 'По неделям',
  },
  {
    id: 1,
    name: 'По месяцам',
  },
];

const defaultStrategy: Option = {
  // @ts-ignore
  id: null,
  name: 'Все',
};

const validationSchema = Yup.object().shape({
  strategy: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
  }),
  period: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
  }),
  startDate: Yup.string().required(ERRORS_TEXTS.required),
  endDate: Yup.string().required(ERRORS_TEXTS.required),
});

type Filters = {
  strategyId: string | null;
  from: string;
  to: string;
  errorsStatMode: number;
};

type FormFields = {
  strategy: Option;
  period: Option;
  startDate: Date;
  endDate: Date;
};

type FormProps = {
  loadData: (filters: Filters) => void;
  setPeriod: (period: number) => void;
} & FormikProps<FormFields>;

const initialDate = moment('2020.08.10');
const initialValues = {
  strategy: defaultStrategy,
  period: periods[0],
  startDate: initialDate.toDate(),
  endDate: moment().toDate(),
};

const FormTemplate: React.FC<FormProps> = ({ values, resetForm, loadData, setPeriod }) => {
  const strategies = useStore(StrategyDictionary$);

  useEffect(() => {
    const data: Filters = {
      strategyId: values.strategy.id,
      from: moment(values.startDate).format(DATE_FORMAT),
      to: moment(values.endDate).format(DATE_FORMAT),
      errorsStatMode: Number(values.period.id),
    };

    loadData(data);
  }, [values.startDate, values.endDate, values.strategy, values.period, loadData]);

  useEffect(() => {
    setPeriod(Number(values.period.id));
  }, [values.period, setPeriod]);

  return (
    <Form className="form" noValidate>
      <div className="form-control-group">
        {strategies && (
          <div className="form-control-group__item">
            <Field
              name="strategy"
              component={Select}
              placeholder="Стратегии"
              options={[defaultStrategy, ...strategies]}
            />
          </div>
        )}
        {periods && (
          <div className="form-control-group__item">
            <Field name="period" component={Select} placeholder="Период" options={periods} />
          </div>
        )}
      </div>
      <div className="form-control-group">
        <div className="form-control-group__item">
          <Field
            name="startDate"
            component={DatePicker}
            placeholder="Дата начала"
            dateFormat="dd.MM.yyyy"
            selectsStart
            startDate={values.startDate}
            endDate={values.endDate}
          />
        </div>
        <div className="form-control-group__item">
          <Field
            name="endDate"
            component={DatePicker}
            placeholder="Дата окончания"
            dateFormat="dd.MM.yyyy"
            selectsEnd
            startDate={values.endDate}
            endDate={values.endDate}
            minDate={values.startDate}
            maxDate={moment().toDate()}
          />
        </div>
      </div>
      <div className="table-filter__actions">
        <Button className="button__primary button__large" onClick={() => resetForm()} type={ButtonType.reset}>
          Сбросить
        </Button>
      </div>
    </Form>
  );
};

const DATE_FORMAT = 'YYYY-MM-DD';
const MONTH_FORMAT = 'MMMM';
const WEEK_FORMAT = 'DD.MM';

export const AllErrors: React.FC = ({}) => {
  const [period, setPeriod] = useState<number>(periods[0].id);
  const errorsStore = useStore(Errors$);

  const loadData = useCallback((filters: Filters) => {
    errorsApi.get(filters);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = () => {};

  const dateFormatted = useCallback(
    (date: string) => {
      const dateMoment = moment(date);
      let datePeriod = '';

      if (period === periods[0].id) {
        datePeriod = `${dateMoment.startOf('week').format(WEEK_FORMAT)} - ${dateMoment
          .endOf('week')
          .format(WEEK_FORMAT)}`;
      } else {
        datePeriod = dateMoment.format(MONTH_FORMAT);
      }

      return datePeriod;
    },
    [period],
  );

  const groupErrors = useMemo(() => {
    const group: Record<string, Error[]> = {};

    errorsStore.all.errorStatsRows.forEach((item) => {
      if (!group[item.source]) {
        group[item.source] = [item];
      } else {
        group[item.source].push(item);
      }
    });

    return group;
  }, [errorsStore.all.errorStatsRows]);

  return (
    <Fragment>
      <div className="table-filter">
        <div className="table-filter__title">Фильтры</div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {(props) =>
            React.createElement(
              FormTemplate,
              // @ts-ignore
              {
                ...props,
                setPeriod,
                loadData,
              },
            )
          }
        </Formik>
      </div>
      <div className="statistic-errors">
        <table className="statistic-errors__table table">
          <thead>
            <tr className="table__tr">
              <th className="statistic-errors__table-th  table__th"></th>
              <th className="statistic-errors__table-th table__th">Ошибки</th>
              {errorsStore.all.dates.map((item) => (
                <th key={item} className="statistic-errors__table-th table__th">
                  {dateFormatted(item)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(groupErrors).map((errors) =>
              errors.map((rowError, i) => (
                <tr key={rowError.error} className="table__tr">
                  {i === 0 && (
                    <td
                      rowSpan={groupErrors[rowError.source].length}
                      className="statistic-errors__table-td statistic-errors__table-td_source"
                    >
                      {rowError.source}
                    </td>
                  )}
                  <td className="statistic-errors__table-td">{rowError.error}</td>
                  {rowError.occurrences.map((occurrence, index) => (
                    <td key={index} className="statistic-errors__table-td statistic-errors__table-td_center">
                      {occurrence}
                    </td>
                  ))}
                </tr>
              )),
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
