/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getAppConfig } from '../api';
import { Config } from '../types/config';
import { Nullable } from '../utils/types';

type TConfig$State = Nullable<Config>;

const initialState: TConfig$State = null;

export const Config$ = createStore<TConfig$State>(initialState);

const { set } = createApi(Config$, {
  // @ts-ignore
  set: (state, { result }) => result.result,
});

export const configApi = {
  get: createEffect().use(getAppConfig),
};

forward({
  from: configApi.get.done,
  to: set,
});
