/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getDeals, getSandboxDeals } from '../api';
import { Deal as DealParams } from '../api/types/deal';
import { Deal } from '../types/deal';

type TDeals$State = {
  totalDeals: number;
  deals: Deal[];
};

const initialState: TDeals$State = {
  totalDeals: 0,
  deals: [],
};

export const Deals$ = createStore<TDeals$State>(initialState);

const { set } = createApi(Deals$, {
  // @ts-ignore
  set: (state, { result }) => {
    const data = {
      // @ts-ignore
      totalDeals: result.result.totalTrades,
      // @ts-ignore
      deals: result.result.pageTrades,
    };

    return data;
  },
});

export const dealsApi = {
  get: createEffect<DealParams, {}, {}>().use(getDeals),
  getSandbox: createEffect<DealParams, {}, {}>().use(getSandboxDeals),
};

forward({
  from: dealsApi.get.done,
  to: set,
});

forward({
  from: dealsApi.getSandbox.done,
  to: set,
});
