import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Route, Routes as RoutesComponent, useParams, Link, useNavigate } from 'react-router-dom';
import SelectField from 'react-select';
import * as Yup from 'yup';

import { ProfitChart } from './ProfitChart';
import { ProfitTable } from './ProfitTable';
import { SignalHistory } from './SignalHistory';
import { SignalTable } from './SignalTable';
import { rebalanceClient, calcRebalanceClient, blockClient, getClientAccepts } from '../../../api';
import { ChartLegend } from '../../../components/Chart/ChartLegend';
import { FREE_MONEY, PieChart } from '../../../components/Chart/PieChart';
import { Input } from '../../../components/FormControls/Input';
import {
  CLIENT_STATUS_AF,
  CLIENT_STATUS_AC,
  CLIENT_STATUS_IB,
  CLIENT_STATUS_NONE,
  AttachDetach,
} from '../../../components/Forms/AttachDetach';
import { Navigation } from '../../../components/Navigation';
import { ProtectedComponent } from '../../../components/ProtectedComponent';
import { PageParams, SortType } from '../../../components/TableGrid';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { ClientDetails$, clientDetailsApi } from '../../../effector/clientDetails';
import { modalApi } from '../../../effector/modals';
import { ClientInfo, StatusEnum } from '../../../types/clientDetails';
import { UserAction } from '../../../utils/permissions';
import { MODALS, Nullable } from '../../../utils/types';
import {
  PAGE_STATISTIC,
  PAGE_STATISTIC_CLIENT_DETAILS_INFO,
  PAGE_STATISTIC_CLIENT_DETAILS_PROFIT_CHART,
  PAGE_STATISTIC_CLIENT_DETAILS_PROFIT_TABLE,
  PAGE_STATISTIC_CLIENT_DETAILS_SIGNALS,
  PAGE_STATISTIC_CLIENT_INFO,
} from '../../Routes';

const validationSchema = Yup.object().shape({
  ticker: Yup.string(),
});

type FormFields = {
  ticker: string;
};

type FormProps = {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  onFilterChanged: () => void;
} & FormikProps<FormFields>;

type IntervalOption = {
  value: string;
  label: string;
};

const DELAY = 500;

const options: IntervalOption[] = [
  { value: '01:00:00', label: '1 час' },
  { value: '02:00:00', label: '2 часа' },
  { value: '04:00:00', label: '4 часа' },
  { value: '12:00:00', label: '12 часов' },
  { value: '24:00:00', label: '1 день' },
  { value: '72:00:00', label: '3 дня' },
  { value: '1000000:00:00', label: 'навсегда' },
];

const FormTemplate: React.FC<FormProps> = ({ values, resetForm, setErrorMessage }) => {
  const navigate = useNavigate();
  const timerId = React.useRef<NodeJS.Timer>();

  const updateTimer = useCallback(
    (value: string) => {
      if (timerId.current) {
        clearTimeout(timerId.current as unknown as number);
      }

      timerId.current = setTimeout(() => {
        clientDetailsApi
          .getClientInfo(value)
          .then((result) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (result.success) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              const newData: ClientInfo = result.result?.[0] || result.result;
              setErrorMessage('');

              if (newData) {
                navigate(`${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${newData.agreementId}`, { replace: true });
              }
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              setErrorMessage(result.errorMessage);
            }
          })
          .catch(() => {
            setErrorMessage('Произошла непредвиденная ошибка');
          });
      }, DELAY);
    },
    [timerId, navigate, setErrorMessage],
  );

  useEffect(() => {
    if (values.ticker.length > 3) {
      updateTimer(values.ticker);
    }
  }, [values.ticker]);

  return (
    <Form className="form" noValidate>
      <div className="form-control-group">
        <div className="form-control-group__item">
          <Field name="ticker" component={Input} placeholder="Г/C или Клиент код" />
        </div>
        <Button
          className="button__primary button__large"
          onClick={() => resetForm({ values: { ticker: '' } })}
          type={ButtonType.reset}
        >
          Сбросить
        </Button>
      </div>
    </Form>
  );
};

export const ClientDetails: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [forceReload, setForceReload] = useState<boolean>(false);
  const params = useParams();
  const { positions, clientInfo } = useStore(ClientDetails$);

  useEffect(() => {
    if (params) {
      const agreementId = params['*']?.split('/')?.[1] ?? '';
      if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(agreementId)) {
        clientDetailsApi.getClientInfoById(agreementId);
      }
    }
    return () => {
      clientDetailsApi.reset('');
    };
  }, [params]);

  useEffect(() => {
    if (clientInfo?.agreementId) {
      clientDetailsApi.getChart(clientInfo.agreementId);
      clientDetailsApi.getPositions(clientInfo.agreementId);
      setForceReload(true);
    }
  }, [clientInfo]);

  const piePositions = useMemo(() => {
    const pos = positions.map(({ securityKey, name, weight, number, currency, avgPrice, lastPrice }) => ({
      symbol: securityKey,
      name: securityKey && securityKey.length > 0 ? name : FREE_MONEY,
      number,
      currency,
      weight,
      avgPrice,
      lastPrice,
    }));

    return pos;
  }, [positions]);

  const clientStatus = useMemo(() => {
    if (clientInfo) {
      if (clientInfo.status === StatusEnum.AutoFollow) {
        return CLIENT_STATUS_AF;
      } else if (clientInfo.status === StatusEnum.Recommendations) {
        return CLIENT_STATUS_AC;
      } else if (clientInfo.status === StatusEnum.InvestMoneybox) {
        return CLIENT_STATUS_IB;
      } else {
        return CLIENT_STATUS_NONE;
      }
    } else {
      return '';
    }
  }, [clientInfo]);

  const initialValues = useMemo(
    () => ({
      ticker: '',
    }),
    [],
  );

  const loadHistory = useCallback(
    (data: PageParams) => {
      if (data.sortFieldName === 'datetime' && clientInfo) {
        const params = {
          agreementId: clientInfo?.agreementId,
          sortType: data.sortDirection === SortType.Desk ? 'd' : '',
        };
        clientDetailsApi.getHistory(params);
        setForceReload(false);
      }
    },
    [clientInfo],
  );

  const onGetClientAccepts = () => getClientAccepts(clientInfo?.agreementId);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = () => {};

  return (
    <>
      <div className="table-filter">
        <div className="table-filter__title">Фильтры</div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {(props) =>
            React.createElement(
              FormTemplate,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              {
                ...props,
                setErrorMessage,
              },
            )
          }
        </Formik>
      </div>
      {errorMessage && <div className="form-control__message">{errorMessage}</div>}
      {clientInfo?.agreementId && (
        <>
          <div className="client-details__info">
            <ProtectedComponent userAction={UserAction.statisticClientDetailsPersonal}>
              <div className="client-details__info-row">
                <div className="client-details__info-row_label">Клиент:</div>
                <div className="client-details__info-row_value">{clientInfo.fio}</div>
              </div>
            </ProtectedComponent>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Ген. соглашение:</div>
              <div className="client-details__info-row_value">{clientInfo.agreement}</div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Клиент код:</div>
              <div className="client-details__info-row_value">{clientInfo.clientCode}</div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">УНК клиента:</div>
              <div className="client-details__info-row_value">{clientInfo.clientId}</div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Статус:</div>
              <div className="client-details__info-row_value">{clientStatus}</div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Стратегия:</div>
              <div className="client-details__info-row_value">{clientInfo.strategyName}</div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Маржинальное кредитование:</div>
              <div className="client-details__info-row_value">{clientInfo.isMarginal ? 'Да' : 'Нет'}</div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Непройденные проверки:</div>
              <div className="client-details__info-row_value">
                {clientInfo.checksNeeded.length === 0 ? 'Все необходимые пройдены' : clientInfo.checksNeeded.join(', ')}
              </div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Блокировка:</div>
              <div className="client-details__info-row_value">{clientInfo.block === null ? '-' : clientInfo.block}</div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Заблокированные позиции:</div>
              <div className="client-details__info-row_value">
                {Object.keys(clientInfo.blockedPositions).length === 0
                  ? '-'
                  : Object.keys(clientInfo.blockedPositions)
                      .map(function (key) {
                        return key + ' = ' + clientInfo.blockedPositions[key];
                      })
                      .join(', ')}
              </div>
            </div>
            <div className="client-details__info-row">
              <div className="client-details__info-row_label">Блокирующий сигнал:</div>
              <div className="client-details__info-row_value">
                {clientInfo.blockSignalId === null ? '-' : clientInfo.blockSignalId}
              </div>
            </div>
            <div className="details__info">
              <Button className="button__large button__primary" onClick={onGetClientAccepts}>
                Выгрузить акцепты
              </Button>
            </div>
          </div>

          {(clientStatus === CLIENT_STATUS_AC ||
            clientStatus === CLIENT_STATUS_AF ||
            clientStatus === CLIENT_STATUS_IB) && (
            <AccountManager
              id={clientInfo.agreementId}
              agreement={clientInfo.agreement}
              strategyId={clientInfo.strategyId}
              type={clientInfo.status}
            />
          )}
        </>
      )}
      <div className="client-details">
        <div className="client-details__content">
          {clientInfo?.agreementId && (
            <div className="form__group">
              <ClientDetailsRoute
                agreementId={clientInfo.agreementId}
                forceReload={forceReload}
                options={loadHistory}
              />
            </div>
          )}
        </div>
        <div className="client-details__chart">
          <div className="client-details__chart-pie">
            {piePositions && piePositions.length > 0 && <PieChart data={piePositions} />}
          </div>
          <div className="client-details__chart-legend">
            {piePositions && piePositions.length > 0 && <ChartLegend positions={piePositions} />}
          </div>
          {clientInfo && clientInfo.agreementId && (
            <>
              <div>
                <div>Ликвид. стоимость: {clientInfo?.portfolio?.liquid_price}</div>
                <div>Исходная стоимость: {clientInfo?.portfolio?.portfolio_price}</div>
                <div>Realized PnL: {clientInfo?.realizedPnl}</div>
              </div>
              <div>
                <ProtectedComponent userAction={UserAction.statisticClientInfo}>
                  <Link to={`${PAGE_STATISTIC}/${PAGE_STATISTIC_CLIENT_INFO}/${clientInfo.agreementId}`}>
                    Детальная информация о клиенте
                  </Link>
                </ProtectedComponent>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ClientDetailsRoute: React.FC<{
  agreementId: string;
  forceReload: boolean;
  options: (arg: PageParams) => void;
}> = ({ agreementId, forceReload, options }) => {
  const items = [
    {
      name: 'История сделок',
      path: `${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${agreementId}`,
      //action: UserAction.statisticClientDetails
    },
    {
      name: 'График доходности',
      path: `${PAGE_STATISTIC_CLIENT_DETAILS_PROFIT_CHART}/${agreementId}`,
      //action: UserAction.statisticClientDetails
    },
    {
      name: 'Таблица доходности',
      path: `${PAGE_STATISTIC_CLIENT_DETAILS_PROFIT_TABLE}/${agreementId}`,
    },
    {
      name: 'История сигналов',
      path: `${PAGE_STATISTIC_CLIENT_DETAILS_SIGNALS}/${agreementId}`,
    },
  ];

  return (
    <div className="statistic">
      <div className="statistic__navigation">
        <Navigation items={items} />
      </div>
      <div>
        <RoutesComponent>
          <Route path="profit_chart/:clientId" element={<ProfitChart />} />
          <Route path="profit_table/:clientId" element={<ProfitTable />} />
          <Route path="signals/:clientId" element={<SignalTable agreementId={agreementId} />} />
          <Route path="info?/:clientId?" element={<SignalHistory forceReload={forceReload} options={options} />} />
        </RoutesComponent>
      </div>
    </div>
  );
};

const AccountManager: React.FC<{ id: string; agreement: string; strategyId: Nullable<string>; type: StatusEnum }> = ({
  id,
  agreement,
  strategyId,
  type,
}) => {
  const [blockedPeriod, setBlockedPeriod] = useState<IntervalOption>();

  const onRebalance = () => {
    const data = {
      clientId: id,
      agreementId: agreement,
      action: () => rebalanceClient({ agreementId: id }),
      description: `Выполнить балансировку аккаунта для ГС ${agreement ?? <i>не определено</i>}`,
    };

    modalApi.show({ modalId: MODALS.ACCOUNT_REBALANCE, data: data });
  };

  const onBlock = () => {
    const data = {
      clientId: id,
      agreementId: agreement,
      action: () => blockClient({ agreementId: id, time: blockedPeriod?.value ?? '00:00:00' }),
      description: `Заблокировать аккаунт ГС ${agreement ?? <i>не определено</i>} на ${blockedPeriod?.label}`,
    };

    modalApi.show({ modalId: MODALS.ACCOUNT_REBALANCE, data: data });
  };

  const onCalcRebalance = () => {
    const data = {
      clientId: id,
      agreementId: agreement,
      action: () => calcRebalanceClient(id),
    };

    modalApi.show({ modalId: MODALS.ACCOUNT_CALC_REBALANCE, data: data });
  };

  return (
    <div className="table-filter">
      <div className="table-filter__title">Управление</div>
      <div className="form__group">
        <Button className="button__large button__primary" onClick={onRebalance}>
          Балансировать
        </Button>
      </div>

      <ProtectedComponent userAction={UserAction.statisticClientInfo}>
        <AttachDetach id={id} strategyId={strategyId} type={type} />
      </ProtectedComponent>

      <div className="form__group">
        <p>Блокировка данного аккаунта</p>
      </div>
      <div className="form-control-group">
        <SelectField
          className="select__control form-control-group__item"
          classNamePrefix="react-select"
          isDisabled={false}
          placeholder="Интервал"
          options={options}
          isMulti={false}
          isClearable={false}
          onChange={(v) => {
            setBlockedPeriod(v as IntervalOption);
          }}
        />
        <Button
          type={ButtonType.button}
          disabled={!blockedPeriod}
          className="button__large button__primary"
          onClick={onBlock}
        >
          Заблокировать
        </Button>
      </div>
      <div className="form__group">
        <Button disabled={false} className="button__large button__primary" onClick={onCalcRebalance}>
          Рассчитать балансировку
        </Button>
      </div>
    </div>
  );
};
