/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getProblemClientsStatistic } from '../api';
import { PageParams } from '../components/TableGrid';
import { ProblemClient } from '../types/problemClient';

type TProblemClients$State = {
  totalClients: number;
  clients: ProblemClient[];
};

const initialState: TProblemClients$State = {
  totalClients: 0,
  clients: [],
};

export const ProblemClients$ = createStore<TProblemClients$State>(initialState);

const { set } = createApi(ProblemClients$, {
  set: (state, { result }) => {
    const data = {
      // @ts-ignore
      totalClients: result.result.total,
      // @ts-ignore
      clients: result.result.pageStats,
    };

    return data;
  },
});

export const problemClientsApi = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  get: createEffect<PageParams, {}, {}>().use(getProblemClientsStatistic),
};

forward({
  from: problemClientsApi.get.done,
  to: set,
});
