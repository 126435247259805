import React, { useState } from 'react';

import { useStore } from 'effector-react';

import { changeStrategy } from '../../api/index';
import { aggregateInvestboxesApi } from '../../effector/aggregateInvestbox';
import { aggregateStrategiesApi } from '../../effector/aggregateStrategies';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { strategiesApi } from '../../effector/strategies';
import { Button, ButtonType } from '../UIKit/Button';

export const ActivateStrategy: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const state =
    useStore<TModal$State<{ id: string; open?: boolean; active?: boolean; isInvestbox?: boolean }>>(Modals$);

  const handleError = (result: { result: { id: string; active: boolean }; success: boolean; errorMessage: string }) => {
    if (result.errorMessage) {
      setError(result.errorMessage);
    } else {
      if (result.result?.active !== undefined && result.result.active !== null && !result.result.active) {
        strategiesApi.remove({ id: result.result.id });
        aggregateStrategiesApi.remove({ id: result.result.id });
        aggregateInvestboxesApi.remove(result.result.id);
      }

      modalApi.hide('');
    }
  };

  const change = () => {
    if (state.data) {
      const data = {
        id: state.data.id,
        open: state.data.open !== undefined ? !state.data.open : undefined,
        active: state.data.active !== undefined ? !state.data.active : undefined,
      };

      setLoading(true);

      changeStrategy(data)
        .then(handleError)
        .catch((e) => {
          setError(e.toString());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getMessage = () => {
    const { open, active, isInvestbox } = { ...state.data };

    if (open !== undefined) {
      return (
        <>
          Вы уверены, что хотите сделать {isInvestbox ? 'инвест-копилку' : 'стратегию'} {open ? 'закрытой' : 'открытой'}
          ?
        </>
      );
    }
    if (active !== undefined) {
      return (
        <>
          Вы уверены, что хотите {active ? 'удалить' : 'активировать'} {isInvestbox ? 'инвест-копилку' : 'стратегию'}?
        </>
      );
    }
  };

  return (
    <div className="strategy-change">
      <div className="strategy-change__description">{getMessage()}</div>
      {error && <p className="error-message">{error}</p>}
      <div className="strategy-change__button-wrapper">
        <Button className="button__primary button__large" onClick={change} disabled={loading} type={ButtonType.button}>
          Изменить
        </Button>
      </div>
    </div>
  );
};
