/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getErrorsByStrategyStatistic, getErrorsStatistic } from '../api';
import { Error, StrategyError } from '../types/error';

type TErrors$State = {
  all: {
    dates: string[];
    errorStatsRows: Error[];
  };
  strategy: {
    dates: string[];
    errorStatsRows: StrategyError[];
  };
};

const initialState: TErrors$State = {
  all: {
    dates: [],
    errorStatsRows: [],
  },
  strategy: {
    dates: [],
    errorStatsRows: [],
  },
};

export const Errors$ = createStore<TErrors$State>(initialState);

const { set, setStrategyErrors } = createApi(Errors$, {
  // @ts-ignore
  set: (state, { result }) => {
    return { ...state, all: result.result };
  },
  // @ts-ignore
  setStrategyErrors: (state, { result }) => {
    return { ...state, strategy: result.result };
  },
});

export const errorsApi = {
  get: createEffect<{}, {}, {}>().use(getErrorsStatistic),
  getStrategyErrors: createEffect<{}, {}, {}>().use(getErrorsByStrategyStatistic),
};

forward({
  from: errorsApi.get.done,
  to: set,
});

forward({
  from: errorsApi.getStrategyErrors.done,
  to: setStrategyErrors,
});
