import React, { useState } from 'react';

import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { useOutSideClick } from '../../../hooks/useOutsideClick';

type Item = {
  text: string;
  id: number;
  onClick?: () => void;
};

type Props = {
  text: string | undefined;
  items: Item[];
};

export const DropDown: React.FC<Props> = ({ text, items }) => {
  const [isOpen, setOpen] = useState(false);
  const reference = React.useRef(null);

  const toggle = () => setOpen(!isOpen);

  const onClick = () => {
    return isOpen ? toggle() : null;
  };

  useOutSideClick(reference, onClick);

  return (
    <div className="dropdown" ref={reference}>
      <span className="dropdown__body" onClick={toggle}>
        {text && <span className="dropdown__text">{text}</span>}
        <FontAwesomeIcon icon={faSortDown} className="footer__icon" />
      </span>
      <div className={cn('dropdown__list', { 'dropdown__list-visible': isOpen })}>
        {items.map((item) => (
          <div
            key={item.id}
            className="dropdown__list-item"
            onClick={() => {
              onClick();
              item.onClick && item.onClick();
            }}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  );
};
