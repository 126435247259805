import React, { PropsWithChildren } from 'react';

import { useStore } from 'effector-react';

import { User$ } from '../../effector/user';
import { checkPermissions, UserAction } from '../../utils/permissions';

type Props = PropsWithChildren & {
  userAction: UserAction;
};

export const ProtectedComponent: React.FC<Props> = ({ userAction, children }) => {
  const user = useStore(User$);

  const isAvailable = user?.role ? checkPermissions(user.role, userAction) : false;

  if (!isAvailable) {
    return null;
  }

  return <>{children}</>;
};
