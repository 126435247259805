import { Nullable } from '../utils/types';

export type TradeTime = {
  start: string;
  end: string;
  description?: string;
};

export enum ScheduleNames {
  'trade',
  'signal',
  'rebalance',
}

export type Weekend = {
  id: number;
  day: string;
};

export type SecurityInfo = {
  key: string;
  classCode: string;
  name: string;
};

export type MarketSchedule = {
  id: number;
  name: Nullable<string>;
  classCodes: string[];
  addForbiddenSecurities: Nullable<string[]>;
  removeForbiddenSecurities: Nullable<string[]>;
  tradeTimes: Nullable<TradeTime[]>;
  signalTimes: Nullable<TradeTime[]>;
  rebalanceTimes: Nullable<TradeTime[]>;
  weekends: Weekend[];
};
