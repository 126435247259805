/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';

import { useStore } from 'effector-react';

import { StrategyParams } from './StrategyParams';
import { StrategiesParams$, strategiesParamsApi } from '../../effector/strategiesParams';
import { StrategySummary } from '../../types/strategySummary';
import { formatMoney } from '../../utils';

export type Option = {
  name: string;
  accessor: string;
  formatter?: (params: StrategySummary, accessor: string) => void;
};

export const StrategiesParams: React.FC = ({}) => {
  const params = useStore(StrategiesParams$);

  useEffect(() => {
    strategiesParamsApi.get('');
  }, []);

  if (!params) {
    return null;
  }

  // @ts-ignore
  const textModifier = (params, accessor) => {
    const value = params[accessor];

    if (typeof value === 'boolean') {
      return value ? 'Да' : 'Нет';
    }

    return value;
  };

  // @ts-ignore
  const moneyModifier = (params, accessor) => formatMoney(params[accessor], params.currency);

  // @ts-ignore
  const percentModifier = (params, accessor) => (params[accessor] ? params[accessor] + ' %' : '');

  // @ts-ignore
  const tariffModifier = (params, accessor) => {
    const tariffs = params[accessor];
    // @ts-ignore
    return tariffs.map((item, ind) => (
      <div className="strategies-params__tariff" key={ind}>
        <div>{item.clientTariff ? item.clientTariff : 'все остальные'}</div>
        <div>AC: {item.afTariff}</div>
        <div>AК: {item.acTariff}</div>
      </div>
    ));
  };

  // @ts-ignore
  const options: Option[] = [
    {
      name: 'Минимальная сумма',
      accessor: 'subscriptionThreshold',
      formatter: moneyModifier,
    },
    {
      name: 'Автоконсультирование',
      accessor: 'autoconsult',
      formatter: textModifier,
    },
    {
      name: 'Автоследование',
      accessor: 'autofollow',
      formatter: textModifier,
    },
    {
      name: 'Категория',
      accessor: 'category',
    },
    {
      name: 'Срок инвестиций',
      accessor: 'duration',
    },
    {
      name: 'Минимальный инвестиционный профиль',
      accessor: 'minInvestProfile',
    },
    {
      name: 'Доходность',
      accessor: 'estimatedProfit',
      formatter: percentModifier,
    },
    {
      name: 'Просадка',
      accessor: 'estimatedDrawdown',
      formatter: percentModifier,
    },
    {
      name: 'Кваливицированный инвестор',
      accessor: 'forQualifiedInvestorsOnly',
      formatter: textModifier,
    },
    {
      name: 'Скрыть портфель',
      accessor: 'hidePortfolio',
      formatter: textModifier,
    },
    {
      name: 'Скрыть последние сигналы',
      accessor: 'hideRecentSignals',
      formatter: textModifier,
    },
    {
      name: 'ИИС',
      accessor: 'iis',
      formatter: textModifier,
    },
    {
      name: 'Бенчмарк',
      accessor: 'index',
    },
    {
      name: 'Цена Автоследования',
      accessor: 'priceAF',
    },
    {
      name: 'Цена Автоконсультирования',
      accessor: 'priceAC',
    },
    {
      name: 'Показать портфель полностью',
      accessor: 'showFullPortfolio',
      formatter: textModifier,
    },
    {
      name: 'Тестовый режим',
      accessor: 'testMode',
      formatter: textModifier,
    },
    {
      name: 'Закрытая',
      accessor: 'isRestricted',
      formatter: textModifier,
    },
    {
      name: 'Доступные тарифы',
      accessor: 'availableTariffs',
      formatter: tariffModifier,
    },
  ];

  return (
    <div className="strategies-params">
      {params.map((item, index) => (
        <StrategyParams key={index} strategy={item} options={options} />
      ))}
    </div>
  );
};
