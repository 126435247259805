import React, { useEffect, useMemo } from 'react';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { ruRU } from '@mui/material/locale';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useStore } from 'effector-react';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { ModalManager } from './components/ModalManager';
import { Navigation } from './components/Navigation';
import { strategyInfoApi } from './effector/aggregateStrategyInfoRefs';
import { classCodesApi } from './effector/classCodes';
import { configApi } from './effector/config';
import { currencyApi } from './effector/currencies';
import { forbiddenApi } from './effector/forbidden';
import { scheduleApi } from './effector/schedule';
import { servicesApi } from './effector/servicesConfig';
import { strategiesApi } from './effector/strategies';
import { tariffApi } from './effector/strategyHelpers';
import { User$, userApi } from './effector/user';
import { userInfoApi } from './effector/userInfoList';
import {
  PAGE_ANALYTICS,
  PAGE_BANNERS,
  PAGE_INSTRUCTION,
  PAGE_SCHEDULE,
  PAGE_STATISTIC,
  PAGE_STRATEGIES,
  PAGE_STRATEGIES_PARAMS,
  PAGE_ADMINISTRATION,
  Routes,
  PAGE_AUDIT,
} from './screens/Routes';
import { checkPermissions, UserAction } from './utils/permissions';

const items = [
  {
    name: 'Стратегии',
    path: PAGE_STRATEGIES,
    action: UserAction.strategy,
  },
  {
    name: 'Статистика',
    path: PAGE_STATISTIC,
    action: UserAction.statistic,
  },
  {
    name: 'Аналитика',
    path: PAGE_ANALYTICS,
    action: UserAction.analytic,
  },
  {
    name: 'Баннеры',
    path: PAGE_BANNERS,
    action: UserAction.banners,
  },
  {
    name: 'Администрирование',
    path: PAGE_ADMINISTRATION,
    action: UserAction.administration,
  },
  {
    name: 'Параметры стратегий',
    path: PAGE_STRATEGIES_PARAMS,
    action: UserAction.strategyParams,
  },
  {
    name: 'Аудит',
    path: PAGE_AUDIT,
    action: UserAction.audit,
  },
  {
    name: 'Расписание торгов',
    path: PAGE_SCHEDULE,
    action: UserAction.schedule,
  },
  {
    name: 'Инструкция',
    path: PAGE_INSTRUCTION,
    action: UserAction.instruction,
  },
];

export const App: React.FC = () => {
  const user = useStore(User$);
  const navigations = useMemo(() => {
    if (user?.role) {
      return items.filter((item) => checkPermissions(user.role, item.action));
    }

    return [];
  }, [user]);

  useEffect(() => {
    if (user) {
      strategiesApi.get('');
    }
  }, [user]);

  useEffect(() => {
    userApi.get('');
    configApi.get('');
  }, []);

  useEffect(() => {
    if (user) {
      strategyInfoApi.get(200);
      tariffApi.get(200);
      userInfoApi.get(200);
      currencyApi.get('');
      servicesApi.get('');
      scheduleApi.get('');
      forbiddenApi.get('');
      classCodesApi.get('');
    }
  }, [user]);

  const theme = useTheme();
  return (
    <ThemeProvider theme={createTheme(theme, ruRU)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Header />
        <nav>
          <Navigation items={navigations} />
        </nav>
        <div className="page">
          <Routes />
        </div>
        <Footer />
        <ModalManager />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
