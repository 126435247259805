import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { ERRORS_TEXTS } from '../../const/validation';
import { aggregateInvestboxesApi } from '../../effector/aggregateInvestbox';
import { currencyInfo$ } from '../../effector/currencies';
import { modalApi } from '../../effector/modals';
import { User$ } from '../../effector/user';
import { userInfo } from '../../effector/userInfoList';
import { AggregateInvestbox } from '../../types/aggregateInvestbox';
import { Currency } from '../../types/aggregateStrategyInfoRef';
import { UserType } from '../../types/aggregateUser';
import { StandartResponse } from '../../types/standartResponse';
import { Nullable } from '../../utils/types';
import { Input } from '../FormControls/Input';
import { InputMaskWrapper } from '../FormControls/InputMask';
import { Select } from '../FormControls/Select';
import { Button, ButtonType } from '../UIKit/Button';

type FormFields = {
  title: string;
  author: { id: number; name: string };
  motto: string;
  currency: Currency;
  minSumma: number;
  tariffId: string;
  tariffName: string;
  leverage: number;
};

type FormProps = {
  error: Nullable<string>;
  send: boolean;
  isUpdate: boolean;
} & FormikProps<FormFields>;

const initValues: Partial<FormFields> = {
  title: '',
  minSumma: 1000,
  leverage: 0,
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ERRORS_TEXTS.required),
  author: Yup.object().required(ERRORS_TEXTS.required),
  motto: Yup.string().required(ERRORS_TEXTS.required),
  currency: Yup.object().required(ERRORS_TEXTS.required),
  minSumma: Yup.number().positive().required(ERRORS_TEXTS.positive),
  tariffId: Yup.string().matches(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
    ERRORS_TEXTS.formatGuid,
  ),
  tariffName: Yup.string().required(ERRORS_TEXTS.required),
  leverage: Yup.number().min(0, ERRORS_TEXTS.positive),
});

const FormTemplate: React.FC<FormProps> = (props) => {
  const userInfoStore = useStore(userInfo);
  const currencyStore = useStore(currencyInfo$);

  const users =
    userInfoStore.total > 0
      ? userInfoStore.users
          .filter((u) => u.userType !== UserType.Representative)
          .map((u) => {
            return { id: u.id, name: u.displayName };
          })
      : [];

  const disable = Object.keys(props.errors).length > 0 || props.send;

  return (
    <Form className="form" noValidate>
      <div>
        <div className="form__group">
          <p>
            Тариф предполагается задавать копированием
            <br />
            GIUD-а в соответствующее поле
          </p>
        </div>
        <div className="form__group">
          <Field name="title" type="text" placeholder="Название" component={Input} />
        </div>
        <div className="form__group">
          <Field name="author" placeholder="Автор" options={users} component={Select} />
        </div>
        <div className="form__group">
          <Field name="motto" type="text" placeholder="Слоган" component={Input} />
        </div>
        <div className="form__group">
          <Field name="currency" placeholder="Валюта" options={currencyStore} component={Select} />
        </div>
        <div className="form__group">
          <Field name="minSumma" type="number" placeholder="Минимальная сумма" component={Input} />
        </div>
        <div className="form__group">
          <Field
            name="tariffId"
            type="text"
            placeholder="Идентификатор тарифа (GUID)"
            disabled={false}
            mask="********-****-****-****-************"
            component={InputMaskWrapper}
          />
        </div>
        <div className="form__group">
          <Field name="tariffName" type="text" placeholder="Название тарифа" component={Input} />
        </div>
        <div className="form__group">
          <Field name="leverage" type="number" placeholder="Используемое плечо" component={Input} />
        </div>
        <Button className="button__primary button__large button__wide" type={ButtonType.submit} disabled={disable}>
          Добавить
        </Button>
        {props.error && <p className="error-message">{props.error}</p>}
      </div>
    </Form>
  );
};

export const AddInvestboxForm: React.FC = () => {
  const userStore = useStore(User$);
  const [error, setError] = useState<Nullable<string>>(null);
  const [send, setSend] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleCommit = (result: StandartResponse<AggregateInvestbox>) => {
    if (result && result.success) {
      modalApi.hide('');
      enqueueSnackbar('Инвест-копилка добавлена');
    } else {
      setError(result?.errorMessage ?? 'На сервере произошла ошибка');
    }
  };

  const onSubmit = (values: FormFields) => {
    setSend(true);

    const data: Partial<AggregateInvestbox> = {
      title: values.title,
      currency: values.currency.code,
      managerId: values.author.id,
      tariffId: values.tariffId,
      tariffName: values.tariffName,
      leverage: values.leverage,
      subscriptionThreshold: values.minSumma,
      tag: values.motto,
      securities: '[]',
      active: true,
      isOpen: false,
      creatorId: userStore !== undefined && userStore !== null ? userStore.id : 0,
      creationDate: new Date().toDateString(),
    };

    aggregateInvestboxesApi
      .add(data)
      .then(handleCommit)
      .catch((e) => setError(e.toString()))
      .finally(() => setSend(false));
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        React.createElement(FormTemplate, { ...props, error, send })
      }
    </Formik>
  );
};
