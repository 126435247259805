import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { addSchedule } from '../../api';
import { Input } from '../../components/FormControls/Input';
import { Select } from '../../components/FormControls/Select';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../const/validation';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { scheduleApi } from '../../effector/schedule';
import { MarketSchedule } from '../../types/marketSchedule';
import { Option } from '../../types/option';
import { TextArea } from '../FormControls/TextArea';

type ModalParams = {
  availableMarkets: Option[];
};

type FormFields = {
  markets: Option[];
  description?: string;
};

type FormProps = {
  //title: string;
  allMarkets: Option[];
  error?: string;
  send: boolean;
} & FormikProps<FormFields>;

const validationSchema = Yup.object().shape({
  markets: Yup.array().min(1, 'Не выбран код торговой площадки'),
  description: Yup.string().required(ERRORS_TEXTS.required),
});

const FormTemplate: React.FC<FormProps> = ({ allMarkets, dirty, send }) => {
  const disabled = !dirty || send;

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <p>Добавление расписания для новой торговой площадки</p>
      </div>
      <div className="form__group">
        <Field
          name="markets"
          placeholder="Коды площадки"
          options={allMarkets}
          multiple={true}
          maxMenuHeight={4}
          component={Select}
        />
      </div>
      <div className="form__group">
        <Field type="text" name="description" placeholder="Название площадки" disabled={false} component={Input} />
      </div>
      <div className="form__group">
        <Field name="text" placeholder="Описание" disabled={true} rows={6} component={TextArea} />
      </div>
      <div className="form__group">
        <Button className="button__primary button__large" type={ButtonType.submit} disabled={disabled}>
          Сохранить
        </Button>
      </div>
    </Form>
  );
};

export const ScheduleAdd: React.FC = () => {
  const [send, setSend] = useState(false);
  const [error, setError] = useState<string>();
  const modalsState = useStore<TModal$State<ModalParams>>(Modals$);

  const init = () => {
    const data: Option[] = [];

    return {
      markets: data,
    };
  };

  const allMarkets = modalsState.data?.availableMarkets ?? [];

  const onSubmit = (values: FormFields) => {
    const data: MarketSchedule = {
      id: 0,
      classCodes: values.markets.map((s) => s.id),
      name: values.description ?? '',
      addForbiddenSecurities: null,
      removeForbiddenSecurities: null,
      tradeTimes: [],
      signalTimes: [],
      rebalanceTimes: [],
      weekends: [],
    };

    addSchedule(data)
      .then((response) => {
        if (response) {
          if (response.success) {
            scheduleApi.set(response.result as MarketSchedule);
            modalApi.hide('');
          } else {
            setError(response.errorMessage ?? 'Ошибка на сервере');
            setSend(false);
          }
        }
      })
      .catch((e) => {
        setError(e.toString());
        setSend(false);
      });
  };

  return (
    <Formik initialValues={init()} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) => React.createElement(FormTemplate, { ...props, allMarkets, error, send })}
    </Formik>
  );
};
