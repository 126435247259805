/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import { useStore } from 'effector-react';

import { CopyToClipboard } from '../../components/CopyToClipboard';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';

export const AccCalcRebalance: React.FC = () => {
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<any>();
  const [complete, setComplete] = useState<boolean>(false);
  const { data } = useStore<TModal$State<{ action: () => Promise<any> }>>(Modals$);

  const handleError = (result: { result: { id: string; result: any }; success: boolean; errorMessage: string }) => {
    if (result?.success) {
      setResult(result.result?.result ?? result.result);
    } else {
      setError(result?.errorMessage ?? 'Произошла ошибка на сервере');
    }
  };

  useEffect(() => {
    data
      ?.action()
      .then(handleError)
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => setComplete(true));
  }, []);

  return (
    <div className="form__group">
      {!complete && <div className="form__group">Рассчет балансировки клиента...</div>}
      {error && <p className="error-message">{error}</p>}
      {complete && (
        <div className="form__group container__json">
          <pre>{JSON.stringify(result, null, 2)}</pre>
        </div>
      )}
      <div className="strategy-change__button-wrapper">
        <div className="form-signal__signal-wrapper form-signal__signal-types">
          <Button
            className="button__primary button__large"
            disabled={false}
            onClick={() => modalApi.hide('')}
            type={ButtonType.button}
          >
            Закрыть
          </Button>
          <CopyToClipboard className="button__large" text={JSON.stringify(result, null, 2)} disabled={!complete} />
        </div>
      </div>
    </div>
  );
};
