/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps, useField } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { ERRORS_TEXTS, FIELDS_LIMITS } from '../../const/validation';
import { aggregateUsersApi } from '../../effector/aggregateUsers';
import { Config$ } from '../../effector/config';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { userInfo } from '../../effector/userInfoList';
import { TUserRoles$State, UserRoles$ } from '../../effector/userRoleList';
import { AggregateUser, UserType } from '../../types/aggregateUser';
import { Option } from '../../types/option';
import { Nullable } from '../../utils/types';
import { Checkbox } from '../FormControls/Checkbox';
import { DatePicker } from '../FormControls/DatePicker';
import { File } from '../FormControls/File';
import { Input } from '../FormControls/Input';
import { InputMaskWrapper } from '../FormControls/InputMask';
import { Phone } from '../FormControls/Phone';
import { Radio } from '../FormControls/Radio';
import { Select } from '../FormControls/Select';
import { TextArea } from '../FormControls/TextArea';
import { TextEditor } from '../FormControls/TextEditor';
import { Button, ButtonType } from '../UIKit/Button';

type FormFields = {
  login: string;
  type: UserType;
  alias: Nullable<string>;
  firstName: Nullable<string>;
  midName: Nullable<string>;
  lastName: Nullable<string>;
  position: Nullable<string>;
  orgName: Nullable<string>;
  org: { id: number; name: string; agreementNum: string };
  inn: Nullable<string>;
  ogrn: Nullable<string>;
  kpp: Nullable<string>;
  passportId: Nullable<string>;
  passportIssued: Nullable<string>;
  issueDate: Date;
  birthDate: Date;
  employeeId: Nullable<string>;
  agreementNum: Nullable<string>;
  photo: string;
  photoChanged: boolean;
  hasComission: boolean;
  phone: string;
  infoHtml: string;
  shortinfoHtml: Nullable<string>;
  infoText: Nullable<string>;
  shortinfoText: Nullable<string>;
  //phone: Nullable<string>;
  email: string;
  password: string;
  confirmPassword: string;
  role: Option;
  changePassword: boolean;
  update: boolean;
};

type FormProps = {
  error: string;
  isUpdate: boolean;
  loading: boolean;
} & FormikProps<FormFields>;

const isPerson: (role: any, type: any) => boolean = (role, type) =>
  (role && role.id !== '0') || (type as UserType) !== UserType.Org;

const isRepresentative: (role: any, type: any) => boolean = (role, type) =>
  role && role.id === '0' && (type as UserType) === UserType.Representative;

const notRepresentative: (role: any, type: any) => boolean = (role, type) =>
  (role && role.id !== '0') || (type as UserType) !== UserType.Representative;

const validationSchema = Yup.object().shape({
  login: Yup.string().when(['role', 'type'], {
    is: notRepresentative,
    then: Yup.string().required(ERRORS_TEXTS.required),
  }),
  role: Yup.object().required(ERRORS_TEXTS.required),
  //firstName: Yup.string().required(ERRORS_TEXTS.required),
  //lastName: Yup.string().required(ERRORS_TEXTS.required),
  firstName: Yup.string()
    .nullable()
    .when(['role', 'type'], {
      is: isPerson,
      then: Yup.string().required(ERRORS_TEXTS.required),
    }),
  lastName: Yup.string()
    .nullable()
    .when(['role', 'type'], {
      is: isPerson,
      then: Yup.string().required(ERRORS_TEXTS.required),
    }),
  orgName: Yup.string()
    .nullable()
    .when(['role', 'type'], {
      is: (role?: Option, type?: UserType) => role && role.id === '0' && type === UserType.Org,
      then: Yup.string().nullable().required(ERRORS_TEXTS.required),
    }),
  org: Yup.object().when(['role', 'type'], {
    is: isRepresentative,
    then: Yup.object().required(ERRORS_TEXTS.required),
  }),
  inn: Yup.string()
    .nullable()
    .matches(/([0-9]{10})/, ERRORS_TEXTS.formatInn),
  ogrn: Yup.string()
    .nullable()
    .matches(/^[0-9]{13}$/, ERRORS_TEXTS.formatOgrn),
  kpp: Yup.string()
    .nullable()
    .matches(/^[0-9]{9}$/, ERRORS_TEXTS.formatKpp),
  employeeId: Yup.string()
    .nullable()
    .when(['role', 'type'], {
      is: (role?: Option, type?: UserType) => role && role.id === '0' && type === UserType.Insider,
      then: Yup.string().nullable().required(ERRORS_TEXTS.required),
    }),
  infoHtml: Yup.string().nullable().max(FIELDS_LIMITS.infoMaxLength, ERRORS_TEXTS.infoMaxLength),
  infoText: Yup.string().nullable().max(FIELDS_LIMITS.infoMaxLength, ERRORS_TEXTS.infoMaxLength),
  shortinfoHtml: Yup.string().nullable().max(FIELDS_LIMITS.infoMaxLength, ERRORS_TEXTS.infoMaxLength),
  shortinfoText: Yup.string().nullable().max(FIELDS_LIMITS.infoMaxLength, ERRORS_TEXTS.infoMaxLength),
  phone: Yup.string().when(['role', 'type'], {
    is: notRepresentative,
    //then: Yup.string().required(ERRORS_TEXTS.required)
    then: Yup.string().matches(/^\+\d\s?\d{3}\s?\d{3}\s?\d\d\s?\d\d$/, ERRORS_TEXTS.required),
  }),
  email: Yup.string().when(['role', 'type'], {
    is: (role?: Option, type?: UserType) => role && role.id === '0' && type !== UserType.Representative,
    then: Yup.string().email(ERRORS_TEXTS.emailInvalid).required(ERRORS_TEXTS.required),
  }),
  password: Yup.string()
    .nullable()
    .min(FIELDS_LIMITS.passwordMinLength, ERRORS_TEXTS.passwordMinLength)
    .max(FIELDS_LIMITS.passwordMaxLength, ERRORS_TEXTS.passwordMaxLength)
    .when('changePassword', {
      is: true,
      then: Yup.string().required(ERRORS_TEXTS.required),
    }),
  confirmPassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref('password'), null], ERRORS_TEXTS.passwordMatches)
    .min(FIELDS_LIMITS.passwordMinLength, ERRORS_TEXTS.passwordMinLength)
    .max(FIELDS_LIMITS.passwordMaxLength, ERRORS_TEXTS.passwordMaxLength)
    .when('changePassword', {
      is: true,
      then: Yup.string().required(ERRORS_TEXTS.required),
    }),
});

const initialValues = {
  login: '',
  type: UserType.Insider,
  //firstName: '',
  //lastName: '',
  //midName:'',
  hasComission: false,
  photoChanged: false,
  phone: '',
  password: '',
  confirmPassword: '',
  role: '',
  picture: '',
  changePassword: true,
  update: false,
};

const userTypes: { id: UserType; name: string }[] = [
  { id: UserType.Insider, name: 'Сотрудник БКС' },
  { id: UserType.NonInsider, name: 'Не сотрудник БКС' },
  { id: UserType.Org, name: 'Организация' },
  { id: UserType.Representative, name: 'Представитель' },
];

const FormTemplate: React.FC<FormProps> = ({ values, errors, dirty, error, isUpdate, loading, setFieldValue }) => {
  const configStore = useStore(Config$);
  const userRoles = useStore<TUserRoles$State>(UserRoles$);
  const [userType, setUserType] = useState(values.type === undefined ? UserType.Insider : values.type);
  const [field] = useField('role');
  const [agreement, setAgreement] = useState<Nullable<number>>(null);

  const disable = Object.keys(errors).length > 0 || !dirty || loading;

  const isAuthor = field.value?.id === '0';
  const isOrg = isAuthor && userType !== UserType.Org;
  const isPassport = !isAuthor || userType !== UserType.NonInsider;
  const isEmployee = !isAuthor || userType !== UserType.Insider;

  const userInfoStore = useStore(userInfo);
  const orgs = userInfoStore.users
    .filter((u) => u.userType === UserType.Org)
    .map((u) => {
      return { id: u.id, name: u.orgName ?? '', agreementNum: u.agreementNum };
    });

  const friendlyUrl = configStore?.friendlyUrl ?? '';

  const setPhoto = (field: string, fieldValue: string) => {
    setFieldValue(field, fieldValue);
    setFieldValue('photoChanged', true);
  };

  if (isAuthor && userType === UserType.Representative && values.org && values.org.id !== agreement) {
    setFieldValue('agreementNum', values.org.agreementNum ?? '');
    setAgreement(values.org.id);
  } else if (isAuthor && userType !== UserType.Representative && agreement) {
    setFieldValue('agreementNum', '');
    setAgreement(null);
  }

  return (
    <Form className="form analytic-form" noValidate>
      <div className="form__group">
        <Field type="text" name="login" placeholder="Логин" component={Input} />
      </div>

      {userRoles && (
        <div className="form__group">
          <Field
            name="role"
            placeholder="Роль"
            options={userRoles
              .filter((item) => item.name && item.name.length > 0)
              .map((item) => ({ id: item.id, name: item.name }))}
            component={Select}
          />
        </div>
      )}

      <div className={cn('form__group', { 'form__group-disabled': !isAuthor })}>
        <Field type="text" name="alias" placeholder="Никнейм" disabled={!isAuthor} component={Input} />
      </div>

      <div className="form__group">
        <div className="form__group form-control__label">Тип пользователя</div>
        <div className={cn('form__group', { 'form__group-disabled': !isAuthor })}>
          {userTypes.map((u) => (
            <Field
              key={u.id}
              name="type"
              text={u.name}
              value={u.id}
              disabled={!isAuthor}
              checked={isAuthor && values.type === u.id}
              onChange={() => {
                setFieldValue('type', u.id);
                setUserType(u.id);
              }}
              component={Radio}
            />
          ))}
        </div>
      </div>

      {isAuthor && userType === UserType.Representative ? (
        <div className="form__group">
          <Field name="org" placeholder="Наименование организации" options={orgs} component={Select} />
        </div>
      ) : (
        <div className={cn('form__group', { 'form__group-disabled': isOrg })}>
          <Field type="text" name="orgName" placeholder="Наименование организации" disabled={isOrg} component={Input} />
        </div>
      )}
      {isAuthor && userType === UserType.Org ? (
        <>
          <div className={cn('form__group', { 'form__group-disabled': isOrg })}>
            <Field
              type="text"
              name="inn"
              placeholder="ИНН"
              disabled={isOrg}
              mask="9999999999"
              component={InputMaskWrapper}
            />
          </div>
          <div className={cn('form__group', { 'form__group-disabled': isOrg })}>
            <Field
              type="text"
              name="ogrn"
              placeholder="ОГРН"
              disabled={isOrg}
              mask="9999999999999"
              component={InputMaskWrapper}
            />
          </div>
          <div className={cn('form__group', { 'form__group-disabled': isOrg })}>
            <Field
              type="text"
              name="kpp"
              placeholder="КПП"
              disabled={isOrg}
              mask="999999999"
              component={InputMaskWrapper}
            />
          </div>
        </>
      ) : (
        <>
          <div className="form__group">
            <Field type="text" name="lastName" placeholder="Фамилия" component={Input} />
          </div>

          <div className="form__group">
            <Field type="text" name="firstName" placeholder="Имя" component={Input} />
          </div>

          <div className="form__group">
            <Field type="text" name="midName" placeholder="Отчество" component={Input} />
          </div>
        </>
      )}

      <div className={cn('form__group', { 'form__group-disabled': !isOrg })}>
        <Field type="text" name="position" placeholder="Должность" disabled={!isOrg} component={Input} />
      </div>

      <div className={cn('form__group', { 'form__group-disabled': !isAuthor || userType === UserType.Representative })}>
        <Field
          type="text"
          name="agreementNum"
          placeholder="Номер договора"
          disabled={!isAuthor || userType === UserType.Representative}
          mask="999/99-*****"
          component={Input}
        />
      </div>

      <div className={cn('form__group', { 'form__group-disabled': isEmployee })}>
        <Field type="text" name="employeeId" placeholder="Табельный номер" disabled={isEmployee} component={Input} />
      </div>

      <div className={cn('form__group', { 'form__group-disabled': isPassport })}>
        <Field
          type="text"
          name="passportId"
          placeholder="Серия номер паспорта"
          disabled={isPassport}
          mask="99 99 999999"
          component={InputMaskWrapper}
        />
      </div>

      <div className={cn('form__group', { 'form__group-disabled': isPassport })}>
        <Field type="text" name="passportIssued" placeholder="Кем выдан" disabled={isPassport} component={Input} />
      </div>

      <div className={cn('form__group', { 'form__group-disabled': isPassport })}>
        <Field
          name="issueDate"
          placeholder="Дата выдачи"
          dropdownMode="select"
          dateFormat="dd.MM.yyyy"
          showMonthDropdown
          showYearDropdown
          disabled={isPassport}
          component={DatePicker}
        />
      </div>

      <div className={cn('form__group', { 'form__group-disabled': isPassport })}>
        <Field
          name="birthDate"
          placeholder="Дата рождения"
          dropdownMode="select"
          dateFormat="dd.MM.yyyy"
          showMonthDropdown
          showYearDropdown
          disabled={isPassport}
          component={DatePicker}
        />
      </div>

      <div className={cn('form__group', { 'form__group-disabled': !isAuthor })}>
        <Field name="hasComission" text="Согласие разделить комиссию" disabled={!isAuthor} component={Checkbox} />
      </div>

      {isAuthor && (
        <div className="form__group">
          {values.photo ? (
            <>
              <div>
                <div className="form-control__placeholder-custom">Изображение</div>
                <img src={`${friendlyUrl}${values.photo}`} alt="" />
              </div>
              <div>
                <FontAwesomeIcon icon={faTimes} className="fall" onClick={() => setPhoto('photo', '')} />
              </div>
            </>
          ) : (
            <Field
              name="photo"
              placeholder="Изображение"
              url="api/admin/files/upload_author"
              fileTypes={['.png', '.jpg', '.jpeg', '.gif']}
              onRemove={() => null}
              onUpload={setPhoto}
              component={File}
            />
          )}
        </div>
      )}

      <div className="form__group">
        <Field name="shortinfoHtml" placeholder="Краткое описание" component={TextEditor} />
      </div>

      <div className="form__group">
        <Field name="infoHtml" placeholder="Полное описание" component={TextEditor} />
      </div>

      <div className="form__group">
        <Field name="shortinfoText" placeholder="Краткое текстовое описание" component={TextArea} />
      </div>
      <div className="form__group">
        <Field name="infoText" placeholder="Текстовое описание" component={TextArea} />
      </div>

      <div className="form__group">
        <Field type="tel" name="phone" placeholder="Номер телефона" component={Phone} />
      </div>

      <div className={cn('form__group', { 'form__group-disabled': !isAuthor })}>
        <Field type="email" name="email" placeholder="Почтовый ящик" disabled={!isAuthor} component={Input} />
      </div>

      <div className="form__group">
        <Field type="password" name="password" placeholder="Пароль" component={Input} />
      </div>

      <div className="form__group">
        <Field type="password" name="confirmPassword" placeholder="Повторить пароль" component={Input} />
      </div>

      <Button className="button__primary button__large button__wide" type={ButtonType.submit} disabled={disable}>
        {isUpdate ? 'Обновить' : 'Добавить'}
      </Button>

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const AddUser: React.FC = () => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const userRolesState = useStore<TUserRoles$State>(UserRoles$);
  const userInfoStore = useStore(userInfo);
  const modalsState = useStore<TModal$State<{ user: AggregateUser }>>(Modals$);

  const user = modalsState.data?.user;

  const isUpdate = !!user;

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      //userInfoApi.add(result.result);

      // @ts-ignore
      modalApi.hide('');

      enqueueSnackbar(isUpdate ? 'Данные пользователя изменены' : 'Пользователь добавлен');
    }
  };

  const getCreateData = (values: FormFields) => {
    const role = userRolesState?.find((item) => item.name === values.role.name);

    const phone = values.phone?.split(' ').join('');

    const data = {
      masterId: values.org?.id,
      login: values.login,
      userType: values.type,
      alias: values.alias,
      firstName: values.firstName,
      lastName: values.lastName,
      midName: values.midName,
      position: values.position,
      company: values.orgName,
      orgInn: values.inn && values.inn.length > 0 ? values.inn : undefined,
      orgOgrn: values.ogrn && values.ogrn.length > 0 ? values.ogrn : undefined,
      orgKpp: values.kpp && values.kpp.length > 0 ? values.kpp : undefined,
      passportId: values.passportId,
      //issueDate: values.issueDate?.toDateString(),
      //birthDate: values.birthDate?.toDateString(),
      passportIssued: values.passportIssued,
      employeeId: values.employeeId,
      agreementNum: values.agreementNum,
      comission: values.hasComission,
      phone: phone,
      email: values.email,
      photo: values.photo,
      photoChanged: values.photoChanged,
      infoHtml: values.infoHtml,
      shortinfoHtml: values.shortinfoHtml,
      infoText: values.infoText,
      shortinfoText: values.shortinfoText,
      password: values.password && values.password.length > 0 ? values.password : undefined,
      role: role?.role,
    };

    return data;
  };

  const getUpdateData = (values: FormFields) => {
    const data = {
      ...user,
      ...getCreateData(values),
    };

    return data;
  };

  const onSubmit = (values: FormFields) => {
    const data = isUpdate ? getUpdateData(values) : getCreateData(values);

    setLoading(true);
    aggregateUsersApi
      .add(data)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const getMasterData = (user: AggregateUser) => {
    if (user.masterId) {
      const org = userInfoStore.users.find((u) => u.id === user.masterId);
      if (org) {
        return { id: org.id, name: org.orgName ?? '', agreementNum: org.agreementNum };
      }
    }

    return undefined;
  };

  const getInitData = () => {
    if (user) {
      const role = userRolesState?.find((item) => item.role === user?.role);

      let phone = user.phone ? user.phone.trim() : '';

      if (phone.length === 10 && phone.match(/[0-9]{10}/)) phone = '+7' + phone;

      const data: Partial<FormFields> = {
        login: user.login,
        firstName: user.firstName,
        lastName: user.lastName,
        midName: user.midName,
        position: user.position,
        orgName: user.company,
        org: getMasterData(user) ?? undefined,
        inn: user.orgInn,
        ogrn: user.orgOgrn,
        kpp: user.orgKpp,
        passportId: user.passportId,
        passportIssued: user.passportIssued,
        employeeId: user.employeeId,
        agreementNum: user.agreementNum ?? '',
        hasComission: !!user.comission,
        phone: phone,
        email: user.email ?? '',
        photo: user.photo,
        photoChanged: false,
        infoHtml: user.infoHtml,
        shortinfoHtml: user.shortinfoHtml,
        infoText: user.infoText,
        shortinfoText: user.shortinfoText,
        password: user.password,
        confirmPassword: user.password,
        role: role ? { id: role.id, name: role.name } : undefined,
        type: user.userType,
        alias: user.alias,
        changePassword: false,
      };

      return data;
    }

    return initialValues;
  };

  const init = getInitData();

  return (
    <Formik
      // @ts-ignore
      initialValues={init}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) =>
        React.createElement(
          FormTemplate,
          // @ts-ignore
          {
            ...props,
            error,
            isUpdate,
            loading,
          },
        )
      }
    </Formik>
  );
};
