/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { useStore } from 'effector-react';

import { Button, ButtonType } from '../../components/UIKit/Button';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';

export type AccountData = {
  clientId: string;
  agreementId: string;
  action: () => Promise<any>;
  description: string;
};

const ServiceManagementAction: React.FC = () => {
  const [error, setError] = useState<string>();
  const [progress, setProgress] = useState<boolean>(false);
  const { data } = useStore<TModal$State<AccountData>>(Modals$);

  const handleError = (result: { result: { id: string; active: boolean }; success: boolean; errorMessage: string }) => {
    if (result.errorMessage) {
      setError(result.errorMessage);
      setProgress(false);
    } else {
      modalApi.hide('');
    }
  };

  const execute = () => {
    setProgress(true);

    data
      ?.action()
      .then(handleError)
      .catch((e) => {
        setError(e.toString());
        setProgress(false);
      });
  };

  return (
    <div className="form__group">
      <div className="form__group">{data?.description}</div>
      {error && <p className="error-message">{error}</p>}
      <div className="strategy-change__button-wrapper">
        <Button
          className="button__primary button__large"
          onClick={execute}
          disabled={progress}
          type={ButtonType.button}
        >
          Ок
        </Button>
      </div>
    </div>
  );
};

export const AccountRebalance: React.FC = () => {
  return <ServiceManagementAction />;
};

export const AccountBlock: React.FC = () => {
  return <ServiceManagementAction />;
};

export const ServiceStopCS: React.FC = () => {
  return <ServiceManagementAction />;
};
