/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { useEffect, useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useStore } from 'effector-react';

import { searchApi, Search$ } from '../../effector/search';
import { Suggestion } from '../../types/suggestion';

const SEARCH_LENGTH = 1;
const DELAY = 500;

type Props = {
  strategyId?: string;
  classCodes?: string[];
  showSelectedValue?: boolean;
  forceClear?: boolean;
  forceSearchValue?: string;
  disabled?: boolean;
  onSearchClick: (securityKey: string, suggestion?: Suggestion) => void;
};

export const Search: React.FC<Props> = ({
  strategyId,
  classCodes,
  forceClear,
  forceSearchValue,
  disabled,
  onSearchClick,
  showSelectedValue = true,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const searchStore = useStore(Search$);

  useEffect(() => {
    if (forceClear) {
      clearSearch();
    }
  }, [forceClear]);

  useEffect(() => {
    if (forceSearchValue) {
      setSearchValue(forceSearchValue);
    }
  }, [forceSearchValue]);

  const timerId = React.useRef<NodeJS.Timer>();

  const updateTimer = (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current as unknown as number);
    }

    timerId.current = setTimeout(() => {
      search(value);
    }, DELAY);
  };

  const search = (value: string) => {
    if (value.length < SEARCH_LENGTH) {
      return;
    }

    const params = {
      strategyId,
      classCodes,
      pattern: value,
    };

    searchApi.get(params);
  };

  const handleOnChange = (event: React.SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value;
    updateTimer(value);
    setSearchValue(value);
  };

  const handleSearchClick = (elem: Suggestion) => {
    const search = showSelectedValue ? elem.name : '';
    setSearchValue(search);
    onSearchClick(`${elem.symbol} ${elem.classCode} QUIK`, elem);
  };

  const clearSearch = () => {
    setSearchValue('');
    // @ts-ignore
    searchApi.clearSuggestion();
    // @ts-ignore
    searchApi.clearDetails();
  };

  return (
    <div className="search">
      <div className="search-bar">
        <div className="search-bar__icon" onClick={clearSearch}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <input
          name="search"
          type="text"
          placeholder="Поиск инструмента"
          className={cn('search-bar__field', {
            'search-bar__field-disabled': disabled,
          })}
          value={searchValue}
          disabled={disabled}
          onChange={handleOnChange}
        />
      </div>
      {searchValue && searchStore?.suggestions?.[searchValue] != null ? (
        <div className="search-result">
          {searchStore.suggestions[searchValue].length ? (
            searchStore.suggestions[searchValue].map((item) => (
              <div className="search-result__row" key={item.key} onClick={() => handleSearchClick(item)}>
                {item.name} ({item.key.split(' ').slice(0, 2).join(' ')}){' '}
                <span className="search-result__row__isin">{item.isin != 'NULL' ? item.isin : ''}</span>
              </div>
            ))
          ) : (
            <div className="search-result__row">По вашему запросу ничего не найдено</div>
          )}{' '}
        </div>
      ) : null}
    </div>
  );
};
