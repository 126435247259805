import React, { useCallback } from 'react';

import DOMPurify from 'dompurify';
import { useStore } from 'effector-react';
import moment from 'moment';

import { Search } from '../../../components/Search';
import { searchApi } from '../../../effector/search';
import { SecurityBidAsk$, securityBidAskApi } from '../../../effector/securitybidask';
import { SecurityCS$, securityCSApi } from '../../../effector/securitycs';
import { SecurityInfo$, securityInfoApi } from '../../../effector/securityinfo';
import { SecurityMarketData$, securityMarketDataApi } from '../../../effector/securitymarketdata';

export const SecurityInfo: React.FC = () => {
  const { securityInfo } = useStore(SecurityInfo$);
  const { securityBidAsk } = useStore(SecurityBidAsk$);
  const { securityMarketData } = useStore(SecurityMarketData$);
  const { securityCS } = useStore(SecurityCS$);

  const onSearchPositionClick = useCallback((securityKey: string) => {
    securityInfoApi.get(securityKey);
    securityBidAskApi.get(securityKey);
    securityMarketDataApi.get(securityKey);
    securityCSApi.get(securityKey);
    searchApi.clearSuggestion('');
  }, []);

  return (
    <>
      <div className="table-filter">
        <Search onSearchClick={onSearchPositionClick} />
      </div>
      <div>
        {securityInfo && securityBidAsk && securityMarketData && securityCS && (
          <table className="security-info-table">
            <tbody>
              <tr>
                <td colSpan={2} className="sec-info-separator">
                  Общие
                </td>
              </tr>
              <tr>
                <td>Тикер</td>
                <td>{securityInfo.ticker}</td>
              </tr>
              <tr>
                <td>Режим торгов</td>
                <td>{securityInfo.classCode}</td>
              </tr>
              <tr>
                <td>Площадка</td>
                <td>{securityInfo.className}</td>
              </tr>
              <tr>
                <td>Тип</td>
                <td>{securityInfo.type}</td>
              </tr>
              <tr>
                <td>Название</td>
                <td>{securityInfo.name}</td>
              </tr>
              <tr>
                <td>Краткое название</td>
                <td>{securityInfo.shortName}</td>
              </tr>
              <tr>
                <td>ISIN</td>
                <td>{securityInfo.isin}</td>
              </tr>
              <tr>
                <td>Валюта</td>
                <td>{securityInfo.currency}</td>
              </tr>
              <tr>
                <td>Шорты</td>
                <td>{securityInfo.shortsAllowed ? 'Да' : 'Нет'}</td>
              </tr>
              <tr>
                <td>Размер лота</td>
                <td>{securityInfo.lotSize}</td>
              </tr>
              <tr>
                <td>Шаг цены</td>
                <td>{securityInfo.priceStep}</td>
              </tr>
              <tr>
                <td>Торгуется сегодня</td>
                <td>{securityInfo.isCurrent ? 'Да' : 'Нет'}</td>
              </tr>
              <tr>
                <td>Последняя сделка</td>
                <td>{securityInfo.lastPrice}</td>
              </tr>
              <tr>
                <td>Время последней сделки</td>
                <td>{moment(securityInfo.lastTime).format('DD.MM.YYYY HH:mm:ss')}</td>
              </tr>
              <tr>
                <td colSpan={2} className="sec-info-separator">
                  Стакан
                </td>
              </tr>
              <tr>
                <td>Bid</td>
                <td>{securityBidAsk.bid}</td>
              </tr>
              <tr>
                <td>Ask</td>
                <td>{securityBidAsk.ask}</td>
              </tr>
              <tr>
                <td>LastPrice</td>
                <td>{securityBidAsk.lastPrice}</td>
              </tr>
              <tr>
                <td>FuturesPrice</td>
                <td>{securityBidAsk.futuresPrice}</td>
              </tr>
              <tr>
                <td>IsOk</td>
                <td>{securityBidAsk.isOk ? 'Да' : 'Нет'}</td>
              </tr>
              <tr>
                <td>Ошибка</td>
                <td>{securityBidAsk.errorMessage}</td>
              </tr>
              <tr>
                <td>Время</td>
                <td>{moment(securityBidAsk.timeStamp).format('DD.MM.YYYY HH:mm:ss')}</td>
              </tr>
              <tr>
                <td colSpan={2} className="sec-info-separator">
                  Балансировщик
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <pre dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(securityCS.html) }}></pre>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="sec-info-separator">
                  Маркетдата
                </td>
              </tr>
              <tr>
                <td colSpan={2} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(securityMarketData.html) }}></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
