/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import {
  getStatisticAuthorsDictionary,
  getStatisticServicesDictionary,
  getStatisticStrategiesDictionary,
  getStatisticProblemsDictionary,
} from '../api';
import { Option } from '../types/option';

type TStatisticFilters$State = {
  strategies: Option[];
  authors: Option[];
  services: Option[];
  problems: Option[];
};

const initialState = {
  strategies: [],
  authors: [],
  services: [],
  problems: [],
};

export const StatisticFilters$ = createStore<TStatisticFilters$State>(initialState);

const { setStrategies, setAuthors, setServices, setProblems } = createApi(StatisticFilters$, {
  // @ts-ignore
  setStrategies: (state, { result }) => ({
    ...state,
    strategies: result.result,
  }),
  // @ts-ignore
  setAuthors: (state, { result }) => ({ ...state, authors: result.result }),
  // @ts-ignore
  setServices: (state, { result }) => ({ ...state, services: result.result }),
  // @ts-ignore
  setProblems: (state, { result }) => ({ ...state, problems: result.result }),
});

export const statisticFiltersApi = {
  getStrategies: createEffect().use(getStatisticStrategiesDictionary),
  getAuthors: createEffect().use(getStatisticAuthorsDictionary),
  getServices: createEffect().use(getStatisticServicesDictionary),
  getProblems: createEffect().use(getStatisticProblemsDictionary),
};

forward({
  from: statisticFiltersApi.getStrategies.done,
  to: setStrategies,
});

forward({
  from: statisticFiltersApi.getAuthors.done,
  to: setAuthors,
});

forward({
  from: statisticFiltersApi.getServices.done,
  to: setServices,
});

forward({
  from: statisticFiltersApi.getProblems.done,
  to: setProblems,
});
