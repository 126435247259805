import { Nullable } from './types';
import { UserRoles } from '../types/userRoles';

export const CURRENCIES_MAP: Record<string, string> = {
  RUB: '₽',
  SUR: '₽',
  USD: '$',
  EUR: '€',
  HKD: 'HK$',
  CNY: '¥',
  '%': '%',
  undefined: '',
  null: '',
};

export const formatNumber: (value: Nullable<number>, toFixed?: number) => string = (value, toFixed = 2) => {
  if (!value) {
    return '';
  }

  const formatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed,
  });

  return formatter.format(value);
};

export const formatMoney: (value: Nullable<number>, currency?: string, toFixed?: number) => string = (
  value,
  currency = 'RUB',
  toFixed = 2,
) => {
  return `${formatNumber(value, toFixed)} ${CURRENCIES_MAP[currency]}`;
};

export const getPercentage: (value: number) => string = (value) => {
  const formattedNumber = formatNumber(value * 100, 1);

  if (!formattedNumber) {
    return '';
  }

  return `${formattedNumber}%`;
};

export const roundNumber = (value: number, to = 2) => parseFloat(value.toFixed(to));

export const roundStringNumber = (value: number | string, to = 2) => Number(value).toFixed(to);

export const roleToText = (role: UserRoles) => {
  let textRole = '';

  switch (role) {
    case UserRoles.Manager:
      textRole = 'Управляющий';
      break;

    case UserRoles.Expert:
      textRole = 'Эксперт';
      break;

    case UserRoles.Administrator:
      textRole = 'Менеджер';
      break;

    case UserRoles.RiskManager:
      textRole = 'Риск-Менеджер';
      break;

    case UserRoles.Support:
      textRole = 'Поддержка';
      break;

    case UserRoles.ContentManager:
      textRole = 'Контент-менеджер';
      break;

    case UserRoles.DigitalExpert:
      textRole = 'Digital-эксперт';
      break;

    case UserRoles.Developer:
      textRole = 'Администратор';
      break;

    case UserRoles.Auditor:
      textRole = 'Аудитор';
      break;
  }

  return textRole;
};

export const boolToString = (value: boolean, options: { yes: string; no: string } = { yes: 'Да', no: 'Нет' }) =>
  value ? options.yes : options.no;

export const isBoolValue = (value: string | number) => value === 'Да' || value === 'Нет';

export const getBoolValueFromString = (value: string | number) => value === 'Да';

export const getMessageFromFailedRequest = (e: Error): string => {
  try {
    const obj = JSON.parse(e.message);
    return obj.message;
  } catch {
    return '';
  }
};

export const isValidDate = (value: string) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};
