import React, { useCallback, useEffect, useMemo } from 'react';

import {
  faBalanceScale,
  faChartLine,
  faMoneyBill,
  faPercent,
  faPoll,
  faUser,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import DOMPurify from 'dompurify';
import { useStore } from 'effector-react';

import { ChartLegend } from '../../../components/Chart/ChartLegend';
import { PieChart } from '../../../components/Chart/PieChart';
import { Navigation } from '../../../components/Navigation';
import { ProtectedComponent } from '../../../components/ProtectedComponent';
import { Timer } from '../../../components/Timer';
import { Button } from '../../../components/UIKit/Button';
import { modalApi } from '../../../effector/modals';
import { Portfolio$, portfolioApi } from '../../../effector/portfolio';
import { Signals$, signalsApi } from '../../../effector/signal';
import { Strategies$ } from '../../../effector/strategies';
import { Strategy, StrategyStatus } from '../../../types/strategy';
import { UserAction } from '../../../utils/permissions';
import { MODALS, Nullable } from '../../../utils/types';

type Props = Partial<Strategy>;

export const Portfolio: React.FC<Props> = ({ id, isChild, parentStrategy }) => {
  const portfolioStore = useStore(Portfolio$);
  const strategies = useStore(Strategies$);
  const { delay } = useStore(Signals$);

  const strategy = strategies.find((item) => item.id === id);
  const timerId = React.useRef<Nullable<number>>(null);

  useEffect(() => {
    loadPortfolio();
    timerId.current = window.setInterval(loadPortfolio, 10000);

    return () => clearInterval(timerId.current as number);
  }, [id]);

  const endTimerDate = useMemo(() => {
    if (delay) {
      const t = new Date();
      t.setSeconds(t.getSeconds() + delay);
      return t;
    }
  }, [delay]);

  const checkSignal = useCallback(() => {
    if (id) {
      signalsApi.checkSignal(id);
    }
  }, [id]);

  const openModal = useCallback(() => {
    modalApi.show({
      modalId: MODALS.NEW_SIGNAL,
      data: { strategyId: id },
    });
  }, [id]);

  const loadPortfolio = () => {
    if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
      portfolioApi.get(id as string);
    } else {
      portfolioApi.getSandbox(id as string);
    }
  };

  const navLinks = [
    {
      name: 'Портфель',
      path: '',
    },
  ];

  if (!portfolioStore) {
    return null;
  }

  const params = [
    {
      icon: <FontAwesomeIcon icon={faBalanceScale} className="footer__icon" />,
      name: 'Текущий баланс',
      value: portfolioStore.currentBalance,
    },
    {
      icon: <FontAwesomeIcon icon={faMoneyBill} className="footer__icon" />,
      name: 'Свободные средства',
      value: portfolioStore.currentFree,
    },
    {
      icon: <FontAwesomeIcon icon={faChartLine} className="footer__icon" />,
      name: 'Realized P&L',
      value: portfolioStore.relizedPL,
      className: portfolioStore.relizedPL >= 0 ? 'rise' : 'fall',
    },
    {
      icon: <FontAwesomeIcon icon={faChartLine} className="footer__icon" />,
      name: 'Unrealized P&L',
      value: portfolioStore.unrealizedPL,
      className: portfolioStore.unrealizedPL >= 0 ? 'rise' : 'fall',
    },
    {
      icon: <FontAwesomeIcon icon={faPercent} className="footer__icon" />,
      name: 'Загрузка',
      value: portfolioStore.portfolioWeight,
    },
    {
      icon: <FontAwesomeIcon icon={faPoll} className="footer__icon" />,
      name: 'Dw<sub>1</sub>',
      value: portfolioStore.deviation,
    },
    {
      icon: <FontAwesomeIcon icon={faPoll} className="footer__icon" />,
      name: 'Dw<sub>max</sub>',
      value: portfolioStore.maxDeviation,
    },
    {
      icon: <FontAwesomeIcon icon={faExclamationTriangle} className="footer__icon" />,
      name: 'НПР1',
      value: portfolioStore.npr1,
    },
  ];

  const progress = portfolioStore.portfolioWeight > 100 ? 100 : portfolioStore.portfolioWeight;

  return (
    <div className="strategy-portfolio">
      <Navigation items={navLinks} />
      <table className="strategy-portfolio__params">
        <tbody>
          <tr>
            <td className="strategy-portfolio__params-item strategy-portfolio__params-size">
              <FontAwesomeIcon icon={faUser} className="footer__icon" />
            </td>
            <td className="strategy-portfolio__params-item">Клиентов</td>
            <td className={'strategy-portfolio__params-item strategy-portfolio__params-item-right'}>
              {portfolioStore?.clients}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="strategy-portfolio__currency">
        Валюта стратегии: <span className="strategy-portfolio__currency-value">{portfolioStore?.currency}</span>
      </div>
      <table className="strategy-portfolio__params">
        <tbody>
          {params.map(({ icon, name, value, className }, index) => (
            <tr key={index}>
              <td className="strategy-portfolio__params-item strategy-portfolio__params-size">{icon}</td>
              <td
                className="strategy-portfolio__params-item"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }}
              ></td>
              <td className={cn('strategy-portfolio__params-item strategy-portfolio__params-item-right', className)}>
                {value}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="strategy-portfolio__progress-bar-wrapper">
        <div className="strategy-portfolio__progress-bar" style={{ width: `${progress}%` }} />
      </div>

      <div className="strategy-portfolio__signal">
        <ProtectedComponent userAction={UserAction.strategyAdd}>
          {isChild && (
            <span className="error-message">
              Данная стратегия является дочерней и дублирует сделки стратегии «{parentStrategy}». Самостоятельная подача
              сигнала по данной стратегии невозможна
            </span>
          )}
          {!isChild && (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) && (
            <>
              <Button
                className="button__primary button__small"
                disabled={!!endTimerDate || strategy?.isFrozen}
                onClick={openModal}
              >
                Новый сигнал
              </Button>
              {endTimerDate && (
                <div className="strategy-portfolio__timer">
                  До возможности подачи нового сигнала осталось{' '}
                  <Timer endDate={endTimerDate} timeFinishCallback={checkSignal} />
                </div>
              )}
              {strategy?.isFrozen && <div className="strategy-portfolio__timer">Стратегия заморожена</div>}
            </>
          )}
        </ProtectedComponent>
      </div>

      <div className="strategy-portfolio__chart">
        <PieChart data={portfolioStore.positions} />
      </div>
      <ChartLegend positions={portfolioStore.positions} />
    </div>
  );
};
