import { UserRoles } from './userRoles';
import { Nullable } from '../utils/types';

export enum UserType {
  Insider = 0,
  NonInsider = 1,
  Org = 2,
  Representative = 3,
}

export type AggregateUser = {
  id: number;
  masterId: Nullable<number>;
  role: UserRoles;
  login: string;
  userType: UserType;
  alias: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  midName: Nullable<string>;
  password: string;
  phone: string;
  // for authors
  company: Nullable<string>;
  orgInn: Nullable<string>;
  orgOgrn: Nullable<string>;
  orgKpp: Nullable<string>;
  passportId: Nullable<string>;
  passportIssued: Nullable<string>;
  issueDate: Nullable<string>;
  employeeId: Nullable<string>;
  agreementNum: Nullable<string>;
  comission: boolean;
  position: Nullable<string>;
  photo: string;
  photoFormat: Nullable<string>;
  photoChanged: boolean;
  //photoBase64: Nullable<string>;
  email: Nullable<string>;
  infoHtml: string;
  shortinfoHtml: Nullable<string>;
  infoText: Nullable<string>;
  shortinfoText: Nullable<string>;
  //rating: Nullable<string>;
};
