import React, { useState, useMemo } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { queryExternalClients } from '../../../api';
import { Input } from '../../../components/FormControls/Input';
import { Select } from '../../../components/FormControls/Select';
import { PageParams, SortType, TableGrid } from '../../../components/TableGrid';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../../const/validation';
import { ServicesConfig$ } from '../../../effector/servicesConfig';
import { Strategies$ } from '../../../effector/strategies';
import { User$ } from '../../../effector/user';
import { StatusEnum } from '../../../types/clientDetails';
import { ExternalClient } from '../../../types/externalClient';
import { Option } from '../../../types/option';
import { UserAction } from '../../../utils/permissions';

type FormFields = {
  contract: string;
  strategy: Option[];
  status: { id: StatusEnum; name: string }[];
};

type FormProps = {
  send: boolean;
} & FormikProps<FormFields>;

type QueryParams = {
  contractMask: string;
  strategies: string[];
  types: StatusEnum[];
} & PageParams;

const validationSchema = Yup.object().shape({
  contract: Yup.string().required(ERRORS_TEXTS.required),
});

const init: FormFields = {
  contract: '',
  strategy: [],
  status: [],
};

const items = [
  {
    Header: 'Номер Г/С',
    accessor: 'contract',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Фамилия',
    accessor: 'lastName',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Имя',
    accessor: 'firstName',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Отчество',
    accessor: 'midName',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Счет',
    accessor: 'tradeCode',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Счет Futures',
    accessor: 'futCode',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Квал',
    accessor: 'isQual',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Риск-профиль',
    accessor: 'riskName',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Обновлено',
    accessor: 'eventTime',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Стратегия',
    accessor: 'strategyName',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Тип',
    accessor: 'strategyType',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Статус',
    accessor: 'strategyStatus',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
  {
    Header: 'Время',
    accessor: 'changeTime',
    className: 'table__column-center',
    action: UserAction.administrationClients,
  },
];

const followTypes = [
  { id: StatusEnum.AutoFollow, name: 'AC' },
  { id: StatusEnum.Recommendations, name: 'AK' },
  { id: StatusEnum.InvestMoneybox, name: 'ИнвестКопилка' },
];

const FormTemplate: React.FC<FormProps> = (props) => {
  const strategies = useStore(Strategies$).map((s) => {
    return { id: s.id, name: s.name };
  });

  return (
    <Form className="form" noValidate>
      <div className="form-control-row">
        <Field name="contract" placeholder="Номер соглашения" component={Input} />
      </div>
      <div className="form-control-row">
        <Field
          name="strategy"
          placeholder="название стратегии"
          multiple={true}
          options={strategies}
          component={Select}
        />
      </div>
      <div className="form-control-row">
        <Field name="status" placeholder="тип следования" multiple={true} options={followTypes} component={Select} />
      </div>
      <div className="form-control-row">
        <Button className="button__primary button__large" disabled={props.send} type={ButtonType.submit}>
          Проверить
        </Button>
      </div>
    </Form>
  );
};

export const Clients: React.FC = () => {
  const user = useStore(User$);

  const config = useStore(ServicesConfig$);
  const [clients, setClients] = useState<ExternalClient[]>([]);
  const [send, setSend] = useState<boolean>(false);

  const columns = useMemo(() => {
    if (!user) return [];

    return items;
  }, [user]);

  const onSubmit = (values: FormFields) => {
    const data: QueryParams = {
      pageNumber: 1,
      pageSize: 20,
      sortFieldName: 'contract',
      sortDirection: SortType.Ask,
      contractMask: values.contract,
      strategies: values.strategy?.map((s) => s.id) ?? [],
      types: values.status?.map((s) => s.id) ?? [],
    };

    setSend(true);

    queryExternalClients(data)
      .then(({ result, success }) => {
        if (success && result && Array.isArray(result)) {
          setClients(result as ExternalClient[]);
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
      .finally(() => setSend(false));
  };

  if (!config?.isEsDb)
    return (
      <div className="client-params__item-name">
        <p className="error-message-left">Просмотр клиентов недоступен на сервере.</p>
        <p>Для подключения измените настройки подключения к БД Сервиса внешних данных и адрес сервиса на сервере.</p>
      </div>
    );

  return (
    <>
      <div className="table-filter">
        <div className="table-filter__title">Фильтры клиента</div>
        <Formik initialValues={init} validationSchema={validationSchema} onSubmit={onSubmit}>
          {(props) =>
            React.createElement(
              FormTemplate,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              {
                ...props,
                send,
              },
            )
          }
        </Formik>
      </div>
      <TableGrid
        columns={columns}
        data={clients}
        sortField="contract"
        pageSize={20}
        totalRecords={clients.length}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onPageChange={() => {}}
      />
    </>
  );
};
