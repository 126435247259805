/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getForbiddenSecurities } from '../api';
import { SecurityInfo } from '../types/marketSchedule';

export const forbidden$ = createStore<SecurityInfo[]>([]);

const helper = createApi(forbidden$, {
  // @ts-ignore
  set: (state, { result }) => {
    if (result && result.success) return result.result;
  },
  // @ts-ignore
  update: (state, securities: SecurityInfo[]) => {
    if (securities) {
      return securities;
    }

    return state;
  },
});

export const forbiddenApi = {
  get: createEffect<{}, SecurityInfo[], undefined>().use(getForbiddenSecurities),
  set: createEffect<SecurityInfo[], SecurityInfo[], undefined>(),
};

forward({
  from: forbiddenApi.get.done,
  to: helper.set,
});

forward({
  from: forbiddenApi.set,
  to: helper.update,
});
