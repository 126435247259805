/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';

import cn from 'classnames';
import { FieldInputProps, useField } from 'formik';
import SelectField, { OnChangeValue } from 'react-select';

import { useFocusOnError } from '../../../hooks/useFocusOnError';
import { Option } from '../../../types/option';

type Props = {
  field: FieldInputProps<any>;
  options: Option[];
  placeholder: string;
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
  isClearable?: boolean;
  onChange?: (options: OnChangeValue<Option, boolean>) => void;
};

export const Select: React.FC<Props> = ({
  field: fieldProp,
  options,
  placeholder,
  className,
  disabled,
  multiple = false,
  isClearable,
  onChange,
}) => {
  const [field, meta, helpers] = useField(fieldProp.name);
  const fieldRef = useRef<HTMLElement | null>();
  const name: string = fieldProp.name;

  useFocusOnError({ fieldRef, name });

  const onChangeInternal = (value: OnChangeValue<Option, boolean>) => {
    if (value) {
      helpers.setValue(value);
    } else {
      helpers.setValue([]);
    }

    onChange?.(value);
  };

  const isError = meta.touched && meta.error;

  return (
    <div className={cn('select', className)}>
      <SelectField
        ref={(x) => {
          if (x) {
            // @ts-ignore
            const xn = x?.select?.inputRef;
            fieldRef.current = xn;
          }
        }}
        value={field.value}
        key={field.value}
        className={cn('select__control', { 'form-control__field-invalid': isError })}
        classNamePrefix="react-select"
        isDisabled={disabled}
        placeholder={placeholder}
        options={options}
        isMulti={multiple}
        isClearable={isClearable}
        onChange={onChangeInternal}
        onBlur={() => helpers.setTouched(true)}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
      />
      {isError && <span className="form-control__message">{meta.error}</span>}
    </div>
  );
};
