import React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

export enum ButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

type Props = {
  className?: string;
  type?: ButtonType;
  disabled?: boolean;
  to?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const Button: React.FC<Props> = ({ to, className, type = 'button', disabled = false, onClick, children }) => {
  const Tag = to ? Link : 'button';
  const linkProps = to ? { to: to } : {};

  // eslint-disable-next-line @typescript-eslint/ban-types
  const elementProps: Record<string, string | object | boolean | void> = {
    type,
    disabled,
    onClick,
    className: cn('button', className),
    ...linkProps,
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Tag {...elementProps}>
      <span className="button__text">{children}</span>
    </Tag>
  );
};
