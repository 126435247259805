import React from 'react';

import { Navigate, Route, Routes as RoutesComponent } from 'react-router-dom';

import { Iir } from './Iir';
import { Order } from './Order';
import { Navigation } from '../../components/Navigation';
import { PAGE_AUDIT_IIR, PAGE_AUDIT_ORDER } from '../Routes';

export const Audit: React.FC = () => {
  const items = [
    {
      name: 'Журнал ИИР',
      path: PAGE_AUDIT_IIR,
    },
    {
      name: 'Журнал исходящих поручений ',
      path: PAGE_AUDIT_ORDER,
    },
  ];

  return (
    <div className="statistic">
      <div className="statistic__navigation">
        <Navigation items={items} />
      </div>
      <div>
        <RoutesComponent>
          <Route path={PAGE_AUDIT_IIR} element={<Iir />} />
          <Route path={PAGE_AUDIT_ORDER} element={<Order />} />
          <Route path="*" element={<Navigate replace to={PAGE_AUDIT_IIR} />} />
        </RoutesComponent>
      </div>
    </div>
  );
};
