import React from 'react';

import { useStore } from 'effector-react';

import { LineChart } from '../../../../components/Chart/LineChart';
import { ClientDetails$ } from '../../../../effector/clientDetails';

export const ProfitChart: React.FC = () => {
  const { chart } = useStore(ClientDetails$);

  return (
    <div className="form__group">
      <div className="client-details__linechart">
        {chart.length > 0 && (
          <LineChart
            data={chart}
            legends={{
              v: 'доходность стратегии',
              vr: 'доходность клиента',
            }}
          />
        )}
      </div>
    </div>
  );
};
