/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';

import { analyticsApi } from '../../effector/analytics';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { Analytic } from '../../types/analytic';
import { Button, ButtonType } from '../UIKit/Button';

export const RemoveAnalytic: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const modalsState = useStore<TModal$State<{ analytic: Analytic }>>(Modals$);

  const analytic = modalsState.data?.analytic;

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      // @ts-ignore
      modalApi.hide('');
    }
  };

  const removeAnalytic = () => {
    const data = {
      ...analytic,
      active: !analytic?.active,
    };

    setLoading(true);
    analyticsApi
      .add(data)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const status = analytic?.active ? 'Неактивный' : 'Активный';

  return (
    <div className="remove-delayed-signal">
      <div className="remove-delayed-signal__description">Вы уверены что хотите сменить статус статьи на {status}?</div>
      {error && <p className="error-message">{error}</p>}
      <div className="remove-delayed-signal__button-wrapper">
        <Button
          className="button__primary button__large"
          onClick={removeAnalytic}
          disabled={loading}
          type={ButtonType.button}
        >
          Изменить
        </Button>
      </div>
    </div>
  );
};
