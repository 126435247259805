/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { sendEmail } from '../../../../api';
import { Checkbox } from '../../../../components/FormControls/Checkbox';
import { File } from '../../../../components/FormControls/File';
import { Input } from '../../../../components/FormControls/Input';
import { Select } from '../../../../components/FormControls/Select';
import { Button, ButtonType } from '../../../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../../../const/validation';
import { strategiesApi } from '../../../../effector/strategies';
import { StrategyDictionary$ } from '../../../../effector/strategyDictionary';
import { Option } from '../../../../types/option';
import { Nullable } from '../../../../utils/types';

const initialValues = {
  subject: '',
  document: '',
  strategy: '',
  email: '',
  isChecked: false,
  documentLoaded: false,
};

const validationSchema = Yup.object().shape({
  subject: Yup.string().required(ERRORS_TEXTS.required),
  strategy: Yup.object().required(ERRORS_TEXTS.required),
  document: Yup.string(),
  email: Yup.string(),
  documentLoaded: Yup.boolean(),
});

type FormFields = {
  subject: string;
  document: string;
  email: string;
  strategy: Nullable<Option>;
  isChecked: boolean;
  documentLoaded: boolean;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ values, errors, dirty, error, loading, setFieldValue }) => {
  const strategies = useStore(StrategyDictionary$);

  const disable =
    Object.keys(errors).length > 0 || !dirty || loading || !values.document || !values.subject || !values.strategy;

  const setDocument = useCallback(
    (field: string, fieldValue: string) => {
      setFieldValue(field, fieldValue);
      setFieldValue('documentLoaded', true);
    },
    [setFieldValue],
  );

  const onRemove = useCallback(() => {
    setFieldValue('document', '');
    setFieldValue('documentLoaded', false);
  }, [setFieldValue]);

  return (
    <Form className="form analytic-form" noValidate>
      <div className="form__group">
        <Field name="subject" placeholder="Тема письма" component={Input} />
      </div>

      <div className="form__group">
        <Field name="email" placeholder="Тестовый email" component={Input} />
      </div>

      {strategies && (
        <div className="form__group">
          <Field name="strategy" component={Select} placeholder="Стратегия" options={strategies} />
        </div>
      )}

      <div className="form__group">
        <Field
          name="document"
          placeholder="Шаблон письма"
          url="/api/admin/files/upload_tmp"
          fileTypes={['.htm']}
          onRemove={onRemove}
          onUpload={setDocument}
          component={File}
        />
      </div>

      <div className="form__group">
        <Field name="isChecked" text="Шаблон проверен" component={Checkbox} />
      </div>

      <Button className="button__primary button__large button__wide" type={ButtonType.submit} disabled={disable}>
        Отправить
      </Button>

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const Strategy: React.FC = ({}) => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (values: FormFields) => {
      setLoading(true);

      const payload = {
        templateFileName: values.document,
        subject: values.subject,
        isChecked: values.isChecked,
        ...(values.email && { testEmail: values.email }),
        strategyId: values.strategy?.id as string,
        skip: 0,
      };

      let allEmailsSent = false;

      while (true) {
        try {
          const response = await sendEmail(payload);

          if (response.success) {
            const { total = 0, sent = 0 } = response.result;

            console.log(`Total clients: ${total}, Sent: ${sent}`);

            if (sent >= total) {
              console.log('All emails have been sent.');
              allEmailsSent = true;
              break;
            }

            payload.skip = sent;
          } else {
            setError(response.errorMessage);
            break;
          }
        } catch (error) {
          // @ts-ignore
          setError(error.toString());
          break;
        } finally {
          setLoading(false);
        }
      }

      if (allEmailsSent) {
        enqueueSnackbar('Письма отправлены');
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    strategiesApi.get('');
  }, []);

  return (
    <Formik
      // @ts-ignore
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) =>
        React.createElement(
          FormTemplate,
          // @ts-ignore
          {
            ...props,
            error,
            loading,
          },
        )
      }
    </Formik>
  );
};
