/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getServicesConfig } from '../api';
import { ServicesConfig } from '../types/servicesConfig';
import { Nullable } from '../utils/types';

type ConfigState = Nullable<ServicesConfig>;

export const ServicesConfig$ = createStore<ConfigState>(null);

const apiHandler = createApi(ServicesConfig$, {
  // @ts-ignore
  set: (state, { result }) => {
    if (result && result.success) return result.result ?? null;
    return state;
  },
});

export const servicesApi = {
  get: createEffect<{}, ServicesConfig, undefined>().use(getServicesConfig),
};

forward({
  from: servicesApi.get.done,
  to: apiHandler.set,
});
