/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Nullable } from '../../../utils/types';
import { COLORS, FREE_MONEY_COLOR } from '../colors';

export const FREE_MONEY = 'Свободные средства';

export type PiePosition = {
  symbol: string | null;
  name: Nullable<string>;
  number?: number;
  currency?: string;
  weight: number;
  avgPrice?: number;
  lastPrice?: number;
};

type Props = {
  data: PiePosition[];
};

export const PieChart: React.FC<Props> = ({ data }) => {
  const dataSeries = data.map((item, key) => ({
    color: item.name !== FREE_MONEY ? COLORS[key] : FREE_MONEY_COLOR,
    name: item.name as string,
    symbol: item.symbol,
    y: parseFloat(Math.abs(item.weight).toFixed(2)),
  }));

  const options: Options = {
    chart: {
      type: 'pie',
      spacing: [0, 0, 0, 0],
      height: '100%',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        size: '75%',
        states: {
          hover: {
            halo: {
              size: 0,
              opacity: 0,
            },
          },
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '<span style="background-color: #fff">{point.symbol} {point.y} %</span>',
          // @ts-ignore
          padding: 7,
          outside: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '',
    },
    // @ts-ignore
    series: [
      {
        type: 'pie',
        data: dataSeries,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
