/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { FieldInputProps, useField } from 'formik';

import { Upload } from './Request/upload';

type Props = {
  field: FieldInputProps<any>;
  url: string;
  name: string;
  fileTypes: string[];
  sizeView: 'kb' | 'mb';
  uploadedFileName?: string;
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  onUpload: (fieldName: string, fileName: string) => null;
  onRemove: (fileName: string) => null;
};

export const File: React.FC<Props> = ({
  field: fieldProp,
  url,
  fileTypes,
  sizeView = 'kb',
  uploadedFileName,
  placeholder,
  disabled,
  onRemove,
  onUpload,
  multiple = false,
}) => {
  const [, meta] = useField(fieldProp.name);
  const isError = meta.touched && meta.error;

  const styles = {
    fontSize: '14px',
    fontWeight: 600,
    color: '#9598a7',
    marginBottom: '5px',
  };

  return (
    <>
      <div style={styles}>{placeholder}</div>
      <Upload
        url={url}
        fileTypes={fileTypes}
        sizeView={sizeView}
        uploadedFileName={uploadedFileName}
        multiple={multiple}
        disabled={disabled}
        fieldName={fieldProp.name}
        onRemove={onRemove}
        onUploaded={onUpload}
      />
      {isError && <span className="form-control__message">{meta.error}</span>}
    </>
  );
};
