export const getCorrectServiceType = (value: string) => {
  if (value === 'Накопилка' || value === 'НК') {
    return 'Накопилка';
  }

  if (value === 'Стратегия АС' || value === 'АС') {
    return 'Автоследование';
  }

  if (value === 'Стратегия АК' || value === 'АК') {
    return 'Автоконсультирование';
  }

  return value;
};

export function onlyUnique(value: any, index: number, array: any) {
  return array.indexOf(value) === index;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export function sortColumnFn(col1, col2, prop) {
  const first = col1.original[prop];

  const second = col2.original[prop];

  return first > second ? 1 : first < second ? -1 : 0;
}
