/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { userInfoApi } from './userInfoList';
import { getUsersAdministration, removeUser, updateUser } from '../api';
import { PageParams } from '../components/TableGrid';
import { AggregateUser } from '../types/aggregateUser';
import { StandartResponse } from '../types/standartResponse';

type TAggregateUsers$State = {
  total: number;
  users: AggregateUser[];
};

const initialState: TAggregateUsers$State = {
  total: 0,
  users: [],
};

export const AggregateUsers$ = createStore<TAggregateUsers$State>(initialState);

const { set, add, remove } = createApi(AggregateUsers$, {
  // @ts-ignore
  set: (state, { result }) => {
    const data = {
      // @ts-ignore
      total: result.result.total,
      // @ts-ignore
      users: result.result.pageItems,
    };

    return data;
  },
  // @ts-ignore
  add: (state, { result }) => {
    if (result.success) {
      // @ts-ignore
      const updated = state.users.filter((item) => item.id !== result.result.id);

      return {
        ...state,
        users: [...updated, result.result],
      };
    }

    return state;
  },
  // @ts-ignore
  remove: (state, { result }: { result: StandartResponse<AggregateUser> }) => {
    if (result && result.success && result.result) {
      const id = result.result.id;
      // @ts-ignore
      const updated = state.users.filter((u) => u.id !== id);

      return {
        total: updated.length,
        users: updated,
      };
    }

    return state;
  },
});

export const aggregateUsersApi = {
  get: createEffect<PageParams, {}, {}>().use(getUsersAdministration),
  add: createEffect<Partial<AggregateUser>, {}, {}>().use(updateUser),
  remove: createEffect<number, StandartResponse<AggregateUser>, {}>().use(removeUser),
};

forward({
  from: aggregateUsersApi.get.done,
  to: set,
});

forward({
  from: aggregateUsersApi.add.done,
  to: add,
});

forward({
  from: aggregateUsersApi.add.done,
  to: userInfoApi.add,
});

forward({
  from: aggregateUsersApi.remove.done,
  to: remove,
});
