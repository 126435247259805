import React from 'react';

import { COLORS, FREE_MONEY_COLOR } from '../colors';
import { PiePosition, FREE_MONEY } from '../PieChart';

export const ChartLegend: React.FC<{ positions: PiePosition[] }> = ({ positions }) => {
  return (
    <>
      <div>
        <table className="chart-legend">
          <tbody>
            {positions.map(({ symbol, name, number, currency, weight, avgPrice, lastPrice }, key) => (
              <tr key={key}>
                <td className="chart-legend__column">
                  <div
                    className="chart-legend__color"
                    style={{
                      backgroundColor: name !== FREE_MONEY ? COLORS[key] : FREE_MONEY_COLOR,
                    }}
                  />
                </td>
                <td className="chart-legend__column">
                  <div>
                    {name === FREE_MONEY
                      ? `Свободные средства, ${currency} ${number} (${weight}%)`
                      : `${symbol} ` +
                        (name && name !== null ? `(${name}) ` : '') +
                        (number && avgPrice && lastPrice
                          ? `${number} x ${avgPrice} -> ${lastPrice} (${weight}%)`
                          : ` ${weight}%`)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
