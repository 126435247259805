/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import cn from 'classnames';
import { FieldInputProps, useField } from 'formik';

type Props = {
  field: FieldInputProps<any>;
  className?: string;
  text: string;
  checked: boolean;
  placeholder: string;
  disabled?: boolean;
  onChange: () => void;
};

export const Radio: React.FC<Props> = ({ field: fieldProp, className, text, checked, disabled, onChange }) => {
  const [field, meta] = useField(fieldProp.name);
  const isError = meta.touched && meta.error;

  return (
    <label className={cn('radio', className)}>
      <input
        type="radio"
        className="radio__control"
        {...field}
        required
        disabled={disabled}
        value={field.value}
        checked={checked}
        onChange={onChange}
      />
      <span className="radio__text">{text}</span>
      {isError && <span className="form-control__message">{meta.error}</span>}
    </label>
  );
};
