/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SyntheticEvent, useState } from 'react';

import cs from 'classnames';
import { FieldInputProps, useField } from 'formik';
import InputMask from 'react-input-mask';

type Props = {
  field: FieldInputProps<any>;
  className?: string;
  type: string;
  placeholder: string;
};

export const Phone: React.FC<Props> = ({ field: fieldProp, className, type, placeholder }) => {
  const [focus, setFocus] = useState(false);
  const [field, meta, helpers] = useField(fieldProp.name);

  const isError = meta.touched && meta.error;
  const isDirty = meta.value !== meta.initialValue;

  const onFocus = () => {
    if (!meta.touched) {
      if (!field.value || field.value.length === 0) {
        helpers.setValue('+7 ');
      }
    }
    setFocus(true);
  };

  const onBlur = (e: SyntheticEvent) => {
    field.onBlur(e);
    setFocus(false);
  };

  return (
    <label
      className={cs('form-control', className, {
        'form-control-focus': focus || isDirty || field.value,
      })}
    >
      <InputMask
        className={cs('form-control__field', {
          'form-control__field-invalid': isError,
        })}
        mask="+9 999 999 99 99"
        placeholder={placeholder}
        type={type}
        required
        {...field}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {isError && <span className="form-control__message">{meta.error}</span>}
      <span className="form-control__placeholder">{placeholder}</span>
    </label>
  );
};
