/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import {
  faArrowDown,
  faArrowUp,
  faDollarSign,
  faDownload,
  faTimes,
  faPercent,
  faQuestion,
  faRandom,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import download from 'downloadjs';
import { useStore } from 'effector-react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Tooltip } from 'react-tippy';

import { downloadDeals, downloadSandboxDeals } from '../../../api';
import { PageParams, TableGrid } from '../../../components/TableGrid';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { Deals$, dealsApi } from '../../../effector/deals';
import { modalApi } from '../../../effector/modals';
import { Strategies$ } from '../../../effector/strategies';
import { DealType } from '../../../types/deal';
import { StrategyStatus } from '../../../types/strategy';
import { MODALS } from '../../../utils/types';

type Props = {
  strategyId: string | undefined;
};

export const Deals: React.FC<Props> = ({ strategyId }) => {
  const dealsStore = useStore(Deals$);
  const strategies = useStore(Strategies$);
  const { enqueueSnackbar } = useSnackbar();

  const strategy = strategies.find((item) => item.id === strategyId);

  const loadDeals = (data: PageParams) => {
    const params = {
      strategyId: strategyId,
      data: data,
    };

    if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
      dealsApi.get(params);
    } else {
      dealsApi.getSandbox(params);
    }
  };

  const onDownload = () => {
    if (strategy?.status === StrategyStatus.SandBox || strategy?.status === StrategyStatus.Moderate) {
      downloadSandboxDeals(strategyId).then((response) => {
        const { success, result, errorMessage } = response;

        if (success) {
          download(
            `data:application/octet-stream;base64,${result.contentBase64}`,
            `${result.fileName}`,
            'application/octet-stream',
          );
        } else {
          enqueueSnackbar(errorMessage);
        }
      });
    } else {
      downloadDeals(strategyId).then((response) => {
        const { success, result, errorMessage } = response;

        if (success) {
          download(
            `data:application/octet-stream;base64,${result.contentBase64}`,
            `${result.fileName}`,
            'application/octet-stream',
          );
        } else {
          enqueueSnackbar(errorMessage);
        }
      });
    }
  };

  const getTooltip = (type: DealType, comment: string) => {
    let tooltip = comment || '';

    switch (type) {
      case DealType.Dividend:
        tooltip = tooltip == '' ? 'Выплата дивидендов' : tooltip;
        break;
      case DealType.Coupon:
        tooltip = 'Начисление купонного дохода';
        break;
      case DealType.Varmargin:
        tooltip = 'Клиринг';
        break;
    }

    return tooltip;
  };

  const columns = [
    {
      id: 'profit',
      Header: '',
      accessor: 'weight',
      className: 'table__column-center',
      Cell: ({
        cell: {
          row: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            original: { weight, type },
          },
        },
      }) => {
        let icon;
        let classes = weight > 0 ? 'rise' : weight === 0 ? 'neutral' : 'fall';
        const isUp = weight > 0;

        switch (type) {
          case DealType.Standard:
            icon = isUp ? faArrowUp : faArrowDown;
            break;
          case DealType.Close:
            icon = faTimes;
            break;
          case DealType.Dividend:
          case DealType.Coupon:
            icon = faDollarSign;
            classes = '';
            break;
          case DealType.Varmargin:
            icon = faPercent;
            break;
          case DealType.Replace:
            icon = faRandom;
          default:
            icon = faQuestion;
            classes = 'neutral';
            break;
        }

        return <FontAwesomeIcon className={classes} icon={icon} />;
      },
    },
    {
      Header: 'Инструмент',
      accessor: 'name',
      className: 'table__column-left',
      Cell: ({
        cell: {
          row: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            original: { name, symbol, comment, type },
          },
        },
      }) => {
        const tooltip = getTooltip(type, comment);

        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // tslint:disable-next-line: jsx-wrap-multiline
          <Tooltip theme="light" title={tooltip} arrow={true} position="top" trigger="mouseenter">
            <span>
              {name} ({symbol})
            </span>
          </Tooltip>
        );
      },
    },
    {
      Header: 'Доля %',
      accessor: 'weight',
      className: 'table__column-right',
    },
    {
      Header: 'Цена',
      accessor: 'price',
      className: 'table__column-right',
    },
    {
      Header: 'Время открытия',
      accessor: 'time',
      className: 'table__column-left',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { time },
          },
        },
      }) => {
        const timeFormated = moment(time).format('DD.MM.YYYY HH:mm');

        return <span>{timeFormated}</span>;
      },
    },
    {
      Header: 'Исполнение',
      accessor: 'execPrice',
      className: 'table__column-right',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { isInProcess, execPrice, nExecPrice },
          },
        },
      }) => {
        return isInProcess ? (
          <span>в процессе</span>
        ) : (
          <span>
            {execPrice} ({nExecPrice})
          </span>
        );
      },
    },
    {
      Header: 'Realized PnL',
      accessor: 'realizedPL',
      className: 'table__column-right',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { realizedPL },
          },
        },
      }) => {
        return <span className={cn({ fall: realizedPL < 0, rise: realizedPL > 0 })}>{realizedPL}</span>;
      },
    },
    {
      Header: '',
      accessor: 'comment',
      className: 'table__column-right table__column-action',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { time },
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faComment}
              title="Изменить комментарий"
              onClick={() => {
                // @ts-ignore
                modalApi.show({
                  modalId: MODALS.COMMENT,
                  data: {
                    strategyId,
                    date: time,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Button
        className="button button__download button__margin-top button__small"
        type={ButtonType.button}
        onClick={onDownload}
      >
        <FontAwesomeIcon icon={faDownload} /> Скачать историю сделок
      </Button>
      <TableGrid
        columns={columns}
        data={dealsStore.deals}
        sortField="time"
        pageSize={25}
        totalRecords={dealsStore.totalDeals}
        onPageChange={loadDeals}
      />
    </>
  );
};
