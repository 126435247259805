/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import image1 from './assets/image1.png';
import image10 from './assets/image10.png';
import image11 from './assets/image11.png';
import image12 from './assets/image12.png';
import image13 from './assets/image13.png';
import image14 from './assets/image14.png';
import image15 from './assets/image15.png';
import image16 from './assets/image16.png';
import image17 from './assets/image17.png';
import image18 from './assets/image18.png';
import image19 from './assets/image19.png';
import image2 from './assets/image2.png';
import image20 from './assets/image20.png';
import image3 from './assets/image3.png';
import image4 from './assets/image4.png';
import image5 from './assets/image5.png';
import image6 from './assets/image6.png';
import image7 from './assets/image7.png';
import image8 from './assets/image8.png';
import image9 from './assets/image9.png';

export const Instruction: React.FC = () => (
  <div className="instruction">
    <div className="instruction__title">Правила подачи и обработки сигналов автором стратегии</div>
    <div className="instruction__section">
      <div className="instruction__section-title">Рыночные сигналы </div>
      <div className="instruction__section-subtitle">1. Открытие позиции</div>
      <p className="instruction__paragraph">При подаче рыночного сигнала система устанавливает ряд ограничений.</p>
      <ul className="instruction__list">
        <li>
          <b>Минимальный объем</b> – параметр показывает минимально допустимый объем сделки <br />
          в процентах. Параметр рассчитывается на основе минимального порога входа в <br />
          стратегию и стоимости лота инструмента, по которому планируется сделка.
          <br />
          Ограничение обеспечивает исполнение сигнала у всех клиентов, подключенных на стратегию.
          <span className="instruction__italic">
            <br />
            Пример:
            <br />
            минимальная сумма на стратегию: 15000 тысяч рублей 
            <br />
            стоимость одного лота: 2025.80 рублей
            <br />
            <br />
            Минимальный объем сделки: 13.5%
          </span>
        </li>
        <li>
          <b>Максимальный объем</b> – устанавливает максимальный объем сделки в соответствии с<br />
          паспортом стратегии
        </li>
        <li>
          <b>Ограничения SL/TP</b> –c целью минимизации заведомо убыточных для клиентов сделок
          <br />
          ограничения по уровням TP и SL устанавливаются на уровнях, отличающихся от <br />
          текущей цены не менее чем на 0.2%
        </li>
      </ul>
      <p className="instruction__paragraph">
        В случае если устанавливаемые автором уровни не попадают в разрешенные диапазоны – в форме
        <br />
        подачи сигнала появляется соответствующее предупреждение, и отправка сигнала <br />
        будет невозможна (кнопка неактивна).
      </p>
      <p className="instruction__paragraph">
        В случае если в портфеле уже есть открытая позиция по выбранному инструменту,
        <br />
        ее объем отобразится в поле «текущая позиция» и также будет участвовать в<br />
        расчете максимально допустимого объема новой сделки.
      </p>
      <img src={image1} className="instruction__image" />

      <div className="instruction__section-subtitle">2. Полное закрытие позиции</div>
      <p className="instruction__paragraph">
        При установленных в сигнале уровнях SL или TP закрытие позиции произойдет
        <br />
        автоматически при достижении цены указанного уровня.
      </p>
      <p className="instruction__paragraph">
        Для ручного закрытия позиции по текущей цене необходимо нажать на «Х» справа от <br />
        соответствующей позиции во вкладке «открытые позиции».
      </p>
      <img src={image2} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        После нажатия появится всплывающее окно с подтверждением сделки, в котором автор по <br />
        желанию может указать комментарий. Комментарии автора к сделкам автоматически <br />
        публикуются на сайте fintarget.ru в блоке «Последние сделки»
      </p>
      <img src={image3} className="instruction__image" />
      <div className="instruction__section-subtitle">3. Частичное закрытие позиции по рынку</div>
      <p className="instruction__paragraph">
        Для частичного закрытия позиции необходимо сформировать сигнал по нужному инструменту
        <br />и указать соответствующую долю, которую необходимо закрыть.
      </p>
      <p className="instruction__paragraph">
        При этом не допускается частичное закрытие с таким объемом сделки, в результате
        <br />
        исполнения которого, оставшаяся доля по позиции будет меньше, чем минимально
        <br />
        допустимая доля по данному инструменту.
      </p>
    </div>

    <div className="instruction__section">
      <div className="instruction__section-title">Отложенные сигналы </div>
      <p className="instruction__paragraph">
        Отложенный сигнал позволяет автоматически выставлять сигналы по <br />
        инструменту при достижении заданной цены.
      </p>
      <p className="instruction__paragraph">
        Отложенный сигнал имеет такие же параметры, как у рыночного сигнала: <br />
        "Объем позиции в %", уровень Stop Loss (опционально), уровень Take Profit <br />
        (опционально) и тип сигнала – Покупка, Продажа.
      </p>
      <p className="instruction__paragraph">Отличием является наличие "цены исполнения":</p>
      <img src={image4} className="instruction__image" />

      <p className="instruction__paragraph">
        Срабатывание отложенного сигнала происходит один раз и только в <br />
        торговое время. При подаче отложенного сигнала есть возможность выбрать тип <br />
        исполнения: "Немедленное исполнение", либо "С предварительной <br />
        активацией".
      </p>
      <img src={image5} className="instruction__image" />

      <p className="instruction__paragraph">
        Сигнал срабатывает при достижении текущей цены уровня цены <br />
        исполнения, при этом надо учитывать направление сигнала. Если цена <br />
        исполнения была выше текущей цены, то сигнал сработает в момент, когда <br />
        текущая цена превысит цену исполнения или станет ей равна, и наоборот, <br />
        если цена исполнены была ниже текущей цены, то сигнал сработает в <br />
        момент, когда текущая цена опустится ниже цены исполнения или станет ей <br />
        равна.
      </p>
      <p className="instruction__paragraph">
        При срабатывании отложенного сигнала типа "Немедленное исполнение" <br />
        генерируется обычный сигнал с весом, Stop Loss, Take Profit <br />
        установленными при подаче отложенного сигнала и рыночной ценой на <br />
        момент срабатывания, и далее исполняется по клиентам.
      </p>
      <p className="instruction__paragraph">
        При срабатывании сигнала типа "С предварительной активацией" <br />
        осуществляется дополнительная сверка текущей цены с ценой исполнения <br />
        и если текущая цена вышла за диапазон +- 0.1% от цены исполнения, то <br />
        сигнал "активируется" и становится отложенным сигналом типа <br />
        "Немедленное исполнение". Если при срабатывании цена не вышла за <br />
        диапазон, то генерируется обычный сигнал с весом, Stop Loss, Take Profit <br />
        установленными при подаче отложенного сигнала и установленной ценой <br />
        исполнения.
      </p>
      <p className="instruction__paragraph">
        Отложенные сигналы типа «Немедленное исполнение» имеют статус «Ждём <br />
        исполнения». Отложенные сигнала типа «С предварительной активацией» <br />
        имеют статус «Ждём активации» в закладке Отложенные сигналы <br />в Стратегии
      </p>
      <img src={image6} className="instruction__image" />
      <p className="instruction__paragraph">
        В том же разделе отображается направление сигнала в зависимости от цены <br />
        инструмента в момент создания отложенного сигнала: если она ниже цены <br />
        исполнения, то отложенный будет обозначаться с направлением "вверх" и <br />
        сработает, когда цена инструмента превысит или сравняется с ценой <br />
        исполнения. И наоборот, если цена инструмента при подаче отложенного <br />
        сигнала была выше установленной цены исполнения, то сигнал будет <br />
        обозначатся с направлением "вниз" и сработает если цена инструмента <br />
        будет меньше либо равна цене исполнения.
      </p>
      <img src={image7} className="instruction__image" />
    </div>

    <div className="instruction__section">
      <div className="instruction__section-title">Пакетные заявки</div>
      <p className="instruction__paragraph">
        Пакетная заявка позволяет одновременно исполнить несколько сигналов в рамках Стратегии. Например, если вам нужно
        закрыть несколько текущих позиций и открыть новые.
      </p>
      <p className="instruction__paragraph">
        Пакетная заявка представляет собой набор Рыночных сигналов, которые исполняются одновременно по счетам клиентов
        на Стратегии. Отличием от одиночных Рыночных сигналов является то, что они исполняются не последовательно, а
        сразу. Это позволяет избежать лишних балансировок по счетам. Например, если последовательно выставлять несколько
        одиночных Рыночных сигналов, то в промежутке между ними в Стратегии доля свободных средств будет увеличиваться,
        что может приводить к лишней продаже фин. инструментов на счетах для увеличения доля свободных средств клиентов.
      </p>
      <p className="instruction__paragraph">
        Чтобы подать пакетную заявку нажмите кнопку <b>Новый сигнал</b> на странице вашей Стратегии.
      </p>
      <p className="instruction__paragraph">
        Вверху появившегося окна вы увидете соответствующий вариант сигнала – <b>Пакетная заявка</b>:
      </p>
      <img src={image8} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        Произведите поиск нужного инструмента. Все параметры будут аналогичны <b>Рыночному сигналу</b>: "Объем позиции в
        %", уровень Stop Loss (опционально), уровень Take Profit (опционально) и тип сигнала – Покупка, Продажа,
        Закрытие.
      </p>
      <p className="instruction__paragraph">
        После того как внесены все параметры – нажмите кнопку <b>Добавить в список</b>. Сигнал отобразится в списке
        справа:
      </p>
      <img src={image9} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">Повторите данное действие для всех необходимых инструментов.</p>
      <img src={image10} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        После того как набор инструментов сформирован нажмите кнопку <b>Сформировать сигнал</b>. Вы увидите
        подтверждающее сообщение:
      </p>
      <img src={image11} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        Всё. Сигналы приняты системой. Вы сможете их увидеть в Истории сделок и в открытых позициях (если были открыты
        новые).
      </p>
    </div>

    <div className="instruction__section">
      <div className="instruction__section-title">Портфельный сигнал</div>
      <p className="instruction__paragraph">
        Портфельный сигнал позволяет изменять весь текущий состав стратегии. Например, если вы хотите выровнять доли по
        всем инструментам в стратегии и/или заменить несколько инструментов, либо изменить по ним направление с Long на
        Short или наоборот.
      </p>
      <p className="instruction__paragraph">
        <b>Внимание!</b> Подача портфельного сигнала приводит к полной перебалансировки. Т.е. все текущие позиции в
        стратегии будут закрыты и открыты заново в соответствии с внесенными изменениями в рамках сигнала. На клиентах
        это приведёт к тому что у них пройдет ребалансировка на дельту между изначальной позицией и измененной.
        Например, до подачи сигнала была открыта позиция long по Сбербанку на 10%, после подачи сигнала позиция
        изменилась long по Сбербанку на 12%. В результате клиентам будет докуплено 2% Сбербанка.
      </p>
      <p className="instruction__paragraph">
        Чтобы подать Портфельный сигнал нажмите кнопку <b>Новый сигнал</b> на странице вашей Стратегии.
      </p>
      <p className="instruction__paragraph">
        Вверху появившегося окна Вы увидите соответствующий вариант сигнала&nbsp;&mdash; <b>Портфельный сигнал</b>:
      </p>
      <img src={image12} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        Справа Вы увидите текущей состав стратегии. По значку <img src={image13} /> можно удалить инструмент из состава.
        По значку <img src={image14} /> левая часть окна заполнится данными для редактирования позиции:
      </p>
      <img src={image15} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        Вы можете изменить инструмент, объем, уровни Stop Loss, Take Profit, а также изменить направление позиции с Long
        на Short или наоборот (Внимание! Некоторые инструменты недоступны для позиций Short. Для таких инструментов
        изменить направление позиции невозможно). Внеся все необходимые изменения нажмите кнопку <b>Внести изменения</b>
        . По её нажатию выбранная позиция соответственно изменится в списке справа.
      </p>
      <p className="instruction__paragraph">
        Также Вы можете добавить новый инструмент в стратегию. Для этого не выбирая никакую из позиций в списке справа,
        просто найдите нужный инструмент в поле Поиск инструмента в левой части окна. Тут также можно задать объем,
        уровни Stop Loss, Take Profit и направление сигнала. После внесения всех необходимых данных нажмите кнопку{' '}
        <b>Добавить</b> в список.
      </p>
      <img src={image16} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        Внеся все необходимые изменения нажмите кнопку <b>Сохранить изменения</b>:
      </p>
      <img src={image17} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        После этого система закроет все текущие позиции по стратегии и откроет их заново в соответствии с новым
        составом, который был определен в рамках <b>Портфельного сигнала</b>.
      </p>
    </div>

    <div className="instruction__section">
      <div className="instruction__section-title">Замена позиции</div>
      <p className="instruction__paragraph">
        Если Вы хотите заменить позиции по одному из инструментов в стратегии на другой инструмент сохранив при этом вес
        позиции, то Вы можете воспользоваться функцией <b>Замена позиции</b>.
      </p>
      <p className="instruction__paragraph">
        Данная функция доступна на странице состава Вашей стратегии. Найдите значок <img src={image14} /> справа от
        нужной позиции.
      </p>
      <img src={image18} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        По нажатию на него откроется окно <b>Замена позиции</b>:
      </p>
      <img src={image19} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        В поле Поиск инструмента найдите нужный инструмент и нажмите <b>Заменить</b>
      </p>
      <img src={image20} className="instruction__image instruction__image-large" />
      <p className="instruction__paragraph">
        После этого текущая позиция по старому инструменту будет закрыта и открыта новая позиция с тем же весом по
        новому инструменту.
      </p>
    </div>
  </div>
);
