import { RecalcMode } from '../types/aggregateStrategy';

export const recalcModes: { name: string; id: RecalcMode }[] = [
  { name: 'По цене входа с пересчётом весов', id: RecalcMode.EntryPriceRecalc },
  { name: 'По цене входа без пересчёта весов', id: RecalcMode.EntryPriceNoRecalc },
  { name: 'По текущим ценам с пересчётом весов', id: RecalcMode.CurrentPriceRecalc },
  { name: 'По текущим ценам без пересчета весов', id: RecalcMode.CurrentPriceNoRecalc },
];

export const categories: { id: number; name: string }[] = [
  { id: 0, name: 'Квал. инвесторы' },
  { id: 1, name: 'Неквал. инвесторы' },
];
