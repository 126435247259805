/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, PropsWithChildren, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

const h1 = {
  textAlign: 'center',
  fontSize: '30px',
  fontWeight: 600,
  marginBottom: '20px',
};

const h2 = {
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: 600,
  marginBottom: '20px',
};

type Props = PropsWithChildren & {
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
};

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [hasError, location.key]);

  return (
    <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundaryInner>
  );
};

class ErrorBoundaryInner extends Component<Props> {
  componentDidCatch() {
    this.props.setHasError(true);
  }

  render() {
    if (this.props.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1
            // @ts-ignore
            style={h1}
          >
            Произошла непредвиденная ошибка
          </h1>
          <h3
            // @ts-ignore
            style={h2}
          >
            Попробуйте загрузить другую страницу
          </h3>
        </div>
      );
    }

    return this.props.children;
  }
}
