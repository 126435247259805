/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Input } from '../../../components/FormControls/Input';
import { Select } from '../../../components/FormControls/Select';
import { Criteria, Filter, PageParams, TableGrid } from '../../../components/TableGrid';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { ProblemClients$, problemClientsApi } from '../../../effector/problemClients';
import { StatisticFilters$ } from '../../../effector/statisticFilters';
import { Option } from '../../../types/option';
import { PAGE_STATISTIC_CLIENT_DETAILS_INFO } from '../../Routes';

const validationSchema = Yup.object().shape({
  number: Yup.string().nullable(),
  description: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      name: Yup.string(),
    }),
  ),
});

type FormFields = {
  number?: string;
  description: Option[];
};

type FormProps = {
  onFilterChanged: (filters: Filter) => void;
} & FormikProps<FormFields>;

const initialValues = {
  number: '',
  description: [],
};

const FormTemplate: React.FC<FormProps> = ({ values, resetForm, onFilterChanged }) => {
  const statisticFilters = useStore(StatisticFilters$);
  useEffect(() => {
    const filters = Object.keys(values)
      .map((item) => {
        // @ts-ignore
        const fieldValue = values[item];

        if (typeof fieldValue === 'string' && fieldValue.trim()) {
          return {
            fieldName: item,
            filterCriteria: Criteria.Contains,
            matches: [fieldValue],
          };
        }

        if (typeof fieldValue === 'number' && fieldValue != null) {
          return {
            fieldName: item,
            filterCriteria: Criteria.Equals,
            matches: [fieldValue],
          };
        }

        if (Array.isArray(fieldValue) && fieldValue.length) {
          return {
            fieldName: item,
            filterCriteria: Criteria.Equals,
            matches: fieldValue.map((item) => item.name),
          };
        }
      })
      .filter((item) => !!item);

    // @ts-ignore
    onFilterChanged(filters);
  }, [values]);

  return (
    <Form className="form" noValidate>
      <div className="form-control-row">
        <Field name="number" component={Input} placeholder="Ген. соглашение" />
      </div>
      <div className="form-control-row">
        {statisticFilters.problems && (
          <div className="form-control-group__item">
            <Field
              name="description"
              component={Select}
              multiple={true}
              placeholder="Проблемы"
              options={statisticFilters.problems}
            />
          </div>
        )}
      </div>
      <div className="table-filter__actions">
        <Button className="button__primary button__large" onClick={() => resetForm()} type={ButtonType.reset}>
          Сбросить
        </Button>
      </div>
    </Form>
  );
};

export const ProblemClients: React.FC = () => {
  const [formFilters, setFormFilters] = useState<Filter[]>([]);
  const [forceReload, setForceReload] = useState<boolean>(false);

  const clientsStore = useStore(ProblemClients$);

  const loadClients = (data: PageParams) => {
    problemClientsApi.get({ ...data, filters: formFilters });
    setForceReload(false);
  };

  const onFilterChanged = (filters: Filter[]) => {
    setFormFilters(filters);
    setForceReload(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = () => {};

  const columns = [
    {
      Header: 'Ген. соглашение',
      accessor: 'number',
      className: 'table__column-left',
      // @ts-ignore
      Cell: ({ cell }) => {
        return (
          <Link to={`${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${cell?.row?.original?.id}`}>
            {cell?.row?.original?.number}
          </Link>
        );
      },
    },
    {
      Header: 'Дата подключения',
      accessor: 'bindingDate',
      className: 'table__column-left',
      // @ts-ignore
      Cell: ({ cell }) => {
        if (!cell?.row?.original?.bindingDate) {
          return '';
        }

        return moment(cell?.row?.original?.bindingDate).format('YYYY-MM-DDTHH:mm:ss');
      },
    },
    {
      Header: 'Описание',
      accessor: 'description',
      className: 'table__column-left',
      // @ts-ignore
      Cell: ({ cell }) => <span>{cell?.row?.original?.description}</span>,
    },
  ];

  return (
    <>
      <div className="table-filter">
        <div className="table-filter__title">Фильтры</div>
        <Formik
          // @ts-ignore
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) =>
            React.createElement(
              FormTemplate,
              // @ts-ignore
              {
                ...props,
                // @ts-ignore
                onFilterChanged,
              },
            )
          }
        </Formik>
      </div>
      <TableGrid
        columns={columns}
        data={clientsStore.clients}
        sortField="number"
        isRecursiveFetch={false}
        forceReload={forceReload}
        pageSize={25}
        totalRecords={clientsStore.totalClients}
        onPageChange={loadClients}
      />
    </>
  );
};
