import React, { useState } from 'react';

import { useStore } from 'effector-react';

import { removeScheduleWeekend } from '../../api/index';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { scheduleApi } from '../../effector/schedule';
import { Weekend } from '../../types/marketSchedule';
import { Button, ButtonType } from '../UIKit/Button';

export const ScheduleRemoveWeekend: React.FC = () => {
  const [send, setSend] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const state = useStore<TModal$State<Weekend>>(Modals$);

  const change = () => {
    if (!state.data) return;

    setSend(true);

    const data = {
      id: state.data.id,
      day: state.data.day,
    };

    removeScheduleWeekend(data)
      .then((response) => {
        if (response && response.success) {
          scheduleApi.removeWeekend(data);
          modalApi.hide('');
        } else {
          setError(response.errorMessage ?? 'Ошибка на сервере');
          setSend(false);
        }
      })
      .catch((e) => {
        setError(e.toString());
        setSend(false);
      });
  };

  return (
    <div className="strategy-change">
      <div className="strategy-change__description">
        Вы уверены, что хотите удалить расписание на дату {state.data?.day}
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="strategy-change__button-wrapper">
        <Button className="button__primary button__large" onClick={change} disabled={send} type={ButtonType.button}>
          Ок
        </Button>
      </div>
    </div>
  );
};
