export enum StrategyStatus {
  Standard = 0,
  SandBox = 1,
  Moderate = 2,
  Closed = 3,
}

export enum RecalculationMode {
  WeightedAverageWithRecalculation = 0,
  WeightedAverageWithoutRecalculation = 1,
  CurrentPriceWithRecalculation = 2,
  BalanceWeightWithCurrentPrice = 3,
  CurrentPriceWithoutRecalculation = 4,
}

export type Strategy = {
  id: string;
  name: string;
  managerId: number;
  isChild: boolean;
  parentStrategy: string;
  recalcMode: RecalculationMode;
  status: StrategyStatus;
  isAutofollow: boolean;
  isAutoconsult: boolean;
  isInvestbox: boolean;
  isFrozen: boolean;
};
