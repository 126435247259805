/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, useState } from 'react';

import { useStore } from 'effector-react';
import { Form, Formik, FormikProps, useField } from 'formik';
import SelectField, { OnChangeValue } from 'react-select';
import * as Yup from 'yup';

import { attachClient, detachClient } from '../../api';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../const/validation';
import { clientDetailsApi } from '../../effector/clientDetails';
import { Strategies$ } from '../../effector/strategies';
import { StatusEnum } from '../../types/clientDetails';
import { Strategy } from '../../types/strategy';
import { Nullable } from '../../utils/types';

type FormFields = {
  id: { label: string; value: string };
  type: { label: string; value: StatusEnum };
  attach: boolean;
};

type FormProps = {
  initialFollowTypes: { label: string; value: StatusEnum }[];
  error?: Nullable<string>;
  send: boolean;
  detach?: () => void;
} & FormikProps<FormFields>;

export const CLIENT_STATUS_AF = 'Автоследование';
export const CLIENT_STATUS_AC = 'Автоконсультирование';
export const CLIENT_STATUS_IB = 'Инвест-копилка';
export const CLIENT_STATUS_NONE = 'Не подключен';

const allStrategyTypes = [
  { label: CLIENT_STATUS_AF, value: StatusEnum.AutoFollow },
  { label: CLIENT_STATUS_AC, value: StatusEnum.Recommendations },
  { label: CLIENT_STATUS_IB, value: StatusEnum.InvestMoneybox },
  { label: 'Отсутствует', value: StatusEnum.Unattached },
];

const validate = Yup.object().shape({
  id: Yup.object()
    .shape({
      value: Yup.string().required(ERRORS_TEXTS.required),
    })
    .required(ERRORS_TEXTS.required),
});

const getFollowTypes = (strategy?: Strategy) => {
  if (!strategy) return undefined;

  return allStrategyTypes.filter(
    (t) =>
      (t.value === StatusEnum.AutoFollow && strategy.isAutofollow) ||
      (t.value === StatusEnum.Recommendations && strategy.isAutoconsult) ||
      (t.value === StatusEnum.InvestMoneybox && strategy.isInvestbox),
  );
};

const FormTemplate: React.FC<FormProps> = ({ dirty, initialFollowTypes, error, send, detach }) => {
  const strategiesStore = useStore(Strategies$);
  const [types, setTypes] = useState<{ value: StatusEnum; label: string }[]>(initialFollowTypes);
  const [strategyField, , strategyHelpers] = useField('id');
  const [typeField, , typeHelpers] = useField<{ label: string; value: StatusEnum }>('type');

  const strategies = strategiesStore?.map((s) => {
    return { label: s.name, value: s.id };
  });

  const onStrategyChange = (value: OnChangeValue<{ label: string; value: string }, false>) => {
    if (value) {
      const strategy = strategiesStore?.find((s) => s.id === value.value);
      const types = getFollowTypes(strategy) ?? [];
      const typeValue = !!types && types.length > 0 ? types[0] : { value: StatusEnum.Unattached, label: 'Недоступно' };

      setTypes(types);
      strategyHelpers.setValue(value);
      typeHelpers.setValue(typeValue);
    }
  };

  const onTypeChange = (value: OnChangeValue<{ label: string; value: StatusEnum }, false>) => {
    if (value) typeHelpers.setValue(value);
  };

  const disabled = !dirty || send || !typeField.value || typeField.value.value === StatusEnum.Unattached;

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <p>Подключение данного аккаунта</p>
      </div>
      <div className="form__group">
        <div className="form-control-group">
          <SelectField
            className="select__control form-control-group__item3"
            classNamePrefix="react-select"
            isDisabled={send}
            placeholder="Стратегия"
            options={strategies ?? []}
            isMulti={false}
            isClearable={false}
            value={strategyField.value}
            onChange={onStrategyChange}
          />
          <SelectField
            className="select__control form-control-group__item3"
            classNamePrefix="react-select"
            isDisabled={send}
            placeholder="Тип следования"
            options={types}
            isMulti={false}
            isClearable={false}
            value={typeField.value}
            onChange={onTypeChange}
          />
          <Button type={ButtonType.submit} disabled={disabled} className="button__large button__primary">
            Подключить
          </Button>
        </div>
      </div>

      <div className="form__group">
        <p>Отключение данного аккаунта</p>
      </div>
      <div className="form__group">
        <Button type={ButtonType.button} disabled={send} className="button__large button__primary" onClick={detach}>
          Отключить
        </Button>
      </div>

      {error && (
        <div className="form__group">
          <span className="form-control__message">{error}</span>
        </div>
      )}
    </Form>
  );
};

export const AttachDetach: React.FC<{ id: string; strategyId: Nullable<string>; type: StatusEnum }> = ({
  id,
  strategyId,
  type,
}) => {
  const [send, setSend] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const strategiesStore = useStore(Strategies$);

  const strategy = useMemo(() => strategiesStore?.find((s) => s.id === strategyId), [strategyId, strategiesStore]);
  const initialFollowTypes = useMemo(() => getFollowTypes(strategy) || [], [strategy]);

  const initialValues = useMemo(
    () => ({
      id: { value: strategy?.id ?? '', label: strategy?.name ?? '' },
      attach: true,
      type: initialFollowTypes.find((t) => t.value === type) ?? { value: StatusEnum.Unattached, label: 'Недоступно' },
    }),
    [strategy, initialFollowTypes, type],
  );

  const onSubmit = (values: FormFields) => {
    if (values.id && values.id.value && values.type?.value !== StatusEnum.Unattached) {
      setSend(true);

      attachClient({ agreementId: id, strategy: values.id.value, status: values.type.value })
        .then((result) => {
          if (result && result.success) {
            const strategyId = values.id.value;

            clientDetailsApi.updateClientInfo({
              strategyId: strategyId,
              strategyName: strategiesStore?.find((s) => s.id === strategyId)?.name ?? 'Unknown',
              status: values.type.value,
            });

            setError(null);
          } else {
            setError(result?.message ?? 'На сервере произошла ошибка');
          }
        })
        .catch((e) => setError(e.toString()))
        .finally(() => setSend(false));
    }
  };

  const detach = () => {
    setSend(true);
    detachClient(id)
      .then((result) => {
        if (result && result.success) {
          setError(null);
        } else {
          setError(result?.errorMessage ?? 'На сервере произошла ошибка');
        }
      })
      .catch((e) => setError(e.toString()))
      .finally(() => setSend(false));
  };

  return (
    <>
      <div className="form__group">
        <Formik initialValues={initialValues} validationSchema={validate} onSubmit={onSubmit}>
          {(props) => React.createElement(FormTemplate, { ...props, initialFollowTypes, detach, send, error })}
        </Formik>
      </div>
    </>
  );
};
