export const COLORS = [
  '#7175d8',
  '#43497b',
  '#5ecba1',
  '#ff6b69',
  '#f3ba81',
  '#81baf3',
  '#81f382',
  '#f381f3',
  '#82f3f3',
  '#baf381',
  '#f38181',
  '#e5e513',
  '#4949ee',
  '#eeee4a',
  '#1515e5',
];

export const FREE_MONEY_COLOR = '#abada8';
