import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { addScheduleWeekend } from '../../api';
import { DatePicker } from '../../components/FormControls/DatePicker';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { schedule$, scheduleApi } from '../../effector/schedule';
import { Weekend } from '../../types/marketSchedule';
import { Button, ButtonType } from '../UIKit/Button';

type FormFields = {
  weekends: string[];
  weekend?: Date;
};

type FormProps = {
  error?: string;
  send: boolean;
} & FormikProps<FormFields>;

const CreateCurrentDate = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

const validationSchema = Yup.object().shape({
  weekend: Yup.date()
    .min(CreateCurrentDate(), 'Дата должна быть не меньше текущей')
    .test('test-existing', 'Уже присутствует в списке неторговых дней', function validate(weekend) {
      const data = weekend?.toLocaleDateString('ru') ?? '';
      const weekends = this.parent.weekends as string[];
      return !weekends?.find((w) => w === data);
    }),
});

const FormTemplate: React.FC<FormProps> = ({ dirty, error, send }) => {
  const disabled = !dirty || send;

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <p>
          Добавление нерабочего дня или дня со специальным расписанием,
          <br />
          предпраздничного дня
        </p>
      </div>
      <div className="form__group">
        <Field
          name="weekend"
          placeholder="Дата"
          dropdownMode="select"
          dateFormat="dd.MM.yyyy"
          showMonthDropdown
          showYearDropdown
          component={DatePicker}
        />
      </div>
      <div className="schedule__dialog-indent"></div>
      <div className="form__group">
        <Button className="button__primary button__large" type={ButtonType.submit} disabled={disabled}>
          Сохранить
        </Button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const ScheduleAddWeekend: React.FC = () => {
  const [send, setSend] = useState(false);
  const [error, setError] = useState<string>();

  const state = useStore<TModal$State<{ id: number }>>(Modals$);
  const scheduleStore = useStore(schedule$);

  const id = state.data?.id ?? 0;

  const init: () => FormFields = () => {
    return {
      weekends: scheduleStore.find((s) => s.id === id)?.weekends?.map((w) => w.day) ?? [],
    };
  };

  const onSubmit = (values: FormFields) => {
    setSend(true);

    const data: Weekend = {
      id: state.data?.id ?? 0,
      day: values.weekend?.toLocaleDateString('ru') ?? '',
    };

    addScheduleWeekend(data)
      .then((response) => {
        if (response) {
          if (response.success) {
            scheduleApi.addWeekend(data);
            modalApi.hide('');
          } else {
            setError(response.errorMessage ?? 'Ошибка на сервере');
            setSend(false);
          }
        }
      })
      .catch((e) => {
        setError(e.toString());
        setSend(false);
      });
  };

  return (
    <Formik initialValues={init()} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) => React.createElement(FormTemplate, { ...props, error, send })}
    </Formik>
  );
};
