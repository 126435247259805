import { FC } from 'react';

import { Draggable } from './Draggable';
import { Question } from '../../../../types/faq';

type DroppableProps = {
  title: string;
  items: Question[];
  columnId: 'category' | 'questions';
  moveItem: (
    itemColumnId: 'category' | 'questions',
    itemIndex: number,
    columnId: 'category' | 'questions',
    hoverIndex: number,
  ) => void;
};

export const Droppable: FC<DroppableProps> = ({ title, columnId, items, moveItem }) => {
  return (
    <div style={{ height: '100%' }}>
      <div className="faq-mapping__title">{title}</div>
      {items.length === 0 && (
        <Draggable
          key={1}
          id="1"
          text="Перетащите вопрос в область"
          index={0}
          disabled
          columnId={columnId}
          moveItem={moveItem}
        />
      )}
      {items.map((item, index) => (
        <Draggable key={item.id} id={item.id} text={item.title} index={index} columnId={columnId} moveItem={moveItem} />
      ))}
    </div>
  );
};
