/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import { strategiesApi } from '../../effector/strategies';
import { Option } from '../../types/option';
import { PAGE_STRATEGIES, PAGE_STRATEGY_POSITIONS } from '../Routes';

export const Strategies: React.FC = () => {
  const navigate = useNavigate();

  // @ts-ignore
  const onChange = (val) => {
    navigate(`${PAGE_STRATEGIES}/${val.id}/${PAGE_STRATEGY_POSITIONS}`);
  };

  const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    strategiesApi.get('').then((result) => {
      callback(
        // @ts-ignore
        result.result.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      );
    });
  };

  const filterOptions = (option: { label: string }, inputValue: string) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <div className="strategies">
      <div className="strategies__selection">
        <AsyncSelect
          placeholder="Выберите стратегию"
          defaultOptions
          cacheOptions
          loadOptions={loadOptions}
          filterOption={filterOptions}
          getOptionLabel={(option) => option.name}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
