/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { FieldInputProps, useField } from 'formik';

import { Button } from '../../../components/UIKit/Button';
import { Nullable } from '../../../utils/types';

export type ClientInfo = {
  id: string;
  name: string;
  value: string;
};

export type AgreementProps = {
  field: FieldInputProps<any>;
  placeholder: string;
  disabled: boolean;
  verify: (args: string[]) => Promise<any>;
  verifyError: string;
  useKeyValue: boolean;
};

export const AgreementHelper: React.FC<AgreementProps> = ({
  field: fieldProp,
  placeholder,
  verify,
  verifyError,
  useKeyValue = true,
  ...props
}) => {
  const [field, , helpers] = useField(fieldProp.name);
  const [agreement, setAgreement] = useState('');
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [loading, setLoading] = useState(false);

  const addClient = (agreementNumber: string): void => {
    if (!agreementNumber) return;
    if (agreementNumber.length === 0) return;

    setAgreement('');

    const clients = field.value as ClientInfo[];

    if (clients.find((c) => c.value === agreementNumber)) return;

    setLoading(true);

    verify([agreementNumber])
      .then((c: { success: boolean; result: ClientInfo[]; errorMessage: string }) => {
        if (!c.success) {
          setError(c.errorMessage || verifyError || 'Неопознанная ошибка');
        } else if (c.result) {
          if (c.result.length > 0) {
            helpers.setValue([...clients, c.result[0]].sort((c1, c2) => c1.name.localeCompare(c2.name)));
            setError(null);
          } else {
            setError(verifyError || 'Неопознанная ошибка');
          }
        }
      })
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeItem = (id: string): void => {
    const clients = field.value as ClientInfo[];
    const index = clients.findIndex((c) => c.id === id);
    if (index >= 0) {
      helpers.setValue(clients.filter((c) => c.id !== id));
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) {
      if (!loading) addClient(agreement);
      e.preventDefault();
    } else if (e.charCode === 27) {
      setAgreement('');
      e.preventDefault();
    }
  };

  const handleTouched = () => {
    helpers.setTouched(true);
    setFocus(false);
  };

  return (
    <>
      <div className="administration__tabs">
        <div className="administration__tab">
          <label className={cs('form-control', { 'form-control-focus': focus || (agreement && agreement.length > 0) })}>
            <input
              className={cs('form-control__field', {
                'form-control__field-disabled': false,
              })}
              type="string"
              placeholder={placeholder}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreement(e.target.value)}
              disabled={props.disabled}
              onFocus={() => setFocus(true)}
              onBlur={() => handleTouched()}
              onKeyPress={handleEnter}
              value={agreement}
            />
            <span className="form-control__placeholder">{placeholder}</span>
          </label>
          {error && <span className="form-control__message">{error}</span>}
        </div>
        <div className="administration__tab">
          <Button
            disabled={loading || props.disabled}
            className="button__large button__primary"
            onClick={() => addClient(agreement)}
          >
            Добавить
          </Button>
        </div>
      </div>
      {!props.disabled && (
        <div className="form__group administration__data">
          <ul className="administration">
            {(field.value as ClientInfo[]).map((c) => (
              <li key={useKeyValue ? c.value : c.name}>
                <span>{c.name}</span>
                <span>{useKeyValue ? c.value : ''}</span>
                <span className="pointer form-schedule__delete">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => {
                      removeItem(c.id);
                    }}
                  />
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
