import React, { useState, useEffect } from 'react';

import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';

import { getClientPersInfo } from '../../../api';
import { CopyToClipboard } from '../../../components/CopyToClipboard';
import { Button } from '../../../components/UIKit/Button';
import { LimitsStore, LimitsApi, limitsRmds, limitsQrc } from '../../../effector/limits';
import { ClientAccountInfo } from '../../../types/clientFullInfo';
import { Nullable } from '../../../utils/types';

type Response = {
  success: boolean;
  errorMessage: string;
  result: ClientAccountInfo;
};

export const ClientAccount: React.FC = () => {
  const columnsRmds = [
    { name: 'Клиентский счет', tag: 'clientCode' },
    { name: 'Security key', tag: 'security' },
    { name: 'Счет DEPO', tag: 'depo' },
    //{ name: 'Тикер', tag: 'symbol' },
    { name: 'Tag', tag: 'tag' },
    { name: 'Тип', tag: 'kind' },
    { name: 'Количество', tag: 'number' },
    { name: 'Цена', tag: 'price' },
    { name: 'Валюта', tag: 'currency' },
    { name: 'VarMargin', tag: 'varMargin' },
  ];

  const columnsQrc = [
    { name: 'Клиентский счет', tag: 'clientCode' },
    { name: 'Security key', tag: 'security' },
    { name: 'Счет DEPO', tag: 'depo' },
    //{ name: 'Тикер', tag: 'symbol' },
    { name: 'Tag', tag: 'tag' },
    { name: 'Тип', tag: 'kind' },
    { name: 'Количество', tag: 'number' },
    { name: 'Цена', tag: 'price' },
    { name: 'Валюта', tag: 'currency' },
    { name: 'VarMargin', tag: 'varMargin' },
    //{ name: 'TotalVarMargin', tag: 'totalVarMargin' }
  ];

  const { agreementId } = useParams<{ agreementId: string }>();
  const [response, setResponse] = useState<Nullable<Response>>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (agreementId) {
      getClientPersInfo(agreementId)
        .then((c) => setResponse(c))
        .catch((e) => setError(e.toString()));
    }
  }, []);

  if (!agreementId) {
    return (
      <div className="client-details__info">
        <p>Не выбран номер клиента</p>
      </div>
    );
  }

  if (!response) {
    return !!error ? (
      <>
        <div className="client-details__info">
          <p>Ошибка получения данных клиента</p>
          <p>Ответ сервера: {error.toString()}</p>
        </div>
      </>
    ) : (
      <>
        <div className="client-details__info">
          <p>Загружаем клиентские данные...</p>
        </div>
      </>
    );
  }

  if (!response.success) {
    return (
      <div className="client-details__info">
        <p>Ошибка получения данных клиента</p>
        {response.errorMessage && <p>Ответ сервера: {response.errorMessage}</p>}
      </div>
    );
  }

  const info = response.result;
  if (!info) {
    return (
      <div className="client-details__info">
        <p>Клиентские данные отсутствуют</p>
      </div>
    );
  }

  return (
    <>
      <div className="client-details__info">
        <p className="strategies-params__item-name">Данные клиента:</p>
        <p></p>
        {!info.client && (
          <p>
            <i>Данные для неподключенного клиента не отображаются</i>
          </p>
        )}
        {info.client && <Client account={info} />}
        {info.clientCode && (
          <>
            <div className="table-filter">
              <div className="table-filter__title">Текущие позиции клиента</div>
              <Limits
                agreementId={agreementId}
                serviceName="RMDS"
                columns={columnsRmds}
                limitsApi={limitsRmds.api}
                limits$={limitsRmds.store$}
                info={info}
              />
              <Limits
                agreementId={agreementId}
                serviceName="QRC"
                columns={columnsQrc}
                limitsApi={limitsQrc.api}
                limits$={limitsQrc.store$}
                info={info}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Client: React.FC<{ account: ClientAccountInfo }> = ({ account }) => {
  const info = account.client;

  return (
    <>
      <div className="form__group">
        {info.isOrganization ? <p>Тип клиента: Организация</p> : <p>Тип клиента: частное лицо</p>}
        <p>Номер телефона: {info.phone}</p>
        <p>Номер генерального соглашения: {info.agreement}</p>
        <p>Номер торгового счета: {account.clientCode}</p>
        <p>Номер futures code: {account.futuresCode ?? 'Отсутствует'}</p>
        <br />
        <p>Данные риск-профиля:</p>
        <p>&nbsp;&nbsp;Тип: {info.riskProfileName}</p>
        <p>&nbsp;&nbsp;Дата изменения: {info.riskProfileDate?.toString()}</p>
        <p>&nbsp;&nbsp;GUID: {info.riskProfileId}</p>
        <br />
        <p>Подключенная стратегия: {info.strategyName}</p>
        <p>
          Тариф стратегии: {account.strategyTariffId ?? 'неопределен'} ({account.strategyTariff ?? ''})
        </p>
        <p>Тип инвестора: {info.isQualified ? 'Квалифицированный' : 'Неквалифицированный'}</p>
        <p></p>
        <p></p>
        <br />
      </div>
      <div className="form__group">
        <CopyToClipboard className="button__large button__primary" text={JSON.stringify(account, null, 2)} />
      </div>
      <div className="form__group">
        <br />
      </div>
    </>
  );
};

const Limits: React.FC<{
  agreementId: string;
  serviceName: string;
  columns: { name: string; tag: string }[];
  info: ClientAccountInfo;
  limitsApi: LimitsApi;
  limits$: LimitsStore;
}> = ({ agreementId, serviceName, columns, limitsApi, limits$, info }) => {
  const limitsStore = useStore(limits$);
  const [query, setQuery] = useState<boolean>(false);

  const getLimits = (info: ClientAccountInfo) => {
    const clientCodes = info.futuresCode ? [info.clientCode, info.futuresCode] : [info.clientCode];
    limitsApi.init({ id: agreementId, codes: clientCodes });
    limitsApi.query(agreementId);

    //if (info.futuresCode)
    //  limitsApi.query({ id: info.futuresCode });

    setQuery(true);
  };

  const disabled = query && limitsStore.id === agreementId && !limitsStore.complete && !limitsStore.error;
  const complete = limitsStore.complete && limitsStore.id === agreementId;
  const isLoading = !limitsStore.complete && limitsStore.id === agreementId;
  const isError = limitsStore.id === agreementId && limitsStore.error;

  const positionItems = complete
    ? limitsStore.positions
        .filter((p) => p.clientCode === info.clientCode || (info.futuresCode && p.clientCode === info.futuresCode))
        .map((p, i) => (
          <tr key={i} className="table__tr">
            <td className="table__column-details">{p['clientCode']}</td>
            <td className="table__column-details">{p['security']}</td>
            <td className="table__column-details">{p['depo']}</td>
            <td className="table__column-details">{p.tag}</td>
            <td className="table__column-details">{p.kind}</td>
            <td className="table__column-details">{p.number}</td>
            <td className="table__column-details">{p.price}</td>
            <td className="table__column-details">{p.currency}</td>
            <td className="table__column-details">{p.varMargin ?? ''}</td>
          </tr>
        ))
    : [];

  return (
    <>
      <div className="form__group">
        <Button disabled={disabled} className="button__large button__primary" onClick={() => getLimits(info)}>
          Запросить из {serviceName}
        </Button>
      </div>

      <div className="form__group">
        {isLoading && <p>Получение лимитов из {serviceName}...</p>}
        {complete && limitsStore.responseTime && <p>Лимиты получены в {limitsStore.responseTime.toString()}</p>}
        {isError && <div className="error-message-left">Ошибка получения лимитов: {limitsStore.error}</div>}
        {complete && limitsStore.positions.length > 0 && (
          <div className="form__group">
            <table className="table">
              <thead>
                <tr className="table__tr">
                  {columns.map((c, i) => (
                    <th key={i} className="table__th">
                      {c.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{positionItems}</tbody>
            </table>
          </div>
        )}
        <br />
      </div>
    </>
  );
};
