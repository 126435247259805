/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { modalApi } from './modals';
import { getBanners, updateBanners } from '../api';
import { PageParams } from '../components/TableGrid';
import { Banner } from '../types/banner';
import { Nullable } from '../utils/types';

export type TBanners$State = {
  total: number;
  banners: Nullable<Banner[]>;
};

const initialState: TBanners$State = {
  total: 0,
  banners: [],
};

export const Banners$ = createStore<TBanners$State>(initialState);

const { set, add, remove } = createApi(Banners$, {
  // @ts-ignore
  set: (state, { result }) => {
    const data = {
      // @ts-ignore
      total: result.result.total,
      // @ts-ignore
      banners: result.result.pageItems,
    };

    return data;
  },
  // @ts-ignore
  add: (state, { result }) => {
    // @ts-ignore
    if (result.success) {
      // @ts-ignore
      const updated = state.banners.filter((item) => item.id !== result.result.id);

      return {
        ...state,
        banners: [...updated, result.result],
      };
    }
    return state;
  },
  // @ts-ignore
  remove: (state, { result, params }) => {
    if (result.success) {
      // @ts-ignore
      modalApi.hide('');
      // @ts-ignore
      return {
        ...state,
        // @ts-ignore
        banners: state.filter((item) => item.id !== params.id),
      };
    }

    return state;
  },
});

export const bannersApi = {
  get: createEffect<PageParams, {}, {}>().use(getBanners),
  remove: createEffect<Partial<Banner>, {}, {}>().use(updateBanners),
  add: createEffect<Partial<Banner>, {}, {}>().use(updateBanners),
};

forward({
  from: bannersApi.get.done,
  to: set,
});

forward({
  from: bannersApi.remove.done,
  to: remove,
});

forward({
  from: bannersApi.add.done,
  to: add,
});
