import React, { useCallback, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { Input } from '../../components/FormControls/Input';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../const/validation';
import { faqApi } from '../../effector/faq';
import { Modals$, TModal$State } from '../../effector/modals';
import { Category } from '../../types/faq';
import { getMessageFromFailedRequest } from '../../utils';

type FormFields = {
  name: string;
  friendlyUrl: string;
};

type FormProps = {
  error?: string;
} & FormikProps<FormFields>;

const validationSchema = Yup.object().shape({
  name: Yup.string().required(ERRORS_TEXTS.required),
  friendlyUrl: Yup.string().nullable(),
});

const FormTemplate: React.FC<FormProps> = ({ dirty, error }) => {
  const disabled = !dirty;

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <Field type="text" name="name" placeholder="Название" component={Input} />
      </div>
      <div className="form__group">
        <Field type="text" name="friendlyUrl" placeholder="Url" component={Input} />
      </div>
      <div className="remove-delayed-signal__button-wrapper">
        <Button className="button__primary button__large" type={ButtonType.submit} disabled={disabled}>
          Сохранить
        </Button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const AddCategory: React.FC = () => {
  const [error, setError] = useState<string>();

  const { enqueueSnackbar } = useSnackbar();

  const modalsState = useStore<TModal$State<{ category: Category }>>(Modals$);

  const isEdit = Boolean(modalsState.data?.category);

  const initialValues = {
    name: modalsState.data?.category?.name || '',
    friendlyUrl: modalsState.data?.category?.friendlyUrl || '',
  };

  const handleError = useCallback((e: Error) => {
    const message = getMessageFromFailedRequest(e);
    setError(message);
  }, []);

  const onSubmit = (values: FormFields) => {
    const data = {
      name: values.name ?? '',
      friendlyUrl: values.friendlyUrl ?? '',
    };
    setError('');
    if (isEdit) {
      faqApi
        .editCategory({ ...data, id: modalsState.data?.category.id as string })
        .then(() => {
          enqueueSnackbar('Категория обновлена');
        })
        .catch(handleError);
    } else {
      faqApi
        .addCategory(data)
        .then(() => {
          enqueueSnackbar('Категория добавлена');
        })
        .catch(handleError);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) => React.createElement(FormTemplate, { ...props, error })}
    </Formik>
  );
};
