import React from 'react';

import { Navigate, Route, Routes as RoutesComponent } from 'react-router-dom';

import { AllErrors } from './all';
import { StrategyErrors } from './strategy';
import { Navigation } from '../../../components/Navigation';
import { PAGE_STATISTIC_ERRORS_ALL, PAGE_STATISTIC_ERRORS_STRATEGY } from '../../Routes';

export const Errors: React.FC = ({}) => {
  const items = [
    {
      name: 'Все',
      path: PAGE_STATISTIC_ERRORS_ALL,
    },
    {
      name: 'По стратегиям',
      path: PAGE_STATISTIC_ERRORS_STRATEGY,
    },
  ];

  return (
    <div className="statistic">
      <div className="statistic__navigation">
        <Navigation items={items} />
      </div>
      <div>
        <RoutesComponent>
          <Route path={PAGE_STATISTIC_ERRORS_ALL} element={<AllErrors />} />
          <Route path={PAGE_STATISTIC_ERRORS_STRATEGY} element={<StrategyErrors />} />
          <Route path="*" element={<Navigate to={PAGE_STATISTIC_ERRORS_ALL} />} />
        </RoutesComponent>
      </div>
    </div>
  );
};
