import React from 'react';

import { ReactComponent as PlusIcon } from '../../../Modal/_assets/i-plus.svg';
import { Button } from '../../../UIKit/Button';
import { MarketSignal } from '../index';

type Props = {
  items: MarketSignal[];
  disabled: boolean;
  onSubmit: () => void;
  onRemove: (item: MarketSignal) => void;
};

export const ToolRool: React.FC<Props> = ({ items, disabled, onSubmit, onRemove }) => (
  <div className="tool-list">
    {items.length > 0 && <div className="tool-list__title">Список инструментов в пакетной заявке</div>}
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <div className="tool-list__item">
            <div className="tool-list__item-wrapper">
              <div className="tool-list__item-param">{item.symbol}</div>
              <div className="tool-list__item-param">
                {(item.typeSignal === 1 && 'Покупка') ||
                  (item.typeSignal === 2 && 'Продажа') ||
                  (item.typeSignal === 3 && 'Закрытие')}
              </div>
              <div className="tool-list__item-param">{item.weight}%</div>
              {item.stopLoss && <div className="tool-list__item-param">SL {item.stopLoss}</div>}
              {item.takeProfit && <div className="tool-list__item-param">TP {item.takeProfit}</div>}
            </div>
            <div className="tool-list__item-remove" onClick={() => onRemove(item)}>
              <PlusIcon />
            </div>
          </div>
        </li>
      ))}
    </ul>
    {items.length > 0 && (
      <div className="form-signal__button-wrapper">
        <Button className="button__primary button__large" disabled={disabled} onClick={() => onSubmit()}>
          Сформировать сигнал
        </Button>
      </div>
    )}
  </div>
);
