import React from 'react';

import cn from 'classnames';
import ReactModal from 'react-modal';

type Props = {
  title?: string;
  isOpen: boolean;
  className?: string;
  onRequestClose: () => void;
  children: React.ReactNode;
};

export const Modal: React.FC<Props> = ({ title, isOpen, children, className, onRequestClose }) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    bodyOpenClassName=""
    overlayClassName="modal__overlay"
    className={cn('modal__container', className)}
    portalClassName="modal modal-opened"
  >
    <header className="modal__header">
      {title && <h2 className="modal__title">{title}</h2>}
      <button className="modal__close" type="button" onClick={onRequestClose}>
        Закрыть
      </button>
    </header>
    <div className="modal__content">{children}</div>
  </ReactModal>
);
