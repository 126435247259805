/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import {
  getClientChartDetails,
  getClientHistoryDetails,
  getClientInfoDetails,
  getClientInfoDetailsById,
  getClientPositionsDetails,
} from '../api';
import { ChartPoint } from '../types/chart';
import { ClientHistory, ClientInfo, ClientPosition, StatusEnum } from '../types/clientDetails';
import { Nullable } from '../utils/types';

type TFollow$State = {
  clientInfo: Nullable<ClientInfo>;
  chart: ChartPoint[];
  history: ClientHistory[];
  positions: ClientPosition[];
};

type UpdateParams = {
  strategyId: Nullable<string>;
  strategyName: string;
  status: StatusEnum;
};

const initialState = {
  clientInfo: null,
  chart: [],
  history: [],
  positions: [],
};

// @ts-ignore
export const ClientDetails$ = createStore<TFollow$State>(initialState);

const { setChart, setHistory, setPositions, setClientInfo, updateClientInfo, reset } = createApi(ClientDetails$, {
  // @ts-ignore
  setChart: (state, { result }) => {
    let chart = [];
    if (result.success) {
      chart = result.result;
    }

    return {
      ...state,
      chart,
    };
  },
  // @ts-ignore
  setHistory: (state, { result }) => {
    let history = [];
    if (result.success) {
      history = result.result;
    }

    return {
      ...state,
      history,
    };
  },
  //  @ts-ignore
  setPositions: (state, { result }) => {
    let positions = [];
    if (result.success) {
      positions = result.result;
    }

    return {
      ...state,
      positions,
    };
  },
  // @ts-ignore
  setClientInfo: (state, { result }) => {
    if (result.success) {
      return {
        ...state,
        clientInfo: result.result?.[0] || result.result,
      };
    }

    return {
      ...state,
      clientInfo: {},
    };
  },
  // @ts-ignore
  updateClientInfo: (state, info: UpdateParams) => {
    if (state && state.clientInfo) {
      const clientInfo = { ...state.clientInfo, ...info };
      return { ...state, clientInfo };
    }

    return state;
  },
  // @ts-ignore
  reset: () => {
    return initialState;
  },
});

export const clientDetailsApi = {
  getChart: createEffect<string, {}, {}>().use(getClientChartDetails),
  getHistory: createEffect<{ agreementId: string; sortType?: string }, {}, {}>().use(getClientHistoryDetails),
  getPositions: createEffect<string, {}, {}>().use(getClientPositionsDetails),
  getClientInfo: createEffect<string, {}, {}>().use(getClientInfoDetails),
  getClientInfoById: createEffect<string, {}, {}>().use(getClientInfoDetailsById),
  updateClientInfo: createEffect<UpdateParams, {}, {}>(),
  reset: createEffect(),
};

forward({
  from: clientDetailsApi.getChart.done,
  to: setChart,
});

forward({
  from: clientDetailsApi.getHistory.done,
  to: setHistory,
});

forward({
  from: clientDetailsApi.getPositions.done,
  to: setPositions,
});

forward({
  from: clientDetailsApi.getClientInfo.done,
  to: setClientInfo,
});

forward({
  from: clientDetailsApi.getClientInfo.fail,
  to: reset,
});

forward({
  from: clientDetailsApi.getClientInfoById.done,
  to: setClientInfo,
});

forward({
  from: clientDetailsApi.updateClientInfo,
  to: updateClientInfo,
});

forward({
  from: clientDetailsApi.reset,
  to: reset,
});
