/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store, Effect, createStore, createEffect, createApi, forward } from 'effector';

import { getTariffInfo } from '../api';
import { TariffInfo } from '../types/tariffInfo';

export type Helper$State<T> = {
  size: number;
  data: T[];
};

const create = <T>(
  f: (size: number) => Promise<any>,
): {
  data$: Store<Helper$State<T>>;
  dataApi: { get: Effect<number, {}, {}> };
} => {
  const initial: Helper$State<T> = {
    size: 0,
    data: [],
  };

  const data$ = createStore<Helper$State<T>>(initial);

  const api = createApi(data$, {
    // @ts-ignore
    set: (state, { result }) => {
      const data: Helper$State<T> = {
        size: result.result.length,
        data: result.result,
      };
      return data;
    },
  });

  const dataApi = {
    get: createEffect<number, {}, {}>().use(f),
  };

  forward({
    from: dataApi.get.done,
    to: api.set,
  });

  return { data$, dataApi };
};

export const { data$: tariffs$, dataApi: tariffApi } = create<TariffInfo>(getTariffInfo);
