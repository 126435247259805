/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { Option } from '../types/option';
import { Nullable } from '../utils/types';

type TStrategyDictionary$State = Nullable<Option[]>;

export const StrategyDictionary$ = createStore<TStrategyDictionary$State>(null);

const { set } = createApi(StrategyDictionary$, {
  // @ts-ignore
  set: (state, strategies) => strategies,
});

export const strategyDictionaryApi = {
  add: createEffect(),
};

forward({
  from: strategyDictionaryApi.add,
  to: set,
});
