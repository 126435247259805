/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useMemo, Fragment, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { DatePicker } from '../../../../components/FormControls/DatePicker';
import { Select } from '../../../../components/FormControls/Select';
import { Button, ButtonType } from '../../../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../../../const/validation';
import { Errors$, errorsApi } from '../../../../effector/errors';
import { StrategyError } from '../../../../types/error';
import { Option } from '../../../../types/option';

import 'moment/locale/ru';

const periods = [
  {
    id: 0,
    name: 'По неделям',
  },
  {
    id: 1,
    name: 'По месяцам',
  },
];

const validationSchema = Yup.object().shape({
  period: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
  }),
  startDate: Yup.string().required(ERRORS_TEXTS.required),
  endDate: Yup.string().required(ERRORS_TEXTS.required),
});

type Filters = {
  from: string;
  to: string;
  errorsStatMode: number;
};

type FormFields = {
  period: Option;
  startDate: Date;
  endDate: Date;
};

type FormProps = {
  loadData: (filters: Filters) => void;
  setPeriod: (period: number) => void;
} & FormikProps<FormFields>;

const initialDate = moment('2020.08.10');
const initialValues = {
  period: periods[0],
  startDate: initialDate.toDate(),
  endDate: moment().toDate(),
};

const FormTemplate: React.FC<FormProps> = ({ values, resetForm, loadData, setPeriod }) => {
  useEffect(() => {
    const data: Filters = {
      from: moment(values.startDate).format(DATE_FORMAT),
      to: moment(values.endDate).format(DATE_FORMAT),
      errorsStatMode: Number(values.period.id),
    };

    loadData(data);
  }, [values.startDate, values.endDate, values.period]);

  useEffect(() => {
    setPeriod(Number(values.period.id));
  }, [values.period]);

  return (
    <Form className="form" noValidate>
      <div className="form-control-group">
        {periods && (
          <div className="form-control-group__item">
            <Field name="period" component={Select} placeholder="Период" options={periods} />
          </div>
        )}
      </div>
      <div className="form-control-group">
        <div className="form-control-group__item">
          <Field
            name="startDate"
            component={DatePicker}
            placeholder="Дата начала"
            dateFormat="dd.MM.yyyy"
            selectsStart
            startDate={values.startDate}
            endDate={values.endDate}
          />
        </div>
        <div className="form-control-group__item">
          <Field
            name="endDate"
            component={DatePicker}
            placeholder="Дата окончания"
            dateFormat="dd.MM.yyyy"
            selectsEnd
            startDate={values.endDate}
            endDate={values.endDate}
            minDate={values.startDate}
            maxDate={moment().toDate()}
          />
        </div>
      </div>
      <div className="table-filter__actions">
        <Button className="button__primary button__large" onClick={() => resetForm()} type={ButtonType.reset}>
          Сбросить
        </Button>
      </div>
    </Form>
  );
};

const DATE_FORMAT = 'YYYY-MM-DD';
const MONTH_FORMAT = 'MMMM';
const WEEK_FORMAT = 'DD.MM';

export const StrategyErrors: React.FC = ({}) => {
  const [period, setPeriod] = useState<number>(periods[0].id);
  const errorsStore = useStore(Errors$);

  const loadData = (filters: Filters) => {
    errorsApi.getStrategyErrors(filters);
  };

  const onSubmit = () => {};

  const dateFormatted = (date: string) => {
    const dateMoment = moment(date);
    let datePeriod = '';

    if (period === periods[0].id) {
      datePeriod = `${dateMoment.startOf('week').format(WEEK_FORMAT)} - ${dateMoment
        .endOf('week')
        .format(WEEK_FORMAT)}`;
    } else {
      datePeriod = dateMoment.format(MONTH_FORMAT);
    }

    return datePeriod;
  };

  const createSource = (strategyName: string) => {
    const sourceGroup: Record<string, StrategyError[]> = {};

    errorsStore.strategy.errorStatsRows.forEach((error) => {
      if (error.strategy === strategyName) {
        if (!sourceGroup[error.source]) {
          sourceGroup[error.source] = [error];
        } else {
          sourceGroup[error.source].push(error);
        }
      }
    });

    return sourceGroup;
  };

  const groupErrors = useMemo(() => {
    const group: Record<string, Record<string, StrategyError[]>[]> = {};

    errorsStore.strategy.errorStatsRows.forEach((item) => {
      if (!group[item.strategy]) {
        const source = createSource(item.strategy);

        group[item.strategy] = [source];
      }
    });

    return group;
  }, [errorsStore.strategy.errorStatsRows]);

  return (
    <Fragment>
      <div className="table-filter">
        <div className="table-filter__title">Фильтры</div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {(props) =>
            React.createElement(
              FormTemplate,
              // @ts-ignore
              {
                ...props,
                setPeriod,
                loadData,
              },
            )
          }
        </Formik>
      </div>
      <div className="statistic-errors">
        <table className="statistic-errors__table table">
          <thead>
            <tr className="table__tr">
              <th className="statistic-errors__table-th  table__th">Стратегия</th>
              <th className="statistic-errors__table-th  table__th">Тип ошибки</th>
              <th className="statistic-errors__table-th table__th">Ошибки</th>
              {errorsStore.strategy.dates.map((item) => (
                <th key={item} className="statistic-errors__table-th table__th">
                  {dateFormatted(item)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(groupErrors).map((sources) => {
              let renderStrategy = true;
              let count = 0;
              sources.forEach((source) =>
                Object.values(source).map((errors) => {
                  count += errors.length;
                }),
              );

              return sources.map((source) => {
                let loopError = 0;
                return Object.values(source).map((errors) =>
                  errors.map((error, i) => {
                    renderStrategy = loopError++ === 0;

                    return (
                      <tr key={error.strategy} className="table__tr">
                        {renderStrategy && (
                          <td rowSpan={count} className="statistic-errors__table-td statistic-errors__table-td_source">
                            {error.strategy}
                          </td>
                        )}
                        {i === 0 && (
                          <td
                            rowSpan={errors.length}
                            className="statistic-errors__table-td statistic-errors__table-td_source"
                          >
                            {error.source}
                          </td>
                        )}
                        <td className="statistic-errors__table-td">{error.error}</td>
                        {error.occurrences.map((occurrence, index) => (
                          <td key={index} className="statistic-errors__table-td statistic-errors__table-td_center">
                            {occurrence}
                          </td>
                        ))}
                      </tr>
                    );
                  }),
                );
              });
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
