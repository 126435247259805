/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { modalApi } from './modals';
import { getAnalytics, updateAnalytic } from '../api';
import { PageParams } from '../components/TableGrid';
import { Analytic } from '../types/analytic';
import { Nullable } from '../utils/types';

type TAnalytics$State = {
  total: number;
  analytics: Nullable<Analytic[]>;
};

const initialState: TAnalytics$State = {
  total: 0,
  analytics: [],
};

export const Analytics$ = createStore<TAnalytics$State>(initialState);

const { set, add, remove } = createApi(Analytics$, {
  // @ts-ignore
  set: (state, { result }) => {
    const data = {
      // @ts-ignore
      total: result.result.total,
      // @ts-ignore
      analytics: result.result.pageItems,
    };

    return data;
  },
  // @ts-ignore
  add: (state, { result }) => {
    // @ts-ignore
    if (result.success) {
      // @ts-ignore
      const updated = state.analytics.filter((item) => item.id !== result.result.id);

      // @ts-ignore
      return {
        ...state,
        // @ts-ignore
        analytics: [...updated, result.result],
      };
    }
    return state;
  },
  // @ts-ignore
  remove: (state, { result, params }) => {
    if (result.success) {
      // @ts-ignore
      modalApi.hide('');
      // @ts-ignore
      return {
        ...state,
        // @ts-ignore
        analytics: state.filter((item) => item.id !== params.id),
      };
    }

    return state;
  },
});

export const analyticsApi = {
  get: createEffect<PageParams, {}, {}>().use(getAnalytics),
  remove: createEffect<Partial<Analytic>, {}, {}>().use(updateAnalytic),
  add: createEffect<Partial<Analytic>, {}, {}>().use(updateAnalytic),
};

forward({
  from: analyticsApi.get.done,
  to: set,
});

forward({
  from: analyticsApi.remove.done,
  to: remove,
});

forward({
  from: analyticsApi.add.done,
  to: add,
});
