import * as React from 'react';

import cn from 'classnames';

type Param = {
  name: string;
  value: string | number | undefined;
  className?: string;
};

type ParamGroup = {
  title: string;
  items: Param[];
};

type Props = {
  params: ParamGroup[];
};

export const Params: React.FC<Props> = ({ params }) => {
  return (
    <div className="signal-params">
      {params.map((item, ind) => (
        <div key={ind} className="signal-params__group">
          <div className="signal-params__group-title">{item.title}</div>
          <table className="signal-params__group-table">
            <tbody>
              {item.items.map((param, index) => (
                <tr key={index}>
                  <td className="signal-params__group-td">{param.name}: </td>
                  <td className={cn('signal-params__group-td signal-params__group-td-right', param.className)}>
                    {param.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};
