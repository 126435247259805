import React, { useCallback, useEffect, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { Question } from 'types/faq';

import { Input } from '../../components/FormControls/Input';
import { Select } from '../../components/FormControls/Select';
import { TextArea } from '../../components/FormControls/TextArea';
import { SortType } from '../../components/TableGrid';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../const/validation';
import { Faq$, faqApi } from '../../effector/faq';
import { Modals$, TModal$State } from '../../effector/modals';
import { Option } from '../../types/option';
import { getMessageFromFailedRequest } from '../../utils';

type FormFields = {
  title: string;
  text: string;
  strategy: Option;
};

type FormProps = {
  error?: string;
} & FormikProps<FormFields>;

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ERRORS_TEXTS.required),
  text: Yup.string().required(ERRORS_TEXTS.required),
  strategy: Yup.object(),
});

const FormTemplate: React.FC<FormProps> = ({ dirty, error }) => {
  const { strategies } = useStore(Faq$);
  const disabled = !dirty;

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <Field type="text" name="title" placeholder="Заголовок" component={Input} />
      </div>
      <div className="form__group">
        <Field type="text" name="text" placeholder="Текст" component={TextArea} />
      </div>
      {strategies && (
        <div className="form__group">
          <Field
            name="strategy"
            component={Select}
            className="select__control__max-height"
            placeholder="Стратегия"
            options={strategies}
          />
        </div>
      )}
      <div className="remove-delayed-signal__button-wrapper">
        <Button className="button__primary button__large" type={ButtonType.submit} disabled={disabled}>
          Сохранить
        </Button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const AddQuestion: React.FC = () => {
  const [error, setError] = useState<string>();
  const { strategies } = useStore(Faq$);

  const { enqueueSnackbar } = useSnackbar();

  const modalsState = useStore<TModal$State<{ question: Question }>>(Modals$);

  const question = modalsState.data?.question as Question;
  const isEdit = Boolean(question);

  const initialValues = {
    title: question?.title ?? '',
    text: question?.text ?? '',
    strategy: strategies.find((item) => item.id === question?.parameters?.strategyId) as Option,
  };

  const handleError = useCallback((e: Error) => {
    const message = getMessageFromFailedRequest(e);
    setError(message);
  }, []);

  const onSubmit = (values: FormFields) => {
    const data = {
      title: values.title ?? '',
      text: values.text,
      parameters: values.strategy?.id ? { strategyId: values.strategy?.id } : {},
    };

    setError('');

    if (isEdit) {
      faqApi
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .editQuestion({ ...data, id: question.id })
        .then(() => {
          enqueueSnackbar('Вопрос обновлен');
        })
        .catch(handleError);
    } else {
      faqApi
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .addQuestion(data)
        .then(() => {
          enqueueSnackbar('Вопрос добавлен');
        })
        .catch(handleError);
    }
  };

  useEffect(() => {
    faqApi.getStrategies({ pageNumber: 0, pageSize: 300, sortFieldName: 'name', sortDirection: SortType.Ask });
  }, []);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) => React.createElement(FormTemplate, { ...props, error })}
    </Formik>
  );
};
