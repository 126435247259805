/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';

import { useStore } from 'effector-react';

import { removeSchedule } from '../../api/index';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { scheduleApi } from '../../effector/schedule';
import { Button, ButtonType } from '../UIKit/Button';

export const RemoveSchedule: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const modalState = useStore<TModal$State<{ id: number; name: string }>>(Modals$);

  const id = modalState?.data?.id ?? 0;
  const name = modalState?.data?.name ?? '';

  const handleError = (result: { success: boolean; errorMessage: string }) => {
    if (result && result.success) {
      scheduleApi.remove(id);
      modalApi.hide('');
    } else {
      setError(result?.errorMessage ?? 'Произошла ошибка на сервере');
      setLoading(false);
    }
  };

  const change = () => {
    setLoading(true);

    removeSchedule(id.toString())
      .then(handleError)
      .catch((e) => {
        setError(e.toString());
        setLoading(false);
      });
  };

  return (
    <div className="strategy-change">
      <div className="strategy-change__description">Вы уверены, что хотите удалить расписание для "{name}"</div>
      {error && <p className="error-message">{error}</p>}
      <div className="strategy-change__button-wrapper">
        <Button className="button__primary button__large" onClick={change} disabled={loading} type={ButtonType.button}>
          Ок
        </Button>
      </div>
    </div>
  );
};
