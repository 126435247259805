/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { modalApi } from './modals';
import {
  addFaqCategory,
  addFaqQuestion,
  deleteFaqCategory,
  deleteFaqQuestions,
  editFaqCategory,
  editFaqCategoryQuestions,
  editFaqQuestion,
  getFaqCategories,
  getFaqCategoryQuestions,
  getFaqQuestions,
  getStrategiesAdministration,
} from '../api';
import { PageParams } from '../components/TableGrid';
import { Question, Category } from '../types/faq';
import { Option } from '../types/option';

type TFaq$State = {
  categories: Category[];
  questions: Question[];
  strategies: Option[];
  categoryQuestions: Question[];
};

const initialState: TFaq$State = {
  categories: [],
  questions: [],
  strategies: [],
  categoryQuestions: [],
};

export const Faq$ = createStore<TFaq$State>(initialState);

const {
  setCategories,
  setQuestions,
  addCategory,
  addQuestion,
  editCategory,
  editQuestion,
  removeCategory,
  removeQuestion,
  setCategoryQuestions,
  setStrategies,
} = createApi(Faq$, {
  // @ts-ignore
  setCategories: (state, { result }) => {
    const data = {
      ...state,
      categories: Array.isArray(result) ? result : [],
    };

    return data;
  },
  // @ts-ignore
  addCategory: (state, { result }) => {
    if (result) {
      modalApi.hide('');
      faqApi.getCategories('');
    }

    return state;
  },
  editCategory: (state) => {
    modalApi.hide('');
    faqApi.getCategories('');

    return state;
  },
  // @ts-ignore
  removeCategory: (state) => {
    modalApi.hide('');
    faqApi.getCategories('');

    return state;
  },
  // @ts-ignore
  setQuestions: (state, { result }) => {
    const data = {
      ...state,
      questions: Array.isArray(result) ? result : [],
    };

    return data;
  },
  // @ts-ignore
  addQuestion: (state, { result }) => {
    if (result) {
      modalApi.hide('');
      faqApi.getQuestions('');
    }

    return state;
  },
  editQuestion: (state) => {
    modalApi.hide('');
    faqApi.getQuestions('');

    return state;
  },
  // @ts-ignore
  removeQuestion: (state) => {
    modalApi.hide('');
    faqApi.getQuestions('');

    return state;
  },
  setCategoryQuestions: (state, { result }) => {
    if (result && result.questions) {
      return {
        ...state,
        categoryQuestions: result.questions,
      };
    }
    return state;
  },
  setStrategies: (state, { result }) => {
    if (result.result.pageItems) {
      // @ts-ignore
      const strategies = result.result.pageItems.map((item) => ({
        id: item.id,
        name: item.name,
      }));

      return {
        ...state,
        strategies: strategies.concat(strategies).concat(strategies),
      };
    }

    return state;
  },
});

export const faqApi = {
  getCategories: createEffect<'', {}, {}>().use(getFaqCategories),
  addCategory: createEffect<Partial<Category>, {}, {}>().use(addFaqCategory),
  editCategory: createEffect<Category, {}, {}>().use(editFaqCategory),
  removeCategory: createEffect<string, {}, {}>().use(deleteFaqCategory),
  getQuestions: createEffect<'', {}, {}>().use(getFaqQuestions),
  addQuestion: createEffect<Partial<Question>, {}, {}>().use(addFaqQuestion),
  editQuestion: createEffect<Question, {}, {}>().use(editFaqQuestion),
  removeQuestion: createEffect<string, {}, {}>().use(deleteFaqQuestions),
  getCategoryQuestions: createEffect<string, {}, {}>().use(getFaqCategoryQuestions),
  editCategoryQuestions: createEffect<{ categoryId: string; questionIds: string[] }, {}, {}>().use(
    editFaqCategoryQuestions,
  ),
  getStrategies: createEffect<PageParams, {}, {}>().use(getStrategiesAdministration),
};

forward({
  from: faqApi.getCategories.done,
  to: setCategories,
});

forward({
  from: faqApi.addCategory.done,
  to: addCategory,
});

forward({
  from: faqApi.editCategory.done,
  to: editCategory,
});

forward({
  from: faqApi.removeCategory.done,
  to: removeCategory,
});

forward({
  from: faqApi.getQuestions.done,
  to: setQuestions,
});

forward({
  from: faqApi.addQuestion.done,
  to: addQuestion,
});

forward({
  from: faqApi.editQuestion.done,
  to: editQuestion,
});

forward({
  from: faqApi.removeQuestion.done,
  to: removeQuestion,
});

forward({
  from: faqApi.getCategoryQuestions.done,
  to: setCategoryQuestions,
});

forward({
  from: faqApi.editCategoryQuestions.done,
  to: setCategoryQuestions,
});

forward({
  from: faqApi.getStrategies.done,
  to: setStrategies,
});
