/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getSecurityInfo } from '../api';
import { SecurityInfo } from '../types/securityinfo';

type TSecurityInfo$State = SecurityInfo;

const initialState: TSecurityInfo$State = {
  securityInfo: {
    ticker: '',
    classCode: '',
    className: '',
    name: '',
    shortName: '',
    isin: '',
    currency: '',
    type: '',
    shortsAllowed: false,
    lotSize: 0,
    priceStep: 0,
    isCurrent: false,
    lastPrice: 0,
    lastTime: Date.now(),
  },
};

export const SecurityInfo$ = createStore<TSecurityInfo$State>(initialState);

const { set } = createApi(SecurityInfo$, {
  // @ts-ignore
  set: (state, { result }) => {
    const updatedState = {
      ...state,
      securityInfo: {
        ...result.result,
      },
    };
    return updatedState;
  },
});

export const securityInfoApi = {
  get: createEffect<string, { result: TSecurityInfo$State }, {}>().use(getSecurityInfo),
};

forward({
  from: securityInfoApi.get.done,
  to: set,
});
