/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SyntheticEvent, useState } from 'react';

import cs from 'classnames';
import { FieldInputProps, useField } from 'formik';
import InputMask from 'react-input-mask';

type Props = {
  field: FieldInputProps<any>;
  className?: string;
  disabled: boolean;
  type: string;
  placeholder: string;
  mask: string;
  hideErrorMessage?: boolean;
  onChange?: (value: any) => null;
};

export const InputMaskWrapper: React.FC<Props> = ({
  field: fieldProp,
  className,
  disabled,
  type,
  placeholder,
  mask,
  hideErrorMessage,
  onChange,
}) => {
  const [focus, setFocus] = useState(false);
  const [field, meta, helpers] = useField(fieldProp.name);

  const isError = meta.touched && meta.error;
  const isDirty = meta.value !== meta.initialValue;
  const onFocus = () => setFocus(true);

  const onBlur = (e: SyntheticEvent) => {
    field.onBlur(e);
    setFocus(false);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.value);
    onChange && onChange(event.target.value);
  };

  return (
    <label
      className={cs('form-control', className, {
        'form-control-focus': focus || isDirty || field.value,
      })}
    >
      <InputMask
        className={cs('form-control__field', {
          'form-control__field-invalid': isError,
          'form-control__field-disabled': disabled,
        })}
        disabled={disabled}
        mask={mask}
        placeholder={placeholder}
        type={type}
        required
        {...field}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChangeHandler}
      />
      {!hideErrorMessage && isError && <span className="form-control__message">{meta.error}</span>}
      <span className="form-control__placeholder">{placeholder}</span>
    </label>
  );
};
