/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';
import moment from 'moment';

import { Config$ } from '../../effector/config';
import { modalApi } from '../../effector/modals';
import { User$, userApi } from '../../effector/user';
import { MODALS } from '../../utils/types';
import { Assembly } from '../Assembly';
import { Logo } from '../Logo';
import { DropDown } from '../UIKit/DropDown';

const items = [
  {
    id: 1,
    text: 'Сменить пароль',
    onClick: () => modalApi.show({ modalId: MODALS.CHANGE_PASSWORD }),
  },
  {
    id: 2,
    text: 'Выход',
    // @ts-ignore
    onClick: () => userApi.logout(),
  },
];

export const Header: React.FC = () => {
  //@ts-ignore
  const user = useStore(User$);
  const config = useStore(Config$);

  return (
    <div className="header">
      <div className="header__logo">
        <Logo />
      </div>
      <div className="header__assembly">
        <Assembly description={`сборка ${config?.version} (${moment(config?.buildDate).format('DD.MM.YYYY HH:mm')})`} />
      </div>
      <div className="header__user">
        <FontAwesomeIcon icon={faUser} className="header__user-icon" />
        <DropDown text={user?.name} items={items} />
      </div>
    </div>
  );
};
