import React, { useEffect, useMemo } from 'react';

import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';
import { Navigate, Route, Routes as RoutesComponent } from 'react-router-dom';

import { EditClient } from './ClientInfo';
import { Clients } from './Clients';
import { EditStrategy } from './Edit';
import { EditInvestbox } from './EditInvestbox';
import { Faq } from './Faq';
import { Investboxes } from './Investboxes';
import { Mailer } from './Mailer';
import { SecurityInfo } from './SecurityInfo';
import { Services } from './Services';
import { Strategies } from './Strategies';
import { StrategyDetails } from './StrategyDetails';
import { Users } from './Users';
import { Navigation } from '../../components/Navigation';
import { User$ } from '../../effector/user';
import { userRolesApi } from '../../effector/userRoleList';
import { UserRoles } from '../../types/userRoles';
import { checkPermissions, UserAction } from '../../utils/permissions';
import { PrivateRoute } from '../PrivateRoute';
import {
  PAGE_ADMINISTRATION_CLIENTS,
  PAGE_ADMINISTRATION_INVESTBOXES,
  PAGE_ADMINISTRATION_SERVICES,
  PAGE_ADMINISTRATION_STRATEGIES,
  PAGE_ADMINISTRATION_STRATEGY_DETAILS,
  PAGE_ADMINISTRATION_USERS,
  PAGE_ADMINISTRATION_SECURITY_INFO,
  PAGE_ADMINISTRATION_FAQ,
  PAGE_ADMINISTRATION_MAILER,
} from '../Routes';

export const Administration: React.FC = () => {
  const user = useStore(User$);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    userRolesApi.get('');
  }, []);

  const navigation = useMemo(() => {
    if (user) {
      const items = [
        {
          name: 'Пользователи',
          path: PAGE_ADMINISTRATION_USERS,
          action: UserAction.administrationUsers,
        },
        {
          name: 'Стратегии',
          path: PAGE_ADMINISTRATION_STRATEGIES,
          action: UserAction.administrationStrategies,
        },
        {
          name: 'Управление стратегией',
          path: PAGE_ADMINISTRATION_STRATEGY_DETAILS,
          action: UserAction.administrationManagement,
        },
        {
          name: 'Копилки',
          path: PAGE_ADMINISTRATION_INVESTBOXES,
          action: UserAction.administrationInvestbox,
        },
        {
          name: 'Сервисы (управление)',
          path: PAGE_ADMINISTRATION_SERVICES,
          action: UserAction.administrationServices,
        },
        {
          name: 'Клиенты',
          path: PAGE_ADMINISTRATION_CLIENTS,
          action: UserAction.administrationClients,
        },
        {
          name: 'Mailer',
          path: PAGE_ADMINISTRATION_MAILER,
          action: UserAction.administrationCb,
        },
        {
          name: 'ЦБ',
          path: PAGE_ADMINISTRATION_SECURITY_INFO,
          action: UserAction.administrationCb,
        },
        {
          name: 'FAQ',
          path: PAGE_ADMINISTRATION_FAQ,
          action: UserAction.administrationCb,
        },
      ];

      return items.filter((item) => checkPermissions(user.role, item.action));
    }
    return [];
  }, [user]);

  const redirectUrl = useMemo(() => {
    if (user?.role !== UserRoles.Developer) {
      return PAGE_ADMINISTRATION_USERS;
    }
    return PAGE_ADMINISTRATION_STRATEGIES;
  }, [user]);

  return (
    <div className="administration">
      <div className="administration__navigation">
        <Navigation items={navigation} />
      </div>
      <div className="administration__content">
        <RoutesComponent>
          <Route
            path={PAGE_ADMINISTRATION_STRATEGIES}
            element={<PrivateRoute userAction={UserAction.administrationStrategies} component={Strategies} />}
          />
          <Route
            path={PAGE_ADMINISTRATION_USERS}
            element={<PrivateRoute userAction={UserAction.administrationUsers} component={Users} />}
          />
          <Route
            path={PAGE_ADMINISTRATION_SERVICES}
            element={<PrivateRoute userAction={UserAction.administrationServices} component={Services} />}
          />
          <Route
            path={PAGE_ADMINISTRATION_SECURITY_INFO}
            element={<PrivateRoute userAction={UserAction.administrationCb} component={SecurityInfo} />}
          />
          <Route
            path={PAGE_ADMINISTRATION_INVESTBOXES}
            element={<PrivateRoute userAction={UserAction.administrationInvestbox} component={Investboxes} />}
          />
          <Route
            path={`${PAGE_ADMINISTRATION_STRATEGIES}/:id`}
            element={<EditStrategy notify={(msg: string) => enqueueSnackbar(msg)} />}
          />
          <Route
            path={`${PAGE_ADMINISTRATION_INVESTBOXES}/:id`}
            element={<EditInvestbox notify={(msg: string) => enqueueSnackbar(msg)} />}
          />
          <Route
            path={`${PAGE_ADMINISTRATION_STRATEGY_DETAILS}/:strategyId?`}
            element={<PrivateRoute userAction={UserAction.administrationManagement} component={StrategyDetails} />}
          />
          <Route
            path={PAGE_ADMINISTRATION_CLIENTS}
            element={<PrivateRoute userAction={UserAction.administrationClients} component={Clients} />}
          />
          <Route path={`${PAGE_ADMINISTRATION_CLIENTS}/:id`} element={<EditClient />} />
          <Route
            path={`${PAGE_ADMINISTRATION_FAQ}/*`}
            element={<PrivateRoute userAction={UserAction.administrationFaq} component={Faq} />}
          />
          <Route
            path={`${PAGE_ADMINISTRATION_MAILER}/*`}
            element={<PrivateRoute userAction={UserAction.administrationFaq} component={Mailer} />}
          />
          <Route path="*" element={<Navigate replace to={redirectUrl} />} />
        </RoutesComponent>
      </div>
    </div>
  );
};
