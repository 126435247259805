/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';
import { Field, FieldArray, Form, Formik, FormikProps, useField } from 'formik';
import * as Yup from 'yup';

import { setScheduleTimes } from '../../api';
import { ERRORS_TEXTS } from '../../const/validation';
import { forbidden$, forbiddenApi } from '../../effector/forbidden';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { scheduleApi } from '../../effector/schedule';
import { MarketSchedule, TradeTime, ScheduleNames, SecurityInfo } from '../../types/marketSchedule';
import { Option } from '../../types/option';
import { Suggestion } from '../../types/suggestion';
import { Input } from '../FormControls/Input';
import { InputMaskWrapper } from '../FormControls/InputMask';
import { Select } from '../FormControls/Select';
import { Search } from '../Search';
import { Button, ButtonType } from '../UIKit/Button';

type ModalParams = {
  id: number;
  title: ScheduleNames;
  markets: string[];
  forbiddenSecurities: SecurityInfo[];
  times: TradeTime[];
  availableMarkets: Option[];
};

type FormFields = {
  markets: Option[];
  times: TradeTime[];
  timesChanged: boolean;
  forbidden: SecurityInfo[];
  start?: string;
  end?: string;
  description?: string;
};

type FormProps = {
  title: string;
  allMarkets: Option[];
  forbidden: SecurityInfo[];
  addForbidden: Set<string>;
  removeForbidden: Set<string>;
  error?: string;
  send: boolean;
} & FormikProps<FormFields>;

const validationSchema = Yup.object().shape({
  times: Yup.array().of(
    Yup.object().shape({
      start: Yup.string()
        .matches(/^(0\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$/, 'Некорректный формат времени')
        .required(ERRORS_TEXTS.required),
      end: Yup.string()
        .matches(/^(0\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$/, 'Некорректный формат времени')
        .test('is-interval', 'Конечное время интервала меньше начального', function validate(end) {
          return !end || this.parent.start < end;
        })
        .required(ERRORS_TEXTS.required),
    }),
  ),
  start: Yup.string()
    .transform((s) => (!s ? '' : s))
    .matches(/^$|^((0\d|1\d|2[0-3]):[0-5]\d:[0-5]\d)$/, 'Некорректный формат времени'),
  //.required(ERRORS_TEXTS.required),
  end: Yup.string()
    .transform((s) => (!s || s === '__:__:__' ? '' : s))
    .matches(/^$|^((0\d|1\d|2[0-3]):[0-5]\d:[0-5]\d)$/, 'Некорректный формат времени')
    .test('is-interval', 'Конечное время интервала меньше начального', function validate(end) {
      return !end || this.parent.start < end;
    })
    .when('start', {
      is: (s: string) => !!s && s.length > 0,
      then: Yup.string().required(ERRORS_TEXTS.required),
    }),
});

const FormTemplate: React.FC<FormProps> = ({
  title,
  allMarkets,
  forbidden,
  addForbidden,
  removeForbidden,
  dirty,
  error,
  send,
  values,
}) => {
  const [, /*field*/ meta] = useField('times');
  const [, start, startHelpers] = useField('start');
  const [, end, endHelpers] = useField('end');
  const [, , timesChangedHelpers] = useField('timesChanged');
  const [, description, descriptionHelpers] = useField('description');
  const [forbiddenSecurities, setForbiddenSecurities] = useState<SecurityInfo[]>(forbidden);
  const [classCodes, setClassCodes] = useState<string[]>(values.markets.map((s) => s.id));
  const [, , forbiddenHelpers] = useField('forbidden');

  const isScheduleError = meta.error && Array.isArray(meta.error);
  // @ts-ignore
  const errorMessage = isScheduleError ? meta.error.find((s) => !!s && (s['start'] || s['end'])) : undefined;
  const isStartDirty = start.value !== start.initialValue;
  const isEndDirty = end.value !== end.initialValue;
  const disabled = !dirty || send;

  const onSearch = (key: string, suggestion?: Suggestion) => {
    const name = `${key} (${suggestion?.name ?? ''})`;

    if (!forbiddenSecurities.find((s) => s.key === key)) {
      addForbidden.add(key);
      removeForbidden.delete(key);

      const result = [
        ...forbiddenSecurities,
        {
          key: key,
          name: name,
          classCode: suggestion?.classCode ?? '',
        },
      ];

      setForbiddenSecurities(result);
      forbiddenHelpers.setValue(result);
    }
  };

  const onRemove = (index: number) => {
    const key = forbiddenSecurities[index].key;
    const result = forbiddenSecurities.filter((s, ii) => index != ii);
    addForbidden.delete(key);
    removeForbidden.add(key);
    setForbiddenSecurities(result);
    forbiddenHelpers.setValue(result);
  };

  const onChangeCodes = (codes: ReadonlyArray<Option>) => {
    setClassCodes(codes?.map((c) => c.id) ?? []);
  };

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <p>{title}</p>
      </div>
      <div className="form__group">
        <Field
          name="markets"
          placeholder="Торговые площадки"
          options={allMarkets}
          multiple={true}
          onChange={onChangeCodes}
          component={Select}
        />
      </div>
      <div className="form__group">
        <p>Список запрещенных инструментов</p>
      </div>
      <div className="form__group">
        <Search classCodes={classCodes} showSelectedValue={false} disabled={false} onSearchClick={onSearch} />
      </div>
      {forbiddenSecurities.length > 0 && (
        <div className="form__group">
          <ul>
            {forbiddenSecurities.map((s, i) => {
              if (classCodes.find((c) => c === s.classCode)) {
                return (
                  <li className="schedule__forbidden" key={i}>
                    <span>{s.name}</span>
                    <span className="pointer form-schedule__delete">
                      <FontAwesomeIcon icon={faTimes} onClick={() => onRemove(i)} />
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      )}

      <div className="form__group">
        <p>Интервалы времени в формате HH:mm:ss – HH:mm:ss с описанием</p>
      </div>
      <div className="form__group">
        <FieldArray
          name="times"
          render={
            // @ts-ignore
            (arrayHelpers) => (
              <>
                {values.times.map((t, i: number) => {
                  return (
                    <div key={i} className="form-schedule__tabs">
                      <div className="form-schedule__tab">
                        <Field
                          type="text"
                          name={`times.${i}.start`}
                          placeholder="начало"
                          disabled={false}
                          mask="99:99:99"
                          hideErrorMessage={true}
                          onChange={() => timesChangedHelpers.setValue(true)}
                          component={InputMaskWrapper}
                        />
                      </div>
                      <div className="form-schedule__tab">
                        <Field
                          type="text"
                          name={`times.${i}.end`}
                          placeholder="завершение"
                          disabled={false}
                          mask="99:99:99"
                          hideErrorMessage={true}
                          onChange={() => timesChangedHelpers.setValue(true)}
                          component={InputMaskWrapper}
                        />
                      </div>
                      <div className="form-schedule__tab">
                        <Field name={`times.${i}.description`} type="text" placeholder="описание" component={Input} />
                      </div>
                      <div className="form-schedule__tab">
                        <span className="pointer form-schedule__delete">
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => {
                              arrayHelpers.remove(i);
                              timesChangedHelpers.setValue(true);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}

                <div className="form-schedule__tabs">
                  <div className="form-schedule__tab">
                    <Field
                      type="text"
                      name="start"
                      placeholder="начало"
                      disabled={false}
                      mask="99:99:99"
                      hideErrorMessage={true}
                      component={InputMaskWrapper}
                    />
                  </div>
                  <div className="form-schedule__tab">
                    <Field
                      type="text"
                      name="end"
                      placeholder="завершение"
                      disabled={false}
                      mask="99:99:99"
                      hideErrorMessage={true}
                      component={InputMaskWrapper}
                    />
                  </div>
                  <div className="form-schedule__tab">
                    <Field name="description" type="text" placeholder="описание" component={Input} />
                  </div>
                  <div className="form-schedule__tab">
                    <span>
                      <FontAwesomeIcon
                        //className='fa-disabled form-schedule__hidden'
                        className="pointer form-schedule__add"
                        icon={faPlus}
                        onClick={() => {
                          arrayHelpers.push({ start: start.value, end: end.value, description: description.value });
                          startHelpers.setValue('');
                          endHelpers.setValue('');
                          descriptionHelpers.setValue('');
                          timesChangedHelpers.setValue(true);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </>
            )
          }
        />
        {errorMessage && <span className="form-control__message">{errorMessage['start'] || errorMessage['end']}</span>}
        {!errorMessage && ((start.error && isStartDirty) || (end.error && isEndDirty)) && (
          <span className="form-control__message">{start.error ?? end.error}</span>
        )}
        {error && <span className="form-control__message">{error}</span>}
      </div>

      <div className="form__group">
        <Button className="button__primary button__large" type={ButtonType.submit} disabled={disabled}>
          Сохранить
        </Button>
      </div>
    </Form>
  );
};

export const ScheduleEdit: React.FC = () => {
  const [send, setSend] = useState(false);
  const [error, setError] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addForbidden, setAddForbidden] = useState<Set<string>>(new Set());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [removeForbidden, setRemoveForbidden] = useState<Set<string>>(new Set());

  const modalsState = useStore<TModal$State<ModalParams>>(Modals$);
  const forbiddenStore = useStore(forbidden$);

  const classCodes = modalsState.data?.markets ?? [];
  const forbidden = forbiddenStore;

  const init = () => {
    return {
      markets:
        classCodes.map((q) => {
          return { id: q, name: q };
        }) ?? [],
      forbidden: [...forbiddenStore],
      times: modalsState.data?.times ?? [],
      timesChanged: false,
    };
  };

  const allMarkets = modalsState.data?.availableMarkets ?? [];
  const schedType = modalsState.data?.title ?? ScheduleNames.trade;

  const title =
    modalsState.data?.title === ScheduleNames.signal
      ? 'Раcписание времени подачи торговых сигналов по стратегиям'
      : modalsState.data?.title === ScheduleNames.rebalance
      ? 'Раcписание времени ребалансировки клиенских портфелей'
      : 'Раcписание рабочего времени биржи';

  const onSubmit = (values: FormFields) => {
    setSend(true);

    const result = [...values.times];

    let timesChanged = values.timesChanged;

    if (values.start && values.start.length > 0 && values.end && values.end.length > 0) {
      result.push({
        start: values.start,
        end: values.end,
        description: values.description ?? '',
      });

      timesChanged = true;
    }

    const data: MarketSchedule = {
      id: modalsState.data?.id ?? 0,
      name: null,
      classCodes: values.markets.map((s) => s.id),
      /// @ts-ignore
      addForbiddenSecurities: [...addForbidden],
      /// @ts-ignore
      removeForbiddenSecurities: [...removeForbidden],
      tradeTimes: schedType === ScheduleNames.trade && timesChanged ? result : null,
      signalTimes: schedType === ScheduleNames.signal && timesChanged ? result : null,
      rebalanceTimes: schedType === ScheduleNames.rebalance && timesChanged ? result : null,
      weekends: [],
    };

    setScheduleTimes(data)
      .then((response) => {
        if (response) {
          if (response.success) {
            scheduleApi.set(response.result);
            forbiddenApi.set([...values.forbidden]);
            modalApi.hide('');
          } else {
            setError(response.errorMessage ?? 'Ошибка на сервере');
            setSend(false);
          }
        }
      })
      .catch((e) => {
        setError(e.toString());
        setSend(false);
      });
  };

  return (
    <Formik initialValues={init()} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) =>
        React.createElement(FormTemplate, {
          ...props,
          title,
          allMarkets,
          forbidden,
          addForbidden,
          removeForbidden,
          error,
          send,
        })
      }
    </Formik>
  );
};
