import React from 'react';

import { Navigate, Route, Routes as RoutesComponent } from 'react-router-dom';

import { Agreement } from './Agreement';
import { Author } from './Author';
import { Strategy } from './Strategy';
import { Navigation } from '../../../components/Navigation';
import {
  PAGE_ADMINISTRATION_MAILER_STRATEGY,
  PAGE_ADMINISTRATION_MAILER_AUTHOR,
  PAGE_ADMINISTRATION_MAILER_AGREEMENT,
} from '../../Routes';

export const Mailer: React.FC = ({}) => {
  const items = [
    {
      name: 'По списку ген. соглашений',
      path: PAGE_ADMINISTRATION_MAILER_AGREEMENT,
    },
    {
      name: 'По стратегии',
      path: PAGE_ADMINISTRATION_MAILER_STRATEGY,
    },
    {
      name: 'По автору',
      path: PAGE_ADMINISTRATION_MAILER_AUTHOR,
    },
  ];

  return (
    <div className="statistic">
      <div className="statistic__navigation">
        <Navigation items={items} />
      </div>
      <div style={{ marginTop: '20px' }}>
        <RoutesComponent>
          <Route path={PAGE_ADMINISTRATION_MAILER_AGREEMENT} element={<Agreement />} />
          <Route path={PAGE_ADMINISTRATION_MAILER_STRATEGY} element={<Strategy />} />
          <Route path={PAGE_ADMINISTRATION_MAILER_AUTHOR} element={<Author />} />
          <Route path="*" element={<Navigate to={PAGE_ADMINISTRATION_MAILER_AGREEMENT} />} />
        </RoutesComponent>
      </div>
    </div>
  );
};
