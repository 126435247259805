/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useState } from 'react';

import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';

import { Button, ButtonType } from '../../components/UIKit/Button';
import { faqApi } from '../../effector/faq';
import { Modals$, TModal$State } from '../../effector/modals';
import { getMessageFromFailedRequest } from '../../utils';

export const RemoveQuestion: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const modalsState = useStore<TModal$State<{ questionId: string }>>(Modals$);

  const questionId = modalsState.data?.questionId as string;

  const handleError = useCallback((e: Error) => {
    const message = getMessageFromFailedRequest(e);
    setError(message);
  }, []);

  const removeQuestion = useCallback(() => {
    setLoading(true);
    setError('');
    faqApi
      .removeQuestion(questionId)
      .then(() => {
        enqueueSnackbar('Вопрос удален');
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  }, [questionId, handleError, enqueueSnackbar]);

  return (
    <div className="remove-delayed-signal">
      <div className="remove-delayed-signal__description">Вы уверены что хотите удалить вопрос?</div>
      {error && <p className="error-message">{error}</p>}
      <div className="remove-delayed-signal__button-wrapper">
        <Button
          className="button__primary button__large"
          onClick={removeQuestion}
          disabled={loading}
          type={ButtonType.button}
        >
          Удалить
        </Button>
      </div>
    </div>
  );
};
