/* eslint-disable react/display-name */
import React from 'react';

import { UserRoles } from '../types/userRoles';
import { checkPermissions, UserAction } from '../utils/permissions';

export type ProtectedProps = {
  role: UserRoles;
  action: UserAction;
};

type IProtected = <T, E extends ProtectedProps>(WrappedComponent: React.FC<T>) => React.FC<T & E>;

export const withProtection: IProtected = (WrappedComponent) => (props) => {
  const isAvailable = checkPermissions(props.role, props.action);

  if (!isAvailable) {
    return null;
  }

  return <WrappedComponent {...props} />;
};
