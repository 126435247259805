/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useMemo } from 'react';

import { faEdit, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';

import { TableGrid } from '../../../../components/TableGrid';
import { Button, ButtonType } from '../../../../components/UIKit/Button';
import { Faq$, faqApi } from '../../../../effector/faq';
import { modalApi } from '../../../../effector/modals';
import { MODALS } from '../../../../utils/types';

export const Categories: React.FC = ({}) => {
  const { categories } = useStore(Faq$);

  const loadCategories = useCallback(() => {
    faqApi.getCategories('');
  }, []);

  const onAddCategory = useCallback(() => {
    modalApi.show({
      modalId: MODALS.FAQ_ADD_CATEGORY,
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: ' Идентификатор',
        accessor: 'id',
        className: 'table__column-left',
      },
      {
        Header: 'Название',
        accessor: 'name',
        className: 'table__column-left',
      },
      {
        Header: 'Url',
        accessor: 'friendlyUrl',
        className: 'table__column-left',
      },
      {
        Header: '',
        accessor: 'edit',
        className: 'table__column-right table__column-action',
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original,
            },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faEdit}
                title="Редактировать"
                onClick={() => {
                  // @ts-ignore
                  modalApi.show({
                    modalId: MODALS.FAQ_ADD_CATEGORY,
                    data: {
                      category: original,
                    },
                  });
                }}
              />
            </span>
          );
        },
      },
      {
        Header: '',
        accessor: 'remove',
        className: 'table__column-right table__column-action',
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original,
            },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faTimes}
                title="Удалить"
                className="fall"
                onClick={() => {
                  // @ts-ignore
                  modalApi.show({
                    modalId: MODALS.FAQ_DELETE_CATEGORY,
                    data: {
                      categoryId: original.id,
                    },
                  });
                }}
              />
            </span>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Button className="button button__primary button__small" type={ButtonType.button} onClick={onAddCategory}>
        <FontAwesomeIcon icon={faPlus} /> Добавить
      </Button>
      <TableGrid columns={columns} data={categories} sortField="firstName" onPageChange={loadCategories} />
    </>
  );
};
