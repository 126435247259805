import React from 'react';

import { NavLink } from 'react-router-dom';

type Link = {
  name: string;
  path: string;
};

type Props = {
  items: Link[];
};

export const Navigation: React.FC<Props> = ({ items }) => {
  return (
    <ul className="navigation">
      {items.map((item) => (
        <NavLink
          key={item.path}
          to={item.path}
          className={({ isActive }) => (isActive ? 'navigation__link navigation__link-active' : 'navigation__link')}
        >
          {item.name}
        </NavLink>
      ))}
    </ul>
  );
};
