export enum UserRoles {
  Administrator = 'Administrator',
  Manager = 'Manager',
  Representative = 'Representative',
  RiskManager = 'RiskManager',
  Expert = 'Expert',
  ContentManager = 'ContentManager',
  DigitalExpert = 'DigitalExpert',
  Support = 'Support',
  Developer = 'Developer',
  Auditor = 'Auditor',
}
