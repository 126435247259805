/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FIELDS_LIMITS, ERRORS_TEXTS } from '../../const/validation';
import { modalApi } from '../../effector/modals';
import { User$, userApi } from '../../effector/user';
import { Input } from '../FormControls/Input';
import { Button, ButtonType } from '../UIKit/Button';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .max(FIELDS_LIMITS.passwordMaxLength, ERRORS_TEXTS.passwordMaxLength)
    .required(ERRORS_TEXTS.required),
  password: Yup.string()
    .min(FIELDS_LIMITS.passwordMinLength, ERRORS_TEXTS.passwordMinLength)
    .max(FIELDS_LIMITS.passwordMaxLength, ERRORS_TEXTS.passwordMaxLength)
    .required(ERRORS_TEXTS.required),
  confirmPassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref('password'), null], ERRORS_TEXTS.passwordMatches)
    .min(FIELDS_LIMITS.passwordMinLength, ERRORS_TEXTS.passwordMinLength)
    .max(FIELDS_LIMITS.passwordMaxLength, ERRORS_TEXTS.passwordMaxLength)
    .required(ERRORS_TEXTS.required),
});

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

type FormFields = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ errors, touched, loading, error }) => {
  const disable = Object.keys(errors).length > 0 || Object.keys(touched).length == 0 || loading;

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <Field type="password" name="currentPassword" placeholder="Текущий пароль" component={Input} />
      </div>

      <div className="form__group">
        <Field type="password" name="password" placeholder="Новый пароль" component={Input} />
      </div>

      <div className="form__group">
        <Field type="password" name="confirmPassword" placeholder="Повторите новый пароль" component={Input} />
      </div>

      {error && <p className="error-message">{error}</p>}
      <Button className="button__primary button__large button__wide" disabled={disable} type={ButtonType.submit}>
        Сменить
      </Button>
    </Form>
  );
};

export const ChangePasswordForm: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useStore(User$);
  const location = useLocation();

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      //  @ts-ignore
      setError(result.errorMessage);
    } else {
      modalApi.hide('');
    }
  };

  const onSubmit = (values: FormFields) => {
    const params = {
      // @ts-ignore
      userId: user?.login?.toString() || location?.state?.login,
      oldPassword: values.currentPassword,
      newPassword: values.password,
      confirmNewPassword: values.confirmPassword,
    };

    setLoading(true);
    userApi
      .changePassword(params)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      onSubmit={onSubmit}
    >
      {(props) =>
        React.createElement(FormTemplate, {
          ...props,
          error,
          loading,
        })
      }
    </Formik>
  );
};
