import React, { useState, useEffect } from 'react';

import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import ReactPaginate from 'react-paginate';
import { useTable } from 'react-table';

import { Nullable } from '../../utils/types';

type Column = {
  Header: string;
  accessor: string;
  className?: string;
  Footer?: () => void;
};

export enum Criteria {
  Equals = 1,
  Contains = 2,
}

export type Filter = {
  fieldName: string;
  filterCriteria: Criteria;
  matches: (string | number)[];
};

export type PageParams = {
  pageNumber: number;
  pageSize: number;
  sortFieldName: string;
  sortDirection: SortType;
  filters?: Filter[];
};

type Props = {
  columns: Column[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: object[];
  sortField: string;
  isRecursiveFetch?: boolean;
  forceReload?: boolean;
  footer?: boolean;
  page?: number;
  pageSize?: number;
  totalRecords?: number;
  onPageChange?: (params: PageParams) => void;
};

export enum SortType {
  Desk = -1,
  Ask = 1,
}

type Sort = {
  id: string;
  sort: SortType;
};

export const TableGrid: React.FC<Props> = ({
  columns,
  data,
  sortField = '',
  isRecursiveFetch = true,
  forceReload = false,
  footer = false,
  page = 0,
  pageSize = 25,
  totalRecords = 0,
  onPageChange,
}) => {
  const [sort, setSort] = useState<Sort>({
    id: sortField,
    sort: SortType.Desk,
  });
  const [pageInner, setPage] = useState(page);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSizeInner, setPageSize] = useState(pageSize);
  const [totalPages, setTotalPages] = useState(Math.ceil(totalRecords / pageSize));

  const timerId = React.useRef<Nullable<number>>(null);

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    columns,
    data,
  });

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / pageSize));
  }, [totalRecords]);

  useEffect(() => {
    load();

    if (isRecursiveFetch) {
      timerId.current = window.setInterval(load, 10000);
    }

    return () => {
      if (isRecursiveFetch) {
        clearInterval(timerId.current as number);
      }
    };
  }, [pageInner, pageSizeInner, sort]);

  useEffect(() => {
    if (forceReload) {
      load();
    }
  }, [forceReload]);

  const onPageClick = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  const onSortHeaderClick = (id: string) => {
    if (sort.id === id) {
      if (sort.sort === SortType.Desk) {
        setSort({ id, sort: SortType.Ask });
      } else {
        setSort({ id, sort: SortType.Desk });
      }
    } else {
      setSort({ id, sort: SortType.Desk });
    }
  };

  const load = () => {
    const params = {
      pageNumber: pageInner || page,
      pageSize: pageSizeInner || pageSize,
      sortFieldName: sort.id.toLowerCase() || sortField.toLowerCase(),
      sortDirection: sort.sort,
    };

    onPageChange && onPageChange(params);
  };

  return (
    <>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, ind) => (
            <tr
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              key={ind}
              className="table__tr"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  key={index}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  className={cn('table__th', column.className, {
                    'table__th-active': sort.id === column.id,
                  })}
                  onClick={() => onSortHeaderClick(column.id)}
                >
                  {column.render('Header')}
                  <span className="table__th-sorted ">
                    {sort.id === column.id ? (
                      <FontAwesomeIcon icon={sort.sort === SortType.Desk ? faSortUp : faSortDown} />
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                key={i}
                className="table__tr"
                {...row.getRowProps()}
              >
                {row.cells.map((cell, ind) => {
                  return (
                    <td
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      key={ind}
                      // eslint-disable-next-line react/jsx-key, @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      className={cell.column.className}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>

        {footer && (
          <tfoot>
            {footerGroups.map((group, ind) => (
              <tr
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                key={ind}
                className="table__tr table__tr-mark"
                {...group.getFooterGroupProps()}
              >
                {group.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <td
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    className={cn('table__td-mark', column.className)}
                    {...column.getFooterProps()}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
      {totalPages > 1 && (
        <ReactPaginate
          previousLabel={''}
          nextLabel={''}
          breakLabel={'...'}
          pageCount={totalPages}
          initialPage={pageInner}
          onPageChange={onPageClick}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          containerClassName="pagination"
          breakLinkClassName="pagination__link"
          pageLinkClassName="pagination__link"
          activeLinkClassName="pagination__link-active"
          previousLinkClassName="pagination__link pagination__prev"
          nextLinkClassName="pagination__link pagination__next"
          activeClassName="active"
        />
      )}
    </>
  );
};
