import React, { useState } from 'react';

import { updateHistory } from '../../api/index';
import { modalApi } from '../../effector/modals';
import { Button, ButtonType } from '../UIKit/Button';

export const UpdateHistory: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleError = (result: { success: boolean; errorMessage: string }) => {
    if (result.errorMessage) {
      setError(result.errorMessage);
    } else {
      modalApi.hide('');
    }
  };

  const change = () => {
    setLoading(true);

    updateHistory()
      .then(handleError)
      .catch((e) => {
        setError(e.toString());
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="strategy-change">
      <div className="strategy-change__description">Вы уверены, что хотите пересчитать рейтинг</div>
      {error && <p className="error-message">{error}</p>}
      <div className="strategy-change__button-wrapper">
        <Button className="button__primary button__large" onClick={change} disabled={loading} type={ButtonType.button}>
          Ок
        </Button>
      </div>
    </div>
  );
};
