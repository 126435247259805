/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import cn from 'classnames';
import { FieldInputProps, useField } from 'formik';

type Props = {
  field: FieldInputProps<any>;
  text: string;
  disabled?: boolean;
  onChange?: (value: any) => null;
};

export const Checkbox: React.FC<Props> = ({ field: fieldProp, text, disabled, onChange }) => {
  const [field, , helpers] = useField(fieldProp.name);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
    onChange && onChange(event.target.checked);
  };

  return (
    <label className={cn('checkbox', { checkbox_disabled: disabled })}>
      <input
        {...field}
        type="checkbox"
        className="checkbox__control"
        disabled={disabled}
        onChange={onChangeHandler}
        checked={field.value}
      />
      <span className="checkbox__text" dangerouslySetInnerHTML={{ __html: text }} />
    </label>
  );
};
