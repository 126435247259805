/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getClassCodes } from '../api';

export const codes$ = createStore<string[]>([]);

const helper = createApi(codes$, {
  // @ts-ignore
  get: (state, { result }) => {
    if (result && result.success) {
      return result.result ?? [];
    }

    return state;
  },
});

export const classCodesApi = {
  get: createEffect<{}, string[], {}>().use(getClassCodes),
};

forward({
  from: classCodesApi.get.done,
  to: helper.get,
});
