import { Nullable } from '../utils/types';

export enum StatusEnum {
  Unattached = 0,
  AutoFollow = 1,
  Recommendations = 2,
  InvestMoneybox = 3,
}

export type ClientHistory = {
  dateTime: string;
  client: string;
  signals: string;
  messages: string;
  executions: string;
  tooltip: string;
};

export type ClientPosition = {
  weight: number;
  lastPrice: number;
  number: number;
  securityKey: string;
  avgPrice: number;
  currency: string;
  name: Nullable<string>;
};

export type Portfolio = {
  currency: string;
  liquid_price: number;
  portfolio_price: number;
};

export type ClientInfo = {
  clientId: string;
  agreementId: string;
  clientCode: string;
  agreement: string;
  fio: string;
  serviceDate: Nullable<string>;
  strategyId: Nullable<string>;
  strategyName: string;
  status: StatusEnum;
  value: number;
  portfolio: Portfolio;
  realizedPnl: number;
  isMarginal: boolean;
  checksNeeded: string[];
  block: Nullable<string>;
  blockedPositions: { [index: string]: number };
  blockSignalId: number;
};
