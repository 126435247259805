/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getCurrencyInfo } from '../api';
import { Currency } from '../types/aggregateStrategyInfoRef';

type Currency$State = Currency[];

export const currencyInfo$ = createStore<Currency$State>([]);

const { set } = createApi(currencyInfo$, {
  // @ts-ignore
  set: (state, result: { result: { result: Currency[] } }) => {
    if (result.result) return result.result.result ?? [];

    return [];
  },
});

export const currencyApi = {
  get: createEffect<{}, { result: Currency[] }, {}>().use(getCurrencyInfo),
};

forward({
  from: currencyApi.get.done,
  to: set,
});
