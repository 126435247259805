import React, { useEffect, useState } from 'react';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import download from 'downloadjs';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';

import { downloadHistory, downloadSandboxHistory } from '../../../api';
import { LineChart } from '../../../components/Chart/LineChart';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { Chart$, chartApi } from '../../../effector/chart';
import { Strategies$ } from '../../../effector/strategies';
import { StrategyStatus } from '../../../types/strategy';

type Period = {
  key: string;
  value: string;
};

const periods = [
  { key: 'ytd', value: 'YTD' },
  { key: '1m', value: '1 мес' },
  { key: '3m', value: '3 мес' },
  { key: '6m', value: '6 мес' },
  { key: '1y', value: '1 год' },
  { key: 'all', value: 'Весь период' },
];

type Props = {
  strategyId: string | undefined;
};

export const Chart: React.FC<Props> = ({ strategyId }) => {
  const [period, setPeriod] = useState(periods[periods.length - 1]);
  const chartStore = useStore(Chart$);
  const strategies = useStore(Strategies$);
  const { enqueueSnackbar } = useSnackbar();

  const strategy = strategies.find((item) => item.id === strategyId);

  useEffect(() => {
    const params = {
      strategyId,
      period: period.key,
    };
    if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
      chartApi.get(params);
    } else {
      chartApi.getSandbox(params);
    }
  }, [strategyId, period, strategy?.status]);

  const onPeriodClick = (newPeriod: Period) => {
    setPeriod(newPeriod);
  };

  const onDownload = () => {
    if (strategy?.status === StrategyStatus.SandBox || strategy?.status === StrategyStatus.Moderate) {
      downloadSandboxHistory(strategyId).then((response) => {
        const { success, result, errorMessage } = response;
        if (success) {
          download(
            `data:application/octet-stream;base64,${result.contentBase64}`,
            `${result.fileName}`,
            'application/octet-stream',
          );
        } else {
          enqueueSnackbar(errorMessage);
        }
      });
    } else {
      downloadHistory(strategyId).then((response) => {
        const { success, result, errorMessage } = response;

        if (success) {
          download(
            `data:application/octet-stream;base64,${result.contentBase64}`,
            `${result.fileName}`,
            'application/octet-stream',
          );
        } else {
          enqueueSnackbar(errorMessage);
        }
      });
    }
  };

  const { history, indexName } = chartStore;

  return (
    <div className="strategy-chart">
      <Button
        className="button button__download button__margin-top button__small"
        type={ButtonType.button}
        onClick={onDownload}
      >
        <FontAwesomeIcon icon={faDownload} /> Скачать историю доходности
      </Button>
      <div className="strategy-chart__periods">
        {periods.map((item) => (
          <span
            className={cn('strategy-chart__period', {
              'strategy-chart__period-active': period.key === item.key,
            })}
            key={item.key}
            onClick={() => onPeriodClick(item)}
          >
            {item.value}
          </span>
        ))}
      </div>
      <LineChart data={history} legends={{ vi: `индекс ${indexName}` }} />
    </div>
  );
};
