/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { ERRORS_TEXTS } from '../../const/validation';
import { User$, userApi } from '../../effector/user';
import { PAGE_STRATEGIES, PAGE_CHANGE_PASSWORD } from '../../screens/Routes';
import { Input } from '../FormControls/Input';
import { Button, ButtonType } from '../UIKit/Button';

const validationSchema = Yup.object().shape({
  smsCode: Yup.string().required(ERRORS_TEXTS.required),
});

const initialValues = {
  smsCode: '',
};

type FormFields = {
  smsCode: string;
};

type FormProps = {
  error: string;
  loading: boolean;
  phone: string;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ error, loading, phone }) => {
  const disable = loading;

  return (
    <Form className="form" noValidate>
      <div className="form__group">Сообщение отправлено на номер {phone}</div>
      <div className="form__group">
        <Field type="text" name="smsCode" placeholder="Код подтверждения" component={Input} />
      </div>

      {error && <p className="error-message">{error}</p>}

      <Button className="button__primary button__large button__wide" type={ButtonType.submit} disabled={disable}>
        Войти
      </Button>
    </Form>
  );
};

export const CheckLoginSmsForm: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useStore(User$);

  const phone = location?.state?.phone;
  const login = location?.state?.login;

  // @ts-ignore
  const errorHandler = (result, userId, success) => {
    if (result.errorCode === 1) {
      navigate(PAGE_CHANGE_PASSWORD, { state: { login: userId } });
      return;
    }
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
      return;
    }
    if (success) {
      navigate(PAGE_STRATEGIES);
    }
  };

  const onSubmit = (values: FormFields) => {
    const formData = {
      smsCode: values.smsCode,
    };

    setLoading(true);
    userApi
      .checkLoginSms(formData)
      .then((result) => {
        // @ts-ignore
        errorHandler(result, login, true);
      })
      .catch((result) => {
        // @ts-ignore
        errorHandler(result, login, false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (user) {
    navigate(PAGE_STRATEGIES);
    return null;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) =>
        React.createElement(FormTemplate, {
          ...props,
          error,
          loading,
          phone,
        })
      }
    </Formik>
  );
};
