/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useMemo } from 'react';

import { faEdit, faExchangeAlt, faPlus, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { useStore } from 'effector-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { ProtectedComponent } from '../../../components/ProtectedComponent';
import { PageParams, TableGrid } from '../../../components/TableGrid';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { aggregateInvestboxes, aggregateInvestboxesApi } from '../../../effector/aggregateInvestbox';
import { modalApi } from '../../../effector/modals';
import { User$ } from '../../../effector/user';
import { UserRoles } from '../../../types/userRoles';
import { boolToString } from '../../../utils';
import { checkPermissions, UserAction } from '../../../utils/permissions';
import { MODALS } from '../../../utils/types';
import { PAGE_ADMINISTRATION, PAGE_ADMINISTRATION_INVESTBOXES } from '../../Routes';

export const Investboxes: React.FC = () => {
  const navigate = useNavigate();
  const user = useStore(User$);
  const aggregateInvestboxesStore = useStore(aggregateInvestboxes);

  const onEdit = useCallback(
    (val: string) => {
      const path = `${PAGE_ADMINISTRATION}/${PAGE_ADMINISTRATION_INVESTBOXES}/${val}`;
      navigate(path);
    },
    [navigate],
  );

  const loadInvestboxes = (data: PageParams) => {
    aggregateInvestboxesApi.get(data);
  };

  const onAddInvestbox = () => {
    modalApi.show({
      modalId: MODALS.ADD_INVESTBOX,
    });
  };

  const isDeveloper = user?.role && user.role === UserRoles.Developer;

  const columns = useMemo(() => {
    if (user) {
      const items = [
        {
          Header: 'Название',
          accessor: 'title',
          className: 'table__column-left',
          action: UserAction.administrationInvestbox,
        },
        {
          Header: 'Создана',
          accessor: 'creationDate',
          className: 'table__column-right',
          action: UserAction.administrationInvestbox,
          Cell: ({
            cell: {
              row: {
                // @ts-ignore
                original,
              },
            },
          }) => {
            const timeFormated = original?.creationDate ? moment(original.creationDate).format('DD.MM.YYYY HH:mm') : '';

            return <span>{timeFormated}</span>;
          },
        },
        {
          Header: 'Тариф',
          accessor: 'tariffName',
          className: 'table__column-left',
          action: UserAction.administrationInvestbox,
        },
        {
          Header: 'Валюта',
          accessor: 'currency',
          className: 'table__column-left',
          action: UserAction.administrationInvestbox,
        },
        {
          Header: 'Открыта',
          accessor: 'open',
          className: 'table__column-right',
          action: UserAction.administrationInvestbox,
          // @ts-ignore
          Cell: ({ cell }) => <span>{boolToString(cell?.row?.original?.isOpen)}</span>,
        },
        {
          Header: '',
          accessor: 'edit',
          className: 'table__column-right table__column-action',
          action: UserAction.administrationInvestboxAction,
          Cell: ({
            cell: {
              row: {
                // @ts-ignore
                original,
              },
            },
          }) => {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faEdit}
                  title="Редактировать"
                  className={cs({ 'fa-disabled': isDeveloper && !original.testMode })}
                  onClick={() => onEdit(original.id)}
                />
              </span>
            );
          },
        },
        {
          Header: '',
          accessor: 'close',
          className: 'table__column-right table__column-action',
          action: UserAction.administrationInvestboxAction,
          Cell: ({
            cell: {
              row: {
                // @ts-ignore
                original,
              },
            },
          }) => {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  title="Открыть/закрыть"
                  className={cs({
                    fall: original.testMode || !isDeveloper,
                    'fa-disabled': isDeveloper && !original.testMode,
                  })}
                  onClick={() =>
                    modalApi.show({
                      modalId: MODALS.OPEN_STRATEGY,
                      data: {
                        id: original.id,
                        open: original.isOpen,
                        isInvestbox: true,
                      },
                    })
                  }
                />
              </span>
            );
          },
        },
        {
          Header: '',
          accessor: 'remove',
          className: 'table__column-right table__column-action',
          action: UserAction.administrationInvestboxAction,
          Cell: ({
            cell: {
              row: {
                // @ts-ignore
                original,
              },
            },
          }) => {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faRecycle}
                  title="Удалить/активировать"
                  className={cs({ 'fa-disabled': isDeveloper && !original.testMode })}
                  onClick={() =>
                    modalApi.show({
                      modalId: MODALS.OPEN_STRATEGY,
                      data: {
                        id: original.id,
                        active: original.active,
                        isInvestbox: true,
                      },
                    })
                  }
                />
              </span>
            );
          },
        },
      ];

      return items.filter((item) => checkPermissions(user.role, item.action));
    }

    return [];
  }, []);

  return (
    <>
      <ProtectedComponent userAction={UserAction.administrationDeveloper}>
        <div className="form__group">
          <br />
          <p>Для данного пользователя доступно редактирование только тестовых копилок.</p>
          <br />
        </div>
      </ProtectedComponent>

      <div className="form__group">
        <ProtectedComponent userAction={UserAction.administrationInvestboxAction}>
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onAddInvestbox}
          >
            <FontAwesomeIcon icon={faPlus} /> Добавить
          </Button>
        </ProtectedComponent>
      </div>

      <TableGrid
        columns={columns}
        data={aggregateInvestboxesStore.investboxes}
        sortField={'name'}
        pageSize={25}
        totalRecords={aggregateInvestboxesStore.total}
        onPageChange={loadInvestboxes}
      />
    </>
  );
};
