/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState } from 'react';

import { getClientSignal } from '../../../../api';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import { TableGrid } from '../../../../components/TableGrid';
import { ClientSignal } from '../../../../types/clientSignal';
import { ExecutionPortfolioPosition } from '../../../../types/executionPortfolio';
import { ExecutionPosition } from '../../../../types/executionPosition';

export const SignalTable: React.FC<{ agreementId: string }> = ({ agreementId }) => {
  function parseJson<T>(value: string, name: string): T[] {
    try {
      const obj = JSON.parse(value);
      const data = obj[name];

      return data && Array.isArray(data) ? (data as T[]) : [];
    } catch (e) {
      // @ts-ignore
      console.error(` *** incorrect signal value message=${e.toString()}`);
      return [];
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        className: 'table__column-details',
      },
      {
        Header: 'SignalId',
        accessor: 'signalId',
        className: 'table__column-details',
      },
      {
        Header: 'Дата и время',
        accessor: 'dateTime',
        className: 'table__column-details',
      },
      {
        Header: 'type',
        accessor: 'type',
        className: 'table__column-details',
      },
      {
        Header: 'Processed',
        accessor: 'processed',
        className: 'table__column-details',
      },
      {
        Header: 'RealizedPnL',
        accessor: 'realizedPnL',
        className: 'table__column-details',
      },
      {
        Header: 'rPL Funds',
        accessor: 'rPLFunds',
        className: 'table__column-details',
      },
      {
        Header: 'Message',
        accessor: 'message',
        className: 'table__column-details',
      },
      {
        Header: 'Execution',
        accessor: 'execution',
        className: 'table__column-details',
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original,
            },
          },
        }) => {
          if (!original?.execution || typeof original.execution !== 'string') return '';

          const positions = parseJson<ExecutionPosition>(original.execution, 'Positions');

          const data = positions.slice(0, 3).map((p, i) => {
            return <div key={i}>{`${p.Symbol} ${p.ClassCode} ${p.ExecNumber} х ${p.ExecPrice}`}</div>;
          });

          return (
            <>
              {positions.length > 0 && data}
              {positions.length > 2 && <div>...</div>}
              <CopyToClipboard className="button__primary" text={original.execution} />
            </>
          );
        },
      },
      {
        Header: 'Portfolio',
        accessor: 'portfolio',
        className: 'table__column-details',
        Cell: ({
          cell: {
            row: {
              //@ts-ignore
              original,
            },
          },
        }) => {
          if (!original.portfolio || typeof original.portfolio !== 'string') return '';

          const portfolio = parseJson<ExecutionPortfolioPosition>(original.portfolio, 'Positions');

          const data = portfolio.slice(0, 3).map((p, i) => {
            const isMoney = !p.SecurityKey || p.SecurityKey.length === 0;

            return (
              <div key={i}>
                {isMoney
                  ? `Деньги ${p.Number} ${p.Currency}`
                  : `${p.SecurityKey} ${p.AvgPrice} x ${p.Number} ${p.Currency}`}
              </div>
            );
          });

          return (
            <>
              {portfolio.length > 0 && data}
              {portfolio.length > 2 && <div>...</div>}
              <CopyToClipboard className="button__primary" text={original.portfolio} />
            </>
          );
        },
      },
      {
        Header: 'Archived',
        accessor: 'archived',
        className: 'table__column-details',
      },
    ];
  }, []);

  const [signals, setSignals] = useState<ClientSignal[]>([]);

  useEffect(() => {
    getClientSignal(agreementId)
      .then((c) => {
        if (c.success) setSignals(c.result ?? []);
      })
      .catch((e) => {
        console.error(` *** getClientSignal message=${e.toString()}`);
      });
  }, [agreementId]);

  return (
    <div className="form__group">
      <TableGrid
        columns={columns}
        data={signals}
        sortField="datetime"
        forceReload={false}
        isRecursiveFetch={false}
        pageSize={100000}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onPageChange={() => {}}
      />
    </div>
  );
};
