import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Droppable } from './Droppable';
import { Button, ButtonType } from '../../../../components/UIKit/Button';
import { Faq$, faqApi } from '../../../../effector/faq';
import { Question } from '../../../../types/faq';
import { getMessageFromFailedRequest } from '../../../../utils';

export enum ItemTypes {
  ITEM = 'ITEM',
}

export const Mapping: React.FC = ({}) => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const { categories, questions, categoryQuestions } = useStore(Faq$);

  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState<{ category: Question[]; questions: Question[] }>({
    category: [],
    questions: [],
  });

  const handleCategoryClick = useCallback((category: string) => {
    setSelectedCategory(category);
  }, []);

  const moveItem = useCallback(
    (
      dragColumnIndex: 'category' | 'questions',
      dragIndex: number,
      hoverColumnIndex: 'category' | 'questions',
      hoverIndex: number,
    ) => {
      const sourceColumn = columns[dragColumnIndex];
      const destinationColumn = columns[hoverColumnIndex];

      const newSourceItems = Array.from(sourceColumn);
      const newDestinationItems = Array.from(destinationColumn);

      newSourceItems.splice(dragIndex, 1);

      const isDifferentColumn = dragColumnIndex !== hoverColumnIndex;

      if (isDifferentColumn) {
        newDestinationItems.splice(hoverIndex, 0, sourceColumn[dragIndex]);
      } else {
        [newDestinationItems[hoverIndex], newDestinationItems[dragIndex]] = [
          newDestinationItems[dragIndex],
          newDestinationItems[hoverIndex],
        ];
      }

      setColumns((state) => ({
        ...state,
        [dragColumnIndex]: newSourceItems,
        [hoverColumnIndex]: newDestinationItems,
      }));
    },
    [columns],
  );

  const handleError = useCallback((e: Error) => {
    const message = getMessageFromFailedRequest(e);
    setError(message);
  }, []);

  const handleSubmit = useCallback(() => {
    if (selectedCategory) {
      setLoading(true);
      setError('');
      const questionIds = columns.category.map(({ id }) => id);
      faqApi
        .editCategoryQuestions({ categoryId: selectedCategory, questionIds })
        .then(() => {
          enqueueSnackbar('Связи обновлены');
        })
        .catch(handleError)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedCategory, columns, handleError, enqueueSnackbar]);

  useEffect(() => {
    faqApi.getCategories('');
    faqApi.getQuestions('');
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      faqApi.getCategoryQuestions(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (categories?.length && !selectedCategory) {
      setSelectedCategory(categories[0].id);
    }
  }, [categories, selectedCategory]);

  useEffect(() => {
    const categoryQuestionIds = categoryQuestions?.map(({ id }) => id);

    setColumns({
      category: categoryQuestions,
      questions: questions.filter(({ id }) => !categoryQuestionIds.includes(id)),
    });
  }, [questions, categoryQuestions]);

  return (
    <>
      <div className="faq-mapping">
        <div className="faq-mapping__categories">
          <div className="faq-mapping__title">Список категорий</div>
          {categories.map(({ name, id }) => (
            <div
              key={name}
              className={classNames('faq-mapping__categories__item', {
                'faq-mapping__categories__item-active': selectedCategory === id,
              })}
            >
              <div
                className={classNames('faq-mapping__categories__item-top', {
                  'faq-mapping__categories__item-top-active': selectedCategory === id,
                })}
                onClick={() => handleCategoryClick(id)}
              >
                <div className="faq-mapping__categories__item-name">{name}</div>
              </div>
            </div>
          ))}
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className="faq-mapping__mapping">
            <Droppable title="Вопросы в категории" items={columns.category} columnId="category" moveItem={moveItem} />
          </div>
          <div className="faq-mapping__questions">
            <Droppable title="Список вопросов" items={columns.questions} columnId="questions" moveItem={moveItem} />
          </div>
        </DndProvider>
      </div>
      <div className="faq-mapping-button">
        <div>
          <Button
            className="button__primary button__large"
            disabled={loading}
            type={ButtonType.button}
            onClick={handleSubmit}
          >
            Сохранить
          </Button>
        </div>
      </div>
      {error && <p className="error-message">{error}</p>}
    </>
  );
};
