/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getChart, getSandboxChart } from '../api';
import { Chart as ChartParams } from '../api/types/chart';
import { Chart } from '../types/chart';

type TChart$State = Chart;

const initialState: TChart$State = {
  history: [],
  indexName: '',
};

export const Chart$ = createStore<TChart$State>(initialState);

const { set } = createApi(Chart$, {
  // @ts-ignore
  set: (state, { result }) => {
    const newState = {
      indexName: result?.result?.indexName || initialState.indexName,
      history: result?.result?.history || initialState.history,
    };

    return newState;
  },
});

export const chartApi = {
  get: createEffect<ChartParams, {}, {}>().use(getChart),
  getSandbox: createEffect<ChartParams, {}, {}>().use(getSandboxChart),
};

forward({
  from: chartApi.get.done,
  to: set,
});

forward({
  from: chartApi.getSandbox.done,
  to: set,
});
