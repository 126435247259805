/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect, KeyboardEvent } from 'react';

import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useField, FieldInputProps, FieldArrayRenderProps } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

import { InfoPart } from '../../../types/aggregateStrategy';

export type EditListProps = {
  placeholder: string;
  disabled: boolean;
};

export const EditList: React.FC<EditListProps> = ({ placeholder }) => {
  const handleClick = (): void => {
    console.log(' ** clicked !');
  };
  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    console.log(` ** key pressed key=${e.key} code=${e.charCode}`);
  };

  return (
    <>
      <div className="form-control__placeholder-custom">{placeholder}</div>
      <div className="form-control__textarea">
        <div contentEditable={true} onClick={handleClick} onKeyPress={handleKey}></div>
      </div>
    </>
  );
};

type HeaderProps = {
  field: FieldInputProps<any>;
  className?: string;
  type: string;
  shouldFocus: boolean;
  disabled?: boolean;
};

const HeaderInput: React.FC<HeaderProps> = ({ field: fieldProp, type, shouldFocus, disabled }) => {
  const [field] = useField(fieldProp.name);

  const onBlur = (e: React.SyntheticEvent) => {
    field.onBlur(e);
  };

  const fieldRef = useRef<HTMLInputElement | null>();

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e && e.key === 'Enter') e.preventDefault();
  };

  useEffect(() => {
    if (shouldFocus && fieldRef.current) fieldRef.current.focus();
  }, []);

  return (
    <label className="form-control">
      <input
        ref={(x) => {
          fieldRef.current = x;
        }}
        className="form-control__title"
        type={type}
        required
        disabled={disabled}
        {...field}
        onKeyPress={handleEnter}
        onBlur={onBlur}
      />
    </label>
  );
};

type TextProps = {
  field: FieldInputProps<any>;
  rows: number;
  className?: string;
  disabled?: boolean;
};

const TextInput: React.FC<TextProps> = ({ field: fieldProp, rows, disabled }) => {
  const [field] = useField(fieldProp.name);

  const onBlur = (e: React.SyntheticEvent) => {
    field.onBlur(e);
  };

  return (
    <label className="form-control">
      <TextareaAutosize
        className="form-control__textarea-inline"
        rows={rows}
        maxRows={6}
        // @ts-ignore
        onBlur={onBlur}
        disabled={disabled}
        {...field}
      />
    </label>
  );
};

export const EditListArray: React.FC<void | FieldArrayRenderProps> = (propsany) => {
  const props = propsany as FieldArrayRenderProps;
  const name = props.name;
  const parts = props.form.values[name] as InfoPart[];

  const [focus, setFocus] = useState(false);

  const handleAdd = () => {
    props.push({ title: '', value: '' });
    setFocus(true);
  };

  const lastIndex = parts.length > 0 ? parts.length - 1 : 0;

  return (
    <>
      <div className="form-control__textarea">
        <div>
          {parts && parts.length > 0
            ? parts.map((p, i) => (
                <div key={i}>
                  <Field
                    name={`${name}[${i}].title`}
                    type="text"
                    shouldFocus={focus && i === lastIndex}
                    component={HeaderInput}
                  />
                  <Field name={`${name}[${i}].value`} rows={3} component={TextInput} />
                  <FontAwesomeIcon
                    icon={faRecycle}
                    onClick={() => {
                      props.remove(i);
                    }}
                  />
                </div>
              ))
            : ''}
        </div>
      </div>

      <button type="button" className="button__small" onClick={handleAdd}>
        Добавить
      </button>
    </>
  );
};
