import * as React from 'react';

import { useStore } from 'effector-react';
import { redirect } from 'react-router-dom';

import { ChangePasswordForm } from '../../components/Forms/ChangePassword';
import { Modal } from '../../components/Modal';
import { User$ } from '../../effector/user';
import { PAGE_STRATEGIES } from '../Routes';

export const ChangePassword: React.FC = () => {
  const user = useStore(User$);

  if (user) {
    redirect(PAGE_STRATEGIES);
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        console.log('close');
      }}
      title="Смена пароля"
    >
      <ChangePasswordForm />
    </Modal>
  );
};
