/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getClientsStatistic } from '../api';
import { PageParams } from '../components/TableGrid';
import { AccountStat } from '../types/accountStat';

type TClients$State = {
  totalClients: number;
  clients: AccountStat[];
};

const initialState: TClients$State = {
  totalClients: 0,
  clients: [],
};

export const Clients$ = createStore<TClients$State>(initialState);

const { set } = createApi(Clients$, {
  // @ts-ignore
  set: (state, { result }) => {
    const data = {
      // @ts-ignore
      totalClients: result.result.total,
      // @ts-ignore
      clients: result.result.pageStats,
    };

    return data;
  },
});

export const clientsApi = {
  get: createEffect<PageParams, {}, {}>().use(getClientsStatistic),
};

forward({
  from: clientsApi.get.done,
  to: set,
});
