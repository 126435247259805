/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { strategyDictionaryApi } from './strategyDictionary';
import { getStrategies, sandboxModerate, sandboxRequestModerate, toSandbox } from '../api';
import { RequestModerate } from '../types/requestModerate';
import { Strategy } from '../types/strategy';

type TStrategies$State = Strategy[];

export const Strategies$ = createStore<TStrategies$State>([]);

const { set, update, remove } = createApi(Strategies$, {
  // @ts-ignore
  set: (state, { result }) => {
    return result.result;
  },
  // @ts-ignore
  update: (state, { result }) => {
    if (result.success) {
      // @ts-ignore
      const strategy = result.result;
      // @ts-ignore
      const strategies = state.filter((item) => item.id !== strategy.id);

      return [...strategies, strategy];
    }

    return state;
  },
  // @ts-ignore
  remove: (state, strategy) => {
    // @ts-ignore
    const id: string = strategy?.id;

    if (id !== undefined) {
      // @ts-ignore
      const strategies = state.filter((s) => s.id !== id);
      return [...strategies];
    }

    return state;
  },
});

Strategies$.watch((state) => {
  const strategies = state.map((item) => ({
    id: item.id,
    name: item.name,
  }));
  strategyDictionaryApi.add(strategies);
});

export const strategiesApi = {
  get: createEffect().use(getStrategies),
  requestModerate: createEffect<RequestModerate, {}, {}>().use(sandboxRequestModerate),
  moderate: createEffect<RequestModerate, {}, {}>().use(sandboxModerate),
  toSandbox: createEffect<{ strategyId: string }, {}, {}>().use(toSandbox),
  remove: createEffect<{ id: string }, {}, {}>(),
};

forward({
  from: strategiesApi.get.done,
  to: set,
});

forward({
  from: strategiesApi.requestModerate.done,
  to: update,
});

forward({
  from: strategiesApi.moderate.done,
  to: update,
});

forward({
  from: strategiesApi.toSandbox.done,
  to: update,
});

forward({
  from: strategiesApi.remove,
  to: remove,
});
