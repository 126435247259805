/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState, useRef } from 'react';

import cs from 'classnames';
import { useField, FieldInputProps } from 'formik';

import { useFocusOnError } from '../../../hooks/useFocusOnError';

type Props = {
  field: FieldInputProps<any>;
  className?: string;
  type: string;
  placeholder: string;
  disabled?: boolean;
};

export const Input: React.FC<Props> = ({ field: fieldProp, className, type, placeholder, disabled }) => {
  const [focus, setFocus] = useState(false);
  const [field, meta] = useField(fieldProp.name);

  const hasValue = useMemo(() => {
    return !!field.value;
  }, [field.value]);

  const isError = meta.touched && meta.error;
  const isDirty = meta.value !== meta.initialValue;

  const onFocus = () => setFocus(true);
  const onBlur = (e: React.SyntheticEvent) => {
    field.onBlur(e);
    setFocus(false);
  };

  const fieldRef = useRef<HTMLInputElement | null>();
  const name: string = fieldProp.name;

  useFocusOnError({ fieldRef, name });

  return (
    <label
      className={cs('form-control', className, {
        'form-control-focus': focus || isDirty || hasValue,
      })}
    >
      <input
        ref={(xn) => (fieldRef.current = xn)}
        className={cs('form-control__field', {
          'form-control__field-invalid': isError,
          'form-control__field-disabled': disabled,
        })}
        type={type}
        placeholder={placeholder}
        required
        disabled={disabled}
        {...field}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {isError && <span className="form-control__message">{meta.error}</span>}
      <span className="form-control__placeholder">{placeholder}</span>
    </label>
  );
};
