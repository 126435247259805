import React, { useMemo } from 'react';

import { useStore } from 'effector-react';

import { TableGrid } from '../../../../components/TableGrid';
import { ClientDetails$ } from '../../../../effector/clientDetails';

export const ProfitTable: React.FC = () => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Номер',
        accessor: 'id',
        className: 'table__column-details',
      },
      {
        Header: 'Дата',
        accessor: 't',
        className: 'table__column-details',
      },
      {
        Header: 'Инструмент',
        accessor: 'security',
        className: 'table__column-details',
      },
      {
        Header: 'weight',
        accessor: 'weight',
        className: 'table__column-details',
      },
      //{
      //  Header: 'clweight',
      //  accessor: 'id',
      //  className: 'table__column-details'
      //},
      {
        Header: 'Цена',
        accessor: 'price',
        className: 'table__column-details',
      },
      {
        Header: 'Цена исполнения',
        accessor: 'execPrice',
        className: 'table__column-details',
      },
      {
        Header: 'pricediff',
        accessor: 'priceDiff',
        className: 'table__column-details',
      },
      {
        Header: 'timediff',
        accessor: 'timeDiff',
        className: 'table__column-details',
      },
      {
        Header: 'stratpl',
        accessor: 'stratPL',
        className: 'table__column-details',
      },
      {
        Header: 'pl',
        accessor: 'pL',
        className: 'table__column-details',
      },
      {
        Header: 'summStratPL',
        accessor: 'v',
        className: 'table__column-details',
      },
      {
        Header: 'summClientPL',
        accessor: 'vr',
        className: 'table__column-details',
      },
    ];
  }, []);

  const { chart } = useStore(ClientDetails$);

  return (
    <div className="form__group">
      <TableGrid
        columns={columns}
        data={chart}
        sortField="datetime"
        forceReload={false}
        isRecursiveFetch={false}
        pageSize={100000}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onPageChange={() => {}}
      />
    </div>
  );
};
