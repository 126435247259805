/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getMoneyStatistic } from '../api';
import { PageParams } from '../components/TableGrid';

export type ProductStat = {
  productType: string;
  name: string;
  author: string;
  clientCount: number;
  value: number;
  valueSUR: number;
  averageVolume: number;
  averageVolumeSUR: number;
  medianVolume: number;
  medianVolumeSUR: number;
  subscriptionThreshold: number;
  subscriptionThresholdSUR: number;
  demand: string;
  currency: string;
  isBlogger: boolean;
  open: boolean;
  isRestricted: boolean;
};

type TMoney$State = {
  total: number;
  pageStats: ProductStat[];
  totalClients: string;
  totalSUR: string;
  totalUSD: string;
};

const initialState: TMoney$State = {
  total: 0,
  pageStats: [],
  totalClients: '',
  totalSUR: '',
  totalUSD: '',
};

export const Money$ = createStore<TMoney$State>(initialState);

const { set } = createApi(Money$, {
  // @ts-ignore
  set: (state, { result }) => {
    return result.result;
  },
});

export const moneyApi = {
  get: createEffect<PageParams, {}, {}>().use(getMoneyStatistic),
};

forward({
  from: moneyApi.get.done,
  to: set,
});
