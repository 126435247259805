/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { addStrategy, updateStrategy, getFullListStrategiesAdministration } from '../api';
import { AggregateStrategy } from '../types/aggregateStrategy';

type TAggregateStrategies$State = {
  strategies: AggregateStrategy[];
};

const initialState: TAggregateStrategies$State = {
  strategies: [],
};

export const AggregateStrategies$ = createStore<TAggregateStrategies$State>(initialState);

const { set, remove } = createApi(AggregateStrategies$, {
  // @ts-ignore
  set: (state, { result }) => {
    const data = {
      // @ts-ignore
      strategies: result.result,
    };

    return data;
  },
  // @ts-ignore
  remove: (state, strategy) => {
    const id: string = strategy?.id;
    if (id !== undefined) {
      // @ts-ignore
      const result = state.strategies.filter((s) => s.id !== id);
      if (result.length !== state.strategies.length) {
        const data = {
          strategies: result,
        };

        return data;
      }
    }

    return state;
  },
});

export const aggregateStrategiesApi = {
  get: createEffect<'', {}, {}>().use(getFullListStrategiesAdministration),
  add: createEffect<Partial<AggregateStrategy>, {}, {}>().use(addStrategy),
  update: createEffect<Partial<AggregateStrategy>, {}, {}>().use(updateStrategy),
  remove: createEffect<{ id: string }, {}, {}>(),
};

forward({
  from: aggregateStrategiesApi.get.done,
  to: set,
});

forward({
  from: aggregateStrategiesApi.remove,
  to: remove,
});
