import React, { useState } from 'react';

import { Delete } from '@mui/icons-material';
import { IconButton, Box } from '@mui/material';

interface ImagePreviewProps {
  image?: string;
  onDelete?: () => void;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({ image, onDelete }) => {
  const [hovered, setHovered] = useState<boolean>(false);

  if (!image) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box position="relative" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <img
          src={image}
          alt=""
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        />
        {hovered && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0, 0, 0, 0.4)"
            borderRadius="8px"
          >
            <IconButton onClick={onDelete} style={{ color: 'white' }}>
              <Delete />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
