/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getPortfolio, getSandboxPortfolio } from '../api';
import { StrategyPortfolio } from '../types/strategyPortfolio';
import { Nullable } from '../utils/types';

type TPortfolio$State = Nullable<StrategyPortfolio>;

const initialState: TPortfolio$State = null;

export const Portfolio$ = createStore<TPortfolio$State>(initialState);

const { set } = createApi(Portfolio$, {
  // @ts-ignore
  set: (state, { result }) => result.result,
});

export const portfolioApi = {
  get: createEffect<string, {}, {}>().use(getPortfolio),
  getSandbox: createEffect<string, {}, {}>().use(getSandboxPortfolio),
};

forward({
  from: portfolioApi.get.done,
  to: set,
});

forward({
  from: portfolioApi.getSandbox.done,
  to: set,
});
