/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getAuthorDictionary } from '../api';
import { Option } from '../types/option';
import { Nullable } from '../utils/types';

type TAuthorDictionary$State = Nullable<Option[]>;

export const AuthorDictionary$ = createStore<TAuthorDictionary$State>(null);

const { set } = createApi(AuthorDictionary$, {
  // @ts-ignore
  set: (state, { result }) => result.result,
});

export const authorDictionaryApi = {
  get: createEffect().use(getAuthorDictionary),
};

forward({
  from: authorDictionaryApi.get.done,
  to: set,
});
