/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';

import { bannersApi } from '../../effector/banners';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { Banner } from '../../types/banner';
import { Button, ButtonType } from '../UIKit/Button';

export const RemoveBanner: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const modalsState = useStore<TModal$State<{ banner: Banner }>>(Modals$);

  const banner = modalsState.data?.banner;

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      // @ts-ignore
      modalApi.hide('');
    }
  };

  const removeBanner = () => {
    const data = {
      ...banner,
      active: !banner?.active,
    };

    setLoading(true);
    bannersApi
      .add(data)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const status = banner?.active ? 'Неактивный' : 'Активный';

  return (
    <div className="remove-delayed-signal">
      <div className="remove-delayed-signal__description">
        Вы уверены что хотите сменить статус баннера на {status}?
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="remove-delayed-signal__button-wrapper">
        <Button
          className="button__primary button__large"
          onClick={removeBanner}
          disabled={loading}
          type={ButtonType.button}
        >
          Изменить
        </Button>
      </div>
    </div>
  );
};
