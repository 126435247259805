/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { useStore } from 'effector-react';
import { FieldInputProps, useField } from 'formik';
import SelectField from 'react-select';

import { Button } from '../../../components/UIKit/Button';
import { codes$ } from '../../../effector/classCodes';
import { Option } from '../../../types/option';
import { Nullable } from '../../../utils/types';

export type CodeWeight = {
  classCode: string;
  value: string;
};

export type WeightProps = {
  field: FieldInputProps<any>;
  disabled: boolean;
};

export const MaxWeightsControl: React.FC<WeightProps> = ({ field: fieldProp, ...props }) => {
  const [field, , helpers] = useField(fieldProp.name);
  const [code, setCode] = useState<Nullable<string>>(null);
  const [weight, setWeight] = useState<number>(0);
  const [focus, setFocus] = useState(false);
  const fieldRef = useRef<HTMLElement | null>();
  const classCodesStore = useStore(codes$);

  const hasValues = !!code && code.length > 0 && weight > 0;

  const codes: Option[] = classCodesStore.map((c) => {
    return { id: c, name: c };
  });

  const addItem = (code: string, weight: number): void => {
    if (!code) return;
    if (code.length === 0) return;
    if (weight === 0) return;

    const weights = field.value as CodeWeight[];
    helpers.setValue(
      [...weights.filter((c) => c.classCode !== code), { classCode: code, value: weight }].sort((c1, c2) =>
        c1.classCode.localeCompare(c2.classCode),
      ),
    );
    setWeight(0);
  };

  const removeItem = (code: string): void => {
    if (!code) return;

    const weights = field.value as CodeWeight[];
    helpers.setValue(weights.filter((c) => c.classCode !== code));
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) {
      if (hasValues) {
        addItem(code ?? '', weight);
        fieldRef.current?.focus();
      }
      e.preventDefault();
    } else if (e.charCode === 27) {
      removeItem(code ?? '');
      e.preventDefault();
    }
  };

  const handleTouched = () => {
    helpers.setTouched(true);
    setFocus(false);
  };

  return (
    <>
      <div className="administration__tabs">
        <div className="administration__tab administration__tab-first">
          <SelectField
            ref={(x) => {
              if (x) {
                fieldRef.current = x?.inputRef;
              }
            }}
            className={'select__control'}
            classNamePrefix="react-select"
            isDisabled={false}
            placeholder="тип инструмента"
            options={codes}
            isMulti={false}
            isClearable={true}
            onChange={(c) => setCode(c?.id)}
            onBlur={() => helpers.setTouched(true)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
          />
        </div>
        <div className="administration__tab">
          <label className={cs('form-control', { 'form-control-focus': focus || weight > 0 })}>
            <input
              className={cs('form-control__field', {
                'form-control__field-disabled': false,
              })}
              type="number"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeight(Number.parseFloat(e.target.value))}
              disabled={props.disabled}
              onFocus={() => setFocus(true)}
              onBlur={() => handleTouched()}
              onKeyPress={handleEnter}
              value={weight}
            />
            <span className="form-control__placeholder">Доля, %</span>
          </label>
        </div>
      </div>
      <div className="administration__tab">
        <Button
          disabled={!hasValues}
          className="button__large button__primary"
          onClick={() => addItem(code ?? '', weight)}
        >
          Добавить
        </Button>
      </div>
      {!props.disabled && (
        <div className="form__group administration__data">
          <ul className="administration">
            {(field.value as CodeWeight[]).map((c) => (
              <li key={c.classCode}>
                <span>{c.classCode}</span>
                <span>{c.value}%</span>
                <span className="pointer form-schedule__delete">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => {
                      removeItem(c.classCode);
                    }}
                  />
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
