import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-tippy/dist/tippy.css';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/index.scss';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
