import React from 'react';

import { useStore } from 'effector-react';
import { RouteProps, Navigate } from 'react-router-dom';

import { User$, TUser$State } from '../../effector/user';
import { ProtectedProps } from '../../hoc/withProtection';
import { checkPermissions, UserAction } from '../../utils/permissions';
import { PAGE_ANALYTICS, PAGE_LOGIN } from '../Routes';

type Props = RouteProps & {
  component: React.FC<ProtectedProps>;
  userAction: UserAction;
};

export const PrivateRoute: React.FC<Props> = ({ component: Component, userAction }) => {
  const user = useStore<TUser$State>(User$);

  if (user?.role && checkPermissions(user.role, userAction)) {
    return <Component role={user.role} action={userAction} />;
  }

  if (user?.role) {
    return <Navigate to={PAGE_ANALYTICS} />;
  }

  return <Navigate to={PAGE_LOGIN} />;
};
