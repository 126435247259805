import React from 'react';

import { SnackbarProvider } from 'notistack';
import { Route, Routes as RoutesComponent } from 'react-router';
import { Navigate } from 'react-router-dom';

import { Administration } from './Administration';
import { Analytics } from './Analytics';
import { Audit } from './Audit';
import { Banners } from './Banners';
import { ChangePassword } from './ChangePassword';
import { CheckLoginSms } from './CheckLoginSms';
import { Instruction } from './Instruction';
import { Login } from './Login';
import { PrivateRoute } from './PrivateRoute';
import { Schedule } from './Schedule';
import { Statistic } from './Statistic';
import { Strategies } from './Strategies';
import { StrategiesParams } from './StrategiesParams';
import { Strategy } from './Strategy';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { withProtection } from '../hoc/withProtection';
import { UserAction } from '../utils/permissions';

export const PAGE_STRATEGIES = '/strategies';

export const PAGE_ANALYTICS = '/analytics';

export const PAGE_SCHEDULE = '/schedule';
export const PAGE_INSTRUCTION = '/instruction';
export const PAGE_STRATEGIES_PARAMS = '/strategies_params';
export const PAGE_LOGIN = '/login';
export const PAGE_CHECK_LOGIN_SMS = '/checkLoginSms';
export const PAGE_CHANGE_PASSWORD = '/change_password';

export const PAGE_STRATEGY = '/strategies/:id';
export const PAGE_STRATEGY_POSITIONS = 'positions';
export const PAGE_STRATEGY_DELAYED = 'delayed';
export const PAGE_STRATEGY_HISTORY = 'history';
export const PAGE_STRATEGY_PROFIT = 'profit';
export const PAGE_STRATEGY_TRACK = 'track';

export const PAGE_STATISTIC = '/statistic';
export const PAGE_STATISTIC_MONEY = 'money';
export const PAGE_STATISTIC_CLIENTS = 'clients';
export const PAGE_STATISTIC_CLIENT_INFO = 'client_info';
export const PAGE_STATISTIC_ERRORS = 'errors';
export const PAGE_STATISTIC_PROBLEMS = 'problems';
export const PAGE_STATISTIC_CLIENT_DETAILS = 'client_details';
export const PAGE_STATISTIC_ERRORS_ALL = 'all';
export const PAGE_STATISTIC_ERRORS_STRATEGY = 'strategies';

export const PAGE_STATISTIC_CLIENT_DETAILS_INFO = '/statistic/client_details/info';
export const PAGE_STATISTIC_CLIENT_DETAILS_PROFIT_CHART = '/statistic/client_details/profit_chart';
export const PAGE_STATISTIC_CLIENT_DETAILS_PROFIT_TABLE = '/statistic/client_details/profit_table';
export const PAGE_STATISTIC_CLIENT_DETAILS_SIGNALS = '/statistic/client_details/signals';

export const PAGE_AUDIT = '/audit';
export const PAGE_AUDIT_IIR = 'iirs';
export const PAGE_AUDIT_ORDER = 'orders';

export const PAGE_ADMINISTRATION = '/administration';
export const PAGE_ADMINISTRATION_CLIENTS = 'clients';
export const PAGE_ADMINISTRATION_USERS = 'users';
export const PAGE_ADMINISTRATION_STRATEGIES = 'strategies';
export const PAGE_ADMINISTRATION_INVESTBOXES = 'investboxes';
export const PAGE_ADMINISTRATION_SERVICES = 'services';
export const PAGE_ADMINISTRATION_STRATEGY_DETAILS = 'strategy_details';
export const PAGE_ADMINISTRATION_SECURITY_INFO = 'securities';
export const PAGE_ADMINISTRATION_FAQ = 'faq';
export const PAGE_ADMINISTRATION_FAQ_CATEGORIES = 'categories';
export const PAGE_ADMINISTRATION_FAQ_QUESTIONS = 'questions';
export const PAGE_ADMINISTRATION_FAQ_MAPPING = 'mapping';
export const PAGE_ADMINISTRATION_MAILER = 'mailer';
export const PAGE_ADMINISTRATION_MAILER_STRATEGY = 'strategy';
export const PAGE_ADMINISTRATION_MAILER_AUTHOR = 'author';
export const PAGE_ADMINISTRATION_MAILER_AGREEMENT = 'agreement';

export const PAGE_BANNERS = '/banners';

const ProtectedStrategies = withProtection(Strategies);
const ProtectedStrategy = withProtection(Strategy);
const ProtectedStatistic = withProtection(Statistic);
const ProtectedAnalytics = withProtection(Analytics);
const ProtectedSchedule = withProtection(Schedule);
const ProtectedInstruction = withProtection(Instruction);
const ProtectedStrategiesParams = withProtection(StrategiesParams);
const ProtectedAudit = withProtection(Audit);

const ProtectedAdministration = withProtection(Administration);

const ProtectedBanners = withProtection(Banners);

export const Routes: React.FC = () => (
  <ErrorBoundary>
    <SnackbarProvider maxSnack={3}>
      <RoutesComponent>
        <Route
          path={PAGE_STRATEGIES}
          element={<PrivateRoute component={ProtectedStrategies} userAction={UserAction.strategy} />}
        />
        <Route
          path={`${PAGE_STRATEGY}/*`}
          element={<PrivateRoute component={ProtectedStrategy} userAction={UserAction.strategy} />}
        />
        <Route
          path={`${PAGE_STATISTIC}/*`}
          element={<PrivateRoute component={ProtectedStatistic} userAction={UserAction.statistic} />}
        />
        <Route
          path={PAGE_ANALYTICS}
          element={<PrivateRoute component={ProtectedAnalytics} userAction={UserAction.analytic} />}
        />
        <Route
          path={`${PAGE_ADMINISTRATION}/*`}
          element={<PrivateRoute component={ProtectedAdministration} userAction={UserAction.administration} />}
        />
        <Route
          path={PAGE_BANNERS}
          element={<PrivateRoute component={ProtectedBanners} userAction={UserAction.banners} />}
        />
        <Route
          path={PAGE_SCHEDULE}
          element={<PrivateRoute component={ProtectedSchedule} userAction={UserAction.schedule} />}
        />
        <Route
          path={PAGE_INSTRUCTION}
          element={<PrivateRoute component={ProtectedInstruction} userAction={UserAction.instruction} />}
        />
        <Route
          path={PAGE_STRATEGIES_PARAMS}
          element={<PrivateRoute component={ProtectedStrategiesParams} userAction={UserAction.strategyParams} />}
        />
        <Route
          path={`${PAGE_AUDIT}/*`}
          element={<PrivateRoute component={ProtectedAudit} userAction={UserAction.audit} />}
        />
        <Route path={PAGE_LOGIN} element={<Login />} />
        <Route path={PAGE_CHECK_LOGIN_SMS} element={<CheckLoginSms />} />
        <Route path={PAGE_CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path="/" element={<Navigate replace to={PAGE_STRATEGIES} />} />
        <Route path="*" element={<Navigate replace to={PAGE_STRATEGIES} />} />
      </RoutesComponent>
    </SnackbarProvider>
  </ErrorBoundary>
);
