/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import cs from 'classnames';
import { FieldInputProps, useField } from 'formik';

type Props = {
  field: FieldInputProps<any>;
  rows: number;
  placeholder: string;
  className?: string;
  disabled?: boolean;
};

export const TextArea: React.FC<Props> = ({ field: fieldProp, rows, placeholder, className, disabled }) => {
  const [focus, setFocus] = useState(false);
  const [field, meta] = useField(fieldProp.name);

  const isError = meta.touched && meta.error;
  const isDirty = meta.value !== meta.initialValue;

  const onFocus = () => setFocus(true);
  const onBlur = (e: React.SyntheticEvent) => {
    field.onBlur(e);
    setFocus(false);
  };

  return (
    <label
      className={cs('form-control', className, {
        'form-control-focus': focus || isDirty || field.value,
      })}
    >
      <textarea
        className={cs('form-control__field form-control__field-textarea', {
          'form-control__field-invalid': isError,
          'form-control__field-disabled': disabled,
        })}
        placeholder={placeholder}
        rows={rows}
        required
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        {...field}
      />
      {isError && <span className="form-control__message">{meta.error}</span>}
      <span className="form-control__placeholder">{placeholder}</span>
    </label>
  );
};
