/* eslint-disable @typescript-eslint/ban-ts-comment */
import download from 'downloadjs';
import { polyfill } from 'es6-promise';

import 'whatwg-fetch';

polyfill();

const UNAUTHORIZED = 401;
const NO_CONTENT = 204;

type Config = {
  basePath: string;
  agent: (input: RequestInfo, options: RequestInit) => Promise<Response>;
  errorCatcher: (err: Error) => void;
};

type Options = {
  headers: Record<string, string>;
  method: RequestTypes;
  body?: string;
};

enum RequestTypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

let config: Config = {
  basePath: '/api',
  agent: window.fetch.bind(window),
  errorCatcher: (err: Error) => console.log('log error:', err),
};

const createEndpoint = (endpoint: string) => `${config.basePath}${endpoint}`;
const createOptions: (method: RequestTypes, body?: string) => Options = (method, body) => {
  const defaultOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method,
  };

  if (method === RequestTypes.GET) {
    return defaultOptions;
  }

  return {
    ...defaultOptions,
    body,
  };
};

const checkResponseOk = async (response: Response) => {
  if (!response.ok) {
    let message = '';
    try {
      message = await response.json();
    } catch {}

    throw new Error(
      JSON.stringify({
        type: 'api',
        url: response.url,
        status: response.status,
        message,
        statusText: response.statusText,
      }),
    );
  }

  return response;
};

const checkResponseCode = (response: Response) => {
  switch (response.status) {
    case NO_CONTENT:
      return new Response('{}');

    default:
      return response;
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const createRequestByType = (type: RequestTypes) => (endpoint: string, body?: object | string) => {
  const options = createOptions(type, JSON.stringify(body));

  return tryFetch(endpoint, options);
};

export const configure: (cfg: Partial<Config>) => Config = (cfg) => (config = { ...config, ...cfg });

// eslint-disable-next-line @typescript-eslint/ban-types
const request = (url: string, options: object) =>
  config
    .agent(createEndpoint(url), options)
    .then(checkResponseOk)
    .then(checkResponseCode)
    .then(async (res) => {
      if (res.headers.has('content-disposition')) {
        const filename = findFileName(res.headers.get('content-disposition')!.split(';'));
        const blob = await res.blob();

        download(blob, filename);
        return res;
      }

      try {
        return await res.json();
      } catch (errParse) {
        return {};
      }
      // return res.json();
    });

const findFileName = (contentDispositionParts: string[]): string | undefined =>
  decodeFileNameStar(
    contentDispositionParts
      .find((n) => n.trim().startsWith('filename*='))
      ?.trim()
      .substring('filename*='.length),
  ) ||
  contentDispositionParts
    .find((n) => n.trim().startsWith('filename='))
    ?.trim()
    .substring('filename='.length)
    .replace(/^"(.*)"$/, '$1');

const decodeFileNameStar = (fileNameStar: string | undefined): string | undefined => {
  const parts = fileNameStar?.split("'");
  if (parts?.length !== 3 || parts[0].toUpperCase() !== 'UTF-8') {
    return undefined;
  }
  return decodeURIComponent(parts[2]);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const tryFetch = async (url: string, options: object) => {
  try {
    return await request(url, options);
  } catch (err: unknown) {
    // @ts-ignore
    const status = JSON.parse(err.message).status;
    const inTokenError = status === UNAUTHORIZED;

    if (!inTokenError) {
      throw err;
    }
    window?.dispatchEvent(new Event('logout'));
    try {
      return await request(url, options);
    } catch (err) {
      // @ts-ignore
      config.errorCatcher(err);
    }
  }
};

export const get = createRequestByType(RequestTypes.GET);

export const post = createRequestByType(RequestTypes.POST);

export const put = createRequestByType(RequestTypes.PUT);

export const deleteMethod = createRequestByType(RequestTypes.DELETE);
