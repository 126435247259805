/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { getComment, updateComment } from '../../api';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { TextArea } from '../FormControls/TextArea';
import { Button, ButtonType } from '../UIKit/Button';

const validationSchema = Yup.object().shape({
  description: Yup.string(),
});

type FormFields = {
  description: string;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ loading, error }) => (
  <Form className="form" noValidate>
    <div className="form__group">
      <Field name="description" rows={5} placeholder="Комментарий" component={TextArea} />
    </div>

    {error && <p className="error-message">{error}</p>}
    <Button className="button__primary button__large button__wide" disabled={loading} type={ButtonType.submit}>
      Обновить
    </Button>
  </Form>
);

export const CommentForm: React.FC = () => {
  const [error, setError] = useState('');
  const [initialComment, setInitialComment] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const modalsState = useStore<TModal$State<{ strategyId: string; date: string }>>(Modals$);

  const strategyId = modalsState.data?.strategyId;
  const date = modalsState.data?.date;

  // @ts-ignore
  const resultHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      throw new Error(result.errorMessage);
    }
    modalApi.hide('');
  };

  const errorHandler = (error: Error) => {
    setError(error.message);
  };

  const onSubmit = (values: FormFields) => {
    setLoading(true);

    updateComment(strategyId as string, moment(date).format('YYYY-MM-DD'), values.description)
      .then(resultHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const loadComment = useCallback(() => {
    getComment(strategyId as string, moment(date).format('YYYY-MM-DD'))
      .then((response) => {
        if (response.success && response.result.comment) {
          setInitialComment(response.result.comment);
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }, [strategyId]);

  useEffect(() => {
    loadComment();
  }, []);

  const initialValues = useMemo(
    () => ({
      description: initialComment,
    }),
    [initialComment],
  );

  return (
    <div className="strategy-change">
      <div className="strategy-change__description">Обвновление комментария за {moment(date).format('DD.MM.YYYY')}</div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {(props) =>
          React.createElement(FormTemplate, {
            ...props,
            error,
            loading,
          })
        }
      </Formik>
    </div>
  );
};
