/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getSchedule } from '../api';
import { MarketSchedule, Weekend } from '../types/marketSchedule';

export const schedule$ = createStore<MarketSchedule[]>([]);

const helper = createApi(schedule$, {
  // @ts-ignore
  set: (state, { result }) => {
    if (result && result.success) {
      return result.result;
    }
  },
  add: (state: MarketSchedule[], schedule: MarketSchedule) => {
    const times = state.find((s) => s.id === schedule.id);
    if (times) {
      times.classCodes = schedule.classCodes;

      if (schedule.name && schedule.name.length > 0) times.name = schedule.name;
      if (schedule.tradeTimes) times.tradeTimes = schedule.tradeTimes;
      if (schedule.signalTimes) times.signalTimes = schedule.signalTimes;
      if (schedule.rebalanceTimes) times.rebalanceTimes = schedule.rebalanceTimes;

      return [...state];
    } else {
      return [...state, schedule];
    }
  },
  addWeekend: (state: MarketSchedule[], { id, day }: Weekend) => {
    const schedule = state.find((s) => s.id === id);
    if (schedule) {
      const weekends = schedule.weekends ?? [];
      const weekend = weekends.find((d) => d.day === day);

      if (!weekend) {
        schedule.weekends = [...weekends, { id: id, day: day }];

        return [...state];
      }
    }

    return state;
  },
  remove: (state: MarketSchedule[], id: number) => {
    return state.filter((s) => s.id !== id);
  },
  removeWeekend: (state: MarketSchedule[], { id, day }: Weekend) => {
    const schedule = state.find((s) => s.id === id);
    if (schedule) {
      schedule.weekends = schedule.weekends?.filter((d) => d.day !== day);

      return [...state];
    }

    return state;
  },
});

export const scheduleApi = {
  get: createEffect<{}, MarketSchedule[], undefined>().use(getSchedule),
  set: createEffect<MarketSchedule, MarketSchedule, undefined>(),
  remove: createEffect<number, any, undefined>(),
  addWeekend: createEffect<Weekend, MarketSchedule[], undefined>(),
  removeWeekend: createEffect<Weekend, MarketSchedule[], undefined>(),
};

forward({
  from: scheduleApi.get.done,
  to: helper.set,
});

forward({
  from: scheduleApi.set,
  to: helper.add,
});

forward({
  from: scheduleApi.remove,
  to: helper.remove,
});

forward({
  from: scheduleApi.addWeekend,
  to: helper.addWeekend,
});

forward({
  from: scheduleApi.removeWeekend,
  to: helper.removeWeekend,
});
