/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import { faEdit, faExchangeAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';

import { PageParams, TableGrid } from '../../../components/TableGrid';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { AggregateUsers$, aggregateUsersApi } from '../../../effector/aggregateUsers';
import { modalApi } from '../../../effector/modals';
import { roleToText } from '../../../utils';
import { MODALS } from '../../../utils/types';

export const Users: React.FC = () => {
  const aggregateUsersStore = useStore(AggregateUsers$);

  const loadUsers = (data: PageParams) => {
    aggregateUsersApi.get(data);
  };

  const onAddUser = () => {
    modalApi.show({
      modalId: MODALS.ADD_USER,
    });
  };

  const columns = [
    {
      Header: ' Идентификатор',
      accessor: 'login',
      className: 'table__column-left',
    },
    {
      Header: 'Организация',
      accessor: 'company',
      className: 'table__column-left',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { company },
          },
        },
      }) => <span>{company}</span>,
    },
    {
      Header: ' Имя',
      accessor: 'firstName',
      className: 'table__column-left',
      // @ts-ignore
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { firstName, lastName },
          },
        },
      }) => (
        <span>
          {lastName} {firstName}
        </span>
      ),
    },
    {
      Header: 'Роль',
      accessor: 'role',
      className: 'table__column-left',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { role },
          },
        },
      }) => <span>{roleToText(role)}</span>,
    },
    {
      Header: '',
      accessor: 'id',
      className: 'table__column-right table__column-action',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faEdit}
              title="Редактировать"
              onClick={() => {
                // @ts-ignore
                modalApi.show({
                  modalId: MODALS.ADD_USER,
                  data: {
                    user: original,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
    {
      Header: '',
      accessor: 'remove',
      className: 'table__column-right table__column-action',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faExchangeAlt}
              title="Удалить"
              className="fall"
              onClick={() => {
                // @ts-ignore
                modalApi.show({
                  modalId: MODALS.REMOVE_USER,
                  data: {
                    ...original,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Button className="button button__primary button__small" type={ButtonType.button} onClick={onAddUser}>
        <FontAwesomeIcon icon={faPlus} /> Добавить
      </Button>
      <TableGrid
        columns={columns}
        data={aggregateUsersStore.users}
        sortField="firstName"
        pageSize={25}
        totalRecords={aggregateUsersStore.total}
        onPageChange={loadUsers}
      />
    </>
  );
};
