/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useRef, useState } from 'react';

import { FieldInputProps, useField } from 'formik';
import JoditEditor from 'jodit-react';

type Props = {
  field: FieldInputProps<any>;
  placeholder: string;
  disabled?: boolean;
};

export const TextEditor: React.FC<Props> = ({ field: fieldProp, placeholder }) => {
  const [, meta, helpers] = useField(fieldProp.name);
  const [content, setState] = useState(fieldProp.value);
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || '',
      height: 300,
      language: 'ru',
      editorClassName: 'text-editor__form',
      uploader: {
        insertImageAsBase64URI: true,
      },
    }),
    [placeholder],
  );

  const handleEditorChange = (content: React.SetStateAction<string>) => {
    setState(content);
    helpers.setValue(content);
  };

  const isError = meta.touched && meta.error;

  const styles = {
    fontSize: '14px',
    fontWeight: 600,
    color: '#9598a7',
    marginBottom: '5px',
  };

  return (
    <div className="text-editor">
      <div style={styles} className="text-editor__placeholder">
        {placeholder}
      </div>

      <JoditEditor ref={editor} value={content} config={config} onBlur={handleEditorChange} />

      {isError && <span className="form-control__message">{meta.error}</span>}
    </div>
  );
};
