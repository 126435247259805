import React, { useEffect, useState } from 'react';

import { useStore } from 'effector-react';

import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { Search$, searchApi } from '../../effector/search';
import { signalsApi } from '../../effector/signal';
import { Position } from '../../types/position';
import { Search } from '../Search';
import { Button } from '../UIKit/Button';

export const ChangePosition: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const searchStore = useStore(Search$);
  const modalsState = useStore<TModal$State<{ strategyId: string; position: Position }>>(Modals$);

  const newPosition = searchStore.details;

  const position = modalsState?.data?.position;
  const strategyId = modalsState?.data?.strategyId;

  useEffect(() => {
    return () => {
      searchApi.clearDetails('');
    };
  }, []);

  const onSearchClick = (securityKey: string) => {
    searchApi.getPosition({ securityKey, strategyId });
    searchApi.clearSuggestion('');
  };

  const errorHandler = (result: { errorMessage?: string }) => {
    if (result.errorMessage) {
      setError(result.errorMessage);
    } else {
      modalApi.hide('');
    }
  };

  const onSubmit = () => {
    setLoading(true);

    const params = {
      strategyId,
      symbol: newPosition?.symbol,
      classCode: newPosition?.classCode,
      board: newPosition?.board,
      securityKey2: position?.securityKey,
      comment: '',
    };

    signalsApi
      .changeSignal(params)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const params = [
    {
      text: 'Текущая позиция:',
      value: `${position?.name || ''} (${position?.securityKey})`,
    },
  ];

  return (
    <div className="change-position">
      <div className="change-position__description">
        <table className="change-position__table">
          <tbody>
            {params.map((item, index) => (
              <tr key={index}>
                <td>{item.text}</td>
                <td className="change-position__value">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <div className="form__group">
          <Search strategyId={strategyId} onSearchClick={onSearchClick} />
        </div>
        {position && <div className="change-position__current-weight">{`Текущий вес: ${position.weight}%`}</div>}
        {error && <p className="error-message">{error}</p>}
        <div className="remove-delayed-signal__button-wrapper">
          <Button className="button__primary button__large" disabled={loading || !newPosition} onClick={onSubmit}>
            Заменить
          </Button>
        </div>
      </div>
    </div>
  );
};
