import React, { useEffect, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { getInvestboxInfo } from '../../../api';
import { Input } from '../../../components/FormControls/Input';
import { Select } from '../../../components/FormControls/Select';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../../const/validation';
import { aggregateInvestboxesApi } from '../../../effector/aggregateInvestbox';
import { strategyInfoRef$ } from '../../../effector/aggregateStrategyInfoRefs';
import { currencyInfo$ } from '../../../effector/currencies';
import { userInfo } from '../../../effector/userInfoList';
import { AggregateInvestbox } from '../../../types/aggregateInvestbox';
import { Currency, StrategyTestInfo } from '../../../types/aggregateStrategyInfoRef';
import { UserType } from '../../../types/aggregateUser';
import { StandartResponse } from '../../../types/standartResponse';
import { Nullable } from '../../../utils/types';
import { PAGE_ADMINISTRATION_INVESTBOXES } from '../../Routes';
import { AgreementHelper, ClientInfo } from '../Edit/agreementHelper';

type FormFields = {
  id: string;
  title: string;
  author: { id: number; name: string };
  motto: string;
  currency: Currency;
  leverage: number;
  minSumma: number;
  securities: ClientInfo[];
  tests: StrategyTestInfo[];
  tariffId: string;
  tariffName: string;
};

type FormProps = {
  error: Nullable<string>;
  upload: boolean;
  complete: () => void;
} & FormikProps<FormFields>;

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ERRORS_TEXTS.required),
  author: Yup.object().required(ERRORS_TEXTS.required),
  motto: Yup.string().nullable().required(ERRORS_TEXTS.required),
  currency: Yup.object().required(ERRORS_TEXTS.required),
  minSumma: Yup.number().positive().required(ERRORS_TEXTS.positive),
  leverage: Yup.number().min(0, ERRORS_TEXTS.positive),
  tariffId: Yup.string()
    .nullable()
    .matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, ERRORS_TEXTS.formatGuid),
  tariffName: Yup.string().nullable().required(ERRORS_TEXTS.required),
});

const FormTemplate: React.FC<FormProps> = (props) => {
  const userInfoStore = useStore(userInfo);
  const currencyStore = useStore(currencyInfo$);
  const infoStore = useStore(strategyInfoRef$);

  const users =
    userInfoStore.total > 0
      ? userInfoStore.users
          .filter((u) => u.userType !== UserType.Representative)
          .map((u) => {
            return { id: u.id, name: u.displayName };
          })
      : [];

  const verifySecurities = (security: string[]) => {
    return Promise.resolve({
      success: true,
      result: security
        ? security.map((s) => {
            return { id: s, name: s, value: s };
          })
        : [],
    });
  };

  const tests = infoStore.data.tests;
  const disabled = /*Object.keys(props.errors).length > 0 ||*/ !props.dirty || props.upload;

  return (
    <Form className="form" noValidate>
      <div>
        <div className="form__group">
          <p>Идентификатор инвест-копилки: {props.values.id}</p>
        </div>
        <div className="strategies__selection">
          <div className="form__group">
            <Field name="title" type="text" placeholder="Название" component={Input} />
          </div>
          <div className="form__group">
            <Field name="author" placeholder="Автор" options={users} component={Select} />
          </div>
          <div className="form__group">
            <Field name="motto" type="text" placeholder="Слоган" component={Input} />
          </div>
          <div className="form__group">
            <Field name="currency" placeholder="Валюта" options={currencyStore} component={Select} />
          </div>
          <div className="form__group">
            <Field name="minSumma" type="number" placeholder="Минимальная сумма" component={Input} />
          </div>
          <div className="form__group">
            <Field name="leverage" type="number" placeholder="Используемое плечо" component={Input} />
          </div>
          <div className="form__group">
            <Field
              name="tests"
              placeholder="Тесты инвест-копилки"
              options={tests}
              multiple={true}
              isClearable={true}
              component={Select}
            />
          </div>

          <div className="table-filter">
            <div className="table-filter__title">Инструменты</div>
            <div className="form__group">
              <Field
                name="securities"
                disabled={false}
                placeholder="Маска инструмента"
                verify={verifySecurities}
                verifyError="Не удается определить код инструмента"
                useKeyValue={false}
                component={AgreementHelper}
              />
            </div>
          </div>

          <div className="table-filter">
            <div className="table-filter__title">Тарифы</div>
            <div className="form__group">
              <Field
                name="tariffId"
                type="text"
                placeholder="Идентификатор тарифа (GUID)"
                disabled={true}
                component={Input}
              />
            </div>
            <div className="form__group">
              <Field name="tariffName" type="text" placeholder="Название тарифа" component={Input} />
            </div>
          </div>

          <div className="form__group">
            <Button className="button__primary button__large" type={ButtonType.submit} disabled={disabled}>
              Изменить
            </Button>
            <Button className="button__large" type={ButtonType.button} onClick={props.complete} disabled={false}>
              Отмена
            </Button>
          </div>
          {props.error && <p className="error-message">{props.error}</p>}
        </div>
      </div>
    </Form>
  );
};

export const EditInvestbox: React.FC<{ notify: (msg: string) => void }> = ({ notify }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const currencyStore = useStore(currencyInfo$);
  const infoStore = useStore(strategyInfoRef$);
  const userInfoStore = useStore(userInfo);
  const [error, setError] = useState<Nullable<string>>(null);
  const [upload, setUpload] = useState(false);
  const [investbox, setInvestbox] = useState<Nullable<AggregateInvestbox>>(null);
  const [loadError, setLoadError] = useState<Nullable<string>>(null);

  useEffect(() => {
    if (id) {
      getInvestboxInfo(id)
        .then((s) => setInvestbox(s.result))
        .catch((e) => setLoadError(e.toString()));
    }
  }, []);

  const complete = () => {
    navigate(`/administration${PAGE_ADMINISTRATION_INVESTBOXES}`);
  };

  const handleCommit = (result: StandartResponse<AggregateInvestbox>) => {
    if (result && result.success) {
      complete();
      notify('Изменения сохранены');
    } else {
      setError(result?.errorMessage ?? 'На сервере произошла ошибка');
    }
  };

  if (!investbox) {
    if (loadError) {
      return (
        <>
          <div className="strategies-params__item-name">
            <p>Ошибка загрузки данных</p>
            <p>{loadError}</p>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="strategies-params__item-name">
          <p>Загружаем данные инвест-копилки...</p>
        </div>
      </>
    );
  }

  if (infoStore.size === 0 || userInfoStore.total === 0) {
    return (
      <>
        <div className="strategies-params__item-name">
          <p>Загружаем справочные данные и тарифы...</p>
        </div>
      </>
    );
  }

  const author = userInfoStore.users.find((s) => s.id === investbox.managerId);
  const securities = JSON.parse(investbox.securities) as string[];

  const initValues: Partial<FormFields> = {
    id: investbox.id,
    title: investbox.title,
    motto: investbox.tag,
    currency: currencyStore.find((c) => c.code === investbox.currency),
    author: author ? { id: author.id, name: author.displayName } : undefined,
    securities:
      securities?.map((s) => {
        return { id: s, name: s, value: s };
      }) ?? [],
    leverage: investbox.leverage,
    minSumma: investbox.subscriptionThreshold,
    tariffId: investbox.tariffId,
    tariffName: investbox.tariffName,
    tests:
      investbox.tests
        ?.map((t) => infoStore.data.tests.find((tt) => tt.id === t))
        .filter((t) => !!t)
        .map((t) => {
          return { id: t?.id ?? '', name: t?.name ?? '' };
        }) ?? [],
  };

  const onSubmit = (values: FormFields) => {
    const data: Partial<AggregateInvestbox> = {
      id: values.id,
      managerId: values.author.id,
      title: values.title,
      tag: values.motto,
      currency: values.currency.code,
      securities: JSON.stringify(values.securities?.map((s) => s.value) ?? []),
      tests: values.tests?.map((t) => t.id) ?? [],
      leverage: values.leverage,
      subscriptionThreshold: values.minSumma,
      tariffId: values.tariffId,
      tariffName: values.tariffName,
    };

    aggregateInvestboxesApi
      .update(data)
      .then(handleCommit)
      .catch((e) => setError(e.toString()))
      .finally(() => {
        setUpload(false);
      });
  };

  return (
    <>
      <div className="strategies-params__item-name">
        <p>{investbox.title}</p>
      </div>
      <Formik
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        initialValues={initValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          React.createElement(FormTemplate, { ...props, error, upload, complete })
        }
      </Formik>
    </>
  );
};
