import React, { useState, useRef } from 'react';

import { Button, ButtonType } from '../../components/UIKit/Button';

export const CopyToClipboard: React.FC<{ className?: string; text: string; disabled?: boolean }> = ({
  className,
  text,
  disabled,
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const dataRef = useRef<HTMLTextAreaElement>(null);

  const isClipboard = !!navigator.clipboard;

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 1000);
        })
        .catch((e) => console.log(` *** copyToClipboard: ${e.toString()}`));
    } else {
      if (dataRef?.current) {
        dataRef.current.select();

        if (document.execCommand('copy')) {
          setCopied(true);
          setTimeout(() => setCopied(false), 1000);
        } else {
          console.log(' *** copyToClipboard: document.execCommand');
        }
      }
    }
  };

  return (
    <>
      <Button className={className} disabled={copied || disabled} onClick={copyToClipboard} type={ButtonType.button}>
        {copied ? 'Скопировано' : 'Копировать в буфер'}
      </Button>
      {isClipboard || (
        <textarea className="client-details__clipboard" ref={dataRef} defaultValue={text} value={text}></textarea>
      )}
    </>
  );
};
