/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useRef } from 'react';

import { faArrowDown, faArrowUp, faExchangeAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useStore } from 'effector-react';
import moment from 'moment';
import { Tooltip } from 'react-tippy';

import { TableGrid } from '../../../components/TableGrid';
import { modalApi } from '../../../effector/modals';
import { Positions$, positionsApi } from '../../../effector/positions';
import { Strategies$ } from '../../../effector/strategies';
import { User$ } from '../../../effector/user';
import { RecalculationMode, Strategy, StrategyStatus } from '../../../types/strategy';
import { checkPermissions, UserAction } from '../../../utils/permissions';
import { MODALS, Nullable } from '../../../utils/types';

type Props = Partial<Strategy>;

export const Positions: React.FC<Props> = ({ id, isChild }) => {
  const timerId = useRef<Nullable<number>>(null);
  //@ts-ignore
  const user = useStore(User$);
  const positionsStore = useStore(Positions$);
  const strategies = useStore(Strategies$);

  const strategy = strategies.find((item) => item.id === id);

  const columns = [
    {
      id: 'profit',
      Header: '',
      accessor: 'weight',
      action: UserAction.strategy,
      isChild: true,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { weight },
          },
        },
      }) => {
        const isUp = weight > 0;
        const icon = isUp ? faArrowUp : faArrowDown;

        return <FontAwesomeIcon className={cn({ fall: !isUp, rise: isUp })} icon={icon} />;
      },
    },
    {
      Header: 'Инструмент',
      accessor: 'symbol',
      className: 'table__column-left',
      action: UserAction.strategy,
      isChild: true,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { name, symbol },
          },
        },
      }) => (
        <span>
          {name} ({symbol})
        </span>
      ),
    },
    {
      Header: 'Доля %',
      accessor: 'weight',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
      hide: strategy?.recalcMode === RecalculationMode.CurrentPriceWithRecalculation,
    },
    {
      Header: 'Цена открытия',
      accessor: 'openPrice',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'Время открытия',
      accessor: 'openTime',
      className: 'table__column-left',
      action: UserAction.strategy,
      isChild: true,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { openTime },
          },
        },
      }) => {
        const time = moment(openTime).format('DD.MM.YYYY HH:mm');

        return <span>{time}</span>;
      },
    },
    {
      Header: 'Текущая цена',
      accessor: 'currentPrice',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'Текущая доля %',
      accessor: 'currentWeight',
      className: 'table__column-right',
      action: UserAction.strategyPositionCurrentShare,
      isChild: true,
      hide:
        strategy?.recalcMode === RecalculationMode.WeightedAverageWithRecalculation ||
        strategy?.recalcMode === RecalculationMode.WeightedAverageWithoutRecalculation,
    },
    {
      Header: 'SL',
      accessor: 'stopLoss',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'TP',
      accessor: 'takeProfit',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'Realized PnL %',
      accessor: 'realizedPL',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'Unealized PnL %',
      accessor: 'unrealizedPL',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { unrealizedPL, unrealizedPLPortfolio },
          },
        },
      }) => {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // tslint:disable-next-line: jsx-wrap-multiline
          <Tooltip
            theme="light"
            title={`${unrealizedPL}${unrealizedPLPortfolio && `(${unrealizedPLPortfolio})`}`}
            arrow={true}
            position="right"
            trigger="mouseenter"
          >
            <span
              className={cn({
                fall: unrealizedPL < 0,
                rise: unrealizedPL > 0,
              })}
            >
              {unrealizedPL}
              {unrealizedPLPortfolio && `(${unrealizedPLPortfolio})`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      id: 'remove',
      Header: '',
      accessor: 'securityKey',
      action: UserAction.strategyPositionRemove,
      isChild: false,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faTimes}
                className="fall"
                onClick={() => {
                  // @ts-ignore
                  modalApi.show({
                    modalId: MODALS.REMOVE_POSITION,
                    data: {
                      position: original,
                      strategyId: id,
                    },
                  });
                }}
              />
            </span>
          );
        }

        return null;
      },
    },
    {
      Header: '',
      accessor: 'change',
      className: 'table__column-right table__column-action',
      action: UserAction.strategyPositionChange,
      isChild: false,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faExchangeAlt}
              className="fall"
              onClick={() => {
                // @ts-ignore
                modalApi.show({
                  modalId: MODALS.CHANGE_POSITION,
                  data: {
                    strategyId: id,
                    position: original,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
  ];

  const cols = useMemo(() => {
    if (user?.role) {
      const cols = columns.filter((item) => checkPermissions(user.role, item.action)).filter((item) => !item.hide);

      return isChild ? cols.filter((item) => item.isChild) : cols;
    }

    return [];
  }, [user, id]);

  useEffect(() => {
    loadPositions();
    timerId.current = window.setInterval(loadPositions, 10000);

    return () => clearInterval(timerId.current as number);
  }, [id]);

  const loadPositions = () => {
    if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
      positionsApi.get(id as string);
    } else {
      positionsApi.getSandbox(id as string);
    }
  };

  return <TableGrid columns={cols} data={positionsStore.positions} sortField="time" />;
};
