/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { ERRORS_TEXTS } from '../../const/validation';
import { analyticsApi } from '../../effector/analytics';
import { AuthorDictionary$ } from '../../effector/authorDictionary';
import { CategoryDictionary$ } from '../../effector/categoryDictionary';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { StrategyDictionary$ } from '../../effector/strategyDictionary';
import { Analytic } from '../../types/analytic';
import { AnalyticsStrategy } from '../../types/analyticStrategy';
import { Option } from '../../types/option';
import { Nullable } from '../../utils/types';
import { Checkbox } from '../FormControls/Checkbox';
import { DatePicker } from '../FormControls/DatePicker';
import { File } from '../FormControls/File';
import { Input } from '../FormControls/Input';
import { Select } from '../FormControls/Select';
import { TextEditor } from '../FormControls/TextEditor';
import { Button, ButtonType } from '../UIKit/Button';

const validationSchema = Yup.object().shape({
  strategies: Yup.array(),
  categories: Yup.array(),
  author: Yup.object().required(ERRORS_TEXTS.required),
  title: Yup.string().required(ERRORS_TEXTS.required),
  seoTitle: Yup.string().required(ERRORS_TEXTS.required),
  seoDescription: Yup.string().required(ERRORS_TEXTS.required),
  date: Yup.string().required(ERRORS_TEXTS.required),
  anons: Yup.string().required(ERRORS_TEXTS.required),
  description: Yup.string().required(ERRORS_TEXTS.required),
  rightTitle: Yup.string().required(ERRORS_TEXTS.required),
  picture: Yup.string().nullable(),
  smallPicture: Yup.string().nullable(),
  friendlyUrl: Yup.string().required(ERRORS_TEXTS.required),
  subscription: Yup.boolean(),
});

type FormFields = {
  strategies: Option[];
  categories: Option[];
  showStrategies: Option[];
  author: Nullable<Option>;
  title: string;
  seoTitle: string;
  seoDescription: string;
  date: Date;
  anons: string;
  description: string;
  rightTitle: Nullable<string>;
  picture: Nullable<string>;
  smallPicture: Nullable<string>;
  friendlyUrl: string;
  subscription: boolean;
};

type FormProps = {
  error: string;
  isUpdate: boolean;
  loading: boolean;
} & FormikProps<FormFields>;

const initialValues = {
  strategies: [],
  categories: [],
  showStrategies: [],
  author: '',
  title: '',
  seoTitle: '',
  seoDescription: '',
  date: new Date(),
  anons: '',
  description: '',
  rightTitle: '',
  picture: '',
  smallPicture: '',
  friendlyUrl: '',
  subscription: false,
};

const FormTemplate: React.FC<FormProps> = ({ values, errors, dirty, error, isUpdate, loading, setFieldValue }) => {
  const strategies = useStore(StrategyDictionary$);
  const authors = useStore(AuthorDictionary$);
  const categories = useStore(CategoryDictionary$);

  const disable = Object.keys(errors).length > 0 || !dirty || loading;

  const styles = {
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <Form className="form analytic-form" noValidate>
      <div className="form__group">
        <Field type="text" name="title" placeholder="Заголовок" component={Input} />
      </div>

      <div className="form__group">
        <Field type="text" name="rightTitle" placeholder="Заголовок рекомендаций" component={Input} />
      </div>

      <div className="form__group">
        <Field type="text" name="friendlyUrl" placeholder="Url" component={Input} />
      </div>

      {strategies && (
        <div className="form__group">
          <Field name="strategies" component={Select} multiple={true} placeholder="Стратегия" options={strategies} />
        </div>
      )}

      {values.strategies?.length > 0 && (
        <div className="form__group">
          <div className="analytic-form__show-in">Показать в стратегии:</div>
          <div className="analytic-form__show-list">
            {strategies?.map((item, ind) => {
              const strategy: Nullable<Option> = values.strategies.find((strat) => strat.id === item.id);

              if (strategy) {
                return (
                  <div className="form__group" key={ind}>
                    <Field
                      name={'zero' + strategy.id}
                      text={strategy.name}
                      onChange={(value: boolean) => {
                        let updatedValues = values.showStrategies?.filter((item) => item.id !== strategy.id);
                        if (value) {
                          updatedValues = [...updatedValues, strategy];
                        }

                        setFieldValue('showStrategies', updatedValues);
                        setFieldValue('zero' + strategy.id, value);
                      }}
                      component={Checkbox}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}

      {authors && (
        <div className="form__group">
          <Field name="author" component={Select} placeholder="Автор" options={authors} />
        </div>
      )}

      {categories && (
        <div className="form__group">
          <Field name="categories" component={Select} multiple={true} placeholder="Категория" options={categories} />
        </div>
      )}

      <div className="form__group">
        <Field
          name="date"
          component={DatePicker}
          placeholder="Дата публикации"
          dropdownMode="select"
          dateFormat="dd.MM.yyyy HH:mm"
          showMonthDropdown
          showYearDropdown
          showTimeSelect
          timeCaption="Время"
          timeFormat="HH:mm"
          timeIntervals={30}
        />
      </div>

      {isUpdate && values.picture ? (
        <div className="form__group">
          <div
            style={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#9598a7',
              marginBottom: '5px',
            }}
          >
            Большое изображение
          </div>
          <div style={styles}>
            <span style={{ paddingBottom: '3px', marginRight: '5px' }}>{values.picture}</span>
            <span className="pointer action-icon">
              <FontAwesomeIcon icon={faTimes} className="fall" onClick={() => setFieldValue('picture', '')} />
            </span>
          </div>
        </div>
      ) : (
        <div className="form__group">
          <Field
            name="picture"
            placeholder="Большое изображение"
            url="api/admin/files/upload"
            fileTypes={['.png', '.jpg', '.jpeg', '.gif']}
            onRemove={() => null}
            onUpload={setFieldValue}
            component={File}
          />
        </div>
      )}

      {isUpdate && values.smallPicture ? (
        <div className="form__group">
          <div
            style={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#9598a7',
              marginBottom: '5px',
            }}
          >
            Маленькое изображение
          </div>
          <div style={styles}>
            <span style={{ paddingBottom: '3px', marginRight: '5px' }}>{values.smallPicture}</span>
            <span className="pointer action-icon">
              <FontAwesomeIcon icon={faTimes} className="fall" onClick={() => setFieldValue('smallPicture', '')} />
            </span>
          </div>
        </div>
      ) : (
        <div className="form__group">
          <Field
            name="smallPicture"
            placeholder="Маленькое изображение"
            fileTypes={['.png', '.jpg', '.jpeg', '.gif']}
            url="api/admin/files/upload"
            onRemove={() => null}
            onUpload={setFieldValue}
            component={File}
          />
        </div>
      )}

      <div className="form__group">
        <Field type="text" name="seoTitle" placeholder="Заголовок для SEO" component={Input} />
      </div>

      <div className="form__group">
        <Field name="seoDescription" placeholder="Описание для SEO" component={TextEditor} />
      </div>

      <div className="form__group">
        <Field name="anons" placeholder="Краткое описание" component={TextEditor} />
      </div>

      <div className="form__group">
        <Field name="description" placeholder="Полное описание" component={TextEditor} />
      </div>

      <div className="form__group">
        <Field name="subscription" text="Подписка" component={Checkbox} />
      </div>

      <Button className="button__primary button__large button__wide" type={ButtonType.submit} disabled={disable}>
        {isUpdate ? 'Обновить' : 'Добавить'}
      </Button>

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const AnalyticForm: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const authors = useStore(AuthorDictionary$);
  const strategies = useStore(StrategyDictionary$);
  const modalsState = useStore<TModal$State<{ analytic: Analytic }>>(Modals$);

  const analytic = modalsState.data?.analytic;

  const isUpdate = !!analytic;

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      // @ts-ignore
      modalApi.hide('');
    }
  };

  const getCreateData = (values: FormFields) => {
    const strategies = values.strategies.map((item) => {
      const show = values.showStrategies.find((strat) => strat.id === item.id);

      const strategy: AnalyticsStrategy = {
        strategyId: item.id,
        showInStrategy: !!show,
      };

      return strategy;
    });

    const data = {
      title: values.title,
      seoTitle: values.seoTitle,
      seoDescription: values.seoDescription,
      date: moment(values.date).format('YYYY-MM-DDTHH:mm:ss'),
      cutHtml: values.anons,
      fullHtml: values.description,
      strategiesTitle: values.rightTitle,
      picture: values.picture,
      smallPicture: values.smallPicture,
      subscription: values.subscription,
      friendlyUrl: values.friendlyUrl,
      strategies: strategies,
      author: { id: Number(values.author?.id) },
      categories: values.categories,
    };

    return data;
  };

  const getUpdateData = (values: FormFields) => {
    const data = {
      ...analytic,
      ...getCreateData(values),
    };

    return data;
  };

  const onSubmit = (values: FormFields) => {
    const data = isUpdate ? getUpdateData(values) : getCreateData(values);

    setLoading(true);
    analyticsApi
      .add(data)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const getInitData = () => {
    if (analytic) {
      const data: Partial<FormFields> = {
        title: analytic.title,
        seoTitle: analytic.seoTitle,
        seoDescription: analytic.seoDescription,
        date: new Date(analytic.date),
        anons: analytic.cutHtml,
        description: analytic.fullHtml,
        rightTitle: analytic.strategiesTitle,
        friendlyUrl: analytic.friendlyUrl,
        picture: analytic.picture,
        smallPicture: analytic.smallPicture,
        categories: analytic.categories,
        subscription: analytic.subscription,
      };

      const strat: Option[] = [];
      const showStrategies: Option[] = [];
      // @ts-ignore
      const author = authors?.find((item) => item.id == analytic.author?.id);

      strategies?.forEach((item) => {
        const strategy = analytic?.strategies?.find((strategy) => strategy.strategyId === item.id);

        if (strategy) {
          strat.push(item);
          if (strategy.showInStrategy) {
            showStrategies.push(item);
          }

          // @ts-ignore
          data[`zero${item.id}`] = strategy.showInStrategy;
        }
      });

      data.strategies = strat;
      data.showStrategies = showStrategies;
      data.author = author;

      return data;
    }

    return initialValues;
  };

  const init = getInitData();

  return (
    <Formik
      // @ts-ignore
      initialValues={init}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) =>
        React.createElement(
          FormTemplate,
          // @ts-ignore
          {
            ...props,
            error,
            isUpdate,
            loading,
          },
        )
      }
    </Formik>
  );
};
