import React from 'react';

import { Navigate, Route, Routes as RoutesComponent } from 'react-router-dom';

import { Categories } from './Categories';
import { Mapping } from './Mapping';
import { Questions } from './Questions';
import { Navigation } from '../../../components/Navigation';
import {
  PAGE_ADMINISTRATION_FAQ_CATEGORIES,
  PAGE_ADMINISTRATION_FAQ_MAPPING,
  PAGE_ADMINISTRATION_FAQ_QUESTIONS,
} from '../../Routes';

export const Faq: React.FC = ({}) => {
  const items = [
    {
      name: 'Категории',
      path: PAGE_ADMINISTRATION_FAQ_CATEGORIES,
    },
    {
      name: 'Вопросы',
      path: PAGE_ADMINISTRATION_FAQ_QUESTIONS,
    },
    {
      name: 'Маппинг',
      path: PAGE_ADMINISTRATION_FAQ_MAPPING,
    },
  ];

  return (
    <div className="statistic">
      <div className="statistic__navigation">
        <Navigation items={items} />
      </div>
      <div style={{ marginTop: '20px' }}>
        <RoutesComponent>
          <Route path={PAGE_ADMINISTRATION_FAQ_CATEGORIES} element={<Categories />} />
          <Route path={PAGE_ADMINISTRATION_FAQ_QUESTIONS} element={<Questions />} />
          <Route path={PAGE_ADMINISTRATION_FAQ_MAPPING} element={<Mapping />} />
          <Route path="*" element={<Navigate to={PAGE_ADMINISTRATION_FAQ_CATEGORIES} />} />
        </RoutesComponent>
      </div>
    </div>
  );
};
