/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import { FieldInputProps, useField } from 'formik';
import { default as Picker, ReactDatePickerProps } from 'react-datepicker';

type Props = {
  field: FieldInputProps<any>;
  placeholder: string;
  className?: string;
  disabled?: boolean;
} & ReactDatePickerProps;

export const DatePicker: React.FC<Props> = ({ field: fieldProp, disabled, placeholder, className, ...rest }) => {
  const [field, meta, helpers] = useField(fieldProp.name);
  const [focus, setFocus] = useState(false);

  const isError = meta.touched && meta.error;
  const isDirty = meta.value !== meta.initialValue;

  const onFocus = () => setFocus(true);
  const onBlur = (e: React.SyntheticEvent) => {
    field.onBlur(e);
    setFocus(false);
  };

  const onChange = (value: Date) => {
    if (value) {
      helpers.setValue(value);
    } else {
      helpers.setValue(undefined);
    }
  };

  return (
    <label
      className={cn('form-control', className, {
        'form-control-focus': focus || isDirty || field.value,
      })}
      onClick={(e) => e.preventDefault()}
    >
      <Picker
        className={cn('form-control__field', {
          'form-control__field-invalid': isError,
          'form-control__field-disabled': disabled,
        })}
        {...field}
        {...rest}
        locale={ru}
        selected={field.value}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      />
      {isError && <span className="form-control__message">{meta.error}</span>}
      <span className="form-control__placeholder">{placeholder}</span>
    </label>
  );
};
