import React from 'react';

import { LoginForm } from '../../components/Forms/Login';
import { Modal } from '../../components/Modal';

export const Login: React.FC = () => (
  <Modal
    isOpen={true}
    onRequestClose={() => {
      console.log('close');
    }}
    title="Войти"
  >
    <LoginForm />
  </Modal>
);
