/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Search$ } from '../../../effector/search';

const onlyDigits = /^(?:\d\.?)+$/;
// @ts-ignore
const checkWeight = (values, activeTab) => {
  const searchStore = Search$.getState();

  if (values.type === 3) {
    if (searchStore?.details?.maxSignalWeight)
      if (Math.abs(Number(searchStore?.details?.currentWeight)) > searchStore?.details?.maxSignalWeight)
        return `Максимальный вес в сигнале равен ${searchStore?.details?.maxSignalWeight}, полное закрытие позиции невозможно`;
    return '';
  }

  if (!values.size) {
    return `Поле является обязательным`;
  }

  if (!onlyDigits.test(values.size.trim())) {
    return `Допустимы только цифры`;
  }

  if (activeTab === 4) {
    // портфельный сигнал
    if (searchStore?.details?.maxSignalWeight)
      if (
        Math.abs(Number(searchStore?.details?.currentWeight) - (values.type === 1 ? 1 : -1) * values.size) >
        searchStore?.details?.maxSignalWeight
      )
        return `Максимальный вес в сигнале равен ${searchStore?.details?.maxSignalWeight}, уменьшить разницу весов`;
  }

  if (searchStore?.details?.maxSignalWeight)
    if (values.size > searchStore?.details?.maxSignalWeight)
      return `Максимальный вес в сигнале равен ${searchStore?.details?.maxSignalWeight}`;

  const minWeight = Number(searchStore?.details?.minWeight); // минимальный вес
  const maxWeight = Number(searchStore?.details?.maxWeight); // максимальный вес
  const currentWeight = activeTab === 4 ? 0 : Number(searchStore?.details?.currentWeight); // текущий вес
  const shortAllowed = searchStore?.details?.shortAllowed; // шорт допустим
  const resultWeight = currentWeight + (values.type === 1 ? 1 : -1) * values.size; // новый вес после сигнала
  const resultWeightAbs = Math.abs(resultWeight);

  const zeroWeight = resultWeight == 0; // будущий вес 0
  const inRange = resultWeightAbs >= minWeight && resultWeightAbs <= maxWeight; // между мин и макс
  const withoutShort = shortAllowed || resultWeight >= 0; // и если без шорта, то положителный
  const withShort = !shortAllowed && resultWeight < 0 && resultWeight > currentWeight; // с шортом, но позиция уменьшилась
  const acceptable = (zeroWeight || inRange) && (withoutShort || withShort);

  if (!acceptable)
    return (
      `Объем должен быть ${-currentWeight} либо между ${minWeight - currentWeight} и ${maxWeight - currentWeight}` +
      (shortAllowed ? ` либо между ${-maxWeight - currentWeight} и ${-minWeight - currentWeight}` : ``)
    );

  return '';
};

// @ts-ignore
const checkSL = (values) => {
  const searchStore = Search$.getState();
  if (values.stoploss) {
    if (!onlyDigits.test(values.stoploss.trim())) {
      return `Допустимы только цифры`;
    } else {
      const maxSLBuy = searchStore?.details?.maxSLBuy;
      const minSLSell = searchStore?.details?.minSLSell;
      // @ts-ignore
      const stoploss = Number(values.stoploss);

      if (values.type === 1) {
        // @ts-ignore
        if (stoploss > maxSLBuy) {
          // @ts-ignore
          return `SL не может быть выше чем ${maxSLBuy}`;
        }
      } else if (values.type === 2) {
        // @ts-ignore
        if (stoploss < minSLSell) {
          // @ts-ignore
          return `SL не может быть ниже чем ${minSLSell}`;
        }
      }
    }
  }

  return '';
};

// @ts-ignore
const checkTP = (values) => {
  const searchStore = Search$.getState();
  if (values.takeprofit) {
    if (!onlyDigits.test(values.takeprofit.trim())) {
      return `Допустимы только цифры`;
    } else {
      const minTPBuy = searchStore?.details?.minTPBuy;
      const maxTPSell = searchStore?.details?.maxTPSell;
      // @ts-ignore
      const takeprofit = Number(values.takeprofit);

      if (values.type === 1) {
        // @ts-ignore
        if (takeprofit < minTPBuy) {
          // @ts-ignore
          return `TP не может быть ниже чем ${minTPBuy}`;
        }
      } else if (values.type === 2) {
        // @ts-ignore
        if (takeprofit > maxTPSell) {
          // @ts-ignore
          return `TP не может быть выше чем ${maxTPSell}`;
        }
      }
    }
  }

  return '';
};

// @ts-ignore
const checkPrice = (values, activeTab) => {
  if (activeTab === 1 || activeTab === 4) {
    return '';
  }

  // @ts-ignore
  if (!values.price) {
    return `Поле является обязательным`;
  }

  // @ts-ignore
  if (!onlyDigits.test(values.price.trim())) {
    return `Допустимы только цифры`;
  }

  return '';
};

// @ts-ignore
const checkType = (values) => {
  if (!values.type) {
    return `Поле является обязательным`;
  }

  return '';
};

export const validateForm = <T>(values: T, activeTabId: number) => {
  const errors = {};

  if (checkWeight(values, activeTabId)) {
    // @ts-ignore
    errors.size = checkWeight(values, activeTabId);
  }

  if (checkSL(values)) {
    // @ts-ignore
    errors.stoploss = checkSL(values);
  }

  if (checkTP(values)) {
    // @ts-ignore
    errors.takeprofit = checkTP(values);
  }

  if (checkPrice(values, activeTabId)) {
    // @ts-ignore
    errors.price = checkPrice(values, activeTabId);
  }

  if (checkType(values)) {
    // @ts-ignore
    errors.type = checkType(values);
  }

  return errors;
};
