/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useState } from 'react';

import { Field, Form, Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { sendEmail } from '../../../../api';
import { Checkbox } from '../../../../components/FormControls/Checkbox';
import { File } from '../../../../components/FormControls/File';
import { Input } from '../../../../components/FormControls/Input';
import { Button, ButtonType } from '../../../../components/UIKit/Button';
import { ERRORS_TEXTS } from '../../../../const/validation';

const initialValues = {
  subject: '',
  document: '',
  documentAgreement: '',
  email: '',
  isChecked: false,
};

const validationSchema = Yup.object().shape({
  subject: Yup.string().required(ERRORS_TEXTS.required),
  document: Yup.string(),
  email: Yup.string(),
  documentAgreement: Yup.string(),
});

type FormFields = {
  subject: string;
  document: string;
  email: string;
  documentAgreement: string;
  isChecked: boolean;
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ values, errors, dirty, error, loading, setFieldValue }) => {
  const disable =
    Object.keys(errors).length > 0 ||
    !dirty ||
    loading ||
    !values.document ||
    !values.documentAgreement ||
    !values.subject;

  const setDocument = useCallback(
    (field: string, fieldValue: string) => {
      setFieldValue(field, fieldValue);
    },
    [setFieldValue],
  );

  const onRemoveDocument = useCallback(() => {
    setFieldValue('document', '');
  }, [setFieldValue]);

  const onRemoveDocumentAgreement = useCallback(() => {
    setFieldValue('documentAgreement', '');
  }, [setFieldValue]);

  return (
    <Form className="form analytic-form" noValidate>
      <div className="form__group">
        <Field name="subject" placeholder="Тема письма" component={Input} />
      </div>

      <div className="form__group">
        <Field name="email" placeholder="Тестовый email" component={Input} />
      </div>

      <div className="form__group">
        <Field
          name="document"
          placeholder="Шаблон письма"
          url="/api/admin/files/upload_tmp"
          fileTypes={['.htm']}
          onRemove={onRemoveDocument}
          onUpload={setDocument}
          component={File}
        />
      </div>

      <div className="form__group">
        <Field
          name="documentAgreement"
          placeholder="Список ген.соглашений"
          url="/api/admin/files/upload_tmp"
          fileTypes={['.txt']}
          onRemove={onRemoveDocumentAgreement}
          onUpload={setDocument}
          component={File}
        />
      </div>

      <div className="form__group">
        <Field name="isChecked" text="Шаблон проверен" component={Checkbox} />
      </div>

      <Button className="button__primary button__large button__wide" type={ButtonType.submit} disabled={disable}>
        Отправить
      </Button>

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const Agreement: React.FC = ({}) => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (values: FormFields) => {
      setLoading(true);

      const payload = {
        templateFileName: values.document,
        subject: values.subject,
        isChecked: values.isChecked,
        ...(values.email && { testEmail: values.email }),
        agreementNumbersFileName: values.documentAgreement,
        skip: 0,
      };

      let allEmailsSent = false;

      while (true) {
        try {
          const response = await sendEmail(payload);

          if (response.success) {
            const { total = 0, sent = 0 } = response.result;

            console.log(`Total clients: ${total}, Sent: ${sent}`);

            if (sent >= total) {
              console.log('All emails have been sent.');
              allEmailsSent = true;
              break;
            }

            payload.skip = sent;
          } else {
            setError(response.errorMessage);
            break;
          }
        } catch (error) {
          // @ts-ignore
          setError(error.toString());
          break;
        } finally {
          setLoading(false);
        }
      }

      if (allEmailsSent) {
        enqueueSnackbar('Письма отправлены');
      }
    },
    [enqueueSnackbar],
  );

  return (
    <Formik
      // @ts-ignore
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) =>
        React.createElement(
          FormTemplate,
          // @ts-ignore
          {
            ...props,
            error,
            loading,
          },
        )
      }
    </Formik>
  );
};
