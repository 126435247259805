/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';

import { faEdit, faPlus, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';

import { PageParams, TableGrid } from '../../components/TableGrid';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { Banners$, bannersApi, TBanners$State } from '../../effector/banners';
import { modalApi } from '../../effector/modals';
import { utmDictionaryApi } from '../../effector/utmDictionary';
import { MODALS } from '../../utils/types';

export const Banners: React.FC = () => {
  const banners = useStore<TBanners$State>(Banners$);

  useEffect(() => {
    utmDictionaryApi.get('');
  }, []);

  const loadData = (data: PageParams) => {
    bannersApi.get(data);
  };

  const onAddBanner = () => {
    modalApi.show({
      modalId: MODALS.BANNER,
    });
  };

  const columns = [
    {
      Header: 'Заголовок',
      accessor: 'title',
      className: 'table__column-left',
    },
    {
      Header: 'Подзаголовок',
      accessor: 'subtitle',
      className: 'table__column-right',
    },
    {
      Header: 'Текст кнопки',
      accessor: 'buttonText',
      className: 'table__column-right',
    },
    {
      Header: 'Ссылка',
      accessor: 'url',
      className: 'table__column-right',
    },
    {
      Header: 'UTM-метка',
      accessor: 'utm',
      className: 'table__column-right',
    },
    {
      Header: 'Статус',
      accessor: 'active',
      className: 'table__column-right',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { active },
          },
        },
      }) => {
        const name = active ? 'Активный' : 'Неактивный';

        return <span>{name}</span>;
      },
    },
    {
      Header: '',
      accessor: 'id',
      className: 'table__column-right table__column-action',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => {
                // @ts-ignore
                modalApi.show({
                  modalId: MODALS.BANNER,
                  data: {
                    banner: original,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
    {
      Header: '',
      accessor: 'remove',
      className: 'table__column-right table__column-action',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faExchangeAlt}
              className="fall"
              onClick={() => {
                // @ts-ignore
                modalApi.show({
                  modalId: MODALS.REMOVE_BANNER,
                  data: {
                    banner: original,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Button className="button button__primary button__small" type={ButtonType.button} onClick={onAddBanner}>
        <FontAwesomeIcon icon={faPlus} /> Добавить
      </Button>
      <TableGrid
        columns={columns}
        // @ts-ignore
        data={banners.banners}
        sortField="date"
        pageSize={25}
        totalRecords={banners.total}
        onPageChange={loadData}
      />
    </>
  );
};
