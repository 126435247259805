/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store, createStore, createApi, createEffect, forward, Effect } from 'effector';

import { getLimitsRmds, getLimitsQrc } from '../api';
import { LimitPosition } from '../types/limitPosition';
import { Nullable } from '../utils/types';

type ClientLimitState = {
  id: string;
  complete: boolean;
  error: Nullable<string>;
  responseTime: Nullable<Date>;
  positions: LimitPosition[];
};

const initState: ClientLimitState = {
  id: '',
  complete: true,
  error: null,
  responseTime: null,
  positions: [],
};

export type LimitsApi = {
  query: Effect<string, LimitPosition[], any>;
  init: Effect<unknown, unknown, Error>;
};

export type LimitsStore = Store<ClientLimitState>;

export const limitsRmds = CreateLimitsApi(getLimitsRmds);
export const limitsQrc = CreateLimitsApi(getLimitsQrc);

function CreateLimitsApi(queryfn: (agreementId: string) => Promise<any>): {
  api: LimitsApi;
  store$: Store<ClientLimitState>;
} {
  const ClientLimits$ = createStore<ClientLimitState>(initState);

  const { initLimits, queryLimits, setError } = createApi(ClientLimits$, {
    // @ts-ignore
    initLimits: (state, { id }) => {
      return {
        id: id,
        complete: false,
        error: null,
        responseTime: null,
        positions: [],
      };
    },
    // @ts-ignore
    queryLimits: (state, { result }) => {
      if (result) {
        if (result.success) {
          const receivedPositions = result.result as LimitPosition[];

          return {
            ...state,
            complete: true,
            responseTime: new Date(),
            positions: [...state.positions, ...receivedPositions],
          };
        } else {
          if (result.errorMessage) {
            return { ...state, error: result.errorMessage };
          }
        }
      }

      return state;
    },
    // @ts-ignore
    setError: (state, error) => {
      if (error) {
        return { ...state, error: error.error ? error.error.toString() : error.toString() };
      }

      return state;
    },
  });

  const limitsApi = {
    query: createEffect<string, LimitPosition[], any>().use(queryfn),
    init: createEffect(),
  };

  forward({
    from: limitsApi.init,
    to: initLimits,
  });

  forward({
    from: limitsApi.query.done,
    to: queryLimits,
  });

  forward({
    from: limitsApi.query.fail,
    to: setError,
  });

  return { api: limitsApi, store$: ClientLimits$ };
}
