/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';

import { faArrowDown, faArrowUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useStore } from 'effector-react';
import moment from 'moment';
import { Tooltip } from 'react-tippy';

import { PageParams, TableGrid } from '../../../components/TableGrid';
import { DelayedSignals$, delayedSignalsApi } from '../../../effector/delayedSignal';
import { modalApi } from '../../../effector/modals';
import { Strategies$ } from '../../../effector/strategies';
import { User$ } from '../../../effector/user';
import { DelayedSignalType } from '../../../types/delayedSignal';
import { Strategy, StrategyStatus } from '../../../types/strategy';
import { checkPermissions, UserAction } from '../../../utils/permissions';
import { MODALS } from '../../../utils/types';

type Props = Partial<Strategy>;

export const Delayed: React.FC<Props> = ({ id, isChild }) => {
  //@ts-ignore
  const user = useStore(User$);
  const delayedSignalStore = useStore(DelayedSignals$);
  const strategies = useStore(Strategies$);

  const strategy = strategies.find((item) => item.id === id);

  const columns = [
    {
      id: 'profit',
      Header: '',
      accessor: 'weight',
      action: UserAction.strategy,
      isChild: true,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { weight },
          },
        },
      }) => {
        const isUp = weight > 0;
        const icon = isUp ? faArrowUp : faArrowDown;

        return <FontAwesomeIcon className={cn({ fall: !isUp, rise: isUp })} icon={icon} />;
      },
    },
    {
      Header: 'Инструмент',
      accessor: 'name',
      className: 'table__column-left',
      action: UserAction.strategy,
      isChild: true,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { name, symbol, comment },
          },
        },
      }) => {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // tslint:disable-next-line: jsx-wrap-multiline
          <Tooltip theme="light" title={comment} arrow={true} position="top" trigger="mouseenter">
            <span>
              {name} ({symbol})
            </span>
          </Tooltip>
        );
      },
    },
    {
      Header: 'Доля %',
      accessor: 'weight',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'Цена',
      accessor: 'execPrice',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'Время открытия',
      accessor: 'time',
      className: 'table__column-left',
      action: UserAction.strategy,
      isChild: true,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { fillingTime },
          },
        },
      }) => {
        const timeFormated = moment(fillingTime).format('DD.MM.YYYY HH:mm');

        return <span>{timeFormated}</span>;
      },
    },
    {
      Header: 'Статус',
      accessor: 'type',
      className: 'table__column-left',
      action: UserAction.strategy,
      isChild: true,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { type },
          },
        },
      }) => {
        return type === DelayedSignalType.Standard ? 'Ждёт исполнения' : 'Ждёт активации';
      },
    },
    {
      Header: 'Текущая цена',
      accessor: 'currentPrice',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'SL',
      accessor: 'stopLoss',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      Header: 'TP',
      accessor: 'takeProfit',
      className: 'table__column-right',
      action: UserAction.strategy,
      isChild: true,
    },
    {
      id: 'remove',
      Header: '',
      accessor: 'id',
      action: UserAction.strategyPositionRemove,
      isChild: false,
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faTimes}
              className="fall"
              onClick={() =>
                modalApi.show({
                  modalId: MODALS.REMOVE_DELAYED_SIGNAL,
                  data: {
                    signalId: original.id,
                  },
                })
              }
            />
          </span>
        );
      },
    },
  ];

  const cols = useMemo(() => {
    if (user?.role) {
      const cols = columns.filter((item) => checkPermissions(user.role, item.action));

      return isChild ? cols.filter((item) => item.isChild) : cols;
    }

    return [];
  }, [user, id]);

  const loadSignals = (data: PageParams) => {
    const params = {
      strategyId: id,
      data,
    };

    if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
      delayedSignalsApi.get(params);
    } else {
      delayedSignalsApi.getSandbox(params);
    }
  };

  return (
    <TableGrid
      columns={cols}
      data={delayedSignalStore.signals}
      sortField="time"
      pageSize={25}
      totalRecords={delayedSignalStore.totalPage}
      onPageChange={loadSignals}
    />
  );
};
