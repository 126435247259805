/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { MRT_ColumnDef } from 'material-react-table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Input } from '../../../components/FormControls/Input';
import { Select } from '../../../components/FormControls/Select';
import { Criteria, Filter, PageParams } from '../../../components/TableGrid';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { getCorrectServiceType, onlyUnique, sortColumnFn } from '../../../components/TableGridLocal/filters';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { Clients$, clientsApi } from '../../../effector/clients';
import { StatisticFilters$ } from '../../../effector/statisticFilters';
import { AccountStat } from '../../../types/accountStat';
import { Option } from '../../../types/option';
import { formatMoney, getPercentage } from '../../../utils';
import { PAGE_STATISTIC_CLIENT_DETAILS_INFO } from '../../Routes';

const validationSchema = Yup.object().shape({
  strategy: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      name: Yup.string(),
    }),
  ),
  service: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      name: Yup.string(),
    }),
  ),
  agreement: Yup.string(),
  clientId: Yup.string(),
});

type FormFields = {
  strategy: Option[];
  service: Option[];
  agreement: string;
  clientId: string;
};

type FormProps = {
  onFilterChanged: (filters: Filter) => void;
} & FormikProps<FormFields>;

const initialValues = {
  strategy: [],
  service: [],
  agreement: '',
  clientId: '',
};

const FormTemplate: React.FC<FormProps> = ({ values, resetForm, onFilterChanged }) => {
  const statisticFilters = useStore(StatisticFilters$);

  useEffect(() => {
    const filters = Object.keys(values)
      .map((item) => {
        // @ts-ignore
        const fieldValue = values[item];

        if (typeof fieldValue === 'string' && fieldValue.trim()) {
          return {
            fieldName: item,
            filterCriteria: Criteria.Contains,
            matches: [fieldValue],
          };
        }

        if (Array.isArray(fieldValue) && fieldValue.length) {
          return {
            fieldName: item,
            filterCriteria: Criteria.Equals,
            matches: fieldValue.map((item) => item.id),
          };
        }
      })
      .filter((item) => !!item);

    // @ts-ignore
    onFilterChanged(filters);
  }, [values]);

  return (
    <Form className="form" noValidate>
      <div className="form-control-row">
        <Field name="agreement" component={Input} placeholder="Г/С" />
      </div>
      <div className="form-control-group">
        {statisticFilters.strategies && (
          <div className="form-control-group__item">
            <Field
              name="strategy"
              component={Select}
              multiple={true}
              placeholder="Стратегии"
              options={statisticFilters.strategies}
            />
          </div>
        )}
        {statisticFilters.services && (
          <div className="form-control-group__item">
            <Field
              name="service"
              component={Select}
              multiple={true}
              placeholder="Услуги"
              options={statisticFilters.services}
            />
          </div>
        )}
      </div>
      <div className="form-control-row">
        <Field name="clientId" component={Input} placeholder="Уникальный ключ клиента" />
      </div>
      <div className="table-filter__actions">
        <Button className="button__primary button__large" onClick={() => resetForm()} type={ButtonType.reset}>
          Сбросить
        </Button>
      </div>
    </Form>
  );
};

export const Clients: React.FC = () => {
  const [formFilters, setFormFilters] = useState<Filter[]>([]);

  const clientsStore = useStore(Clients$);

  const loadClients = useCallback(
    (params: PageParams) => {
      console.log(params);
      clientsApi.get({
        ...params,
        filters: formFilters.concat(params.filters || []),
      });
    },
    [formFilters],
  );

  const updatedData = useMemo(() => {
    return clientsStore.clients.map((item) => ({
      ...item,
      currency: item.currency === 'SUR' ? 'RUB' : item.currency,
    }));
  }, [clientsStore.clients]);

  const currencies = useMemo(() => updatedData.map(({ currency }) => currency).filter(onlyUnique), [updatedData]);

  const columns = useMemo<MRT_ColumnDef<AccountStat>[]>(() => {
    const items: MRT_ColumnDef<AccountStat>[] = [
      {
        accessorKey: 'clientId',
        header: 'MasterId',
        filterVariant: 'text',
        // @ts-ignore
        hiddenByDefault: true,
        Cell: ({ cell }) => {
          return (
            <Link to={`${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${cell?.row?.original?.agreementId}`}>
              {cell?.row?.original?.clientId}
            </Link>
          );
        },
      },
      {
        accessorKey: 'agreement',
        header: 'Г/С',
        filterVariant: 'text',
        Cell: ({ cell }) => (
          <Link to={`${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${cell?.row?.original?.agreementId}`}>
            {cell?.row?.original?.agreement}
          </Link>
        ),
      },
      {
        header: 'Портфель',
        accessorKey: 'portfolio',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.portfolioSUR : originalRow.portfolio;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col, col2, 'portfolioSUR');
        },
        Cell: ({ cell }) => {
          const value =
            cell.row.original.currency === 'SUR' ? cell.row.original.portfolioSUR : cell.row.original.portfolio;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
      },
      {
        header: 'В инструментах',
        accessorKey: 'positions',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        // @ts-ignore
        hiddenByDefault: true,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.positionsSUR : originalRow.positions;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col, col2, 'positionsSUR');
        },
        Cell: ({ cell }) => {
          const value =
            cell.row.original.currency === 'SUR' ? cell.row.original.positionsSUR : cell.row.original.positions;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
      },
      {
        header: 'Валюта',
        accessorKey: 'currency',
        filterVariant: 'multi-select',
        filterSelectOptions: currencies,
        // @ts-ignore
        hiddenByDefault: true,
        size: 50,
      },
      {
        accessorKey: 'free',
        header: 'Свободно',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        // @ts-ignore
        hiddenByDefault: true,
        accessorFn: (originalRow) => {
          const value = originalRow.currency === 'SUR' ? originalRow.freeSUR : originalRow.free;
          return value;
        },
        sortingFn: (col, col2) => {
          return sortColumnFn(col, col2, 'freeSUR');
        },
        Cell: ({ cell }) => {
          const value = cell.row.original.currency === 'SUR' ? cell.row.original.freeSUR : cell.row.original.free;

          if (!value) {
            return '';
          }

          return formatMoney(value, cell.row.original.currency);
        },
      },
      {
        accessorKey: 'deviation',
        header: 'Отклонение (%)',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        size: 100,
        Cell: ({ cell }) => {
          return getPercentage(cell.row.original.deviation);
        },
      },
      {
        accessorKey: 'minDiff',
        header: 'Мин. откл.',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        // @ts-ignore
        hiddenByDefault: true,
        size: 100,
        Cell: ({ cell }) => {
          return getPercentage(cell.row.original.minDiff);
        },
      },
      {
        accessorKey: 'lifetime',
        header: 'Время на услуге (общее) д:ч:м',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'strategy',
        header: 'Стратегия',
        filterVariant: 'text',
      },
      {
        accessorFn: (originalRow) => getCorrectServiceType(originalRow.service),
        header: 'Услуга',
        filterVariant: 'select',
        filterSelectOptions: ['Автоследование', 'Автоконсультирование', 'Накопилка'],
        size: 150,
        Cell: ({ cell }) => getCorrectServiceType(cell.row.original.service),
      },
      {
        accessorKey: 'bindDate',
        header: 'Подключен',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => <span>{moment(cell?.row?.original?.bindDate).format('DD.MM.YYYY HH:mm')}</span>,
      },
      {
        accessorKey: 'branchName',
        header: 'Офис',
        filterVariant: 'text',
      },
    ];
    return items;
  }, [currencies]);

  const columnVisibility = useMemo(() => {
    return columns.reduce((prev, cur) => {
      // @ts-ignore
      if (cur.hiddenByDefault && cur.accessorKey) {
        prev[cur.accessorKey] = false;
      }
      return prev;
    }, {} as Record<string, boolean>);
  }, [columns]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = () => {};

  const onFilterChanged = (filters: Filter[]) => {
    setFormFilters(filters);
  };

  return (
    <>
      <div className="table-filter">
        <div className="table-filter__title">Фильтры</div>
        <Formik
          // @ts-ignore
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) =>
            React.createElement(
              FormTemplate,
              // @ts-ignore
              {
                ...props,
                // @ts-ignore
                onFilterChanged,
              },
            )
          }
        </Formik>
      </div>
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          serverMode
          pageSize={25}
          columns={columns}
          data={updatedData}
          columnVisibility={columnVisibility}
          totalRowCount={clientsStore.totalClients}
          onFetchData={loadClients}
        />
      </div>
    </>
  );
};
