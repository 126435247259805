/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';

import { faEdit, faPlus, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';
import moment from 'moment';

import { PageParams, TableGrid } from '../../components/TableGrid';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { Analytics$, analyticsApi } from '../../effector/analytics';
import { authorDictionaryApi } from '../../effector/authorDictionary';
import { categoryDictionaryApi } from '../../effector/categoryDictionary';
import { modalApi } from '../../effector/modals';
import { MODALS } from '../../utils/types';

export const Analytics: React.FC = ({}) => {
  const analytics = useStore(Analytics$);

  useEffect(() => {
    // @ts-ignore
    authorDictionaryApi.get();
    // @ts-ignore
    categoryDictionaryApi.get();
  }, []);

  const loadData = (data: PageParams) => {
    analyticsApi.get(data);
  };

  const onAddAnalytic = () => {
    modalApi.show({
      modalId: MODALS.ANALYTIC,
    });
  };

  const columns = [
    {
      Header: 'Заголовок',
      accessor: 'title',
      className: 'table__column-left',
    },
    {
      Header: 'Стратегия',
      accessor: 'strategyId',
      className: 'table__column-right',
    },
    {
      Header: 'Время публикации',
      accessor: 'date',
      className: 'table__column-right',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { date },
          },
        },
      }) => {
        const timeFormated = moment(date).format('DD.MM.YYYY HH:mm');

        return <span>{timeFormated}</span>;
      },
    },
    {
      Header: 'Автор',
      accessor: 'authorId',
      className: 'table__column-right',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { author },
          },
        },
      }) => {
        const name = author?.displayName ?? '';

        return <span>{name}</span>;
      },
    },
    {
      Header: 'Статус',
      accessor: 'active',
      className: 'table__column-right',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original: { active },
          },
        },
      }) => {
        const name = active ? 'Активный' : 'Неактивный';

        return <span>{name}</span>;
      },
    },
    {
      Header: '',
      accessor: 'id',
      maxWidth: 50,
      className: 'table__column-right',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => {
                // @ts-ignore
                modalApi.show({
                  modalId: MODALS.ANALYTIC,
                  data: {
                    analytic: original,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
    {
      Header: '',
      accessor: 'remove',
      maxWidth: 50,
      className: 'table__column-right',
      Cell: ({
        cell: {
          row: {
            // @ts-ignore
            original,
          },
        },
      }) => {
        return (
          <span className="pointer">
            <FontAwesomeIcon
              icon={faExchangeAlt}
              className="fall"
              onClick={() => {
                // @ts-ignore
                modalApi.show({
                  modalId: MODALS.REMOVE_ANALYTIC,
                  data: {
                    analytic: original,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Button className="button button__primary button__small" type={ButtonType.button} onClick={onAddAnalytic}>
        <FontAwesomeIcon icon={faPlus} /> Добавить
      </Button>
      <TableGrid
        columns={columns}
        // @ts-ignore
        data={analytics.analytics}
        sortField="date"
        pageSize={25}
        totalRecords={analytics.total}
        onPageChange={loadData}
      />
    </>
  );
};
