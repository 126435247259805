/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ERRORS_TEXTS } from '../../const/validation';
import { Config$ } from '../../effector/config';
import { User$, userApi } from '../../effector/user';
import { PAGE_STRATEGIES, PAGE_CHANGE_PASSWORD, PAGE_CHECK_LOGIN_SMS } from '../../screens/Routes';
import { Input } from '../FormControls/Input';
import { Button, ButtonType } from '../UIKit/Button';

const validationSchema = Yup.object().shape({
  login: Yup.string().required(ERRORS_TEXTS.required),
  password: Yup.string().required(ERRORS_TEXTS.required),
});

const initialValues = {
  login: '',
  password: '',
};

type FormFields = {
  login: string;
  password: string;
};

type FormProps = {
  error: string;
  loading: boolean;
  rcKey: string | undefined;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ errors, error, rcKey, loading }) => {
  const rcEnabled = rcKey != 'disabled';
  const [needValidation, setUserValidate] = useState<boolean>(rcEnabled);

  const disable = Object.keys(errors).length > 0 || loading;

  const verifyCallback = () => {
    setUserValidate(false);
  };

  const expiredCallback = () => {
    setUserValidate(true);
  };

  return (
    <Form className="form" noValidate>
      <div className="form__group">
        <Field type="text" name="login" placeholder="Имя пользователя" component={Input} />
      </div>

      <div className="form__group">
        <Field type="password" name="password" placeholder="Пароль" component={Input} />
      </div>
      {rcEnabled && (
        <div className="form__group">
          <ReCAPTCHA sitekey={rcKey as string} onChange={verifyCallback} onExpired={expiredCallback} />
        </div>
      )}

      {error && <p className="error-message">{error}</p>}

      <Button
        className="button__primary button__large button__wide"
        type={ButtonType.submit}
        disabled={needValidation || disable}
      >
        Войти
      </Button>
    </Form>
  );
};

export const LoginForm: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const user = useStore(User$);
  const config = useStore(Config$);

  // @ts-ignore
  const errorHandler = (result, userId) => {
    if (result.errorCode === 1) {
      navigate(PAGE_CHANGE_PASSWORD, { state: { login: userId } });
      return;
    } else if (result.errorCode === 5) {
      navigate(PAGE_CHECK_LOGIN_SMS, { state: { login: userId, phone: result.errorMessage } });
      return;
    }
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    }
  };

  const onSubmit = (values: FormFields) => {
    const formData = {
      userId: values.login,
      password: values.password,
    };

    setLoading(true);
    userApi
      .login(formData)
      .then((result) => {
        errorHandler(result, values.login);
      })
      .catch((result) => {
        errorHandler(result, values.login);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (user) {
    navigate(PAGE_STRATEGIES);
    return null;
  }

  if (!config?.rcKey) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) =>
        React.createElement(FormTemplate, {
          ...props,
          rcKey: config?.rcKey,
          error,
          loading,
        })
      }
    </Formik>
  );
};
