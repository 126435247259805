import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { sandboxUploadFileData } from '../../api';
import { ERRORS_TEXTS } from '../../const/validation';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { Nullable } from '../../utils/types';
import { Checkbox } from '../FormControls/Checkbox';
import { File } from '../FormControls/File';
import { Input } from '../FormControls/Input';
import { Radio } from '../FormControls/Radio';
import { Button, ButtonType } from '../UIKit/Button';

enum TrackType {
  Digit = 0,
  Percent = 1,
}

const validationSchema = Yup.object().shape({
  file: Yup.string().required(ERRORS_TEXTS.required),
  trackType: Yup.number().required(ERRORS_TEXTS.required),
  nkd: Yup.boolean(),
  isAlgo: Yup.boolean(),
  sum: Yup.number().when('trackType', {
    is: (value: number) => value === 0,
    then: Yup.number().required(ERRORS_TEXTS.required).integer(ERRORS_TEXTS.number).typeError(ERRORS_TEXTS.number),
  }),
  markets: Yup.array().of(Yup.number()),
});

const initialValues = {
  file: '',
  trackType: TrackType.Percent,
  sum: 0,
  nkd: false,
  isAlgo: false,
  markets: [],
};

type FormFields = {
  file: Nullable<string>;
  trackType: TrackType;
  sum: number;
  nkd: boolean;
  isAlgo: boolean;
  markets: number[];
};

type FormProps = {
  error: string;
  loading: boolean;
} & FormikProps<FormFields>;

const FormTemplate: React.FC<FormProps> = ({ values, errors, error, setFieldValue }) => {
  useEffect(() => {
    setFieldValue('sum', 0);
    setFieldValue('isAlgo', values.trackType === TrackType.Percent);
  }, [values.trackType]);

  const trackTypes = useMemo(
    () => [
      {
        name: 'Доли',
        value: TrackType.Percent,
      },
      {
        name: 'Количество',
        value: TrackType.Digit,
      },
    ],
    [],
  );

  const marketsMMVBUSD = useMemo(() => {
    return [
      {
        name: 'ММВБ (USD)',
        value: 6,
      },
    ];
  }, []);

  const marketsMMVB = useMemo(() => {
    return [
      {
        name: 'Основной рынок ММВБ (акции)',
        value: 1,
      },
      {
        name: 'Основной рынок ММВБ (облигации)',
        value: 2,
      },
      {
        name: 'Цены облигации в %',
        value: -1,
      },
      {
        name: 'Срочный рынок Фортс',
        value: 4,
      },
    ];
  }, []);

  const marketsSPB = useMemo(() => {
    return [
      {
        name: 'СПБ',
        value: 3,
      },
    ];
  }, []);

  const marketsUS = useMemo(() => {
    return [
      {
        name: 'US MARKETS',
        value: 5,
      },
    ];
  }, []);

  const marketsEtf = useMemo(() => {
    return [
      {
        name: 'LSE',
        value: 7,
      },
    ];
  }, []);

  const marketsSPBEUR = useMemo(() => {
    return [
      {
        name: 'СПБ-EUR',
        value: 8,
      },
    ];
  }, []);

  const marketsSPBNKD = useMemo(() => {
    return [
      {
        name: 'Площадка СПБ-HKD',
        value: 9,
      },
    ];
  }, []);

  return (
    <Form className="form upload-track-form" noValidate>
      <div className="form__group">
        <Field
          name="file"
          fileTypes={['.txt']}
          url="api/sandbox/uploadfile"
          uploadedFileName="Файл загружен"
          onRemove={() => null}
          onUpload={setFieldValue}
          component={File}
        />
      </div>

      <div className="upload-track-form__track">
        <div className="upload-track-form__label">Тип сигнала:</div>
        <div className="upload-track-form__track-wrapper upload-track-form__track-types">
          {trackTypes.map((item) => (
            <Field
              key={item.name}
              name="trackType"
              text={item.name}
              value={item.value}
              checked={values.trackType === item.value}
              className="upload-track-form__track-type"
              onChange={() => setFieldValue('trackType', item.value)}
              component={Radio}
            />
          ))}
        </div>
      </div>

      {values.trackType === TrackType.Digit && (
        <div className="form__group">
          <Field type="text" name="sum" placeholder="Стартовая сумма" component={Input} />
        </div>
      )}

      {values.trackType === TrackType.Percent && (
        <div className="form__group">
          <div className="form__group upload-track-form__track-sub-type">
            <Field name="isAlgo" text="Стратегия с фиксированными весами" component={Checkbox} />
          </div>
        </div>
      )}

      <div className="form__group">
        <div className="upload-track-form__label">Рынки:</div>
        <div className="upload-track-form__show-list">
          {marketsMMVBUSD.map(({ value, name }) => (
            <div className="form__group" key={name}>
              <Field
                name={`markets${value}`}
                text={name}
                disabled={
                  values.markets.length &&
                  ((!values.markets.includes(value) &&
                    !marketsMMVB.some(({ value }) => values.markets.includes(value))) ||
                    (marketsMMVB.some(({ value }) => values.markets.includes(value)) && values.markets.includes(1)))
                }
                onChange={(checked: boolean) => {
                  const updatedValues = checked
                    ? [...values.markets, value]
                    : values.markets.filter((item) => item !== value);
                  setFieldValue('markets', updatedValues);
                }}
                component={Checkbox}
              />
            </div>
          ))}
          {marketsMMVB.map(({ value, name }) => {
            if (value === -1) {
              return values.markets.includes(2) ? (
                <div className="form__group upload-track-form__track-sub-type" key={name}>
                  <Field name="nkd" text={name} component={Checkbox} />
                </div>
              ) : null;
            }

            if (value === 1) {
              return (
                <div className="form__group" key={name}>
                  <Field
                    name={`markets${value}`}
                    text={name}
                    disabled={
                      values.markets.length &&
                      (!marketsMMVB.some(({ value }) => values.markets.includes(value)) || values.markets.includes(6))
                    }
                    onChange={(checked: boolean) => {
                      const updatedValues = checked
                        ? [...values.markets, value]
                        : values.markets.filter((item) => item !== value);
                      setFieldValue('markets', updatedValues);
                    }}
                    component={Checkbox}
                  />
                </div>
              );
            }

            return (
              <div className="form__group" key={name}>
                <Field
                  name={`markets${value}`}
                  text={name}
                  disabled={
                    values.markets.length &&
                    !marketsMMVB.some(({ value }) => values.markets.includes(value)) &&
                    !values.markets.includes(6)
                  }
                  onChange={(checked: boolean) => {
                    const updatedValues = checked
                      ? [...values.markets, value]
                      : values.markets.filter((item) => item !== value);
                    setFieldValue('markets', updatedValues);
                  }}
                  component={Checkbox}
                />
              </div>
            );
          })}
          {marketsSPB.map(({ value, name }) => (
            <div className="form__group" key={name}>
              <Field
                name={`markets${value}`}
                text={name}
                disabled={values.markets.length && !values.markets.includes(value)}
                onChange={(checked: boolean) => {
                  const updatedValues = checked
                    ? [...values.markets, value]
                    : values.markets.filter((item) => item !== value);
                  setFieldValue('markets', updatedValues);
                }}
                component={Checkbox}
              />
            </div>
          ))}
          {marketsSPBEUR.map(({ value, name }) => (
            <div className="form__group" key={name}>
              <Field
                name={`markets${value}`}
                text={name}
                disabled={values.markets.length && !values.markets.includes(value)}
                onChange={(checked: boolean) => {
                  const updatedValues = checked
                    ? [...values.markets, value]
                    : values.markets.filter((item) => item !== value);
                  setFieldValue('markets', updatedValues);
                }}
                component={Checkbox}
              />
            </div>
          ))}
          {marketsUS.map(({ value, name }) => (
            <div className="form__group" key={name}>
              <Field
                name={`markets${value}`}
                text={name}
                disabled={values.markets.length && !values.markets.includes(value)}
                onChange={(checked: boolean) => {
                  const updatedValues = checked
                    ? [...values.markets, value]
                    : values.markets.filter((item) => item !== value);
                  setFieldValue('markets', updatedValues);
                }}
                component={Checkbox}
              />
            </div>
          ))}
          {marketsEtf.map(({ value, name }) => (
            <div className="form__group" key={name}>
              <Field
                name={`markets${value}`}
                text={name}
                disabled={values.markets.length && !values.markets.includes(value)}
                onChange={(checked: boolean) => {
                  const updatedValues = checked
                    ? [...values.markets, value]
                    : values.markets.filter((item) => item !== value);
                  setFieldValue('markets', updatedValues);
                }}
                component={Checkbox}
              />
            </div>
          ))}
          {marketsSPBNKD.map(({ value, name }) => (
            <div className="form__group" key={name}>
              <Field
                name={`markets${value}`}
                text={name}
                disabled={values.markets.length && !values.markets.includes(value)}
                onChange={(checked: boolean) => {
                  const updatedValues = checked
                    ? [...values.markets, value]
                    : values.markets.filter((item) => item !== value);
                  setFieldValue('markets', updatedValues);
                }}
                component={Checkbox}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="upload-track-form__button-wrapper">
        <Button
          className="button__primary button__large"
          type={ButtonType.submit}
          disabled={Object.keys(errors).length > 0}
        >
          Загрузить трек
        </Button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const UploadTrack: React.FC = ({}) => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const modalsState = useStore<TModal$State<{ strategyId: string }>>(Modals$);

  const strategyId = modalsState.data?.strategyId;

  const onSubmit = useCallback(
    (values: FormFields) => {
      const data = {
        fileName: values.file,
        stratId: strategyId,
        markets: values.markets,
        calcNkd: values.nkd,
        isAlgo: values.isAlgo,
        type: values.trackType,
        startingFunds: Number(values.sum ?? 0),
      };

      setLoading(true);
      sandboxUploadFileData(data)
        .then((result) => {
          const { success, errorMessage } = result;

          if (success) {
            modalApi.hide('');
          } else {
            setError(errorMessage);
          }
        })
        .catch(() => {
          setError('Произошла непредвиденная ошибка');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [strategyId],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) =>
        React.createElement(
          FormTemplate,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          {
            ...props,
            error,
            loading,
          },
        )
      }
    </Formik>
  );
};
