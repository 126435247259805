/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { aggregateUsersApi } from '../../effector/aggregateUsers';
import { modalApi } from '../../effector/modals';
import { userInfoApi } from '../../effector/userInfoList';
import { Button, ButtonType } from '../UIKit/Button';

export const RemoveUser: React.FC<{ id: number; userName: string }> = ({ id, userName }) => {
  const [error, setError] = useState('');
  const [remove, setRemove] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const onRemove = () => {
    setRemove(true);

    aggregateUsersApi
      .remove(id)
      .then((result: any) => {
        if (result.errorMessage) setError(result.errorMessage);
        else {
          userInfoApi.remove(id);
          modalApi.hide('');
          enqueueSnackbar('Пользователь удален');
        }
      })
      .catch((e) => setError(e.toString()))
      .finally(() => {
        setRemove(false);
      });
  };

  return (
    <div className="strategy-change">
      <div className="strategy-change__description">
        После удаления пользователь больше не сможет авторизоваться
        <br />
        в expert.fintarget.ru со своим логином и паролем.
        <br />
        Вы уверены что хотите удалить пользователя {userName}?
      </div>

      {error && <p className="error-message">{error}</p>}
      <div className="strategy-change__button-wrapper">
        <Button className="button__primary button__large" onClick={onRemove} disabled={remove} type={ButtonType.button}>
          Удалить
        </Button>
      </div>
    </div>
  );
};
