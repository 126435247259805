/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getSecurityCS } from '../api';
import { SecurityCS } from '../types/securityinfo';

type TSecurityCS$State = SecurityCS;

const initialState: TSecurityCS$State = {
  securityCS: {
    html: '',
  },
};

export const SecurityCS$ = createStore<TSecurityCS$State>(initialState);

const { set } = createApi(SecurityCS$, {
  // @ts-ignore
  set: (state, { result }) => {
    const updatedState = {
      ...state,
      securityCS: {
        ...result.result,
      },
    };
    return updatedState;
  },
});

export const securityCSApi = {
  get: createEffect<string, { result: TSecurityCS$State }, {}>().use(getSecurityCS),
};

forward({
  from: securityCSApi.get.done,
  to: set,
});
