/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { search, getPositionInfo } from '../api';
import { Search, SearchPosition } from '../api/types/search';
import { Suggestion, SuggestionDetails } from '../types/suggestion';
import { Nullable } from '../utils/types';

type Suggestions = Record<string, Suggestion[]>;
type TSearch$State = {
  suggestions: Nullable<Suggestions>;
  details: Nullable<SuggestionDetails>;
};

const initialState: TSearch$State = {
  suggestions: null,
  details: null,
};

export const Search$ = createStore<TSearch$State>(initialState);

const { setSuggestions, setDetails, clearSuggestion, clearDetails } = createApi(Search$, {
  // @ts-ignore
  setSuggestions: (state, { result, params }) => {
    return {
      ...state,
      suggestions: {
        [`${params.pattern}`]: result.result,
      },
    };
  },
  // @ts-ignore
  setDetails: (state, { result }) => {
    return { ...state, details: result.result };
  },
  // @ts-ignore
  clearSuggestion: (state) => ({ ...state, suggestions: null }),
  // @ts-ignore
  clearDetails: (state) => ({ ...state, details: null }),
});

export const searchApi = {
  get: createEffect<Search, {}, {}>().use(search),
  getPosition: createEffect<SearchPosition, {}, {}>().use(getPositionInfo),
  clearSuggestion: createEffect(),
  clearDetails: createEffect(),
};

forward({
  from: searchApi.get.done,
  to: setSuggestions,
});

forward({
  from: searchApi.getPosition.done,
  to: setDetails,
});

forward({
  from: searchApi.clearSuggestion,
  to: clearSuggestion,
});

forward({
  from: searchApi.clearDetails,
  to: clearDetails,
});
