/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createEffect, createApi, forward } from 'effector';

import { getStrategyInfoRefs } from '../api/index';
import { StrategyInfoHelper } from '../types/aggregateStrategyInfoRef';

export type Helper$State = {
  size: number;
  data: StrategyInfoHelper;
};

const initial: Helper$State = {
  size: 0,
  data: {
    durations: [],
    indexes: [],
    markets: [],
    marketTools: [],
    profiles: [],
    boards: [],
    tests: [],
    tradeTypes: [],
  },
};

export const strategyInfoRef$ = createStore<Helper$State>(initial);

const api = createApi(strategyInfoRef$, {
  // @ts-ignore
  set: (state, e) => {
    if (e.result && e.result.success) {
      const data: Helper$State = {
        size: Object.keys(e.result.result).length,
        data: e.result.result,
      };
      return data;
    }

    return state;
  },
});

export const strategyInfoApi = {
  get: createEffect<number, {}, {}>().use(getStrategyInfoRefs),
};

forward({
  from: strategyInfoApi.get.done,
  to: api.set,
});
