import React from 'react';

import { modalApi } from '../../effector/modals';
import { Button, ButtonType } from '../UIKit/Button';

export const SignalApproved: React.FC = () => (
  <div className="remove-delayed-signal">
    <div className="remove-delayed-signal__description">Ваш сигнал успешно принят!</div>
    <div className="remove-delayed-signal__button-wrapper">
      <Button className="button__primary button__large" onClick={() => modalApi.hide('')} type={ButtonType.button}>
        Закрыть
      </Button>
    </div>
  </div>
);
