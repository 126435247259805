/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useCallback, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { getAuditOrder } from '../../../api';
import { DatePicker } from '../../../components/FormControls/DatePicker';
import { Input } from '../../../components/FormControls/Input';
import { Select } from '../../../components/FormControls/Select';
import { Search } from '../../../components/Search';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { Search$, searchApi } from '../../../effector/search';
import { StrategyDictionary$ } from '../../../effector/strategyDictionary';
import { Option } from '../../../types/option';

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  agreement: Yup.string(),
  strategy: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
  }),
  order: Yup.string(),
  startDate: Yup.string(),
  endDate: Yup.string(),
  iir: Yup.string(),
});

type FormFields = {
  name?: string;
  agreement?: string;
  strategy?: Option;
  order?: string;
  startDate?: Date;
  endDate?: Date;
  iir?: string;
};

type FormProps = {
  isLoading: boolean;
  onSearchClick: (value: string) => void;
} & FormikProps<FormFields>;

const initialValues = {
  name: '',
  agreement: '',
  strategy: undefined,
  order: '',
  startDate: '',
  endDate: '',
  iir: '',
};

const FormTemplate: React.FC<FormProps> = ({ values, submitForm, resetForm, isLoading, onSearchClick }) => {
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const strategies = useStore(StrategyDictionary$);

  const forceClearSearch = useCallback(() => {
    setClearSearch(true);
    setTimeout(() => setClearSearch(false), 300);
  }, [setClearSearch]);

  const handleResetForm = useCallback(() => {
    resetForm({});
    onSearchClick('');
    forceClearSearch();
  }, []);

  return (
    <Form className="form" noValidate>
      <div className="form-control-group">
        <div className="form-control-group__item">
          <Field name="name" component={Input} placeholder="ФИО" />
        </div>
        <div className="form-control-group__item">
          <Field name="agreement" component={Input} placeholder="Г/С" />
        </div>
      </div>
      <div className="form-control-group">
        <div className="form-control-group__item">
          <Field name="strategy" component={Select} placeholder="Стратегия" options={strategies} />
        </div>
        <div className="form-control-group__item">
          <Search forceClear={clearSearch} onSearchClick={onSearchClick} />
        </div>
      </div>
      <div className="form-control-group">
        <div className="form-control-group__item">
          <Field
            name="startDate"
            component={DatePicker}
            placeholder="Дата начала"
            dateFormat="dd.MM.yyyy"
            selectsStart
            startDate={values.startDate}
            endDate={values.endDate}
          />
        </div>
        <div className="form-control-group__item">
          <Field
            name="endDate"
            component={DatePicker}
            placeholder="Дата окончания"
            dateFormat="dd.MM.yyyy"
            selectsEnd
            startDate={values.endDate}
            endDate={values.endDate}
            minDate={values.startDate}
            maxDate={moment().toDate()}
          />
        </div>
      </div>
      <div className="form-control-group">
        <div className="form-control-group__item">
          <Field name="iir" component={Input} placeholder="Номер ИИР" />
        </div>
        <div className="form-control-group__item">
          <Field name="order" component={Input} placeholder="Номер поручения" />
        </div>
      </div>
      <div className="table-filter__actions">
        <Button
          className="button__primary button__large button__margin-right"
          disabled={isLoading}
          onClick={submitForm}
        >
          Сформировать
        </Button>

        <Button
          className="button__primary button__large"
          disabled={isLoading}
          onClick={handleResetForm}
          type={ButtonType.reset}
        >
          Сбросить
        </Button>
      </div>
    </Form>
  );
};

export const Order: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tool, setTool] = useState<string | null>(null);
  const searchStore = useStore(Search$);

  const onSearchClick = useCallback((securityKey: string) => {
    setTool(securityKey);
    // @ts-ignore
    searchApi.clearSuggestion();
  }, []);

  const onSubmit = useCallback(
    async (values: FormFields) => {
      const [clientFirstName, clientLastName, clientMiddleName] = values?.name?.split(' ') || [null, null, null];
      const params = {
        clientFirstName: clientFirstName || null,
        clientLastName: clientLastName || null,
        clientMiddleName: clientMiddleName || null,
        agreementNumber: values.agreement || null,
        strategyName: values.strategy?.name || null,
        orderNumber: values.order || null,
        iirNumber: values.iir || null,
        iirStartDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD HH:mm:ss') : null,
        iirEndDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD HH:mm:ss') : null,
        securityKey: tool || null,
      };

      try {
        setIsLoading(true);
        await getAuditOrder(params);
      } catch {
      } finally {
        setIsLoading(false);
      }
    },
    [searchStore, tool],
  );

  return (
    <div className="order-page">
      <div className="table-filter">
        <div className="table-filter__title">Фильтры</div>
        <Formik
          // @ts-ignore
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) =>
            React.createElement(
              FormTemplate,
              // @ts-ignore
              {
                ...props,
                isLoading,
                onSearchClick,
              },
            )
          }
        </Formik>
      </div>
    </div>
  );
};
