/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { stopClientService, getCsDiag } from '../../../api/index';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { modalApi } from '../../../effector/modals';
import { MODALS } from '../../../utils/types';

export const Services: React.FC = () => {
  const [diagInfo, setDiagInfo] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const onStop = () => {
    const data = {
      action: () => stopClientService(),
      description: 'Остановить сервис клиентов?',
    };

    modalApi.show({ modalId: MODALS.SERVICE_STOP_CS, data: data });
  };

  const onDiag = () => {
    setError(undefined);
    setLoading(true);

    getCsDiag()
      .then((result: { result: any; success: boolean; errorMessage: string }) => {
        if (result.success) {
          setDiagInfo(JSON.stringify(result.result));
        } else {
          setError(result.errorMessage ?? '');
        }
      })
      .catch((e) => setError(e.toString()))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="table-filter">
        <div className="table-filter__title">Сервис клиентов</div>

        <div className="form__group">
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onStop}
          >
            Остановить
          </Button>
        </div>
        <div className="form__group">
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            disabled={loading}
            onClick={onDiag}
          >
            Диагностика
          </Button>
        </div>
        <div className="form__group">
          {loading && <p>Загрузка диагностической информации...</p>}
          {error ? <p>Ошибка:{error}</p> : !loading && diagInfo && <div className="form__group">{diagInfo}</div>}
        </div>
      </div>

      <div className="table-filter">
        <div className="table-filter__title">Сервис внешних данных</div>
        <div className="form__group">
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}}
          >
            Остановить
          </Button>
        </div>
      </div>
    </>
  );
};
