/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getSecurityMarketData } from '../api';
import { SecurityMarketData } from '../types/securityinfo';

type TSecurityMarketData$State = SecurityMarketData;

const initialState: TSecurityMarketData$State = {
  securityMarketData: {
    html: '',
  },
};

export const SecurityMarketData$ = createStore<TSecurityMarketData$State>(initialState);

const { set } = createApi(SecurityMarketData$, {
  // @ts-ignore
  set: (state, { result }) => {
    const updatedState = {
      ...state,
      securityMarketData: {
        ...result.result,
      },
    };
    return updatedState;
  },
});

export const securityMarketDataApi = {
  get: createEffect<string, { result: TSecurityMarketData$State }, {}>().use(getSecurityMarketData),
};

forward({
  from: securityMarketDataApi.get.done,
  to: set,
});
