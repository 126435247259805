import React from 'react';

import { CheckLoginSmsForm } from '../../components/Forms/CheckLoginSms';
import { Modal } from '../../components/Modal';

export const CheckLoginSms: React.FC = () => (
  <Modal
    isOpen={true}
    onRequestClose={() => {
      console.log('close');
    }}
    title="Войти"
  >
    <CheckLoginSmsForm />
  </Modal>
);
