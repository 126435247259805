/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { recalcModes } from '../../const/strategyRefs';
import { ERRORS_TEXTS } from '../../const/validation';
import { aggregateStrategiesApi } from '../../effector/aggregateStrategies';
import { strategyInfoRef$ } from '../../effector/aggregateStrategyInfoRefs';
import { currencyInfo$ } from '../../effector/currencies';
import { modalApi } from '../../effector/modals';
import { Strategies$ } from '../../effector/strategies';
import { User$ } from '../../effector/user';
import { userInfo } from '../../effector/userInfoList';
import { RecalcMode, AggregateStrategy } from '../../types/aggregateStrategy';
import { Currency, StrategyDuration } from '../../types/aggregateStrategyInfoRef';
import { UserType } from '../../types/aggregateUser';
import { Option } from '../../types/option';
import { Profile } from '../../types/profile';
import { Checkbox } from '../FormControls/Checkbox';
import { Input } from '../FormControls/Input';
import { Select } from '../FormControls/Select';
import { TextArea } from '../FormControls/TextArea';
import { Button, ButtonType } from '../UIKit/Button';

type FormFields = {
  /**
   * Описание
   */
  title: string;
  author: Option;
  managerIds: Option[];
  isBlogger: boolean;
  welcomeLetter: string;
  subscription: string;
  /**
   * Характеристики
   */
  profile: Profile | undefined;
  currency: Currency;
  minSumma: number;
  recommendedAmount: number;
  capacity?: number;
  estimatedProfit?: number;
  estimatedTradesPerWeek?: number;
  index?: Option; // fintarget.index
  period: StrategyDuration; // Срок

  /**
   * Параметры
   */
  maxPosWeight: number; // % Максимальная доля инструмента
  maxSignalWeight: number;
  leverage: number;
  recalcMode: { name: string; id: RecalcMode };
  hasParent: boolean;
  parent: Option;
};

type FormProps = {
  error?: string;
  send: boolean;
  isUpdate: boolean;
} & FormikProps<FormFields>;

const initialValues: Partial<FormFields> = {
  title: '',
  managerIds: [],
  isBlogger: false,
  welcomeLetter: '',
  subscription: '',
  minSumma: 1000,
  capacity: undefined,
  estimatedProfit: undefined,
  estimatedTradesPerWeek: undefined,
  maxPosWeight: 50,
  maxSignalWeight: 50,
  leverage: 0,
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ERRORS_TEXTS.required),
  author: Yup.object().required(ERRORS_TEXTS.required),
  managerIds: Yup.array(),
  welcomeletter: Yup.string().default(''),
  profile: Yup.object().nullable().default(null),
  currency: Yup.object().required(ERRORS_TEXTS.required),
  minSumma: Yup.number().positive().required(ERRORS_TEXTS.positive),
  recommendedAmount: Yup.number().positive(ERRORS_TEXTS.positive).nullable(),
  capacity: Yup.number().positive(ERRORS_TEXTS.positive).nullable().default(null),
  estimatedProfit: Yup.number().positive(ERRORS_TEXTS.positive).nullable().default(null),
  estimatedTradesPerWeek: Yup.number().positive(ERRORS_TEXTS.positive).nullable().default(null),
  index: Yup.object().required(ERRORS_TEXTS.required),
  period: Yup.object().required(ERRORS_TEXTS.required),
  maxPosWeight: Yup.number().positive(ERRORS_TEXTS.positive),
  maxSignalWeight: Yup.number().positive(ERRORS_TEXTS.positive).nullable().default(null),
  leverage: Yup.number().min(0, ERRORS_TEXTS.positive),
  recalcMode: Yup.object().required(ERRORS_TEXTS.required),
  hasParent: Yup.boolean(),
  parent: Yup.object().when('hasParent', {
    is: true,
    then: Yup.object().required(ERRORS_TEXTS.required),
  }),
});

const FormTemplate: React.FC<FormProps> = (props) => {
  const disabled = Object.keys(props.errors).length > 0 || !props.dirty || props.send;

  const [hasParent, setParent] = useState(props.values.hasParent);

  const userInfoStore = useStore(userInfo);
  const strategiesStore = useStore(Strategies$);
  const infoStore = useStore(strategyInfoRef$);
  const currencyStore = useStore(currencyInfo$);

  const users =
    userInfoStore.total > 0
      ? userInfoStore.users
          .filter((u) => u.userType !== UserType.Representative)
          .map((u) => {
            return { id: u.id, name: u.displayName };
          })
      : [];

  const durations = infoStore.data.durations;

  const parentStrategies = strategiesStore
    .filter((s) => s.parentStrategy === null)
    .map((s) => ({ id: s.id, name: s.name }));

  return (
    <Form className="form" noValidate>
      <div>
        <div className="form__group">
          <p>Описание</p>
        </div>
        <div className="form__group">
          <Field name="title" type="text" placeholder="Название" component={Input} />
        </div>
        <div className="form__group">
          <Field name="author" placeholder="Автор" options={users} component={Select} />
        </div>
        <div className="form__group">
          <Field name="managerIds" placeholder="Управляющие" options={users} multiple={true} component={Select} />
        </div>
        <div className="form__group">
          <Field name="isBlogger" text="Стратегия блогера" component={Checkbox} />
        </div>
        <div className="form__group">
          <Field name="welcomeLetter" type="text" placeholder="Текст для Welcome letter" component={TextArea} />
        </div>
        <div className="form__group">
          <Field name="subscription" type="text" placeholder="Подпись под графиком" component={Input} />
        </div>

        <div className="form__group">
          <p>Характеристики</p>
        </div>
        <div className="form__group">
          <Field
            name="profile"
            placeholder="Инвестпрофиль"
            options={infoStore.data.profiles}
            isClearable={true}
            component={Select}
          />
        </div>
        <div className="form__group">
          <Field name="currency" placeholder="Валюта" options={currencyStore} component={Select} />
        </div>
        <div className="form__group">
          <Field type="number" name="minSumma" placeholder="Минимальная сумма" component={Input} />
        </div>
        <div className="form__group">
          <Field type="number" name="recommendedAmount" placeholder="Рекомендованная сумма" component={Input} />
        </div>
        <div className="form__group">
          <Field type="number" name="capacity" placeholder="Ёмкость" component={Input} />
        </div>
        <div className="form__group">
          <Field type="number" name="estimatedProfit" placeholder="Прогнозная доходность" component={Input} />
        </div>
        <div className="form__group">
          <Field
            type="number"
            name="estimatedTradesPerWeek"
            placeholder="Прогнозная частота сделок"
            component={Input}
          />
        </div>
        <div className="form__group">
          <Field name="index" placeholder="Бенчмарк" options={infoStore.data.indexes} component={Select} />
        </div>
        <div className="form__group">
          <Field name="period" placeholder="Срок" options={durations} component={Select} />
        </div>

        <div className="form__group">
          <p>Параметры</p>
        </div>
        <div className="form__group">
          <Field name="maxPosWeight" type="number" placeholder="Максимальная доля инструмента, %" component={Input} />
        </div>
        <div className="form__group">
          <Field
            name="maxSignalWeight"
            type="number"
            placeholder="Максимальная доля инструмента в сигнале, %"
            component={Input}
          />
        </div>
        <div className="form__group">
          <Field name="leverage" type="number" placeholder="Используемое плечо" component={Input} />
        </div>
        <div className="form__group">
          <Field name="recalcMode" placeholder="Пересчет весов" options={recalcModes} component={Select} />
        </div>
        <div className="form__group">
          <div>
            <Field name="hasParent" text="Дочерняя" onChange={(v: boolean) => setParent(v)} component={Checkbox} />
          </div>
          <div>
            <Field
              name="parent"
              placeholder="Родительская стратегия"
              disabled={!hasParent}
              options={parentStrategies}
              component={Select}
            />
          </div>
        </div>

        <div className="button__large">
          <Button className="button__primary button__large" type={ButtonType.submit} disabled={disabled}>
            Добавить
          </Button>
        </div>

        {props.error && <p className="error-message">{props.error}</p>}
      </div>
    </Form>
  );
};

export const AddStrategyForm: React.FC = () => {
  const initError: string | undefined = undefined; // #fixme переделать на partial

  const [error, setError] = useState(initError);

  const { enqueueSnackbar } = useSnackbar();

  //@ts-ignore
  const userStore = useStore(User$);

  const init = () => {
    return initialValues;
  };

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      // @ts-ignore
      modalApi.hide('');
      enqueueSnackbar('Стратегия добавлена');
    }
  };

  const onSubmit = (values: FormFields) => {
    const data: Partial<AggregateStrategy> = {
      name: values.title,
      managerId: Number(values.author.id),
      managerName: values.author.name,
      managerIds: values.managerIds?.map(({ id: managerId }) => Number(managerId)) || [],
      creatorId: userStore !== undefined && userStore !== null ? userStore.id : 0,
      creationDate: new Date().toDateString(),
      goal: values.welcomeLetter,
      chartComment: values.subscription,
      currency: values.currency.code,
      subscriptionThreshold: values.minSumma,
      recommendedAmount: values.recommendedAmount,
      capacity: values.capacity,
      estimatedProfit: values.estimatedProfit,
      estimatedTradesPerWeek: values.estimatedTradesPerWeek,
      indexId: values.index === undefined ? undefined : Number(values.index.id),
      durationId: values.period.id,
      maxInstrumentWeight: values.maxPosWeight,
      maxSignalWeight: values.maxSignalWeight,
      leverage: values.leverage,
      recalcMode: values.recalcMode.id,
      parentId: values.hasParent ? values.parent.id : undefined,
      securities: '[]',
      active: true,
      rating: 0,
      estimatedDrawdown: 0, // obsolete
      actualProfit: 0, // calculated
      maxIndustryWeight: 0, // not used
      maxPositionWeight: values.maxPosWeight,
      minInvestProfileId: values.profile == undefined ? undefined : (values.profile as Profile).id,
      open: false,
      order: 1000, // #fixme
      price: '',
      showFullPortfolio: false,
      tradesFrequency: '',
      isRestricted: false,
      isBlogger: values.isBlogger,
    };

    const result = aggregateStrategiesApi.add(data);

    result
      .then(errorHandler)
      //.then(() => { enqueueSnackbar('Стратегия добавлена'); })
      .catch(errorHandler);
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={init()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        React.createElement(FormTemplate, {
          ...props,
          error,
        })
      }
    </Formik>
  );
};
