/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getUserRoles } from '../api';
import { Option } from '../types/option';
import { UserRoles } from '../types/userRoles';
import { roleToText } from '../utils';
import { Nullable } from '../utils/types';

type RoleOption = { role: UserRoles } & Option;
export type TUserRoles$State = Nullable<RoleOption[]>;

export const UserRoles$ = createStore<TUserRoles$State>(null);

const { set } = createApi(UserRoles$, {
  // @ts-ignore
  set: (state, { result }) => {
    if (result.success) {
      //@ts-ignore
      const roles = result.result.map((item, index) => ({
        id: index.toString(),
        name: roleToText(item),
        role: item as UserRoles,
      }));

      return roles;
    }

    return [];
  },
});

export const userRolesApi = {
  get: createEffect().use(getUserRoles),
};

forward({
  from: userRolesApi.get.done,
  to: set,
});
