/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';

import Highcharts, { Options } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { HIGHCHART_SETTINGS } from '../../../const';
import { ChartPoint } from '../../../types/chart';

Highcharts.setOptions(HIGHCHART_SETTINGS);

type Point = ChartPoint;

type Props = {
  data: Point[];
  legends?: {
    v?: string;
    vi?: string;
    vc?: string;
    vr?: string;
  };
};

export const LineChart: React.FC<Props> = ({ data, legends }) => {
  const CHART_STYLES = useMemo(() => {
    return {
      HISTORY: {
        type: 'line',
        name: legends?.v || 'доходность по сигналам',
        color: '#5ECBA1',
        threshold: null,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      HISTORY_COMMISSION: {
        type: 'line',
        name: legends?.vc || 'доходность с учетом издержек *',
        color: '#FF6B69',
        threshold: null,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      HISTORY_EXECUTION: {
        type: 'line',
        name: legends?.vr || 'доходность без издержек',
        color: '#066b8a',
        threshold: null,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      BENCHMARK: {
        type: 'line',
        name: legends?.vi || 'бенчмарк',
        color: '#9598a7',
        threshold: null,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    };
  }, [legends]);

  type Rep = <T, P extends keyof T>(data: T[], prop: T[P]) => T[];

  const representData: Rep = (data, prop) =>
    data.reduce((array, curPoint) => {
      const propValue =
        // @ts-ignore
        typeof curPoint[prop] === 'object' ? curPoint[prop][0] : curPoint[prop];

      // @ts-ignore
      const newPoint = [curPoint.t, propValue];
      // @ts-ignore
      return array.concat([newPoint]);
    }, []);

  // @ts-ignore
  const fillData = (data, options) => {
    if (!data) {
      return null;
    }

    return {
      ...options,
      data,
    };
  };

  const firstHistoryPoint = data && data[0];

  // @ts-ignore
  const points = firstHistoryPoint?.v != null ? representData(data, 'v') : null;
  // @ts-ignore
  const pointsWithCommissions = firstHistoryPoint?.vc != null ? representData(data, 'vc') : null;
  // @ts-ignore
  const pointsExecutions = firstHistoryPoint?.vr != null ? representData(data, 'vr') : null;
  // @ts-ignore
  const pointsIndex = firstHistoryPoint?.vi != null ? representData(data, 'vi') : null;
  // @ts-ignore

  const chartData = fillData(points, CHART_STYLES.HISTORY);
  const chartDataCommissions = fillData(pointsWithCommissions, CHART_STYLES.HISTORY_COMMISSION);
  const chartDataExecutions = fillData(pointsExecutions, CHART_STYLES.HISTORY_EXECUTION);
  const chartDataIndex = fillData(pointsIndex, CHART_STYLES.BENCHMARK);

  const series = [chartData, chartDataExecutions, chartDataCommissions, chartDataIndex].filter((item) => item !== null);

  const options: Options = {
    chart: {
      style: {
        fontFamily: 'Gilroy',
        color: '#9598A7',
      },
    },
    xAxis: {
      type: 'datetime',
      crosshair: {
        color: '#EBE9EA',
      },
      gridLineWidth: 1,
      gridLineColor: '#EBE9EA',
      labels: {
        style: {
          color: '#9598A7',
        },
        format: '{value:%d.%m.%Y}',
      },
      lineColor: '#EBE9EA',
      tickLength: 0,
      tickWidth: 0,
    },
    yAxis: {
      gridLineColor: '#EBE9EA',
      labels: {
        style: {
          color: '#9598A7',
        },
      },
      title: {
        text: null,
      },
      lineColor: '#EBE9EA',
      lineWidth: 1,
      opposite: true,
    },
    credits: {
      enabled: false,
    },
    legend: {
      align: 'left',
      verticalAlign: 'bottom',
      x: 0,
      y: 0,
    },
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: 2,
      valueDecimals: 2,
      useHTML: true,
      split: false,
      shared: true,
      formatter: function () {
        // @ts-ignore
        const date = Highcharts.dateFormat('%a, %e %B %Y', new Date(this.x));
        // @ts-ignore
        const seriesData = this.points.reduce(
          // @ts-ignore
          (array, point) =>
            // @ts-ignore
            array.concat([
              // @ts-ignore
              {
                name: point.series.name,
                value: point.y,
                color: point.color,
              },
            ]),
          [],
        );

        let data = '';

        seriesData.forEach(
          // @ts-ignore
          (item) => {
            // @ts-ignore
            const color = `color:${item.color};`;
            // @ts-ignore
            const name = item.name;
            // @ts-ignore
            const value = item.value;

            data += `<span class='__point-dot' style=${color}>
                                        \u25CF
                                      </span>
                                      <span class='chart-tooltip__series'>
                                        ${name}:
                                        <b class='chart-tooltip__series-value'>
                                          ${Highcharts.numberFormat(value, 2)}%
                                        </b>
                                      </span>
                                      <br />`;
          },
        );

        return `<span className='chart-tooltip'>${date}</span><br />${data}<br />`;
      },
    },
    series: series,
  };

  return (
    <div className="line-chart">
      <HighchartsReact options={options} highcharts={Highcharts} />
      <div className="line-chart__description">
        {chartDataCommissions &&
          '* доходность с учетом средней цены исполнения сигналов и стоимости услуги Автоследование'}
      </div>
    </div>
  );
};
