import { useEffect, useRef, RefObject } from 'react';

import { useFormikContext } from 'formik';

type FocusProp = {
  fieldRef: RefObject<HTMLElement | undefined>;
  name: string;
};

type FocusContext = {
  errors: object;
  isValid: boolean;
  submitCount: number;
};

type FocusOnError = (arg: FocusProp) => void;

export const useFocusOnError: FocusOnError = ({ fieldRef, name }) => {
  const context = useFormikContext<FocusContext>();
  const submitCountRef = useRef(context.submitCount);
  const firstErrorKey = Object.keys(context.errors)[0];

  useEffect(() => {
    if (submitCountRef.current !== context.submitCount && !context.isValid) {
      if (fieldRef && fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    submitCountRef.current = context.submitCount;
  }, [context.submitCount, context.isValid, firstErrorKey]);
};
