/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { delayedSignalsApi } from './delayedSignal';
import { positionsApi } from './positions';
import {
  newMultipleMarketSignal,
  newMarketSignal,
  newDelayedSignal,
  changeSignal,
  newMarketSignalSandbox,
  newDelayedSignalSandbox,
  canSendSignal,
} from '../api';
import { DelayedSignal } from '../types/delayedSignal';
import { Position } from '../types/position';
import { Nullable } from '../utils/types';

type TSignals$State = {
  delay: Nullable<number>;
};

const initialState: TSignals$State = {
  delay: null,
};

export const Signals$ = createStore(initialState);

const { set, setMultiple, setDelay, update } = createApi(Signals$, {
  // @ts-ignore
  set: (state, { result, params }) => {
    if (result?.success) {
      if (params?.execQuotation) {
        delayedSignalsApi.add(result.result);
      } else {
        positionsApi.add(result.result);
      }
    }

    return state;
  },
  // @ts-ignore
  setMultiple: (state, { result }) => {
    if (result?.success) {
    }
    return state;
  },
  // @ts-ignore
  update: (state, { result }) => {
    if (result?.success) {
      positionsApi.update(result.result);
    }
    return state;
  },
  // @ts-ignore
  setDelay: (state, { result }) => {
    if (result?.success) {
      return { delay: result.result.secondsLeft };
    }
    return state;
  },
});

export const signalsApi = {
  newMarket: createEffect<Partial<Position>, {}, {}>().use(newMarketSignal),
  newMarketSandbox: createEffect<Partial<Position>, {}, {}>().use(newMarketSignalSandbox),
  newDelayed: createEffect<Partial<DelayedSignal>, {}, {}>().use(newDelayedSignal),
  newMultipleMarketSignal: createEffect<Partial<Position>[], {}, {}>().use(newMultipleMarketSignal),
  newDelayedSandbox: createEffect<Partial<DelayedSignal>, {}, {}>().use(newDelayedSignalSandbox),
  changeSignal: createEffect<Partial<Position>, {}, {}>().use(changeSignal),
  checkSignal: createEffect<string, {}, {}>(canSendSignal),
};

forward({
  from: signalsApi.newMarket.done,
  to: set,
});

forward({
  from: signalsApi.newMarketSandbox.done,
  to: set,
});

forward({
  from: signalsApi.newDelayed.done,
  to: set,
});

forward({
  from: signalsApi.newDelayedSandbox.done,
  to: set,
});

forward({
  from: signalsApi.newMultipleMarketSignal.done,
  to: setMultiple,
});

forward({
  from: signalsApi.changeSignal.done,
  to: update,
});

forward({
  from: signalsApi.checkSignal.done,
  to: setDelay,
});
