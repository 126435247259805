/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getUserInfo } from '../api';
import { UserType } from '../types/aggregateUser';
import { Nullable } from '../utils/types';

type UserInfo = {
  id: number;
  userType: UserType;
  login: string;
  firstName: string;
  lastName: string;
  orgName: Nullable<string>;
  displayName: string;
  agreementNum: string;
};

type UserInfo$State = {
  total: number;
  users: UserInfo[];
};

export const userInfo = createStore<UserInfo$State>({
  total: 0,
  users: [],
});

const api = createApi(userInfo, {
  // @ts-ignore
  set: (state, e) => {
    const data: UserInfo$State = {
      total: e.result.result.length,
      users: e.result.result,
    };
    return data;
  },
  // @ts-ignore
  add: (state, { result }) => {
    if (result && result.success) {
      const user = result.result as {
        id: number;
        userType: Nullable<UserType>;
        login: string;
        firstName: string;
        lastName: string;
        orgName: string;
        fullName: string;
        agreement: string;
      };

      if (user.id && user.userType !== null && user.userType !== undefined) {
        // @ts-ignore
        const updated = state.users.filter((u) => u.id !== user.id);

        const info: UserInfo = {
          id: user.id,
          userType: user.userType,
          login: user.login,
          displayName: user.fullName,
          firstName: user.firstName,
          lastName: user.lastName,
          orgName: user.orgName,
          agreementNum: user.agreement,
        };

        return {
          ...state,
          users: [...updated, info],
        };
      }
    }

    return state;
  },
  // @ts-ignore
  remove: (state, id: number) => {
    // @ts-ignore
    const updated = state.users.filter((u) => u.id !== id);

    return {
      total: updated.length,
      users: updated,
    };
  },
});

export const userInfoApi = {
  get: createEffect<number, {}, {}>().use(getUserInfo),
  add: createEffect<any, {}, {}>(),
  remove: createEffect<number, {}, {}>(),
};

forward({
  from: userInfoApi.get.done,
  to: api.set,
});

forward({
  from: userInfoApi.add,
  to: api.add,
});

forward({
  from: userInfoApi.remove,
  to: api.remove,
});
