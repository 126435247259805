import React from 'react';

import { useStore } from 'effector-react';

import { RemoveUser } from './RemoveUser';
import { RemoveUserInfo } from './RemoveUserInfo';
import { Modals$, TModal$State } from '../../effector/modals';
import { Strategies$ } from '../../effector/strategies';
import { UserType } from '../../types/aggregateUser';

export const RemoveUserForm: React.FC = () => {
  const modalsState = useStore<TModal$State<{ id: number; userName: string; userType: UserType }>>(Modals$);
  const strategiesStore = useStore(Strategies$);
  const user = modalsState.data;

  const strategy =
    user && user.userType !== UserType.Representative
      ? strategiesStore.find((s) => s.managerId === user.id)
      : undefined;

  return user && strategy ? (
    <RemoveUserInfo id={user.id} userName={user.userName} />
  ) : (
    <RemoveUser id={user?.id ?? 0} userName={user?.userName ?? ''} />
  );
};
