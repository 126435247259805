export const HIGHCHART_SETTINGS = {
  lang: {
    contextButtonTitle: 'Вызвать контекстное меню',
    decimalPoint: '.',
    downloadJPEG: 'Скачать JPEG картинку',
    downloadPDF: 'Скачать PDF документ',
    downloadPNG: 'Скачать PNG картинку',
    downloadSVG: 'Скачать SVG картинку',
    drillUpText: 'Назад в {series.name}',
    invalidDate: '',
    rangeSelectorZoom: '',
    loading: 'Загрузка ...',
    months: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
    noData: 'Данные для показа отсутствуют',
    numericSymbols: ['К', 'М', 'Г', 'Т', 'П', 'Э'],
    printChart: 'Распечатать график',
    resetZoom: 'Сбросить увеличение',
    resetZoomTitle: 'Сбросить увеличение level 1:1',
    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    shortWeekdays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    thousandsSep: ' ',
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  },
};
