/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getSecurityBidAsk } from '../api';
import { SecurityBidAsk } from '../types/securityinfo';

type TSecurityBidAsk$State = SecurityBidAsk;

const initialState: TSecurityBidAsk$State = {
  securityBidAsk: {
    bid: 0,
    ask: 0,
    lastPrice: 0,
    futuresPrice: 0,
    isOk: false,
    errorMessage: '',
    timeStamp: Date.now(),
  },
};

export const SecurityBidAsk$ = createStore<TSecurityBidAsk$State>(initialState);

const { set } = createApi(SecurityBidAsk$, {
  // @ts-ignore
  set: (state, { result }) => {
    const updatedState = {
      ...state,
      securityBidAsk: {
        ...result.result,
      },
    };
    return updatedState;
  },
});

export const securityBidAskApi = {
  get: createEffect<string, { result: TSecurityBidAsk$State }, {}>().use(getSecurityBidAsk),
};

forward({
  from: securityBidAskApi.get.done,
  to: set,
});
