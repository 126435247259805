import { FC, useRef } from 'react';

import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';

import { ItemTypes } from '.';

type Props = {
  id: string;
  text: string;
  index: number;
  columnId: 'category' | 'questions';
  disabled?: boolean;
  moveItem: (
    itemColumnId: 'category' | 'questions',
    itemIndex: number,
    columnId: 'category' | 'questions',
    hoverIndex: number,
  ) => void;
};

type DragItem = {
  type: ItemTypes.ITEM;
  id: string;
  index: number;
  columnId: 'category' | 'questions';
};

export const Draggable: FC<Props> = ({ id, text, index, columnId, moveItem, disabled = false }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: { type: ItemTypes.ITEM, id, index, columnId },
    canDrag: !disabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(item: DragItem) {
      moveItem(item.columnId, item.index, columnId, index);
      item.index = index;
      item.columnId = columnId;
    },
  });

  drag(drop(ref));

  return (
    <div ref={ref} className={classNames('faq-mapping__item', { 'faq-mapping__item-disabled': disabled })}>
      {text}
    </div>
  );
};
