/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getStrategiesParams } from '../api';
import { StrategySummary } from '../types/strategySummary';
import { Nullable } from '../utils/types';

type TStrategiesParams$State = Nullable<StrategySummary[]>;

const initialState: TStrategiesParams$State = null;

export const StrategiesParams$ = createStore<TStrategiesParams$State>(initialState);

const { set } = createApi(StrategiesParams$, {
  // @ts-ignore
  set: (state, { result }) => result.result,
});

export const strategiesParamsApi = {
  get: createEffect().use(getStrategiesParams),
};

forward({
  from: strategiesParamsApi.get.done,
  to: set,
});
