/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';

import { Button, ButtonType } from '../../components/UIKit/Button';
import { delayedSignalsApi } from '../../effector/delayedSignal';
import { Modals$, TModal$State } from '../../effector/modals';

export const RemoveDelayedSignal: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const modalsState = useStore<TModal$State<{ signalId: string }>>(Modals$);

  const signalId = modalsState.data?.signalId;

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    }
  };

  const cancelSignal = () => {
    setLoading(true);
    delayedSignalsApi
      .close(signalId)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="remove-delayed-signal">
      <div className="remove-delayed-signal__description">Вы уверены что хотите закрыть отложенную позицую?</div>
      {error && <p className="error-message">{error}</p>}
      <div className="remove-delayed-signal__button-wrapper">
        <Button
          className="button__primary button__large"
          onClick={cancelSignal}
          disabled={loading}
          type={ButtonType.button}
        >
          Закрыть
        </Button>
      </div>
    </div>
  );
};
