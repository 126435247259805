import React from 'react';

import { faExchangeAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Position } from '../../../../types/position';
import { RecalculationMode } from '../../../../types/strategy';
import { roundStringNumber } from '../../../../utils';
import { Nullable } from '../../../../utils/types';
import { Button } from '../../../UIKit/Button';

type Props = {
  positions: Position[];
  disabled: boolean;
  onSubmit: () => void;
  onEditPortfolioPositionSignal: (name: string) => void;
  onRemovePortfolioPositionSignal: (position: Position) => void;
  recalculationMode: Nullable<RecalculationMode>;
};

export const PositionList: React.FC<Props> = ({
  positions,
  disabled,
  onSubmit,
  onEditPortfolioPositionSignal,
  onRemovePortfolioPositionSignal,
  recalculationMode,
}) => {
  if (!positions.length) {
    return null;
  }

  return (
    <div className="position-list">
      <table>
        <thead>
          <tr>
            <th>Инструмент</th>
            <th>Доля %</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {positions.map((position, index) => (
            <tr key={index}>
              <td className="position-list__name">
                {position.name} ({position.symbol})
              </td>
              <td className="position-list__percent">
                {recalculationMode === RecalculationMode.WeightedAverageWithRecalculation ||
                recalculationMode === RecalculationMode.WeightedAverageWithoutRecalculation
                  ? roundStringNumber(position.weight)
                  : roundStringNumber(position.currentWeight)}
              </td>
              <td className="position-list__delete">
                <span className="pointer">
                  <FontAwesomeIcon icon={faTimes} onClick={() => onRemovePortfolioPositionSignal(position)} />
                </span>
              </td>
              <td className="position-list__change">
                <span className="pointer">
                  <FontAwesomeIcon
                    icon={faExchangeAlt}
                    className="fall"
                    onClick={() => onEditPortfolioPositionSignal(position.name)}
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ marginTop: '16px' }} className="form-signal__button-wrapper">
        <Button className="button__primary button__large" disabled={disabled} onClick={onSubmit}>
          Сохранить изменения
        </Button>
      </div>
    </div>
  );
};
