import React, { Fragment } from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';

import { ProtectedComponent } from '../../components/ProtectedComponent';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { codes$ } from '../../effector/classCodes';
import { forbidden$ } from '../../effector/forbidden';
import { modalApi } from '../../effector/modals';
import { schedule$ } from '../../effector/schedule';
import { TradeTime, ScheduleNames, SecurityInfo } from '../../types/marketSchedule';
import { UserAction } from '../../utils/permissions';
import { MODALS, Nullable } from '../../utils/types';

export const Schedule: React.FC = () => {
  const allTimes = useStore(schedule$);
  const allCodes = useStore(codes$);
  const allForbidden = useStore(forbidden$);
  const allMarkets = allCodes.map((c) => {
    return { id: c, name: c };
  });

  const onEdit = (
    id: number,
    name: ScheduleNames,
    markets: string[],
    forbiddenSecurities: SecurityInfo[],
    intervals: Nullable<TradeTime[]>,
  ) => {
    if (intervals) {
      modalApi.show({
        modalId: MODALS.SCHEDULE_EDIT,
        data: {
          id: id,
          title: name,
          markets: [...markets],
          forbiddenSecurities: forbiddenSecurities,
          availableMarkets: allMarkets,
          times: [...intervals],
        },
      });
    }
  };

  const onAdd = () => {
    modalApi.show({
      modalId: MODALS.SCHEDULE_ADD,
      data: {
        availableMarkets: allMarkets,
      },
    });
  };

  const onAddWeekend = (id: number) => {
    modalApi.show({
      modalId: MODALS.SCHEDULE_ADD_WEEKEND,
      data: {
        id: id,
      },
    });
  };

  const onRemove = (id: number, name: string) => {
    modalApi.show({
      modalId: MODALS.SCHEDULE_REMOVE,
      data: {
        id: id,
        name: name,
      },
    });
  };

  const onRemoveWeekend = (id: number, day: string) => {
    modalApi.show({
      modalId: MODALS.SCHEDULE_REMOVE_WEEKEND,
      data: {
        id: id,
        day: day,
      },
    });
  };

  if (!allTimes) {
    return (
      <div className="schedule">
        <p>Расписание недоступно.</p>
      </div>
    );
  }

  const data = allTimes.map((t, i) => {
    const name = `${t.name} (режимы торгов ${t.classCodes.join(', ')})`;

    return (
      <Fragment key={i}>
        <tr key="">
          <th className="schedule__header" colSpan={2}>
            <span>{name}</span>
          </th>
        </tr>
        <tr key="t">
          <th className="schedule__header2" colSpan={2}>
            <b>Рабочее время:</b>
          </th>
        </tr>
        {t.tradeTimes?.map((tt, ii) => (
          <tr key={'t' + ii.toString()}>
            <td>{`${tt.start} – ${tt.end}`}</td>
            <td>{tt.description ?? ''}</td>
          </tr>
        ))}
        <ProtectedComponent userAction={UserAction.scheduleEdit}>
          <tr key="te">
            <td colSpan={2}>
              <Button
                className="button button__primary button__small form__button-inline"
                type={ButtonType.button}
                onClick={() => onEdit(t.id, ScheduleNames.trade, t.classCodes, allForbidden, t.tradeTimes)}
              >
                Редактировать
              </Button>
            </td>
          </tr>
        </ProtectedComponent>
        <tr key="s">
          <th className="schedule__header2" colSpan={2}>
            <b>Принимаем сигналы от управляющего:</b>
          </th>
        </tr>
        {t.signalTimes?.map((tt, ii) => (
          <tr key={'s' + ii.toString()}>
            <td>{`${tt.start} – ${tt.end}`}</td>
            <td>{tt.description ?? ''}</td>
          </tr>
        ))}
        <ProtectedComponent userAction={UserAction.scheduleEdit}>
          <tr key="se">
            <td colSpan={2}>
              <Button
                className="button button__primary button__small form__button-inline"
                type={ButtonType.button}
                onClick={() => onEdit(t.id, ScheduleNames.signal, t.classCodes, allForbidden, t.signalTimes)}
              >
                Редактировать
              </Button>
            </td>
          </tr>
        </ProtectedComponent>
        <tr key="r">
          <th className="schedule__header2" colSpan={2}>
            <b>Время балансировки:</b>
          </th>
        </tr>
        {t.rebalanceTimes?.map((tt, ii) => (
          <tr key={'r' + ii.toString()}>
            <td>{`${tt.start} – ${tt.end}`}</td>
            <td>{tt.description ?? ''}</td>
          </tr>
        ))}
        <ProtectedComponent userAction={UserAction.scheduleEdit}>
          <tr key="e">
            <td colSpan={2}>
              <Button
                className="button button__primary button__small form__button-inline"
                type={ButtonType.button}
                onClick={() => onEdit(t.id, ScheduleNames.rebalance, t.classCodes, allForbidden, t.rebalanceTimes)}
              >
                Редактировать
              </Button>
              <Button
                className="button button__primary button__small form__button-inline"
                type={ButtonType.button}
                onClick={() => onRemove(t.id, t.name ?? '')}
              >
                Удалить
              </Button>
            </td>
          </tr>
        </ProtectedComponent>

        <tr key="w">
          <th className="schedule__header2" colSpan={2}>
            <b>Праздничные и иные неторговые дни:</b>
          </th>
        </tr>

        {t.weekends?.map((w, ii) => (
          <tr key={'w' + ii.toString()}>
            <td>
              <div className="schedule__header2">
                <b className="schedule__date">{w.day} года нерабочий</b>
              </div>
            </td>
            <ProtectedComponent userAction={UserAction.scheduleEdit}>
              <td>
                <Button
                  className="button button__primary button__small form__button-inline"
                  type={ButtonType.button}
                  onClick={() => onRemoveWeekend(w.id, w.day)}
                >
                  Удалить
                </Button>
              </td>
            </ProtectedComponent>
          </tr>
        ))}

        <ProtectedComponent userAction={UserAction.scheduleEdit}>
          <tr key="we">
            <td>
              <Button
                className="button button__primary button__small form__button-inline"
                type={ButtonType.button}
                onClick={() => onAddWeekend(t.id)}
              >
                Добавить
              </Button>
            </td>
          </tr>
        </ProtectedComponent>
      </Fragment>
    );
  });

  return (
    <>
      <ProtectedComponent userAction={UserAction.scheduleEdit}>
        <div className="form__group">
          <Button
            className="button button__primary button__small form__button-inline"
            type={ButtonType.button}
            onClick={onAdd}
          >
            <FontAwesomeIcon icon={faPlus} /> Добавить
          </Button>
        </div>
      </ProtectedComponent>
      <div className="schedule">
        <table className="schedule__list">
          <tbody>{data}</tbody>
        </table>
      </div>
    </>
  );
};
