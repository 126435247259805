/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import type1 from './assets/type1.png';
import type2 from './assets/type2.png';
import type3 from './assets/type3.png';
import type4 from './assets/type4.png';
import { ERRORS_TEXTS } from '../../const/validation';
import { bannersApi } from '../../effector/banners';
import { modalApi, Modals$, TModal$State } from '../../effector/modals';
import { UtmDictionary$ } from '../../effector/utmDictionary';
import { Banner, BannerType } from '../../types/banner';
import { Option } from '../../types/option';
import { Input } from '../FormControls/Input';
import { Radio } from '../FormControls/Radio';
import { Select } from '../FormControls/Select';
import { Button, ButtonType } from '../UIKit/Button';

const banners = [
  {
    id: BannerType.one,
    name: <img className="form-control__img" src={type1} alt="" />,
  },
  {
    id: BannerType.two,
    name: <img className="form-control__img" src={type2} alt="" />,
  },
  {
    id: BannerType.three,
    name: <img className="form-control__img" src={type3} alt="" />,
  },
  {
    id: BannerType.four,
    name: <img className="form-control__img" src={type4} alt="" />,
  },
];

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ERRORS_TEXTS.required),
  subtitle: Yup.string().required(ERRORS_TEXTS.required),
  buttonText: Yup.string().required(ERRORS_TEXTS.required),
  url: Yup.string().required(ERRORS_TEXTS.required),
  utm: Yup.object().required(ERRORS_TEXTS.required),
  type: Yup.string().required(ERRORS_TEXTS.required),
  order: Yup.number().required(ERRORS_TEXTS.required).positive(ERRORS_TEXTS.number).typeError(ERRORS_TEXTS.number),
});

type FormFields = {
  title: string;
  subtitle: string;
  buttonText: string;
  url: string;
  utm: Option;
  type: BannerType;
  order: number;
};

type FormProps = {
  error: string;
  isUpdate: boolean;
  loading: boolean;
} & FormikProps<FormFields>;

const initialValues = {
  title: '',
  subtitle: '',
  buttonText: '',
  url: '',
  utm: '',
  type: '',
  order: '',
};

const FormTemplate: React.FC<FormProps> = ({ values, errors, dirty, error, isUpdate, loading, setFieldValue }) => {
  const utms = useStore(UtmDictionary$);

  const disable = Object.keys(errors).length > 0 || !dirty || loading;

  return (
    <Form className="form analytic-form" noValidate>
      <div className="form__group">
        <Field type="text" name="title" placeholder="Заголовок" component={Input} />
      </div>

      <div className="form__group">
        <Field type="text" name="subtitle" placeholder="Подзаголовок" component={Input} />
      </div>

      <div className="form__group">
        <Field type="text" name="order" placeholder="Порядковый номер" component={Input} />
      </div>

      {utms && (
        <div className="form__group">
          <Field name="utm" component={Select} placeholder="Utm-метка" options={utms} />
        </div>
      )}

      <div className="form__group">
        {banners.map((item) => (
          <Field
            key={item.id}
            name="type"
            text={item.name}
            value={item.id}
            checked={values.type === item.id}
            onChange={() => setFieldValue('type', item.id)}
            component={Radio}
          />
        ))}
      </div>

      <div className="form__group">
        <Field type="text" name="buttonText" placeholder="Текст кнопки" component={Input} />
      </div>

      <div className="form__group">
        <Field type="text" name="url" placeholder="Целевой адрес" component={Input} />
      </div>

      <Button className="button__primary button__large button__wide" type={ButtonType.submit} disabled={disable}>
        {isUpdate ? 'Обновить' : 'Добавить'}
      </Button>

      {error && <p className="error-message">{error}</p>}
    </Form>
  );
};

export const BannerForm: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const utms = useStore(UtmDictionary$);
  const modalsState = useStore<TModal$State<{ banner: Banner }>>(Modals$);

  const banner = modalsState.data?.banner;

  const isUpdate = !!banner;

  // @ts-ignore
  const errorHandler = (result) => {
    // @ts-ignore
    if (result.errorMessage) {
      // @ts-ignore
      setError(result.errorMessage);
    } else {
      // @ts-ignore
      modalApi.hide('');
    }
  };

  const getCreateData = (values: FormFields) => {
    const data = {
      title: values.title,
      subtitle: values.subtitle,
      buttonText: values.buttonText,
      url: values.url?.trim(),
      utm: values.utm.id,
      type: values.type,
      order: Number(values.order),
    };

    return data;
  };

  const getUpdateData = (values: FormFields) => {
    const data = {
      ...banner,
      ...getCreateData(values),
    };

    return data;
  };

  const onSubmit = (values: FormFields) => {
    const data = isUpdate ? getUpdateData(values) : getCreateData(values);

    setLoading(true);
    bannersApi
      .add(data)
      .then(errorHandler)
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const getInitData = () => {
    if (banner) {
      const utm = utms?.find((item) => item.id === banner?.utm);

      const data: Partial<FormFields> = {
        title: banner.title,
        subtitle: banner.subtitle,
        buttonText: banner.buttonText,
        url: banner.url,
        type: banner?.type,
        order: banner.order,
        utm,
      };

      return data;
    }

    return initialValues;
  };

  const init = getInitData();

  return (
    <Formik
      // @ts-ignore
      initialValues={init}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) =>
        React.createElement(FormTemplate, {
          ...props,
          error,
          isUpdate,
          loading,
        })
      }
    </Formik>
  );
};
